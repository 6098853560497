/* @flow */
import React from 'react';

import NavBar from '@src/components/NavBar';
import NavBarTitle from '@src/components/NavBarTitle';

import styles from './AuthLayout.css';

type Props = {
  isMobile: boolean,
  header: Object,
  footer?: ?Object,
  children: Object,
  title?: string,
};

export default class AuthLayout extends React.Component {
  props: Props;
  render() {
    const hasNavBar = !!this.props.title && this.props.isMobile;
    const rootStyle = hasNavBar ?
      styles.rootNavBar :
      this.props.isMobile ?
        styles.rootMobile :
        styles.rootNotMobile;

    return (
      <div className={rootStyle}>
        {hasNavBar &&
          <NavBar isLarge={true} padding="none">
            <div className={styles.navbar}>
              <NavBarTitle>{this.props.title}</NavBarTitle>
            </div>
          </NavBar>
        }
        <div className={this.props.isMobile ? styles.containerMobile : styles.containerNotMobile}>
          {this.props.header}
          <div className={this.props.isMobile ? styles.content : styles.contentNotMobile}>
            {this.props.children}
          </div>
          {this.props.footer &&
            <div className={this.props.footer}>
              {this.props.footer}
            </div>
          }
        </div>
      </div>
    );
  }
}
