import * as React from 'react';
function SvgMoneyReceipt(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 31H11V10l3 2 3-2 3 2 3-2 3 2 3-2v21z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M17.048 25a2.471 2.471 0 002.452 2h1c1.4 0 2.5-1.1 2.5-2.5 0-3.5-6-1.5-6-5 0-1.4 1.1-2.5 2.5-2.5h1c1.228 0 2.225.846 2.452 1.999M20 16v12"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMoneyReceipt;

