"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberField = void 0;
var lodash_1 = require("lodash");
var NumberField = /** @class */ (function () {
    function NumberField(jsonBlob, format) {
        this.fieldType = 'NumberField';
        this.jsonBlob = jsonBlob;
        this.format = format;
    }
    NumberField.prototype.validate = function () {
        return null;
    };
    NumberField.prototype.isEqual = function (field) {
        return (field instanceof NumberField &&
            (0, lodash_1.isEqual)(this.jsonBlob, field.jsonBlob) &&
            this.format === field.format);
    };
    NumberField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(NumberField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return NumberField;
}());
exports.NumberField = NumberField;
