import * as React from 'react';
function SvgMagnifyingSearch01(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.56 28.439l-5.232-5.233a9.028 9.028 0 10-2.122 2.122l5.233 5.233a1.5 1.5 0 002.122-2.122zM11 18a7 7 0 117 7 7.008 7.008 0 01-7-7z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgMagnifyingSearch01;

