"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepeatTextButton = RepeatTextButton;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var lodash_1 = require("lodash");
var RepeatText_1 = require("./RepeatText");
// TODO: i18n
function RepeatTextButton(_a) {
    var onPress = _a.onPress, rruleOptions = _a.rruleOptions, isConfiguring = _a.isConfiguring, onClear = _a.onClear, disabled = _a.disabled;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var hasRruleOptions = !(0, lodash_1.isNil)(rruleOptions);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress, disabled: disabled },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingLeft: react_native_1.Platform.OS === 'web' ? 1.5 : 2, paddingRight: 1, paddingBottom: 1, style: {
                minHeight: 60,
                backgroundColor: 'transparent',
                opacity: disabled ? 0.5 : 1,
            } },
            react_1.default.createElement(react_native_1.View, { style: { marginRight: (0, components_1.grid)(1.5) } },
                react_1.default.createElement(components_1.MBIconV2, { name: "repeat", size: 35, color: colors.accent })),
            !hasRruleOptions && !isConfiguring && (react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, "Add Repeat")),
            hasRruleOptions && react_1.default.createElement(RepeatText_1.RepeatText, { rruleOptions: rruleOptions, onClear: onClear }),
            isConfiguring && !hasRruleOptions && (react_1.default.createElement(components_1.MBTextBody2Inactive, { color: colors.foregroundInactive }, "Custom Repeat")))));
}
