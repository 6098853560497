import * as React from 'react';
function SvgMoon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27 23c-5.5 0-10-4.5-10-10 0-.9.1-1.8.4-2.6-4.3 1.1-7.4 5-7.4 9.6 0 5.5 4.5 10 10 10 4.6 0 8.5-3.1 9.6-7.4-.8.3-1.7.4-2.6.4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMoon;

