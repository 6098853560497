"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateField = void 0;
exports.isDateField = isDateField;
var lodash_1 = require("lodash");
var date_1 = require("@shared/util/date");
var DateField = /** @class */ (function () {
    function DateField(date) {
        this.fieldType = 'DateField';
        this.date = date;
        this.formattedDate = (0, date_1.formatDateTimeWithYear)(date);
    }
    DateField.prototype.validate = function () {
        return null;
    };
    DateField.prototype.isEqual = function (field) {
        return field instanceof DateField && this.date === field.date;
    };
    DateField.prototype.toFieldJson = function () {
        return this.date;
    };
    Object.defineProperty(DateField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.formattedDate);
        },
        enumerable: false,
        configurable: true
    });
    return DateField;
}());
exports.DateField = DateField;
function isDateField(field) {
    return field instanceof DateField;
}
