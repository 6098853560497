import * as React from 'react';
function SvgRemoveFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 8a12 12 0 1012 12A12.035 12.035 0 0020 8zm4.95 15.536l-1.414 1.414L20 21.414l-3.536 3.536-1.414-1.414L18.586 20l-3.536-3.536 1.414-1.414L20 18.586l3.536-3.536 1.414 1.414L21.414 20l3.536 3.536z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgRemoveFilled;

