"use strict";
//#region Time Tracker Component
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTimeTrackerGeolocationCoordinates = isTimeTrackerGeolocationCoordinates;
exports.isTimeTrackerGeolocation = isTimeTrackerGeolocation;
exports.isTimeTrackerEntry = isTimeTrackerEntry;
exports.isTimeTrackerComponentDataValue = isTimeTrackerComponentDataValue;
exports.isTimeTrackerComponentData = isTimeTrackerComponentData;
function isTimeTrackerGeolocationCoordinates(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        typeof obj.lat === 'number' &&
        typeof obj.lon === 'number');
}
function isTimeTrackerGeolocation(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        (typeof obj.accuracy === 'undefined' || typeof obj.accuracy === 'number') &&
        isTimeTrackerGeolocationCoordinates(obj.coords) &&
        typeof obj.userId === 'number');
}
function isTimeTrackerEntry(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        (typeof obj.geolocation === 'undefined' || isTimeTrackerGeolocation(obj.geolocation)) &&
        typeof obj.timestamp === 'string');
}
function isTimeTrackerComponentDataValue(obj) {
    return (typeof obj === 'object' &&
        obj !== null &&
        (typeof obj.end === 'undefined' || isTimeTrackerEntry(obj.end)) &&
        isTimeTrackerEntry(obj.start));
}
function isTimeTrackerComponentData(obj) {
    return Array.isArray(obj) && obj.every(isTimeTrackerComponentDataValue);
}
//#endregion
