"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderTableRow = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var redactedCardLines_1 = require("@shared/components/blocks/redactedCardLines");
var render_1 = require("@shared/components/blocks/render");
var base_1 = require("@shared/components/card/base");
var PrintContext_1 = require("@shared/util/print/PrintContext");
var react_1 = __importStar(require("react"));
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var ChannelEventHandlers_1 = require("../../../workflows/hooks/ChannelEventHandlers");
var quickCreate_1 = require("../../list/components/quickCreate");
var hooks_1 = require("../hooks");
var MBCardRow = function (_a) {
    var columns = _a.columns, card = _a.card, props = __rest(_a, ["columns", "card"]);
    var onOpenCard = (0, ChannelEventHandlers_1.useChannelEventHandlers)().onOpenCard;
    var values = (0, react_1.useContext)(form_1.CardFormContext).values;
    var cellsToRender = (0, hooks_1.useColumnsToRender)(card, columns);
    var channelPermissions = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext);
    var printing = (0, react_1.useContext)(PrintContext_1.PrintModeContext);
    return (react_1.default.createElement(base_1.MBCardBase, __assign({}, props, { card: card, onPress: onOpenCard, rounded: false }),
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, flex: true, padding: 1, style: { minHeight: '50px' } },
            cellsToRender.map(function (_a, i) {
                var block = _a.block, width = _a.width;
                return (react_1.default.createElement(components_1.MBView, { key: i, style: { width: width } }, (0, render_1.renderBlockIfNotEmpty)(block, card, values, channelPermissions.canReadThread.enabled, channelPermissions.canCreateThread.enabled, channelPermissions.canUpdateCard.enabled, printing, undefined)));
            }),
            card.planLimitImposed && react_1.default.createElement(redactedCardLines_1.MBRedactedCardLines, { singleLine: true }))));
};
var renderTableRow = function (_a) {
    var card = _a.item, _b = _a.section, columnBlocks = _b.columnBlocks, cardBlocks = _b.cardBlocks, quickCreate = _b.quickCreate, index = _a.index;
    return (react_1.default.createElement(form_1.MBCardFormProvider, { card: card, blocks: cardBlocks, cardDefinitionId: card === null || card === void 0 ? void 0 : card.cardDefinitionId },
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 1, testID: "card-list-row-".concat(index) }, quickCreate && index === 0 ? (react_1.default.createElement(quickCreate_1.MBQuickCreate, { label: quickCreate.label, fieldOverrides: quickCreate.fieldOverrides, rounded: false })) : (react_1.default.createElement(MBCardRow, { columns: columnBlocks, card: card })))));
};
exports.renderTableRow = renderTableRow;
MBCardRow.displayName = 'MBCardRow';
