"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
// @ts-strict-ignore
var react_native_1 = require("react-native");
var default_1 = require("./default");
function host() {
    if (react_native_1.Platform.OS === 'web') {
        return "".concat(window.location.hostname, ":8080");
    }
    // Script URL is the bundle url where React Native fetches the dev bundle
    // http://localhost:8081/index.bundle?platform=ios
    var scriptURL = react_native_1.NativeModules.SourceCode.scriptURL;
    // Grab everything after "http://" until the first ":" (localhost)
    // Note(fant): with CodePush enabled, scriptURL can be a local url
    var match = scriptURL.match(/^https?:\/\/(.*):/);
    if (!match) {
        return null;
    }
    return "".concat(match[1], ":8080");
}
var getBuilderHost = function () {
    if (react_native_1.Platform.OS === 'web' && window.location.hostname === 'localhost') {
        return 'http://localhost:5173';
    }
    return 'https://staging.coastapp.com/builder';
};
exports.Config = __assign(__assign({}, default_1.Config), { API_URL: "http://".concat(host()), APP_URL: "http://".concat(host()), BUILDER_APP_URL: getBuilderHost(), WEBSOCKET_URL: "ws://".concat(host()), IMGIX_URL: 'https://coast-staging.imgix.net', SEGMENT_API_KEY: 'lMJb4Q6F71ShGZ0ikCp25YLHmqU4ZekS', ONESIGNAL_APP_ID: '42f3f83b-188c-411d-89ac-73649d6bc8be', STRIPE_API_KEY: 'pk_test_sXdfX0kUywpMgtEse5xMTcV700bQMQdSuD', SENTRY_DSN: 'https://2d0de514d80c441fa7e58b8327bd2c03@o272668.ingest.sentry.io/1486111', LAUNCH_DARKLY_CLIENT_KEY: '65b12b2fa0cf7e0fa3c90604', LAUNCH_DARKLY_MOBILE_KEY: 'mob-4b0e3098-dd77-4501-8598-76e1b0e96b92' });
