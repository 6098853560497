import * as React from 'react';
function SvgImagePortrait(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 9H11v22h18V9z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M11 28.182l5.727-5.727 2.864 2.863L24.5 20.41l4.5 4.5M17.898 18.578c1.016 0 1.84-.848 1.84-1.895 0-1.046-.824-1.894-1.84-1.894-1.017 0-1.841.848-1.841 1.894 0 1.047.824 1.895 1.84 1.895z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgImagePortrait;

