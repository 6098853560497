import * as React from 'react';
function SvgCard(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 11a2 2 0 012 2v13a2 2 0 01-2 2H11a2 2 0 01-2-2V13a2 2 0 012-2h18zM13 17h14M13 22h10"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgCard;

