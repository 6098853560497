"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CombinedTagsField = void 0;
var lodash_1 = require("lodash");
var CombinedTagsField = /** @class */ (function () {
    function CombinedTagsField(componentIds, entity) {
        this.fieldType = 'CombinedTagsField';
        this.componentIds = componentIds;
        var tags = new Map();
        componentIds === null || componentIds === void 0 ? void 0 : componentIds.forEach(function (componentId) {
            var _a;
            var value = (_a = entity.fields) === null || _a === void 0 ? void 0 : _a[componentId];
            if (!(0, lodash_1.isNil)(value)) {
                tags.set(componentId, value);
            }
        });
        this.tags = tags;
    }
    CombinedTagsField.prototype.validate = function () {
        return null;
    };
    CombinedTagsField.prototype.isEqual = function (field) {
        return field instanceof CombinedTagsField && (0, lodash_1.isEqual)(this.tags, field.tags);
    };
    CombinedTagsField.prototype.toFieldJson = function () {
        return this.tags;
    };
    Object.defineProperty(CombinedTagsField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.tags);
        },
        enumerable: false,
        configurable: true
    });
    return CombinedTagsField;
}());
exports.CombinedTagsField = CombinedTagsField;
