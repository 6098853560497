import * as React from 'react';
function SvgList(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.111 11a1.111 1.111 0 110 2.222 1.111 1.111 0 010-2.222z"
        fill={props.color}
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M18 12h13"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M12.111 19a1.111 1.111 0 110 2.222 1.111 1.111 0 010-2.222z"
        fill={props.color}
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M18 20h13"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M12.111 27a1.111 1.111 0 110 2.222 1.111 1.111 0 010-2.222z"
        fill={props.color}
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M18 28h13"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgList;

