"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.UnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
var config_1 = require("@shared/config");
exports.UnvalidatedStyles = {
    sizeTiny: { fontSize: 10 },
    sizeSmall: { fontSize: 14 },
    sizeNormal: { fontSize: 16 },
    sizeLarge: { fontSize: 18 },
    sizeXLarge: { fontSize: 28 },
    numberOfLines: function (num) {
        return react_native_1.Platform.select({
            default: {},
            web: {
                // Semi-hacky way to get a multiline numberOfLines cap.
                // TODO(fant): how well does this work across browsers?
                // https://css-tricks.com/line-clampin
                // TODO(fant): react-native-web will print errors
                // because it doesn't recognize these styles.
                display: '-webkit-box',
                WebkitLineClamp: num,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                whiteSpace: 'normal',
            },
        });
    },
};
exports.Styles = react_native_1.StyleSheet.create({
    sizeTiny: exports.UnvalidatedStyles.sizeTiny,
    sizeSmall: exports.UnvalidatedStyles.sizeSmall,
    sizeNormal: exports.UnvalidatedStyles.sizeNormal,
    sizeLarge: exports.UnvalidatedStyles.sizeLarge,
    sizeXLarge: exports.UnvalidatedStyles.sizeXLarge,
    bold: { fontWeight: 'bold' },
    light: { fontWeight: '300' },
    italic: { fontStyle: 'italic' },
    center: { textAlign: 'center' },
    default: { fontFamily: config_1.Config.DEFAULT_FONT_FAMILY },
    underline: { textDecorationLine: 'underline' },
    formattedTextTouchable: {
        maxWidth: '100%',
        // prevent MBTouchableOpacity from filling width
        alignSelf: 'flex-end',
    },
    lineThrough: {
        textDecorationLine: 'line-through',
    },
});
