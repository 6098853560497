"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
exports.Styles = react_native_1.StyleSheet.create({
    background: { backgroundColor: 'white' },
    paddingBottom: react_native_1.Platform.select({
        // This padding is to give some space and allow the "X is typing"
        // bar to be absolutely positioned at the bottom of the chat thread
        // without overlapping any messages.
        web: { paddingBottom: 20 },
        // Since the SectionList is inverted, top is at the bottom of the screen
        default: { marginTop: 20, paddingBottom: 40 },
    }),
    offlineMaxWidth: {
        maxWidth: 240,
    },
    isTypingBarBottomPosition: {
        backgroundColor: 'white',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
    },
    // Make the scrollable content min full height so that the
    // chat banner appears at the top of the screen.
    webScrollviewContentStyle: {
        minHeight: '100%',
    },
});
