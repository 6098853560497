"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeeklyRecurrencePicker = void 0;
var react_1 = __importStar(require("react"));
var rrule_1 = require("rrule");
var RecurrencePresets = __importStar(require("../../presets"));
var SelectableDays_1 = require("./SelectableDays");
var RepeatEveryLabel_1 = require("./RepeatEveryLabel");
var DescriptiveRecurrenceHeader_1 = require("./DescriptiveRecurrenceHeader");
var RRuleHelpers_1 = require("../RRuleHelpers");
var WeeklyRecurrencePicker = function (_a) {
    var _b;
    var rruleString = _a.rruleString, onChange = _a.onChange, nav = _a.nav;
    var rule = (0, react_1.useMemo)(function () { return (!!rruleString ? rrule_1.RRule.fromString(rruleString) : undefined); }, [rruleString]);
    var openIntervalPicker = (0, react_1.useCallback)(function () {
        nav.push({ name: 'selectWeeklyInterval' });
    }, []);
    var handleToggle = (0, react_1.useCallback)(function (day) {
        var prevInterval = rule === null || rule === void 0 ? void 0 : rule.options.interval;
        if ((rule === null || rule === void 0 ? void 0 : rule.options.freq) !== rrule_1.RRule.WEEKLY) {
            onChange(RecurrencePresets.weekly([day]));
        }
        else if ((0, RRuleHelpers_1.matchesWeekday)(rule, day)) {
            var otherDays = rule.options.byweekday.filter(function (d) { return d !== day.weekday; });
            if (!otherDays.length) {
                onChange(undefined);
            }
            else {
                onChange(RecurrencePresets.weekly(otherDays, prevInterval));
            }
        }
        else {
            var weekdays = __spreadArray(__spreadArray([], rule.options.byweekday, true), [day.weekday], false).sort();
            var updatedRule = RecurrencePresets.weekly(weekdays, prevInterval);
            onChange(updatedRule);
        }
        nav.replace({ name: 'weeklyRecurrence' });
    }, [rule, onChange]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !!rule && react_1.default.createElement(DescriptiveRecurrenceHeader_1.DescriptiveRecurrenceHeader, { rule: rule }),
        react_1.default.createElement(RepeatEveryLabel_1.RepeatEveryLabel, { intervalValue: (_b = rule === null || rule === void 0 ? void 0 : rule.options.interval) !== null && _b !== void 0 ? _b : 1, intervalPluralStringKey: 'card.blocks.recurrenceInput.weekly.xWeeks', isPressable: !rule, onPress: openIntervalPicker }),
        react_1.default.createElement(SelectableDays_1.SelectableDays, { rruleString: rruleString, onChange: handleToggle })));
};
exports.WeeklyRecurrencePicker = WeeklyRecurrencePicker;
