import * as React from 'react';
function SvgPenFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.707 12.293l-4-4a1 1 0 00-1.414 0L23.5 11.086l5.414 5.414 2.793-2.793a1 1 0 000-1.414zM22.086 12.5l-9.793 9.793a.999.999 0 00-.241.391l-2 6a.999.999 0 001.264 1.264l6-2a1 1 0 00.391-.241l9.793-9.793-5.414-5.414z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgPenFilled;

