"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAnalytics = void 0;
exports.useTrackEvent = useTrackEvent;
var react_1 = require("react");
var config_1 = require("@shared/config");
var sentry_1 = require("@shared/util/sentry");
/*
    Analytics wrapper for Segment on the frontend.
    This is taken from frontend/web/src/core/Segment.js
*/
exports.MBAnalytics = {
    identify: function (userId, properties) {
        if (config_1.Config.DEBUG_ANALYTICS) {
            console.warn(['MBAnalytics.identify'], userId, properties);
        }
        if (__DEV__) {
            return;
        }
        if (!!window['analytics']) {
            window['analytics'].identify(userId, properties);
        }
    },
    track: function (eventName, eventProperties) {
        if (eventProperties === void 0) { eventProperties = {}; }
        // For compatibility with older events, we suffix all events
        var eventNameWithPrefix = "MyBiz::Web::".concat(eventName);
        var eventPropertiesWithDefault = __assign({ path: window.location.pathname }, eventProperties);
        if (config_1.Config.DEBUG_ANALYTICS) {
            console.warn('[MBAnalytics]', eventNameWithPrefix, eventPropertiesWithDefault);
        }
        sentry_1.MBSentry.breadcrumb({
            message: eventName,
            category: 'analytics',
            data: eventProperties,
        });
        if (__DEV__) {
            return;
        }
        if (!!window['analytics']) {
            window['analytics'].track(eventNameWithPrefix, eventPropertiesWithDefault);
        }
    },
    screen: function (_eventName, _eventProperties) {
        if (_eventProperties === void 0) { _eventProperties = {}; }
        throw new Error('TODO(fant): implement screen tracking on web');
    },
    reset: function () {
        if (!!window['analytics']) {
            window['analytics'].reset();
        }
    },
};
function useTrackEvent(eventName) {
    return (0, react_1.useCallback)(function () { return exports.MBAnalytics.track(eventName); }, [eventName]);
}
