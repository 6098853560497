"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditRecurringEntity = useEditRecurringEntity;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useEditFields_1 = require("./useEditFields");
var useUpdateRecurringEntity_1 = require("./useUpdateRecurringEntity");
var useVisibleComponents_1 = require("../../util/useVisibleComponents");
var hooks_2 = require("@shared/util/hooks");
var EntityBatchField_1 = require("@shared/data/entity/fields/EntityBatchField");
function useEditRecurringEntity(_a) {
    var entityId = _a.entityId, recurringEntitiesScheduleId = _a.recurringEntitiesScheduleId, workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId, allOrUpcoming = _a.allOrUpcoming;
    // TODO:[sc-42885] if allOrUpcoming is 'upcoming', we need to provide a
    // fromDate to the mutation request. I assume I'll need to get that from the
    // workflowEntity, but i'm not sure which date component to use.
    var workflowEntity = (0, hooks_1.useWorkflowEntity)(entityId).workflowEntity;
    (0, hooks_2.useLog)('workflowEntity', workflowEntity);
    (0, hooks_2.useLog)('allOrUpcoming', allOrUpcoming);
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var viewTemplate = (0, hooks_1.useViewTemplate)(workflowTemplateId, viewTemplateId).viewTemplate;
    var fields = (0, useEditFields_1.useEditFields)(entityId, workflowTemplate);
    var update = (0, useUpdateRecurringEntity_1.useUpdateRecurringEntity)();
    var _b = useStatus(fields, update), status = _b[0], setStatus = _b[1];
    var visibleComponents = (0, useVisibleComponents_1.useVisibleComponents)(workflowTemplate, viewTemplate);
    var supportedComponents = useSupportedComponents({
        components: visibleComponents,
        fields: fields.fields,
    });
    var save = useSaveCallback(fields, update, workflowTemplateId, recurringEntitiesScheduleId, setStatus);
    return (0, react_1.useMemo)(function () {
        return {
            save: save,
            status: status,
            components: supportedComponents,
            fields: fields.fields,
            errors: update.errors,
            updateField: fields.updateField,
            isLoading: status === 'loading',
            isDirty: fields.isDirty,
        };
    }, [save, status, fields, update, supportedComponents]);
}
function useStatus(fields, update) {
    var _a = (0, react_1.useState)('loading'), status = _a[0], setStatus = _a[1];
    (0, react_1.useEffect)(function () {
        setStatus(function (prevStatus) {
            if (fields.loading || update.loading) {
                return 'loading';
            }
            else if (!(0, lodash_1.isEmpty)(fields.error) && !(0, lodash_1.isEmpty)(update.errors)) {
                return 'error';
            }
            else if (prevStatus !== 'success') {
                return 'input';
            }
            else {
                return prevStatus;
            }
        });
    }, [fields.error, fields.loading, update.errors, update.loading]);
    return [status, setStatus];
}
function useSaveCallback(fields, update, workflowTemplateId, recurringEntitiesScheduleId, setStatus) {
    var _this = this;
    return (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var input;
        return __generator(this, function (_a) {
            if (!workflowTemplateId) {
                return [2 /*return*/];
            }
            if (!update.loading) {
                input = {
                    fields: fields.modifiedFields,
                    workflowTemplateId: workflowTemplateId,
                    recurringEntitiesScheduleId: recurringEntitiesScheduleId,
                    // TODO: add fromDate
                };
                console.log('input', input);
                update.updateRecurringEntity(input).then(function (_fulfilled) {
                    setStatus('success');
                }, function (_error) {
                    // parse remote validation
                    setStatus('error');
                });
            }
            return [2 /*return*/];
        });
    }); }, [fields, update, workflowTemplateId, recurringEntitiesScheduleId, setStatus]);
}
function useSupportedComponents(_a) {
    var components = _a.components, fields = _a.fields;
    // filter out unsupported components and mark the batch date field as readonly
    return (0, react_1.useMemo)(function () {
        var batchDateFieldId = getBatchDateFieldId(fields);
        return components
            .filter(function (component) { return isComponentSupported(component); })
            .map(function (component) {
            if (component.id === batchDateFieldId) {
                return __assign(__assign({}, component), { readonly: true });
            }
            else {
                return component;
            }
        });
    }, [components, fields]);
}
function getBatchDateFieldId(fields) {
    var _a;
    var batchField = Object.values(fields).find(function (field) { return (field === null || field === void 0 ? void 0 : field.fieldType) === 'EntityBatch'; });
    if (!(0, EntityBatchField_1.isEntityBatchField)(batchField)) {
        return undefined;
    }
    var ref = (_a = batchField.refs) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id === batchField.id; });
    return ref === null || ref === void 0 ? void 0 : ref.dateComponentId;
}
function isComponentSupported(component) {
    switch (component.type) {
        case 'DATE':
        case 'TEXT':
            return true;
        default:
            return false;
        // other types we don't support yet
        // case 'ADDRESS':
        // case 'AUDIT_CHECKBOX':
        // case 'AUDIT_TAG':
        // case 'AUDIT_TEXT':
        // case 'COMBINED_TAGS':
        // case 'EMAIL':
        // case 'ENTITY_BATCH':
        // case 'FILE':
        // case 'GEOLOCATION':
        // case 'INPUT_BUTTON':
        // case 'NUMBER':
        // case 'PERSON':
        // case 'REFERENCED_IN':
        // case 'RELATED_CARD':
        // case 'RELATED_CARD_LOOKUP':
        // case 'SCHEDULED_AUTOMATION':
        // case 'SIGNATURE':
        // case 'STATIC_TEXT':
        // case 'SUBFORM':
        // case 'TAG':
        // case 'TIME_TRACKER':
        // case 'TODO':
    }
}
