import * as React from 'react';
function SvgMicrophoneMute(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 18v-4a4.951 4.951 0 015-5v0a4.951 4.951 0 015 5"
        stroke={props.color}
        strokeWidth={2}
      />
      <path d="M15 31h10" stroke={props.color} strokeWidth={2} strokeLinecap="square" />
      <path
        d="M20 27v4M25 15v-1a5 5 0 10-10 0v4a4.932 4.932 0 002.615 4.385M21.484 22.758a4.882 4.882 0 003.274-3.274M11 18a8.957 8.957 0 003.711 7.29M17.577 26.665a9.075 9.075 0 005.873-.34A8.962 8.962 0 0029 18"
        stroke={props.color}
        strokeWidth={2}
      />
      <path d="M11 29l18-18" stroke={props.color} strokeWidth={2} strokeLinecap="square" />
    </svg>
  );
}
export default SvgMicrophoneMute;

