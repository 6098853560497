import * as React from 'react';
function SvgFlowerDaisy(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 22a2 2 0 100-4 2 2 0 000 4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M31 20c0-1.286-2.11-2.38-4.224-2.807 1.194-1.796 1.912-4.062 1.002-4.971-.91-.91-3.175-.191-4.971 1.002C22.38 11.11 21.287 9 20 9c-1.286 0-2.38 2.11-2.807 4.224-1.796-1.194-4.062-1.912-4.971-1.002-.91.91-.191 3.175 1.002 4.971C11.11 17.62 9 18.713 9 20c0 1.286 2.11 2.38 4.223 2.807-1.193 1.796-1.91 4.062-1.001 4.971.91.91 3.175.192 4.971-1.002C17.62 28.89 18.713 31 20 31c1.286 0 2.38-2.11 2.807-4.224 1.796 1.194 4.062 1.912 4.971 1.002.91-.91.192-3.175-1.002-4.971C28.89 22.38 31 21.287 31 20z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgFlowerDaisy;

