import * as React from 'react';
function SvgScaleJustice(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 11l3 7c0 1.657-1 4-4 4s-4-2.343-4-4l5-7zm0 0h12l5 7c0 1.657-1 4-4 4s-4-2.343-4-4l3-7"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 11v19M13 30h14"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M9 18h8M23 18h8" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
    </svg>
  );
}
export default SvgScaleJustice;

