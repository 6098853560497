"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBPermissionLevelRow = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var styles_1 = require("@shared/components/list/row/styles");
var provider_1 = require("@shared/scenes/billing/upgradeModal/provider");
var accessSheet_1 = require("@shared/scenes/channel/addUsers/components/accessSheet");
var styles_2 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var PermissionsContext_1 = require("@shared/util/permissions/PermissionsContext");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var OPTIONS_MIN_WIDTH = (0, components_1.grid)(40);
var MBPermissionLevelRow = function (_a) {
    var accessLevel = _a.accessLevel, accessLevels = _a.accessLevels, onUpdateAccessLevel = _a.onUpdateAccessLevel, isDesktop = _a.isDesktop;
    var colors = (0, react_1.useContext)(styles_2.ColorThemeContext);
    var permissionsContext = (0, react_1.useContext)(PermissionsContext_1.PermissionsContext);
    var showUpgradeModal = (0, react_1.useContext)(provider_1.UpgradeModalContext).show;
    var container = (0, react_1.useRef)();
    var _b = (0, react_1.useState)(false), showingSheet = _b[0], setShowingSheet = _b[1];
    var showSheet = (0, react_1.useCallback)(function () { return setShowingSheet(true); }, []);
    var hideSheet = (0, react_1.useCallback)(function () { return setShowingSheet(false); }, []);
    var showUpgradeBanner = (0, react_1.useCallback)(function () {
        showUpgradeModal({
            title: (0, i18n_1.i18n)('channel.userList.permissionsFeatureGate.modal.title'),
            description: (0, i18n_1.i18n)('channel.userList.permissionsFeatureGate.modal.description'),
        });
        hideSheet();
    }, [showUpgradeModal, hideSheet]);
    return (react_1.default.createElement(react_native_1.View, { ref: container },
        react_1.default.createElement(components_1.MBListRow, { title: react_1.default.createElement(components_1.MBView, null,
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.input.label }, "permission level"),
                react_1.default.createElement(components_1.MBTextBody1, null, accessLevel.title)), icon: 
            /*FUTURE: use MBIconV2*/
            react_1.default.createElement(components_1.MBIcon, { source: require('@shared/assets/icons/arrows/right.png'), style: styles_1.Styles.image, color: colors.foregroundInactive }), image: react_1.default.createElement(components_1.MBIconV2, { name: "lock", size: (0, components_1.grid)(5), color: colors.accent }), onPress: showSheet }),
        showingSheet && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { initialSize: "full", onClose: hideSheet, containerRef: container, title: (0, i18n_1.i18n)('channel.userList.selectRole'), layout: {
                minHeight: 'fit-content',
                minWidth: OPTIONS_MIN_WIDTH,
                offset: { x: (0, components_1.grid)(4), y: 0 },
            } },
            react_1.default.createElement(PermissionsContext_1.PermissionsContext.Provider, { value: permissionsContext },
                react_1.default.createElement(accessSheet_1.MBAccessSheet, { accessLevel: accessLevel, accessLevels: accessLevels, style: isDesktop && { maxWidth: (0, components_1.grid)(48) }, onShowUpgradeBanner: showUpgradeBanner, onUpdateAccessLevel: onUpdateAccessLevel }))))));
};
exports.MBPermissionLevelRow = MBPermissionLevelRow;
