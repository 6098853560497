"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBReactionButtonWithPicker = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var overlay_1 = require("@shared/util/overlay");
var button_1 = require("../button");
var picker_1 = require("../picker");
var util_1 = require("./util");
/*
    Combine a reaction button with a reaction picker when pressed.
*/
var MBReactionButtonWithPicker = /** @class */ (function (_super) {
    __extends(MBReactionButtonWithPicker, _super);
    function MBReactionButtonWithPicker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showPicker = function () {
            return overlay_1.MBOverlay.show({
                parent: _this,
                position: function (_a) {
                    var y = _a.top, x = _a.left, width = _a.width, height = _a.height;
                    return (0, util_1.pickerPositionFromButtonPosition)({ x: x, y: y, width: width, height: height }, _this.props.pickerBottomMargin);
                },
                closeOnBackdropPress: true,
                // TODO(fant): figure out how to make this HOC situation nicer
                component: function (_a) {
                    var close = _a.close;
                    return (React.createElement(picker_1.MBReactionPicker, { selected: _this.props.selected, onToggleReaction: function (reactionType, add) {
                            close();
                            _this.props.onToggleReaction(reactionType, add);
                        }, testID: _this.props.testID ? "".concat(_this.props.testID, "-picker") : null }));
                },
            });
        };
        return _this;
    }
    MBReactionButtonWithPicker.prototype.render = function () {
        return React.createElement(button_1.MBReactionButton, __assign({}, this.props, { onPress: this.showPicker }));
    };
    return MBReactionButtonWithPicker;
}(React.PureComponent));
exports.MBReactionButtonWithPicker = MBReactionButtonWithPicker;
