import * as React from 'react';
function SvgFireFlame(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.182 17.966s.943-5.554-2.909-7.966a6.8 6.8 0 01-2.564 4.92c-1.646 1.448-4.742 4.696-4.709 8.169a7.962 7.962 0 004.368 7.164 5.048 5.048 0 011.765-3.487A4.112 4.112 0 0019.71 24a7.413 7.413 0 013.926 6.179v.021a7.449 7.449 0 004.336-6.463A11.574 11.574 0 0025.309 16"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgFireFlame;

