import { Config } from '@shared/config';

/* @flow */
/* eslint no-console: 0 */
type SegmentUser = {
  displayName: string,
  businessId_str: string,
};
const Segment = {
  identify(id: string, traits: SegmentUser, options: Object, callback: Function) {
    if (!!window.analytics) {
      window.analytics.identify(id, traits, options, callback);
    } else {
      console.info('[Segment] identify', id, traits, options);
      callback();
    }
  },
  page(pathname?: string, properties?: Object) {
    if (Config.DEBUG_ANALYTICS) {
      console.warn('[Segment] page', pathname, properties);
    }

    if (!!window.analytics) {
      window.analytics.page(pathname, properties);
    } else {
      if (pathname) {
        console.info('[Segment] page', pathname, properties);
      } else {
        console.info('[Segment] page', window.location.pathname);
      }
    }
  },
  track(event: string, properties: Object = {}) {
    if (!!window.analytics) {
      window.analytics.track(event, properties);
    } else {
      console.info('[Segment] track', event, properties);
    }
  },
  reset() {
    if (!!window.analytics) {
      window.analytics.reset();
    } else {
      console.info('[Segment] reset user');
    }
  },
  init() {
    if (!Config.SEGMENT_API_KEY) return;
    // Make sure the Segment SDK is loaded properl
    // https://github.com/segmentio/analytics-react#%EF%B8%8F-step-1-copy-the-snippet
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
    analytics.load(Config.SEGMENT_API_KEY);
    }}();
  }
};

export default Segment;
