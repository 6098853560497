import * as React from 'react';
function SvgAirplane(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#airplane_svg__clip0)">
        <path
          d="M21.6 14.157L14.479 9.41a2 2 0 00-2.524.25l-2.376 2.376 8.485 5.657M25.843 18.4l4.747 7.121a2 2 0 01-.25 2.524l-2.376 2.376-5.657-8.485"
          stroke={props.color}
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M13.401 23.341l-4.322-.338c-.926-.071-1.44 1.048-.784 1.704l2.605 2.586 2.501-3.952zM16.659 26.599l.338 4.322c.071.926-1.048 1.44-1.704.784L12.707 29.1l3.952-2.501z"
          fill={props.color}
        />
        <path
          d="M19.343 24.899l-7.822 4.952c-.893.536-1.908-.479-1.372-1.372l4.952-7.822 9.778-9.778a3 3 0 114.243 4.243l-9.779 9.777z"
          stroke={props.color}
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="airplane_svg__clip0">
          <path fill="#fff" transform="translate(8 8)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgAirplane;

