import * as React from 'react';
function SvgImagestack(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.127 9.125h-1v21.277H15.426l5.87 3.185 3.231 1.764.878.479.48-.878 2.49-4.55h1.01v-1.846l4.964-9.069.48-.877-.878-.479-2.55-1.39-2.015-1.1V9.125h-17.26zm17.259 8.794v6.471l2.727-4.983-1.672-.913-1.055-.575zm-2-2.123v-4.671h-14.26v12.82l3.374-3.368.707-.705.706.705 2.343 2.338 7.13-7.119zm0 2.826v9.422l-.196.358H13.127v-1.63l4.08-4.074 4.374 4.367 1.413-1.415-1.323-1.321 5.715-5.707zm-1.29 11.78h-6.477l2.634 1.43h.002l2.355 1.285 1.485-2.714z"
        fill={props.color}
      />
      <path
        d="M19.24 18.241a2.03 2.03 0 002.032-2.03c0-1.12-.91-2.028-2.033-2.028a2.03 2.03 0 00-2.032 2.029c0 1.12.91 2.029 2.032 2.029z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgImagestack;

