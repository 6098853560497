"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBLinkPreviewUtil = void 0;
/*
    Extracts www.google.com from:
    ✅ https://www.google.com
    ✅ http://www.google.com
    ✅ http://www.google.com?asd=q
    ✅ http://www.google.com#swag
    ✅ http://www.google123.co.com
    ✅ http://google.com/asd?q=true
    🛑 www.google.com
    🛑 htt://www.google.com
    🛑 ://www.google.com

    To experiment: https://www.regextester.com
*/
var baseUrlRegex = /^(https?:\/\/[a-z0-9.]*)(\/|\?|#|$)/;
var baseUrlWithoutProtocolRegex = /^https?:\/\/([a-z0-9.]*)(\/|\?|#|$)/;
exports.MBLinkPreviewUtil = {
    baseUrlFromUrl: function (url) {
        var match = url.match(baseUrlRegex);
        if (!!match) {
            return match[1];
        }
        return null;
    },
    baseUrlWithoutProtocolFromUrl: function (url) {
        var match = url.match(baseUrlWithoutProtocolRegex);
        if (!!match) {
            return match[1];
        }
        return null;
    },
};
