"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NestedOptionsRow = void 0;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var NestedOptionsRow = function (_a) {
    var defaultIcon = _a.defaultIcon, selectedIcon = _a.selectedIcon, imageUrl = _a.imageUrl, name = _a.name, _b = _a.enabled, enabled = _b === void 0 ? true : _b, _c = _a.isBoxedSelectedIcon, isBoxedSelectedIcon = _c === void 0 ? true : _c;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var textColor = colors.foregroundActive;
    var rightIcon = enabled ? 'arrow-point-right' : 'lock';
    var LeftView = function () {
        if (enabled && (0, lodash_1.isNil)(imageUrl)) {
            return react_1.default.createElement(components_1.MBIconV2, { name: defaultIcon, color: textColor, size: (0, components_1.grid)(3) });
        }
        if ((0, lodash_1.isNil)(imageUrl)) {
            if (isBoxedSelectedIcon) {
                return (react_1.default.createElement(components_1.MBBoxedIcon, { source: require("@shared/assets/icons/v2/".concat(selectedIcon, ".png")), color: colors.avatar.foreground, backgroundColor: colors.avatar.background, size: 'small' }));
            }
            else {
                return react_1.default.createElement(components_1.MBIconV2, { name: selectedIcon, color: textColor, size: (0, components_1.grid)(3) });
            }
        }
        return react_1.default.createElement(components_1.MBImage, { source: { uri: imageUrl }, style: { height: (0, components_1.grid)(3), width: (0, components_1.grid)(3) } });
    };
    return (react_1.default.createElement(components_1.MBView, { style: enabled ? styles_1.cursorPointerStyle : null },
        react_1.default.createElement(components_1.MBHover, { hoverStyle: enabled && { backgroundColor: colors.input.backgroundInactive }, centerVertically: true },
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 1, paddingHorizontal: 3 },
                react_1.default.createElement(LeftView, null),
                react_1.default.createElement(components_1.MBView, { fill: true, centerVertically: true, paddingLeft: 1 },
                    react_1.default.createElement(components_1.MBTextBody1, { color: textColor }, name)),
                react_1.default.createElement(components_1.MBIconV2, { name: rightIcon, color: textColor, size: (0, components_1.grid)(3) })))));
};
exports.NestedOptionsRow = NestedOptionsRow;
