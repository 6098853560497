import * as React from 'react';
function SvgBellMuted(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 13.001A9.953 9.953 0 0010 19v10h17.5m-13-18.351A9.95 9.95 0 0120 9c5.52 0 10 4.48 10 10v6.5M20 33c-1.66 0-3-1.34-3-3v-1h6v1c0 1.66-1.34 3-3 3z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path stroke={props.color} strokeWidth={2} d="M7.707 8.293l24 24" />
    </svg>
  );
}
export default SvgBellMuted;

