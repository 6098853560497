"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultRelatedCardSelection = void 0;
var components_1 = require("@shared/components");
var index_web_1 = require("@shared/components/scrollview/index.web");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var react_1 = __importStar(require("react"));
var divider_1 = require("../../divider");
var DefaultRelatedCardSelection = function (_a) {
    var relatedCardDefaultHooks = _a.relatedCardDefaultHooks;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.responseOptions'))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingBottom: 2 },
            react_1.default.createElement(components_1.MBSearchBar, { value: relatedCardDefaultHooks.keyword, onChangeText: relatedCardDefaultHooks.onSearch, placeholder: (0, i18n_1.i18n)('common.search') })),
        react_1.default.createElement(index_web_1.MBScrollView, { style: { maxHeight: (0, components_1.grid)(30) } },
            relatedCardDefaultHooks.loading && react_1.default.createElement(components_1.MBLoadingIndicator, { style: { height: (0, components_1.grid)(30) } }),
            !relatedCardDefaultHooks.loading && (react_1.default.createElement(react_1.default.Fragment, null,
                relatedCardDefaultHooks.keyword.length === 0 &&
                    relatedCardDefaultHooks.selectedCards.length > 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, row: true },
                        react_1.default.createElement(components_1.MBTextBody2Bold, null, relatedCardDefaultHooks.selectedCards.length + ' '),
                        react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('common.selected'))),
                    relatedCardDefaultHooks.selectedCards.map(function (card) {
                        return (react_1.default.createElement(DefaultRelatedCardSelectionRow, { key: card.id, name: card.name, isSelected: card.isSelected, onPress: function () { return relatedCardDefaultHooks.onToggleDefault(card.id); } }));
                    }),
                    react_1.default.createElement(components_1.MBView, { paddingTop: 0.5 },
                        react_1.default.createElement(divider_1.Divider, null)))),
                relatedCardDefaultHooks.keyword.length === 0 &&
                    relatedCardDefaultHooks.cardsHasMore && (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextBody2Bold, null, (0, i18n_1.i18n)('builder.defaultCard.maxCardListHelper')))),
                relatedCardDefaultHooks.cards.map(function (card) {
                    return (react_1.default.createElement(DefaultRelatedCardSelectionRow, { key: card.id, name: card.name, isSelected: card.isSelected, onPress: function () { return relatedCardDefaultHooks.onToggleDefault(card.id); } }));
                }))))));
};
exports.DefaultRelatedCardSelection = DefaultRelatedCardSelection;
var DefaultRelatedCardSelectionRow = function (_a) {
    var name = _a.name, isSelected = _a.isSelected, onPress = _a.onPress;
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
        react_1.default.createElement(components_1.MBView, { flex: true, row: true, centerVertically: true, paddingVertical: 0.25, paddingHorizontal: 3 },
            react_1.default.createElement(components_1.MBView, { style: { alignContent: 'flex-start', flexWrap: 'wrap', flex: 4 } },
                react_1.default.createElement(components_1.MBTextBody1, null, name)),
            react_1.default.createElement(components_1.MBView, { style: {
                    alignItems: 'flex-end',
                    flex: 1,
                } },
                react_1.default.createElement(components_1.MBSelectableDot, { selected: isSelected, radio: false })))));
};
