import * as React from 'react';
function SvgImageFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 9H10a1 1 0 00-1 1v20a1 1 0 001 1h20a1 1 0 001-1V10a1 1 0 00-1-1zm-12.5 5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM13 25l3-5 3 3 4-6 4 8H13z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgImageFilled;

