"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBItemType = exports.DEFAULT_TAG_GROUP = exports.ITEM_TYPES_OPTIONS = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var sheet_1 = require("./sheet");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("./hooks");
var tagOptions_1 = require("../../contexts/tagOptions");
exports.ITEM_TYPES_OPTIONS = [
    {
        value: 'questionSingleSelect',
        icon: 'list',
        label: (0, i18n_1.i18n)('channel.options.fragmentTemplates.fieldTypeLabel.questionSingleSelect'),
    },
    {
        value: 'questionTextArea',
        icon: 'pen',
        label: (0, i18n_1.i18n)('channel.options.fragmentTemplates.fieldTypeLabel.questionTextArea'),
    },
    {
        value: 'questionCheckbox',
        icon: 'check-task',
        label: (0, i18n_1.i18n)('channel.options.fragmentTemplates.fieldTypeLabel.questionCheckbox'),
    },
];
exports.DEFAULT_TAG_GROUP = 'yesnona';
var MBItemType = function (_a) {
    var itemName = _a.itemName, value = _a.value, onChange = _a.onChange, settings = _a.settings, onSettingsChange = _a.onSettingsChange, disabled = _a.disabled;
    var containerRef = (0, react_1.useRef)();
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, tagOptions_1.useTagOptionGroups)(), createNewGroup = _b.createNewGroup, availableTagGroups = _b.availableTagGroups, cleanupGroups = _b.cleanupGroups;
    var _c = (0, react_1.useState)((0, hooks_1.findTagGroupFromSettings)(availableTagGroups, settings)), tagGroup = _c[0], setTagGroup = _c[1];
    var _d = (0, react_1.useState)(tagGroup), lastTagGroup = _d[0], setLastTagGroup = _d[1];
    (0, hooks_1.useSyncSettings)({
        itemName: itemName,
        tagGroup: tagGroup,
        setSettings: onSettingsChange,
        selectedFieldType: value,
    });
    var onCreateCustom = (0, react_1.useCallback)(function () {
        var newGroup = createNewGroup();
        setLastTagGroup(tagGroup);
        setTagGroup(newGroup);
    }, [createNewGroup, tagGroup]);
    var onReturnFromCustom = (0, react_1.useCallback)(function () {
        var _a, _b;
        var customGroupIsEmpty = !((_b = (_a = availableTagGroups[tagGroup]) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.length);
        if (customGroupIsEmpty) {
            cleanupGroups();
            setTagGroup(lastTagGroup);
        }
    }, [availableTagGroups, cleanupGroups, lastTagGroup, tagGroup]);
    var selectedType = (0, react_1.useMemo)(function () {
        return (exports.ITEM_TYPES_OPTIONS.find(function (_a) {
            var _value = _a.value;
            return _value === value;
        }) || exports.ITEM_TYPES_OPTIONS[0]);
    }, [value]);
    var _e = (0, react_1.useState)(false), showingSheet = _e[0], setShowingSheet = _e[1];
    var showSheet = (0, react_1.useCallback)(function () { return setShowingSheet(true); }, []);
    var hideSheet = (0, react_1.useCallback)(function () { return setShowingSheet(false); }, []);
    var color = disabled ? colors.foregroundInactive : colors.foregroundActive;
    var buttonRightIcon = !disabled
        ? showingSheet
            ? 'arrow-point-up'
            : 'arrow-point-down'
        : undefined;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef }),
        react_1.default.createElement(components_1.MBButton, { borderColor: !disabled ? color : colors.backgroundInactive, titleColor: color, leftIcon: selectedType.icon, leftIconSize: (0, components_1.grid)(3), leftIconStyle: styles.leftIcon, title: selectedType.label, rightIcon: buttonRightIcon, rightIconSize: (0, components_1.grid)(3), rightIconStyle: styles.rightIcon, style: [styles.button, disabled && { backgroundColor: colors.backgroundInactive }], disabled: disabled, onPress: !disabled && showSheet, theme: "outline", size: "small" }),
        showingSheet && (react_1.default.createElement(sheet_1.MBItemTypeSheet, { containerRef: containerRef, onClose: hideSheet, selectedLabel: selectedType.label, selectedType: selectedType.value, availableOptions: exports.ITEM_TYPES_OPTIONS, onTypeChange: onChange, onTagGroupChange: setTagGroup, selectedTagGroup: tagGroup, onCreateCustom: onCreateCustom, onReturnFromCustom: onReturnFromCustom }))));
};
exports.MBItemType = MBItemType;
var styles = react_native_1.StyleSheet.create({
    button: {
        paddingHorizontal: (0, components_1.grid)(2),
    },
    leftIcon: {
        paddingRight: (0, components_1.grid)(0.5),
    },
    rightIcon: {
        paddingLeft: (0, components_1.grid)(0.5),
    },
});
