"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUserCollage = void 0;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var profile_1 = require("./profile");
var styles_1 = require("../styles");
/*
    A rounded image which shows multiple pictures in a grid.
*/
var MBUserCollage = function (_a) {
    var users = _a.users, _b = _a.size, size = _b === void 0 ? 'normal' : _b, styleOverride = _a.style;
    var style = [
        size === 'small' && styles_1.Styles.userSmall,
        size === 'normal' && styles_1.Styles.userNormal,
        size === 'small' && styles_1.Styles.collageSmall,
        size === 'normal' && styles_1.Styles.collageNormal,
        styleOverride,
    ];
    var smallerSize = (function () {
        if (size === 'small') {
            return 'tiny';
        }
        if (size === 'normal') {
            return 'small';
        }
        return 'small';
    })();
    if (users.length === 0) {
        return React.createElement(components_1.MBView, { fill: true, style: style });
    }
    if (users.length === 1) {
        return (React.createElement(components_1.MBView, { style: style },
            React.createElement(profile_1.MBUserImage, { user: users[0] })));
    }
    if (users.length === 2) {
        return (React.createElement(components_1.MBView, { row: true, style: style },
            React.createElement(profile_1.MBUserImage, { user: users[0], size: size }),
            React.createElement(components_1.MBSeparator, { vertical: true }),
            React.createElement(profile_1.MBUserImage, { user: users[1], size: size })));
    }
    if (users.length === 3) {
        return (React.createElement(components_1.MBView, { row: true, style: style },
            React.createElement(profile_1.MBUserImage, { size: size, user: users[0] }),
            React.createElement(components_1.MBSeparator, { vertical: true }),
            React.createElement(components_1.MBView, { fill: true },
                React.createElement(profile_1.MBUserImage, { user: users[1], size: smallerSize }),
                React.createElement(components_1.MBSeparator, null),
                React.createElement(profile_1.MBUserImage, { user: users[2], size: smallerSize }))));
    }
    // if we have 4 or more images
    return (React.createElement(components_1.MBView, { row: true, style: style },
        React.createElement(components_1.MBView, { fill: true },
            React.createElement(profile_1.MBUserImage, { user: users[0], size: smallerSize }),
            React.createElement(components_1.MBSeparator, null),
            React.createElement(profile_1.MBUserImage, { user: users[1], size: smallerSize })),
        React.createElement(components_1.MBSeparator, { vertical: true }),
        React.createElement(components_1.MBView, { fill: true },
            React.createElement(profile_1.MBUserImage, { user: users[2], size: smallerSize }),
            React.createElement(components_1.MBSeparator, null),
            React.createElement(profile_1.MBUserImage, { user: users[3], size: smallerSize }))));
};
exports.MBUserCollage = MBUserCollage;
exports.MBUserCollage.displayName = 'MBUserCollage';
