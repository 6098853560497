import * as React from 'react';
function SvgFilled(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 0c5.52 0 10 4.48 10 10s-4.48 10-10 10S0 15.52 0 10 4.48 0 10 0zm4.786 5.214a.69.69 0 00-1 0L10 9 6.214 5.214a.69.69 0 00-1 0 .69.69 0 000 1L9 10l-3.786 3.786a.69.69 0 000 1 .648.648 0 00.5.214.648.648 0 00.5-.214L10 11l3.786 3.786a.772.772 0 00.5.214.772.772 0 00.5-.214.69.69 0 000-1L11 10l3.786-3.786a.69.69 0 000-1z"
        fill={props.color}
        fillRule="nonzero"
      />
    </svg>
  );
}
export default SvgFilled;

