"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBToast = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var react_native_1 = require("react-native");
var index_1 = require("@shared/scenes/auth/contentWrapper/index");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("@shared/util/hooks");
var gestures_1 = require("@shared/components/toast/gestures");
var config_1 = require("@shared/config");
var MBToast = function (_a) {
    var options = _a.options, state = _a.state, onDismiss = _a.onDismiss, onExit = _a.onExit;
    var isDesktop = (0, index_1.useIsDesktop)();
    var buttons = (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        var btns = ((_a = options.actions) === null || _a === void 0 ? void 0 : _a.map(function (action, idx) {
            var _a, _b;
            return (react_1.default.createElement(react_native_1.TouchableOpacity, { key: idx, style: styles.action, onPress: function () {
                    action.onPress();
                    onDismiss();
                }, disabled: !action.onPress }, action.label ? (react_1.default.createElement(components_1.MBTextBody1Bold, null, (_b = (_a = action.label) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== null && _b !== void 0 ? _b : '')) : (action.element)));
        })) || [];
        if (isDesktop && options.style === 'default') {
            btns.push(react_1.default.createElement(react_native_1.TouchableOpacity, { style: styles.action, onPress: onDismiss, key: "close" }, options.layout === 'long' ? (react_1.default.createElement(components_1.MBTextBody1Bold, null, (_c = (_b = (0, i18n_1.i18n)('common.close')) === null || _b === void 0 ? void 0 : _b.toUpperCase()) !== null && _c !== void 0 ? _c : '')) : (react_1.default.createElement(components_1.MBIconV2, { name: "exit-small", color: styles_1.Colors.v2.purple, size: (0, components_1.grid)(3) }))));
        }
        return btns;
    }, [options, isDesktop, onDismiss]);
    var backgroundColor = (0, react_1.useMemo)(function () {
        switch (options.style) {
            case 'confirmation':
                return { backgroundColor: styles_1.Colors.v2.green };
            case 'warning':
                return { backgroundColor: styles_1.Colors.v2.actionTropicalRed };
            default:
                return { backgroundColor: styles_1.Colors.v2.green10 };
        }
    }, [options]);
    var textColor = (0, react_1.useMemo)(function () {
        switch (options.style) {
            case 'warning':
                return styles_1.Colors.v2.purple;
            default:
                return styles_1.Colors.v2.black;
        }
    }, [options]);
    var icon = (0, react_1.useMemo)(function () {
        if (options.icon) {
            return options.icon;
        }
        else if (options.style === 'confirmation') {
            return 'check-filled';
        }
        else if (options.style === 'warning') {
            return 'remove-x';
        }
        return null;
    }, [options]);
    var position = (0, react_1.useMemo)(function () {
        var style = {};
        if (options.style === 'confirmation' || options.style === 'warning') {
            style.top = (0, components_1.grid)(2) + styles_1.Constants.headerHeight;
        }
        else if (options.style === 'default') {
            style.bottom = (0, components_1.grid)(2);
        }
        else if (options.style === 'modal') {
            style.top = '45%';
        }
        if (isDesktop && options.style !== 'modal') {
            style.width = 'auto';
            style.right = (0, components_1.grid)(2);
        }
        return style;
    }, [options, isDesktop]);
    var opacity = (0, react_1.useRef)(new react_native_1.Animated.Value(1)).current;
    var scale = (0, react_1.useRef)(new react_native_1.Animated.Value(1)).current;
    var autoDismissTimerRef = (0, react_1.useRef)(null);
    var translateX = (0, react_1.useRef)(new react_native_1.Animated.Value(react_native_1.Dimensions.get('screen').width)).current;
    var animIn = (0, react_1.useRef)(react_native_1.Animated.timing(translateX, {
        toValue: 0,
        useNativeDriver: true,
        duration: 300,
        delay: config_1.Config.TOAST_DELAY_DURATION,
    })).current;
    var animOut = (0, react_1.useRef)(react_native_1.Animated.parallel([
        react_native_1.Animated.timing(opacity, {
            toValue: 0,
            useNativeDriver: true,
            duration: 200,
        }),
        react_native_1.Animated.timing(scale, {
            toValue: 0.9,
            useNativeDriver: true,
            duration: 200,
        }),
    ])).current;
    (0, hooks_1.useComponentDidMount)(function () {
        animIn.start();
        if (options.duration && options.style !== 'modal') {
            autoDismissTimerRef.current = setTimeout(function () {
                animIn.stop();
                onDismiss();
            }, options.duration);
        }
        return function () {
            if (autoDismissTimerRef.current) {
                clearTimeout(autoDismissTimerRef.current);
            }
        };
    });
    var prevState = (0, hooks_1.usePrevious)(state);
    (0, react_1.useEffect)(function () {
        if (prevState !== 'anim-out' && state === 'anim-out') {
            clearTimeout(autoDismissTimerRef.current);
            animIn.stop();
            animOut.start(onExit);
        }
    }, [prevState, state, animIn, animOut, onExit]);
    var panHandlers = (0, gestures_1.useToastFling)(onExit, translateX).panHandlers;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        options.style === 'modal' && (react_1.default.createElement(components_1.MBView, { style: {
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
            } })),
        react_1.default.createElement(react_native_1.Animated.View, __assign({ style: [styles.container, position, { opacity: opacity, transform: [{ scale: scale }, { translateX: translateX }] }] }, (options.style !== 'modal' && panHandlers)),
            react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: onDismiss, activeOpacity: 1, disabled: options.style === 'default' || options.style === 'modal' },
                react_1.default.createElement(components_1.MBView, { style: [styles.toast, backgroundColor], paddingHorizontal: 2 },
                    react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: styles.toastContentRow },
                        icon && (react_1.default.createElement(components_1.MBView, { paddingRight: 1 },
                            react_1.default.createElement(components_1.MBIconV2, { name: icon, color: styles_1.Colors.v2.purple, size: (0, components_1.grid)(5) }))),
                        react_1.default.createElement(components_1.MBView, { flex: true, paddingTop: 2, paddingBottom: options.layout !== 'long' && 2 },
                            react_1.default.createElement(components_1.MBTextBody1, { color: textColor }, options.message)),
                        options.layout !== 'long' && buttons),
                    options.layout === 'long' && (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: styles.toastButtonRow }, buttons)))))));
};
exports.MBToast = MBToast;
var styles = react_native_1.StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
        alignItems: 'center',
        zIndex: 1000000,
    },
    toast: {
        width: (0, components_1.grid)(43),
        maxWidth: '90%',
        minHeight: (0, components_1.grid)(7),
        borderRadius: (0, components_1.grid)(1),
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: (0, components_1.grid)(0.5),
        shadowOpacity: 0.4,
        elevation: (0, components_1.grid)(0.5),
    },
    toastContentRow: {
        minHeight: (0, components_1.grid)(7),
    },
    toastButtonRow: {
        minHeight: (0, components_1.grid)(7),
        justifyContent: 'flex-end',
    },
    action: {
        height: (0, components_1.grid)(5),
        paddingRight: (0, components_1.grid)(1),
        paddingLeft: (0, components_1.grid)(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: (0, components_1.grid)(2),
    },
});
