"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignatureField = void 0;
var lodash_1 = require("lodash");
var SignatureField = /** @class */ (function () {
    function SignatureField(jsonBlob) {
        this.fieldType = 'SignatureField';
        this.jsonBlob = jsonBlob;
    }
    SignatureField.prototype.validate = function () {
        return null;
    };
    SignatureField.prototype.isEqual = function (field) {
        return field instanceof SignatureField && this.jsonBlob === field.jsonBlob;
    };
    SignatureField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(SignatureField.prototype, "isEmpty", {
        get: function () {
            var _a;
            return (0, lodash_1.isNil)((_a = this.jsonBlob) === null || _a === void 0 ? void 0 : _a.url);
        },
        enumerable: false,
        configurable: true
    });
    return SignatureField;
}());
exports.SignatureField = SignatureField;
