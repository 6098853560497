
// const initialState: FileUploadReducer = {
const initialState = {
  filesBeingUploaded: {},
  isFileUploadCancelled: {},
};

export default function (state = initialState, action) {
// export default function (state: FileUploadReducer = initialState, action: Action) {
  switch (action.type) {
    case 'FILE_UPLOAD:ADD': {
      return {
        ...state,
        filesBeingUploaded: {
          ...state.filesBeingUploaded,
          [action.uploadId]: action.attachment,
        },
      };
    }

    case 'FILE_UPLOAD:REMOVE': {
      const filesBeingUploaded = { ...state.filesBeingUploaded };
      delete filesBeingUploaded[action.uploadId];
      return {
        ...state,
        filesBeingUploaded,
      };
    }

    case 'FILE_UPLOAD:CANCEL': {
      return {
        ...state,
        isFileUploadCancelled: {
          ...state.isFileUploadCancelled,
          [action.uploadId]: true,
        },
      };
    }

    default:
      return state;
  }
}
