import * as React from 'react';
function SvgQuestionMark(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 33c.552 0 1-.43 1-.96s-.448-.96-1-.96-1 .43-1 .96.448.96 1 .96z"
        fill={props.color}
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M15 10.108c4.081-1.732 9.362-1.526 11.166 1.276 1.803 2.802.558 6.06-2.534 8.576-3.091 2.515-4.207 3.88-4.207 5.859"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgQuestionMark;

