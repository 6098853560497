"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatInputBar = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var emojiButton_1 = require("./emojiButton");
var plusButton_1 = require("../containers/plusButton");
var attachmentButton_1 = require("../containers/attachmentButton");
var sendButton_1 = require("../containers/sendButton");
var attachmentList_1 = require("../containers/attachmentList");
var styles_2 = require("./styles");
var hooks_1 = require("@shared/scenes/upload/hooks");
var MBChatInputBar = /** @class */ (function (_super) {
    __extends(MBChatInputBar, _super);
    function MBChatInputBar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { sending: false };
        _this.canSend = function () { return !!_this.props.value.length || _this.props.hasAttachments; };
        _this.send = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.canSend()) {
                            return [2 /*return*/];
                        }
                        this.setState({ sending: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.props.onSend()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.setState({ sending: false });
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        /*
              When pressing enter on web: submit editing
              (will select current mention or send the mssage)
              When pressing the keyboard button on native: don't do anything
              (this requires pressing the actual send button)
          */
        _this.onSubmitEditing = function () {
            if (react_native_1.Platform.OS === 'web') {
                _this.props.onSubmitEditing();
            }
        };
        _this.change = function (text) {
            if (_this.state.sending) {
                return;
            }
            _this.props.onChangeText(text);
        };
        return _this;
    }
    MBChatInputBar.prototype.componentDidUpdate = function (prevProps) {
        var canSend = this.canSend();
        var couldSend = !!prevProps.value || prevProps.hasAttachments;
        if (canSend !== couldSend) {
            if (!!react_native_1.LayoutAnimation) {
                react_native_1.LayoutAnimation.configureNext(react_native_1.LayoutAnimation.Presets.easeInEaseOut);
            }
        }
    };
    MBChatInputBar.prototype.render = function () {
        var _a = this.props, value = _a.value, draftId = _a.draftId, placeholder = _a.placeholder, hideUrgent = _a.hideUrgent, isEditing = _a.isEditing, autoFocus = _a.autoFocus, onAddEmoji = _a.onAddEmoji, onPasteFiles = _a.onPasteFiles, onCursorPositionChange = _a.onCursorPositionChange, onBlur = _a.onBlur, onFocus = _a.onFocus;
        var sending = this.state.sending;
        var trimmedPlaceholder = placeholder.slice(0, 40) + '...';
        return (React.createElement(components_1.MBView, { paddingVertical: "tiny", style: styles_2.Styles.container },
            React.createElement(components_1.MBView, { row: true, wrap: true, fullWidth: true, style: { minHeight: 42, alignItems: 'flex-end' } },
                React.createElement(react_native_1.View, { style: { justifyContent: 'flex-end', flexGrow: 0, flexShrink: 0 } },
                    !isEditing && React.createElement(MBPlusButtonWrapped, { draftId: draftId, hideUrgent: hideUrgent }),
                    isEditing && React.createElement(components_1.MBView, { paddingLeft: "small" })),
                React.createElement(components_1.MBView, { flex: true, style: styles_2.Styles.inputContainer },
                    React.createElement(attachmentList_1.MBAttachmentList, { draftId: draftId }),
                    React.createElement(components_1.MBView, { row: true },
                        react_native_1.Platform.OS !== 'android' && (React.createElement(components_1.MBAutosizeTextInput, { style: { flex: 1 }, ref: this.props.innerRef, placeholder: placeholder, size: "normal", autoFocus: autoFocus, padding: "normal", value: value, onChangeText: this.change, maxRows: 10, onPasteFiles: onPasteFiles, onSubmitEditing: this.onSubmitEditing, onCursorPositionChange: onCursorPositionChange, onBlur: onBlur, onFocus: onFocus })),
                        react_native_1.Platform.OS === 'android' && (React.createElement(components_1.MBView, { style: {
                                flex: 1,
                                maxHeight: 160,
                            } },
                            React.createElement(react_native_1.TextInput, { autoFocus: autoFocus, blurOnSubmit: false, textAlignVertical: 'center', style: [
                                    components_1.MBTypographyStyles.body1,
                                    {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        marginTop: 4,
                                        marginBottom: 4,
                                    },
                                ], value: value, returnKeyType: "done", placeholder: trimmedPlaceholder, onFocus: onFocus, onChangeText: this.change, onBlur: onBlur, multiline: true, onSubmitEditing: this.onSubmitEditing }))),
                        React.createElement(emojiButton_1.MBEmojiButton, { onSelect: onAddEmoji }))),
                React.createElement(react_native_1.View, { style: { justifyContent: 'flex-end', flexGrow: 0, flexShrink: 0 } },
                    !!sending && (React.createElement(components_1.MBLoadingIndicator, { style: [styles_2.Styles.iconButton, styles_2.Styles.alignBottom], color: styles_1.Colors.v2.purple })),
                    !sending && this.canSend() && (React.createElement(sendButton_1.MBSendButton, { draftId: draftId, style: styles_2.Styles.alignBottom, onPress: this.send, testID: "chat-send-button" })),
                    !isEditing && !sending && !this.canSend() && (React.createElement(MBAttachmentButtonWrapped, { draftId: draftId })))),
            isEditing && (React.createElement(components_1.MBView, { row: true, wrap: true, style: styles_2.Styles.cancelButton },
                React.createElement(components_1.MBTouchableOpacity, { onPress: this.props.onCancelEdit },
                    React.createElement(components_1.MBText, null, " Cancel "))))));
    };
    return MBChatInputBar;
}(React.PureComponent));
exports.MBChatInputBar = MBChatInputBar;
var MBAttachmentButtonWrapped = function (_a) {
    var draftId = _a.draftId;
    var validateUploads = (0, hooks_1.useValidateUploads)({ showUpgradeModal: true });
    return React.createElement(attachmentButton_1.MBAttachmentButton, { draftId: draftId, validateUploads: validateUploads });
};
var MBPlusButtonWrapped = function (_a) {
    var draftId = _a.draftId, hideUrgent = _a.hideUrgent;
    var validateUploads = (0, hooks_1.useValidateUploads)({ showUpgradeModal: true });
    return (React.createElement(plusButton_1.MBPlusButton, { draftId: draftId, hideUrgent: hideUrgent, validateUploads: validateUploads }));
};
