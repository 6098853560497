import * as React from 'react';
function SvgEnvelope(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path stroke={props.color} strokeWidth={2} d="M9 11h22v18H9z" />
      <path
        d="M26.462 15.539L20 22l-6.461-6.461M14.461 22.923l-1.846 1.846M25.538 22.923l1.847 1.846"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgEnvelope;

