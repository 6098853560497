import * as React from 'react';
function SvgBuildingCapitol(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 20v11M30 20v11M25 20v11M20 20v11M15 20v11"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M9 31h22M9 20h22M28 20c0-4.4-3.6-8-8-8s-8 3.6-8 8M20 9v3"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgBuildingCapitol;

