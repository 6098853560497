"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformContent = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var hooks_2 = require("./hooks");
var SubformContent = function (_a) {
    var subform = _a.subform, onSelect = _a.onSelect, onClear = _a.onClear;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(subform.workflowTemplateId).workflowTemplate;
    var completedFields = (0, hooks_2.countCompletedSubformFields)(workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components, subform.data);
    return (react_1.default.createElement(components_1.MBView, { fill: true },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return onSelect(subform.workflowTemplateId); } },
            react_1.default.createElement(components_1.MBView, { style: [{ backgroundColor: colors.background }, styles.cardContainer], flex: true, padding: 2 },
                react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: { justifyContent: 'space-between' } },
                    react_1.default.createElement(components_1.MBTextBody1Bold, null, workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.name),
                    react_1.default.createElement(components_1.MBClearButton, { onPress: onClear })),
                react_1.default.createElement(components_1.MBView, { paddingTop: 1 }),
                react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('card.blocks.subformsInput.fieldsCompleted', { count: completedFields })),
                react_1.default.createElement(components_1.MBView, { paddingTop: 2 }),
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2 },
                    react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('card.blocks.subformsInput.start'), onPress: function () { return onSelect(subform.workflowTemplateId); } }))))));
};
exports.SubformContent = SubformContent;
exports.SubformContent.displayName = 'SubformContent';
var styles = react_native_1.StyleSheet.create({
    cardContainer: __assign(__assign({}, styles_1.SharedUnvalidatedStyles.shadow), { borderRadius: (0, components_1.grid)(1) }),
});
