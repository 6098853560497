import * as React from 'react';
function SvgBoldLight(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.583 19.083V9.917M11.75 9.917h10.083a4.583 4.583 0 110 9.166h-8.25M13.583 30.083v-11h9.167a5.5 5.5 0 015.5 5.5v0a5.5 5.5 0 01-5.5 5.5h-11"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgBoldLight;

