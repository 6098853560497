"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardQuantityBottomSheetList = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var row_1 = require("./row");
var header_1 = require("./header");
var RelatedCardsHeader_1 = require("../RelatedCardsHeader");
var MBCardQuantityBottomSheetList = function (_a) {
    var cards = _a.cards, editable = _a.editable, label = _a.label, onRemove = _a.onRemove, onChange = _a.onChange, onPressCard = _a.onPressCard, onEditCards = _a.onEditCards;
    var _b = (0, react_1.useState)(''), filter = _b[0], setFilter = _b[1];
    var filteredCards = (0, react_1.useMemo)(function () { return cards.filter(function (card) { return card.name.toLowerCase().includes(filter.toLowerCase()); }); }, [cards, filter]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 1, paddingTop: 2, paddingBottom: 1 },
            react_1.default.createElement(components_1.MBSearchBar, { value: filter, onChangeText: setFilter })),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2 },
            react_1.default.createElement(RelatedCardsHeader_1.RelatedCardsHeader, { label: label, onEditCards: editable ? onEditCards : undefined })),
        react_1.default.createElement(header_1.MBCardQuantityHeader, null),
        react_1.default.createElement(react_native_1.FlatList, { data: filteredCards, style: react_native_1.Platform.OS === 'web' && { maxWidth: (0, components_1.grid)(40) }, keyExtractor: function (card) { return card.cardId; }, contentContainerStyle: { paddingBottom: (0, components_1.grid)(1) }, renderItem: function (_a) {
                var card = _a.item;
                return (
                // FlatList scrolling within the bottom sheet (modal) is problematic:
                // https://stackoverflow.com/questions/57537511/how-to-scroll-flatlist-in-modal-component
                react_1.default.createElement(react_native_1.TouchableHighlight, { activeOpacity: 1 },
                    react_1.default.createElement(row_1.MBCardQuantityRow, { card: card, editable: editable, onRemove: onRemove, onChange: onChange, onPressCard: onPressCard })));
            } })));
};
exports.MBCardQuantityBottomSheetList = MBCardQuantityBottomSheetList;
