import * as React from 'react';
function SvgLogo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#281E38" d="M0 0h1024v1024H0z" />
      <path
        d="M814.704 561.951c-23.977 145.858-150.353 256.75-303.204 256.75-169.335 0-307.2-137.366-307.2-307.2 0-169.835 137.865-306.701 307.2-306.701 152.351 0 279.227 111.391 303.204 256.749h-74.927C716.799 356.652 623.391 278.228 511.5 278.228c-128.874 0-233.272 104.398-233.272 233.273 0 128.874 104.398 233.272 233.272 233.272 111.891 0 205.299-78.424 228.277-183.321h74.927v.499zm-201.304 0c-18.481 37.464-56.944 63.438-101.401 63.438-44.456 0-82.919-25.974-101.401-63.438h-79.422c21.979 78.923 94.408 136.867 180.324 136.867 85.916 0 158.345-57.944 180.324-136.867H613.4z"
        fill="#00CAAC"
      />
    </svg>
  );
}
export default SvgLogo;

