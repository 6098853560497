import * as React from 'react';
function SvgMedal(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.034 19.068L10 9h6l4 8M24.966 19.068L30 9h-6l-4 8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 31a7 7 0 100-14 7 7 0 000 14z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 20.382l1.176 2.382 2.628.382L21.902 25l.449 2.618L20 26.382l-2.351 1.236.449-2.618-1.902-1.854 2.628-.382L20 20.382z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgMedal;

