"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectableItemList = SelectableItemList;
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var lodash_1 = require("lodash");
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var i18n_1 = require("@shared/util/i18n");
function SelectableItemList(_a) {
    var selectedItem = _a.selectedItem, items = _a.items, onSelectItem = _a.onSelectItem, enabled = _a.enabled, query = _a.query, setQuery = _a.setQuery, queryPlaceholder = _a.queryPlaceholder, _b = _a.loading, loading = _b === void 0 ? false : _b, _c = _a.hasMore, hasMore = _c === void 0 ? false : _c, loadMore = _a.loadMore, helpText = _a.helpText, label = _a.label, _d = _a.showRadios, showRadios = _d === void 0 ? false : _d;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, null,
        !(0, lodash_1.isNil)(label) && (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, label))),
        !(0, lodash_1.isNil)(setQuery) && (react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 2 },
            react_1.default.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, placeholder: queryPlaceholder !== null && queryPlaceholder !== void 0 ? queryPlaceholder : (0, i18n_1.i18n)('common.search') }))),
        !(0, lodash_1.isNil)(helpText) && (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBTextBody2, { color: colors.foregroundInactive }, helpText))),
        loading && (react_1.default.createElement(react_native_1.View, { style: { height: 150, alignItems: 'center', justifyContent: 'center' } },
            react_1.default.createElement(components_1.MBLoadingIndicator, null))),
        !loading && (0, lodash_1.isEmpty)(items) && (react_1.default.createElement(components_1.MBView, { paddingBottom: 1, centerHorizontally: true, paddingHorizontal: 2 },
            react_1.default.createElement(components_1.MBView, { center: true, padding: 1 },
                react_1.default.createElement(components_1.MBIconV2, { name: "list", color: colors.accent, size: (0, components_1.grid)(7) })),
            react_1.default.createElement(components_1.MBTextBody1, { align: "center" }, (0, i18n_1.i18n)('common.empty')))),
        react_1.default.createElement(react_native_1.ScrollView, null,
            items.map(function (item) {
                return (react_1.default.createElement(components_1.MBTouchableOpacity, { key: item.id, onPress: function () { return onSelectItem(item.id); } },
                    react_1.default.createElement(components_1.MBHover, { hoverStyle: enabled && { backgroundColor: colors.input.backgroundInactive }, centerVertically: true },
                        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingHorizontal: 1.5, style: { height: 40 } },
                            showRadios && (react_1.default.createElement(react_native_1.View, { style: { marginRight: (0, components_1.grid)(2) } },
                                react_1.default.createElement(components_1.MBIconV2, { name: item.id === selectedItem ? 'check-filled' : 'circle', color: styles_1.Colors.v2.green, size: (0, components_1.grid)(5) }))),
                            !showRadios && !(0, lodash_1.isNil)(item.icon) && (react_1.default.createElement(components_1.MBView, { paddingRight: 1 },
                                react_1.default.createElement(components_1.MBIconV2, { name: item.icon, color: colors.foregroundActive, size: (0, components_1.grid)(3) }))),
                            (0, lodash_1.isNil)(item.icon) && !(0, lodash_1.isNil)(item.imageUrl) && (react_1.default.createElement(components_1.MBImage, { source: { uri: item.imageUrl }, style: { height: (0, components_1.grid)(3), width: (0, components_1.grid)(3) } })),
                            react_1.default.createElement(components_1.MBView, { fill: true, centerVertically: true, paddingLeft: 1 },
                                react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, item.label)),
                            !showRadios && item.id === selectedItem && (react_1.default.createElement(components_1.MBIconV2, { name: "check-filled", size: (0, components_1.grid)(4), color: colors.accent }))))));
            }),
            hasMore && (react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(3) } },
                react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('card.list.showMore'), size: "small", theme: "transparent", bold: false, borderColor: styles_1.Colors.v2.gray10, onPress: loadMore, style: { alignSelf: 'center' } }))))));
}
