/* @flow */
import { apolloClient } from '@shared/api/client';
import { store } from '@shared/store';
import fetchAndInsertUserIdIntoStore from '@src/redux/actions/fetchAndInsertUserIdIntoStore';

import type { RouteOptions } from '@src/types/RouterTypes';

// TODO(fant): the naming of this file makes 0 sense...

export async function updateAuth(path: RouteOptions = '/') {
  // Make sure to await the store reset. Otherwise the following issue arises:
  // https://github.com/apollographql/apollo-client/issues/2919
  await apolloClient.resetStore();
  window.location = path;
  await fetchAndInsertUserIdIntoStore(store.dispatch);
}
