import * as React from 'react';
function SvgArrowPointLeftHeavy(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 21l-9-9 9-9"
        stroke={props.color}
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgArrowPointLeftHeavy;

