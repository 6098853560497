"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilterControls = useFilterControls;
var react_1 = require("react");
var uuid = __importStar(require("@shared/util/uuid"));
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
function useFilterControls() {
    var _a = (0, ControlsProvider_1.useControlsState)(), filters = _a.filters, availableFilters = _a.availableFilters, selectedFilters = _a.selectedFilters;
    var _b = (0, ControlsProvider_1.useControlsActions)(), setSelectedFilters = _b.setSelectedFilters, setFilterOption = _b.setFilterOption;
    var onAdd = (0, react_1.useCallback)(function (filterOption) {
        var newFilterOption = __assign(__assign({}, filterOption), { uuid: uuid.v4() });
        setSelectedFilters(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newFilterOption], false); });
        setFilterOption(newFilterOption, 'addFilter');
        return newFilterOption;
    }, [setFilterOption, setSelectedFilters]);
    var onUpdate = (0, react_1.useCallback)(function (filterOption) {
        setSelectedFilters(function (prev) {
            return prev.map(function (item) { return (item.uuid === filterOption.uuid ? filterOption : item); });
        });
        setFilterOption(filterOption, 'addFilter');
        return filterOption;
    }, [setFilterOption, setSelectedFilters]);
    var onDelete = (0, react_1.useCallback)(function (filterOption) {
        setSelectedFilters(function (prev) { return prev.filter(function (item) { return item.uuid !== filterOption.uuid; }); });
        setFilterOption(filterOption, 'removeFilter');
    }, [setFilterOption, setSelectedFilters]);
    var onClearAll = (0, react_1.useCallback)(function () {
        setSelectedFilters([]);
        setFilterOption(undefined, 'removeAllFilters');
    }, [setFilterOption, setSelectedFilters]);
    var onClearAllOptions = (0, react_1.useCallback)(function (filterOption) {
        setFilterOption(filterOption, 'removeAllSelections');
    }, [setFilterOption]);
    return (0, react_1.useMemo)(function () { return ({
        onAdd: onAdd,
        onDelete: onDelete,
        onClearAll: onClearAll,
        onUpdate: onUpdate,
        onClearAllOptions: onClearAllOptions,
        selectedFilters: selectedFilters,
        availableFilters: availableFilters,
        setFilterOption: setFilterOption,
        filters: filters,
    }); }, [
        availableFilters,
        filters,
        onAdd,
        onClearAll,
        onClearAllOptions,
        onDelete,
        onUpdate,
        selectedFilters,
        setFilterOption,
    ]);
}
