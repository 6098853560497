"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnvalidatedStyles = exports.Styles = void 0;
var react_native_1 = require("react-native");
var dimensions_1 = require("@shared/util/dimensions");
exports.Styles = react_native_1.StyleSheet.create({
    container: __assign({}, react_native_1.Platform.select({
        web: {
            minWidth: 320,
            // TODO(fant): semi-hack to prevent max width from
            // overflowing the modal for smaller screens
            maxWidth: dimensions_1.MBDimensions.width > 640 ? 640 : '100vw',
            maxHeight: '95vh',
            borderRadius: 6,
        },
    })),
    rightButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: 56,
    },
    leftButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: 56,
    },
    titleContainer: {
        // Need to set z-index, otherwise the content below the title
        // (eg if it's scrollable) won't be "under" the title's drop
        // shadow (when there is one, aka when backgroundColor is set).
        zIndex: 1,
        height: 60,
    },
    titleWithShadow: {
        backgroundColor: 'white',
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
        // TODO(fant): if we use shadows more in shared components, move this to SharedStyles.
        shadowColor: 'black',
        shadowRadius: 10,
        shadowOpacity: 0.2,
    },
    titleText: {
        maxWidth: '90%',
    },
    fromBottomContainer: {
        // align content at bottom
        justifyContent: 'flex-end',
    },
    fromBottomContent: {
        backgroundColor: 'white',
    },
    fromBottomContentBorderRadius: {
        borderRadius: 10,
        overflow: 'hidden',
    },
    popupContent: {
        borderRadius: 6,
        overflow: 'hidden',
    },
});
// react-native-web complains about some styles in here when they're created
// through StyleSheet.create (eg border = string is not ok)
exports.UnvalidatedStyles = {
    webReactModalContent: {
        backgroundColor: 'transparent',
        border: 'none',
        position: 'relative',
        top: null,
        left: null,
        right: null,
        bottom: null,
        display: 'flex',
        overflow: 'visible',
        padding: 0,
        maxWidth: '90%',
    },
    webReactModalOverlay: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        // Make the modal appear over the top nav bar
        // and LayoutContainer overlays on web.
        zIndex: 900,
    },
};
