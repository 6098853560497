"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHandleSend = void 0;
var react_1 = require("react");
var react_native_1 = require("react-native");
var useHandleSend = function (scrollRef, onSend) {
    return (0, react_1.useCallback)(function (message) {
        onSend === null || onSend === void 0 ? void 0 : onSend(message);
        // scroll to bottom
        if (!!scrollRef) {
            if (react_native_1.Platform.OS === 'web') {
                scrollRef.scrollToBottom();
            }
            else {
                scrollRef.scrollToLocation({
                    itemIndex: 0,
                    sectionIndex: 0,
                });
            }
        }
    }, [scrollRef, onSend]);
};
exports.useHandleSend = useHandleSend;
