import * as React from 'react';
function SvgConstructionPaintbrush(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.021 26.879l7.071-7.071-2.121-2.122h-2.829v-2.828l-4.95-4.95-7.071 7.071"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M12.414 17.686c-1.202 1.202-1.202 3.041 0 4.243l1.414 1.414-4.172 4.172c-.707.707-.849 1.838-.283 2.687.778 1.061 2.192 1.061 3.041.212l4.243-4.243 1.414 1.414c1.202 1.202 3.041 1.202 4.243 0l.707-.707-9.9-9.899-.707.707z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgConstructionPaintbrush;

