"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    header: {
        backgroundColor: styles_1.Colors.gray.touch,
    },
    rightButtonPosition: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
    },
});
