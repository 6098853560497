import * as React from 'react';
function SvgPlusCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 20c0 6.075-4.925 11-11 11S9 26.075 9 20 13.925 9 20 9s11 4.925 11 11z"
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M16 20h8M20 16v8"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgPlusCircle;

