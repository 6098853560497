import * as React from 'react';
function SvgArrowsUpDownSwap(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15 25V10" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M11 14l4-4 4 4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M25 14v16" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M21 26l4 4 4-4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgArrowsUpDownSwap;

