import * as React from 'react';
function SvgPushPin(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.518 17.633a7.251 7.251 0 019.497.658l3.89 3.89a7.252 7.252 0 01.658 9.497L8.518 17.632z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={24.468}
        y={6.536}
        width={13}
        height={1}
        rx={0.5}
        transform="rotate(45 24.468 6.536)"
        stroke={props.color}
      />
      <path
        stroke={props.color}
        strokeWidth={2}
        d="M25.263 8.568l-8.75 8.751M31.627 14.932l-8.75 8.751M15.319 24.346l-6.806 6.806"
      />
    </svg>
  );
}
export default SvgPushPin;

