import * as React from 'react';
function SvgPalette(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 20c0-3.7 1.8-7 4.7-9 1-.7 2.7-.9 3.3 0 1 1.2-.8 3 0 4 2 2.4 6.1-2.3 11 0 3.5 1.8 3 6.3 2.6 8-1.3 4.6-5.6 8-10.6 8-6.1 0-11-4.9-11-11z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M25 22a2 2 0 100-4 2 2 0 000 4zM13 21a1 1 0 100-2 1 1 0 000 2zM15.5 26a1 1 0 100-2 1 1 0 000 2zM21 28a1 1 0 100-2 1 1 0 000 2z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPalette;

