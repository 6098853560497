import * as React from 'react';
function SvgWorldPin(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 165 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#world-pin_svg__filter0_d_25_770)">
        <path
          d="M82.5 120.229c27.591 0 49.958-22.367 49.958-49.958 0-27.591-22.367-49.959-49.958-49.959-27.591 0-49.958 22.368-49.958 49.959 0 27.591 22.367 49.958 49.958 49.958z"
          fill="#DAF6F0"
        />
        <path
          d="M32.542 70.27a49.825 49.825 0 0016.872 37.42l4.414-6.62s3.155-.908 3.834-2.13c2.13-3.83-1.651-10.502-1.651-10.502a21.62 21.62 0 00-.761-4.726c-.568-1.42-4.542-3.363-4.542-3.363s-3.833-6.245-3.833-10.078a5.715 5.715 0 015.393-5.959 9.311 9.311 0 006.104-4.4s4.826-1.28 5.961-3.266c1.136-1.987-1.276-9.07-1.276-9.07.447-1.873.43-3.826-.047-5.69a10.762 10.762 0 00-3.575-4.304 23.617 23.617 0 00-5.561-8.245A49.89 49.89 0 0032.542 70.27zM120.539 69.626c1.135 2.698-2.133 9.583-4.826 10.927a18.29 18.29 0 00-6.994 5.577c-.995 1.563-2.3 8.003-5.564 9.281-3.263 1.279-11.002 8.561-15.116 7.426-4.115-1.136-4.118-10.93-1.279-15.33 1.694-2.628-.284-8.375-.427-10.36-.143-1.985-6.104-5.677-6.104-7.664 0-3.123 6.813-11.354 6.813-11.354a24.237 24.237 0 017.284-1.42 25.768 25.768 0 014.921 2.412c3.002.224 5.961.844 8.8 1.844l3.265 2.98s8.091 2.983 9.227 5.68z"
          fill="#8DE1CF"
        />
        <g filter="url(#world-pin_svg__filter1_d_25_770)">
          <path
            d="M100.667 15.77a20.438 20.438 0 00-20.438 20.438c0 12.192 15.476 27.932 19.493 31.806a1.362 1.362 0 001.889 0c4.017-3.874 19.493-19.614 19.493-31.806a20.437 20.437 0 00-20.437-20.437z"
            fill="#FF5E84"
          />
        </g>
        <path d="M100.667 43.02a6.812 6.812 0 100-13.624 6.812 6.812 0 000 13.625z" fill="#fff" />
      </g>
      <defs>
        <filter
          id="world-pin_svg__filter0_d_25_770"
          x={26.542}
          y={9.771}
          width={115.917}
          height={120.458}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_25_770" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_25_770" result="shape" />
        </filter>
        <filter
          id="world-pin_svg__filter1_d_25_770"
          x={74.229}
          y={9.771}
          width={56.875}
          height={68.623}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_25_770" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_25_770" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgWorldPin;

