import * as React from 'react';
function SvgCheckTodo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path stroke={props.color} strokeWidth={2} d="M11 10h18v20H11z" />
      <path
        d="M25 18l-2.046 2.046-3.67 3.662L16 20.429"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path stroke={props.color} strokeWidth={2} d="M16 11h3M21 11h3" />
    </svg>
  );
}
export default SvgCheckTodo;

