import * as React from 'react';
function SvgBuildingCity(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 31H9V21h4M17 24h2M17 27h2M17 21h2M21 14v-4l10 3v18H13V17h10v14M27 27V17"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgBuildingCity;

