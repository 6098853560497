"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBButtonBlockButton = void 0;
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var form_1 = require("@shared/components/blocks/contexts/form");
var actions_1 = require("@shared/components/blocks/lib/button/actions");
var toast_1 = require("@shared/components/toast");
var hooks_1 = require("@shared/scenes/cards/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var react_1 = __importStar(require("react"));
var makeButtonProps = function (button) {
    return {
        leftIcon: button.icon,
        title: button.label,
        size: button.size || 'normal',
        theme: button.style === 'secondary' ? 'outline' : 'normal',
        style: { marginHorizontal: 4 }, // this spaces out buttons in the same row
    };
};
var MBButtonBlockButton = function (_a) {
    var button = _a.button, card = _a.card;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var templates = (0, hooks_1.useCardTemplates)(cardDefinition);
    var formContext = (0, react_1.useContext)(form_1.CardFormContext);
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var navigator = (0, useNavigator_1.useNavigator)();
    var onPress = (0, react_1.useCallback)(function (node) {
        return (0, actions_1.executeActions)(button.actions, card, channel.slug, formContext, toast, templates, navigator, node);
    }, [button.actions, card, channel, formContext, templates]);
    var props = (0, react_1.useMemo)(function () { return makeButtonProps(button); }, [button]);
    return (react_1.default.createElement(components_1.MBView, { style: { flex: 1, maxWidth: 320 } },
        react_1.default.createElement(components_1.MBButton, __assign({}, props, { onPress: onPress }))));
};
exports.MBButtonBlockButton = MBButtonBlockButton;
