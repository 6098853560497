"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUserRow = void 0;
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var styles_2 = require("./styles");
var i18n_1 = require("@shared/util/i18n");
var toString = function (user) {
    if (user.isPending) {
        return "".concat(user.fullName, " (").concat((0, i18n_1.i18n)('menu.pending').toLowerCase(), ")");
    }
    if (user.isRemoved) {
        return "".concat(user.fullName, " (").concat((0, i18n_1.i18n)('menu.deactivated').toLowerCase(), ")");
    }
    return user.fullName;
};
var MBUserRow = function (_a) {
    var title = _a.title, user = _a.user, imageShadow = _a.imageShadow, nameHighlight = _a.nameHighlight, _b = _a.bold, bold = _b === void 0 ? true : _b, otherProps = __rest(_a, ["title", "user", "imageShadow", "nameHighlight", "bold"]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBListRow, __assign({ title: (function () {
            if (!!title) {
                return title;
            }
            // If we have supplied a range to highlight the name
            // we semi-hackily return a MBHighlightedText instead.
            // The alternative would be to clone this component
            // and have a MBHighlightedUserRow component, but that
            // would have been more maintenance work than this.
            if (!!nameHighlight) {
                return (react_1.default.createElement(components_1.MBHighlightedText, { color: colors.foregroundActive, range: nameHighlight }, user.fullName));
            }
            var Type = user.isPending ? components_1.MBTextBody1Inactive : components_1.MBTextBody1;
            return (react_1.default.createElement(Type, { color: user.isPending || user.isRemoved ? colors.foregroundInactive : colors.foregroundActive, children: toString(user) }));
        })(), image: (function () {
            if (user.isPending) {
                return (react_1.default.createElement(components_1.MBBoxedIcon, { backgroundColor: colors.foregroundInactive, source: require('@shared/assets/icons/v2/person.png') }));
            }
            return (react_1.default.createElement(components_1.MBUserImage, { style: [styles_2.Styles.image, styles_2.Styles.imageRound, imageShadow && styles_2.Styles.imageWithShadow], user: user }));
        })() }, otherProps)));
};
exports.MBUserRow = MBUserRow;
exports.MBUserRow.displayName = 'MBUserRow';
