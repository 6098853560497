import * as React from 'react';
function SvgThumbUp(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 31H9V20h5" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M14 20l3-11a3 3 0 013 3v6h7.5a3 3 0 012.965 3.456l-1.077 7A3 3 0 0126.426 31H14V20z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgThumbUp;

