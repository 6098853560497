"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformItem = void 0;
var components_1 = require("@shared/components");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var useSubformOptions_1 = require("./useSubformOptions");
var SubformItem = function (_a) {
    var _b, _c;
    var subformWorkflowTemplate = _a.subformWorkflowTemplate, componentId = _a.componentId, mainWorkflowTemplateId = _a.mainWorkflowTemplateId, channelSlug = _a.channelSlug, isSelectionMode = _a.isSelectionMode, toggleSelection = _a.toggleSelection, isSelected = _a.isSelected;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var optionsRef = (0, react_1.useRef)(null);
    var _d = (0, react_1.useState)(false), isShowingRenameDialog = _d[0], setIsShowingRenameDialog = _d[1];
    var showRename = function () {
        setIsShowingRenameDialog(true);
    };
    var _e = (0, useSubformOptions_1.useSubformOptions)({
        componentId: componentId,
        subformWorkflowTemplate: subformWorkflowTemplate,
        channelSlug: channelSlug,
        workflowTemplateId: mainWorkflowTemplateId,
        onShowRename: showRename,
        onEnableMultiSelect: function () { return toggleSelection === null || toggleSelection === void 0 ? void 0 : toggleSelection(subformWorkflowTemplate); },
    }), onPressOptions = _e.showOptions, onEditSubform = _e.onEditSubform, onSubformRename = _e.onSubformRename, loading = _e.loading;
    var _f = (0, react_1.useState)(subformWorkflowTemplate === null || subformWorkflowTemplate === void 0 ? void 0 : subformWorkflowTemplate.name), subformName = _f[0], setsubformName = _f[1];
    var handlePress = function () {
        isSelectionMode ? toggleSelection === null || toggleSelection === void 0 ? void 0 : toggleSelection(subformWorkflowTemplate) : onEditSubform();
    };
    return (react_1.default.createElement(components_1.MBView, { row: true, centerHorizontally: true },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handlePress, disabled: loading, style: [
                styles.card,
                styles_1.SharedStyles.shadow,
                styles_1.SharedStyles.borderRadius,
                { backgroundColor: colors.background },
                { borderWidth: 1, borderStyle: 'solid', borderColor: 'transparent' },
                isSelected && { borderColor: styles_1.Colors.v2.green },
            ] },
            react_1.default.createElement(components_1.MBView, { padding: 2, row: true, centerVertically: true },
                react_1.default.createElement(components_1.MBIconV2, { name: "check-todo", size: (0, components_1.grid)(5), color: colors.accent }),
                react_1.default.createElement(components_1.MBView, { fill: true, style: { justifyContent: 'space-between', marginLeft: (0, components_1.grid)(2) } },
                    react_1.default.createElement(components_1.MBTextBody1Bold, null, subformName),
                    react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('workflowTemplate.subformFields', {
                        count: (_c = (_b = subformWorkflowTemplate === null || subformWorkflowTemplate === void 0 ? void 0 : subformWorkflowTemplate.components) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0,
                    }))),
                !isSelectionMode && (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPressOptions, disabled: loading, ref: optionsRef },
                    loading ? (react_1.default.createElement(components_1.MBLoadingIndicator, null)) : (react_1.default.createElement(components_1.MBIconV2, { name: "dots", color: colors.foregroundActive, size: (0, components_1.grid)(4) })),
                    isShowingRenameDialog && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { containerRef: optionsRef, layout: {
                            offset: { x: (0, components_1.grid)(1), y: (0, components_1.grid)(2) },
                            minHeight: (0, components_1.grid)(48),
                        }, onClose: function () {
                            onSubformRename(subformName);
                            setIsShowingRenameDialog(false);
                        } },
                        react_1.default.createElement(components_1.MBView, { padding: 2 },
                            react_1.default.createElement(components_1.MBTextOverlineBold, null, (0, i18n_1.i18n)('workflowTemplate.subformName')),
                            react_1.default.createElement(components_1.MBView, { paddingTop: 1 }),
                            react_1.default.createElement(components_1.TextInput, { value: subformName, onChangeText: setsubformName, autoFocus: true })))))),
                isSelectionMode && (react_1.default.createElement(react_native_1.View, null, isSelected ? (react_1.default.createElement(components_1.MBIconV2, { name: "check-box", color: colors.accent, size: 32 })) : (react_1.default.createElement(components_1.MBIconV2, { name: "check-box-empty", color: colors.input.borderInactive, size: 32 }))))))));
};
exports.SubformItem = SubformItem;
exports.SubformItem.displayName = 'SubformItem';
var styles = react_native_1.StyleSheet.create({
    card: { marginHorizontal: (0, components_1.grid)(2), marginVertical: (0, components_1.grid)(1), marginBottom: (0, components_1.grid)(1), width: '80%' },
});
