"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUpgradeModal = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var i18n_1 = require("@shared/util/i18n");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var MBUpgradeModal = function (_a) {
    var title = _a.title, description = _a.description, visible = _a.visible, header = _a.header, onClose = _a.onClose, onLearnMore = _a.onLearnMore, onUpgrade = _a.onUpgrade;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var handleUpgrade = (0, react_1.useCallback)(function () {
        onClose();
        onUpgrade();
    }, [onUpgrade, onClose]);
    var handleLearnMore = (0, react_1.useCallback)(function () {
        onClose();
        onLearnMore();
    }, [onLearnMore, onClose]);
    return (react_1.default.createElement(components_1.MBModal, { webOverlayStyle: { zIndex: 999 }, webContainerStyle: { width: '100%' }, visible: visible, onClose: onClose, padding: "none" },
        react_1.default.createElement(components_1.MBView, { center: true, paddingHorizontal: 4, paddingBottom: 4 }, header !== null && header !== void 0 ? header : react_1.default.createElement(components_1.MBIconV2, { name: "lock", color: colors.accent }),
            react_1.default.createElement(components_1.MBView, { paddingTop: 2 }),
            react_1.default.createElement(typography_1.MBTextBody1Bold, { color: colors.foregroundActive }, title),
            react_1.default.createElement(components_1.MBView, { row: true, paddingTop: 1 }),
            react_1.default.createElement(typography_1.MBTextBody2, { color: colors.foregroundActive, style: { textAlign: 'center' } }, description),
            react_1.default.createElement(components_1.MBView, { row: true, paddingTop: 4 },
                !!onLearnMore ? (react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('common.learnMore'), size: "small", theme: "outline", onPress: handleLearnMore })) : (react_1.default.createElement(components_1.MBButton, { title: !!onUpgrade ? (0, i18n_1.i18n)('common.close') : (0, i18n_1.i18n)('common.ok'), size: "small", theme: "outline", onPress: onClose })),
                react_1.default.createElement(components_1.MBView, { paddingLeft: 2 }),
                !!onUpgrade && (react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('common.upgrade'), size: "small", onPress: handleUpgrade }))))));
};
exports.MBUpgradeModal = MBUpgradeModal;
