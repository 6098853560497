import * as React from 'react';
function SvgArrowsExpand(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 9h-9v2h6.586l-6.293 6.293 1.414 1.414L29 12.414V19h2v-9a1 1 0 00-1-1zM18.707 22.707l-1.414-1.414L11 27.586V21H9v9a1 1 0 001 1h9v-2h-6.586l6.293-6.293z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgArrowsExpand;

