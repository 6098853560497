import * as React from 'react';
function SvgSnowman(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.53 21.465L31 17M13.47 21.474L9 17M22.647 13.001A4 4 0 1117.354 13"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M14 13h12M17 13V9h6v4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M23.5 17.937A6.967 6.967 0 0127 24a7 7 0 11-10.5-6.064"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgSnowman;

