import * as React from 'react';
function SvgInfo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 8C13.383 8 8 13.383 8 20s5.383 12 12 12 12-5.383 12-12S26.617 8 20 8zm2.658 18.284c-.661.26-2.952 1.354-4.272.191a1.676 1.676 0 01-.59-1.318c0-.998.328-1.868.919-3.957.104-.395.231-.907.231-1.313 0-.701-.266-.887-.987-.887-.352 0-.742.125-1.095.257l.195-.799c.787-.32 1.775-.71 2.621-.71 1.269 0 2.203.633 2.203 1.837 0 .347-.06.955-.186 1.375l-.73 2.582c-.151.522-.424 1.673-.001 2.014.416.337 1.401.158 1.887-.071l-.195.799zM21.452 16a1.5 1.5 0 11.001-3.001A1.5 1.5 0 0121.452 16z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgInfo;

