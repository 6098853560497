"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTrackEvent = useTrackEvent;
exports.useTrackEventWithCallback = useTrackEventWithCallback;
var react_1 = require("react");
var _1 = require(".");
function useTrackEvent(eventName, eventProperties) {
    return (0, react_1.useCallback)(function () { return _1.MBAnalytics.track(eventName, eventProperties); }, [eventName, eventProperties]);
}
function useTrackEventWithCallback(eventName, callback, eventProperties) {
    return (0, react_1.useCallback)(function () {
        _1.MBAnalytics.track(eventName, eventProperties);
        callback();
    }, [eventName, eventProperties, callback]);
}
