"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDashboardFavorites = useDashboardFavorites;
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var react_1 = require("react");
function useDashboardFavorites() {
    var _a, _b, _c;
    var favorites = (0, client_1.useQuery)(api_1.Queries.dashboard.listUserEntityWidgets, {
        fetchPolicy: 'network-only',
    });
    var _d = (0, client_1.useMutation)(api_1.Mutations.dashboard.createUserEntityWidgetAssociation), addFavoriteMutation = _d[0], addFavoriteLoading = _d[1].loading;
    var _e = (0, client_1.useMutation)(api_1.Mutations.dashboard.deleteUserEntityWidgetAssociation), removeFavoriteMutation = _e[0], removeFavoriteLoading = _e[1].loading;
    var addFavorite = (0, react_1.useCallback)(function (id) {
        return addFavoriteMutation({
            variables: { input: { entityWidgetId: id } },
            refetchQueries: [{ query: api_1.Queries.dashboard.listUserEntityWidgets }],
        });
    }, [addFavoriteMutation]);
    var removeFavorite = (0, react_1.useCallback)(function (id) {
        return removeFavoriteMutation({
            variables: { input: { entityWidgetId: id } },
            refetchQueries: [{ query: api_1.Queries.dashboard.listUserEntityWidgets }],
        });
    }, [removeFavoriteMutation]);
    return {
        favorites: (_c = (_b = (_a = favorites.data) === null || _a === void 0 ? void 0 : _a.listUserEntityWidgets) === null || _b === void 0 ? void 0 : _b.map(function (widget) { return widget.entityWidgetId; })) !== null && _c !== void 0 ? _c : [],
        addFavorite: addFavorite,
        removeFavorite: removeFavorite,
        loading: favorites.loading,
        updateLoading: addFavoriteLoading || removeFavoriteLoading,
    };
}
