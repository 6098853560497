import * as React from 'react';
function SvgArrowRefresh(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 20c0 5.5-4.5 10-10 10s-10-4.5-10-10 4.5-10 10-10c3.9 0 7.3 2.2 8.9 5.5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M29.8 9.7l-.8 5.9-6-.8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgArrowRefresh;

