"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAspect = void 0;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("./styles");
/*
    This view keeps a consistent aspect ratio based on its width.
    Here's the logic beind it:
    https://www.w3schools.com/howto/howto_css_aspect_ratio.asp

    Why do we need to wrap it in 3 views?
    1. to apply the view's style (eg something that can affect width)
    2. create a view with full width and padding (CSS hack to keep aspect ratio)
    3. absolutely positioned child inside CSS aspect ratio hack

*/
var MBAspect = function (_a) {
    var ratio = _a.ratio, children = _a.children, center = _a.center, otherProps = __rest(_a, ["ratio", "children", "center"]);
    return (React.createElement(components_1.MBView, __assign({}, otherProps, { center: center }),
        React.createElement(components_1.MBView, { style: [styles_1.Styles.container, { paddingTop: "".concat(100 / ratio, "%") }] },
            React.createElement(components_1.MBView, { absolute: true, center: center }, children))));
};
exports.MBAspect = MBAspect;
exports.MBAspect.displayName = 'MBAspect';
