import * as React from 'react';
function SvgWrench(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.711 12.89l-3.085 3.071-3.6-3.583 3.084-3.071A8.036 8.036 0 0024.85 9a5.624 5.624 0 00-5.141 5.733 4.222 4.222 0 00.309 1.638l-9.871 8.7a3.426 3.426 0 00-.206 4.914v0a3.292 3.292 0 004.936-.205l8.739-9.827a6.748 6.748 0 002.879.2 5.7 5.7 0 004.319-3.992 5.9 5.9 0 00-.103-3.271v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgWrench;

