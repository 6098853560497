"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTaskCard = void 0;
// @ts-strict-ignore
var schemas_1 = require("@coast-app/schemas");
var components_1 = require("@shared/components");
var blocks_1 = require("@shared/components/blocks");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var form_1 = require("@shared/components/blocks/contexts/form");
var actions_1 = require("@shared/components/blocks/lib/button/actions");
var utils_1 = require("@shared/components/blocks/utils");
var tokens_1 = require("@shared/components/blocks/utils/tokens");
var toast_1 = require("@shared/components/toast");
var hooks_1 = require("@shared/scenes/cards/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var hooks_2 = require("@shared/util/permissions/hooks");
var react_1 = __importStar(require("react"));
var base_1 = require("./base");
var isTaskOptionsV1 = function (taskOptions) { return !!taskOptions.completedSelector; };
var Icon = function (_a) {
    var completed = _a.completed, highlight = _a.highlight;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (completed) {
        return react_1.default.createElement(components_1.MBIconV2, { name: "task-filled", color: colors.accent });
    }
    if (highlight) {
        return react_1.default.createElement(components_1.MBIconV2, { name: "task", color: colors.accentInactive });
    }
    return react_1.default.createElement(components_1.MBIconV2, { name: "task", color: colors.input.borderInactive });
};
var MBTaskCard = function (_a) {
    var taskOptions = _a.taskOptions, otherProps = __rest(_a, ["taskOptions"]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var resolveCardDefinition = (0, hooks_1.useResolveCardDefinition)();
    var formContext = (0, react_1.useContext)(form_1.CardFormContext);
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var navigator = (0, useNavigator_1.useNavigator)();
    var updateCardPermission = (0, hooks_2.usePermission)({
        action: 'update',
        subject: 'Card',
        channelId: channel === null || channel === void 0 ? void 0 : channel.id,
    });
    var _b = (0, react_1.useState)(false), isHovered = _b[0], setIsHovered = _b[1];
    var _c = (0, react_1.useState)(false), loading = _c[0], setLoading = _c[1];
    var fields = (0, react_1.useMemo)(function () {
        var _a;
        return (0, utils_1.extractAllFields)(otherProps.card, (_a = resolveCardDefinition(otherProps.card.cardDefinitionId)) === null || _a === void 0 ? void 0 : _a.definition);
    }, [otherProps.card, resolveCardDefinition]);
    var completed = (0, react_1.useMemo)(function () {
        if (isTaskOptionsV1(taskOptions)) {
            return (0, schemas_1.evaluateSelector)(taskOptions.completedSelector, fields, tokens_1.defaultEvaluationContext);
        }
        else {
            var tags = fields[taskOptions.tagName];
            return tags === null || tags === void 0 ? void 0 : tags.includes(taskOptions.completedTagSlug);
        }
    }, [taskOptions, fields]);
    var handleToggle = (0, react_1.useCallback)(function (node) { return __awaiter(void 0, void 0, void 0, function () {
        var actions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    actions = (function () {
                        if (isTaskOptionsV1(taskOptions)) {
                            return completed ? taskOptions.onIncompleteActions : taskOptions.onCompleteActions;
                        }
                        else {
                            var newTagSlug = completed ? taskOptions.openTagSlug : taskOptions.completedTagSlug;
                            return [
                                {
                                    type: 'actionSetValues',
                                    values: [{ name: taskOptions.tagName, value: [newTagSlug] }],
                                },
                                { type: 'actionSubmitForm' },
                                {
                                    type: 'actionToast',
                                    toastOptions: {
                                        icon: 'check-filled',
                                        message: (0, i18n_1.i18n)('card.list.cardUpdated'),
                                        buttons: [
                                            {
                                                label: (0, i18n_1.i18n)('card.list.viewCard'),
                                                actions: [{ type: 'actionNavContext', context: 'detail' }],
                                            },
                                        ],
                                    },
                                },
                            ];
                        }
                    })();
                    if (!(actions === null || actions === void 0 ? void 0 : actions.length)) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    setLoading(true);
                    // TODO(fant): add support to navigate to a template
                    // from a task card by hooking up UI templates
                    return [4 /*yield*/, (0, actions_1.executeActions)(actions, otherProps.card, channel === null || channel === void 0 ? void 0 : channel.slug, formContext, toast, [
                        /* templates */
                        ], navigator, node)];
                case 2:
                    // TODO(fant): add support to navigate to a template
                    // from a task card by hooking up UI templates
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [completed, taskOptions, toast, channel, formContext, otherProps.card, navigator]);
    var disabled = !updateCardPermission.enabled || loading || otherProps.card.planLimitImposed;
    return (react_1.default.createElement(base_1.MBCardBase, __assign({}, otherProps),
        react_1.default.createElement(components_1.MBView, { row: true },
            react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: disabled, onPress: handleToggle, style: { alignSelf: 'flex-start' } },
                react_1.default.createElement(components_1.MBHover, { padding: 0.25, onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); } },
                    react_1.default.createElement(components_1.MBView, null,
                        react_1.default.createElement(Icon, { completed: completed, highlight: isHovered && !disabled }),
                        loading && (react_1.default.createElement(components_1.MBLoadingIndicator, { absolute: true, center: true, color: colors.accent, style: { backgroundColor: styles_1.Colors.overlay.white70 } }))))),
            react_1.default.createElement(components_1.MBView, { fill: true },
                react_1.default.createElement(blocks_1.MBCardBlocks, { card: otherProps.card, blocks: otherProps.blocks })))));
};
exports.MBTaskCard = MBTaskCard;
