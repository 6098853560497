/* @flow */

const storageUnavailableInMemoryCache = {};
let canUseLocalStorage = true;

function setItem(key, value) {
  if (canUseLocalStorage) {
    try {
      return localStorage.setItem(key, value);
    } catch (ex) {
      console.error('LocalStorage not supported'); // eslint-disable-line no-console
      console.error(ex); // eslint-disable-line no-console
      canUseLocalStorage = false;
    }
  }

  storageUnavailableInMemoryCache[key] = value;
  return undefined;
}

function getItem(key) {
  if (canUseLocalStorage) {
    return localStorage.getItem(key);
  }
  return storageUnavailableInMemoryCache[key];
}

function tryParse(value) {
  try {
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
}

export function setToken(token) {
  return setItem('authToken', token);
}

export function getToken() {
  return getItem('authToken');
}

export function setBizToken(token) {
  setToken('');
  return setItem('authBizToken', token);
}

export function getBizToken() {
  return getItem('authBizToken');
}

export function setUserPushToken(token) {
  return setItem('userPushToken', JSON.stringify(token));
}

export function getUserPushToken() {
  const token = getItem('userPushToken');
  return token ? tryParse(token) : null;
}

export function setCurrentBusinessId(businessId) {
  return setItem('currentBusinessId', JSON.stringify(businessId));
}

export function getCurrentBusinessId() {
  return tryParse(getItem('currentBusinessId') || 'null');
}

function getPendingKey(channelSlug) {
  return `notifyPendingUsers:${channelSlug}`;
}

export function setNotifyPendingUsers(channelSlug, notify) {
  const now = new Date();
  return setItem(getPendingKey(channelSlug), JSON.stringify({ date: now, notify }));
}

export function getNotifyPendingUsers(channelSlug) {
  const notifyPendingUsers = getItem(getPendingKey(channelSlug));
  if (notifyPendingUsers) {
    return tryParse(notifyPendingUsers);
  }

  return null;
}
