"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    root: {
        width: '100%',
    },
    image: {
        width: 44,
        height: 44,
    },
    imageMargin: {
        marginRight: 16,
    },
    imageRound: {
        borderRadius: 22,
        overflow: 'hidden',
    },
    imageWithShadow: __assign(__assign({}, styles_1.SharedUnvalidatedStyles.shadow), { width: 44, height: 44, borderRadius: 22, overflow: 'hidden', borderWidth: 3, borderColor: 'white' }),
    dotContainer: {
        borderRadius: 10,
        width: 20,
        height: 20,
        padding: 0,
    },
    deselectedDotContainer: {
        borderColor: styles_1.Colors.gray.border,
        borderWidth: 1,
    },
    selectedDotContainer: {
        borderColor: styles_1.Colors.v2.green,
        borderWidth: 2,
    },
    selectedDotInside: {
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: styles_1.Colors.v2.green,
    },
    notificationBadge: {
        position: 'absolute',
        top: -2,
        right: -8,
    },
    highlight: __assign(__assign({}, styles_1.SharedUnvalidatedStyles.borderRadius), { marginHorizontal: 4, marginVertical: 1, overflow: 'hidden' }),
});
