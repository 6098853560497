"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUnhandledError = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var restart_1 = require("@shared/util/restart");
var linking_1 = require("@shared/util/linking");
var config_1 = require("@shared/config");
var sentry_1 = require("@shared/util/sentry");
var analytics_1 = require("@shared/util/analytics");
var i18n_1 = require("@shared/util/i18n");
var storeHelpers_1 = require("@shared/scenes/auth/guards/storeHelpers");
var IMAGE_ASPECT = 0.9;
var MBUnhandledError = /** @class */ (function (_super) {
    __extends(MBUnhandledError, _super);
    function MBUnhandledError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    MBUnhandledError.prototype.componentDidCatch = function (error, errorInfo) {
        console.error(error);
        try {
            sentry_1.MBSentry.exception({ error: error, data: { caughtBy: 'MBUnhandledError', errorInfo: errorInfo } });
            analytics_1.MBAnalytics.track('UnhandledException::UnchartedWatersPrompt', { error: error, errorInfo: errorInfo });
        }
        catch (e) {
            console.log(e);
        }
        this.setState({ error: error, errorInfo: errorInfo });
    };
    MBUnhandledError.prototype.handleButton = function () {
        restart_1.MBRestart.restart();
    };
    MBUnhandledError.prototype.handleSupport = function () {
        linking_1.MBLinking.open(config_1.Config.CONTACT_EMAIL_URL +
            "?subject=Uncharted+Waters&body=".concat(JSON.stringify({
                error: String(this.state.error),
                stack: this.state.error.stack,
            })));
    };
    MBUnhandledError.prototype.handleStore = function () {
        var link = (0, storeHelpers_1.getStoreLink)();
        react_native_1.Linking.openURL(link);
    };
    MBUnhandledError.prototype.render = function () {
        // This is used here because the redux state isn't available here and we only need a simple breakpoint
        var isDesktop = react_native_1.Dimensions.get('screen').width >= 900;
        if (this.state.error) {
            return (react_1.default.createElement(components_1.MBView, { center: true, flex: true },
                react_1.default.createElement(components_1.MBImage, { source: require('@shared/assets/images/v2/ship.png'), style: {
                        width: isDesktop ? (0, components_1.grid)(23) : (0, components_1.grid)(10), // taken directly from Figma
                        height: isDesktop ? (0, components_1.grid)(23) * IMAGE_ASPECT : (0, components_1.grid)(10) * IMAGE_ASPECT,
                        marginBottom: (0, components_1.grid)(5),
                    } }),
                react_1.default.createElement(components_1.MBView, { paddingBottom: 1 }, isDesktop ? (react_1.default.createElement(components_1.MBTextH4, null, (0, i18n_1.i18n)('errors.unhandled.heading'))) : (react_1.default.createElement(components_1.MBTextSubtitle, null, (0, i18n_1.i18n)('errors.unhandled.heading')))),
                react_1.default.createElement(components_1.MBView, { paddingBottom: 4 },
                    react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('errors.unhandled.subHeading'))),
                react_1.default.createElement(components_1.MBView, { paddingBottom: 4 },
                    react_1.default.createElement(components_1.MBButton, { onPress: this.handleButton.bind(this), title: (0, i18n_1.i18n)('errors.unhandled.reloadButton'), theme: "outline", style: { width: 170 } })),
                react_1.default.createElement(components_1.MBView, { row: isDesktop, center: true },
                    react_1.default.createElement(components_1.MBView, { style: { marginRight: (0, components_1.grid)(1), marginTop: (0, components_1.grid)(1) } },
                        react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('errors.unhandled.helpMessage'))),
                    react_1.default.createElement(components_1.MBView, { row: true, style: { marginTop: (0, components_1.grid)(1) } },
                        react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: this.handleSupport.bind(this) },
                            react_1.default.createElement(components_1.MBTextBody2Bold, null, (0, i18n_1.i18n)('errors.unhandled.helpLink'))),
                        react_native_1.Platform.OS !== 'web' && (react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 0.5 },
                                react_1.default.createElement(components_1.MBTextBody2, null, "or")),
                            react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: this.handleStore.bind(this) },
                                react_1.default.createElement(components_1.MBTextBody2Bold, null, (0, i18n_1.i18n)('errors.unhandled.storeLink', { storeName: (0, storeHelpers_1.getStoreName)() })))))))));
        }
        return this.props.children;
    };
    return MBUnhandledError;
}(react_1.PureComponent));
exports.MBUnhandledError = MBUnhandledError;
