"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    badge: {
        minWidth: 18,
        height: 18,
        borderRadius: 9,
        backgroundColor: styles_1.Colors.v2.red,
        // add some horizontal padding so that eg "99+" won't
        // be capped at the edges because of border radius
        paddingHorizontal: 3,
    },
});
