/* @flow */
import { throttle } from 'lodash';

let hiddenKey;
let visibilityChangeKey;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hiddenKey = 'hidden';
  visibilityChangeKey = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hiddenKey = 'msHidden';
  visibilityChangeKey = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hiddenKey = 'webkitHidden';
  visibilityChangeKey = 'webkitvisibilitychange';
}

type VisibilityInfo = { isHidden: boolean };
type OnChangeCallback = (VisibilityInfo) => void;
type RemoveEventListenerCallback = () => void;

export function getVisibility(): VisibilityInfo {
  // $FlowFixMe
  return { isHidden: document[hiddenKey] };
}

function onChangeFactory(callback) {
  return () => callback(getVisibility());
}

export function addOnVisibilityChange(callback: OnChangeCallback): RemoveEventListenerCallback {
  const throttledCallback = throttle(callback, 100);
  const onVisChange = onChangeFactory(throttledCallback);
  const onBlur = () => throttledCallback({ isHidden: true });
  const onFocus = () => throttledCallback({ isHidden: false });

  document.addEventListener(visibilityChangeKey, onVisChange, false);
  window.addEventListener('focus', onFocus);
  window.addEventListener('blur', onBlur);
  return function removeEventListener() {
    document.removeEventListener(visibilityChangeKey, onVisChange);
    window.removeEventListener('focus', onFocus);
    window.removeEventListener('blur', onBlur);
  };
}
