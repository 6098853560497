"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.frequencyLabels = exports.useRrulePresets = exports.MAX_COUNT = void 0;
exports.getUTCDate = getUTCDate;
exports.useCustomRrule = useCustomRrule;
var lodash_1 = require("lodash");
var react_1 = require("react");
var rrule_1 = __importStar(require("rrule"));
var rrule_2 = require("rrule/dist/esm/src/rrule");
function getUTCDate(date) {
    var year = date.getUTCFullYear();
    var month = date.getUTCMonth();
    var day = date.getUTCDate();
    var hour = date.getUTCHours();
    var minute = date.getUTCMinutes();
    var second = 0;
    return new Date(Date.UTC(year, month, day, hour, minute, second));
}
exports.MAX_COUNT = 60;
var useRrulePresets = function (_a) {
    var date = _a.date;
    return (0, react_1.useCallback)(function (_a) {
        var frequency = _a.frequency, _b = _a.includeAllFrequencies, includeAllFrequencies = _b === void 0 ? false : _b, count = _a.count;
        var dateObj = !(0, lodash_1.isNil)(date) ? new Date(date) : undefined;
        var presets = [];
        if ((0, lodash_1.isNil)(dateObj)) {
            throw new Error('Date is required');
        }
        if ((0, lodash_1.isNil)(frequency) && !includeAllFrequencies) {
            throw new Error('Either frequency or includeAllFrequencies must be provided');
        }
        var sharedOptions = getSharedOptions(date, count);
        // every day
        if (frequency === rrule_1.Frequency.DAILY || includeAllFrequencies) {
            presets.push(__assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.DAILY }));
        }
        // every week on specific day
        if (frequency === rrule_1.Frequency.WEEKLY || includeAllFrequencies) {
            presets.push(__assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.WEEKLY, byweekday: getDayOfWeek(dateObj) }));
        }
        if (frequency === rrule_1.Frequency.MONTHLY || includeAllFrequencies) {
            var nthWeekdayOfMonth = getNthWeekdayOfMonth(dateObj);
            var weekday = Object.keys(rrule_2.Days)[getDayOfWeek(dateObj)];
            if (nthWeekdayOfMonth < 5) {
                // every month on nth weekday (e.g., 2nd Monday)
                presets.push(__assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.MONTHLY, byweekday: [rrule_1.default[weekday].nth(nthWeekdayOfMonth)] }));
            }
            // every month on specific day (e.g., 7th)
            presets.push(__assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.MONTHLY, bymonthday: dateObj.getUTCDate() }));
        }
        // yearly on specific month and day
        if (frequency === rrule_1.Frequency.YEARLY || includeAllFrequencies) {
            presets.push(__assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.YEARLY, bymonth: [dateObj.getUTCMonth() + 1], bymonthday: [dateObj.getUTCDate()] }));
        }
        // every weekday
        if (frequency === rrule_1.Frequency.WEEKLY || includeAllFrequencies) {
            presets.push(__assign(__assign({}, sharedOptions), { freq: rrule_1.Frequency.WEEKLY, byweekday: [rrule_1.default.MO, rrule_1.default.TU, rrule_1.default.WE, rrule_1.default.TH, rrule_1.default.FR] }));
        }
        return presets;
    }, [date]);
};
exports.useRrulePresets = useRrulePresets;
function useCustomRrule(_a) {
    var date = _a.date;
    if ((0, lodash_1.isNil)(date)) {
        throw new Error('Date is required');
    }
    return (0, react_1.useCallback)(function (_a) {
        var frequency = _a.frequency, count = _a.count;
        var sharedOptions = getSharedOptions(date, count);
        return __assign(__assign({}, sharedOptions), { freq: frequency });
    }, [date]);
}
function getSharedOptions(date, count) {
    var dateObj = !(0, lodash_1.isNil)(date) ? new Date(date) : undefined;
    if ((0, lodash_1.isNil)(dateObj)) {
        throw new Error('Date is required');
    }
    var dtstart = !(0, lodash_1.isNil)(date) ? getUTCDate(dateObj) : undefined;
    return { dtstart: dtstart, wkst: rrule_1.default.SU, count: count };
}
function getDayOfWeek(date) {
    return date.getUTCDay() - 1;
}
function getNthWeekdayOfMonth(date) {
    return Math.ceil(date.getUTCDate() / 7);
}
exports.frequencyLabels = (_a = {},
    _a[rrule_1.default.DAILY] = 'Daily',
    _a[rrule_1.default.WEEKLY] = 'Weekly',
    _a[rrule_1.default.MONTHLY] = 'Monthly',
    _a[rrule_1.default.YEARLY] = 'Yearly',
    _a);
