"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBToastProvider = exports.ToastContext = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var toast_1 = require("./toast");
var config_1 = require("@shared/config");
var uuid = __importStar(require("@shared/util/uuid"));
exports.ToastContext = react_1.default.createContext(null);
var applyDefaults = function (options) {
    return __assign(__assign({}, options), { duration: typeof options.duration === 'number' ? options.duration : config_1.Config.TOAST_DEFAULT_DURATION, layout: options.layout || 'short', style: options.style || 'default' });
};
var useToastQueue = function () {
    var _a = (0, react_1.useState)([]), renderQueue = _a[0], setRenderQueue = _a[1];
    var queue = (0, react_1.useRef)([]);
    var process = (0, react_1.useRef)(function () {
        if (queue.current.length > 0 && queue.current[0].state === 'pending') {
            // Show the first pending toast in the queue, this starts the lifecycle sequence
            queue.current[0].state = 'active';
        }
        setRenderQueue(__spreadArray([], queue.current, true));
    }).current;
    var updateToastState = (0, react_1.useRef)(function (toastId, state) {
        for (var i = 0; i < queue.current.length; i++) {
            if (queue.current[i].uuid === toastId) {
                queue.current[i].state = state;
                break;
            }
        }
        process();
    }).current;
    var removeToast = (0, react_1.useRef)(function (toastId) {
        for (var i = 0; i < queue.current.length; i++) {
            if (queue.current[i].uuid === toastId) {
                queue.current.splice(i, 1);
                break;
            }
        }
        process();
    }).current;
    var createToast = (0, react_1.useRef)(function (options) {
        var newToast = {
            options: applyDefaults(options),
            state: 'pending',
            uuid: uuid.v4(),
        };
        if (options.prioritize) {
            queue.current.forEach(function (_, i) {
                queue.current[i].state = 'pending';
            });
            queue.current.unshift(newToast);
        }
        else {
            queue.current.push(newToast);
        }
        process();
    }).current;
    var toasts = (0, react_1.useMemo)(function () {
        return renderQueue
            .filter(function (toast) { return toast.state !== 'pending'; })
            .map(function (toast) { return (react_1.default.createElement(toast_1.MBToast, { key: toast.uuid, options: toast.options, onDismiss: function () { return updateToastState(toast.uuid, 'anim-out'); }, state: toast.state, onExit: function () { return removeToast(toast.uuid); } })); });
    }, [renderQueue, updateToastState, removeToast]);
    var clear = (0, react_1.useCallback)(function () { return setRenderQueue([]); }, []);
    return {
        toasts: toasts,
        createToast: createToast,
        clear: clear,
    };
};
var MBToastProvider = function (_a) {
    var _b, _c;
    var children = _a.children;
    var messageToasts = useToastQueue();
    var actionToasts = useToastQueue();
    var modalToasts = useToastQueue();
    var show = (0, react_1.useCallback)(function (options) {
        if (options.style === 'confirmation' || options.style === 'warning') {
            messageToasts.createToast(options);
        }
        else if (options.style === 'modal') {
            modalToasts.createToast(options);
        }
        else {
            actionToasts.createToast(options);
        }
    }, [messageToasts.createToast, modalToasts.createToast, actionToasts.createToast]);
    var value = (0, react_1.useMemo)(function () { return ({
        show: show,
        clearAll: function () {
            messageToasts.clear();
            actionToasts.clear();
            modalToasts.clear();
        },
    }); }, [show, messageToasts.clear, actionToasts.clear, modalToasts.clear]);
    return (react_1.default.createElement(exports.ToastContext.Provider, { value: value },
        children,
        !((_b = modalToasts.toasts) === null || _b === void 0 ? void 0 : _b.length) && messageToasts.toasts,
        !((_c = modalToasts.toasts) === null || _c === void 0 ? void 0 : _c.length) && actionToasts.toasts,
        modalToasts.toasts));
};
exports.MBToastProvider = MBToastProvider;
