"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardTableContainer = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var render_1 = require("@shared/components/blocks/contexts/render");
var sectionHeader_1 = require("./components/sectionHeader");
var cardRow_1 = require("./components/cardRow");
var v2_1 = require("../list/containers/v2");
var sectionFooter_1 = require("../list/components/sectionFooter");
var CollectionViewMetrics_1 = require("../collection/CollectionViewMetrics");
exports.MBCardTableContainer = (0, react_1.memo)(function MBCardTableContainer(_a) {
    var template = _a.template;
    (0, react_1.useEffect)(function () {
        (0, CollectionViewMetrics_1.trackCollectionViewLoad)(CollectionViewMetrics_1.CollectionViewType.LIST);
    }, []);
    return (react_1.default.createElement(render_1.CardRenderContext.Provider, { value: "table" },
        react_1.default.createElement(v2_1.MBCardListContainer, { template: template, renderSectionHeader: sectionHeader_1.renderSectionHeaderForTable, renderItem: cardRow_1.renderTableRow, renderSectionFooter: sectionFooter_1.renderCardListSectionFooter })));
});
exports.MBCardTableContainer.displayName = 'MBCardTableContainer';
