import * as React from 'react';
function SvgDumbbell(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 22.25h12M14 17.75h12"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M11.75 32.75A2.25 2.25 0 019.5 30.5v-21a2.25 2.25 0 012.25-2.25v0A2.25 2.25 0 0114 9.5v21a2.25 2.25 0 01-2.25 2.25zM5.75 13.25v13.5M5.75 20H3.5M26 30.5v-21a2.25 2.25 0 014.5 0v21a2.25 2.25 0 01-4.5 0zM34.25 26.75v-13.5M36.5 20h-2.25"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgDumbbell;

