"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowAuditCheckbox = void 0;
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var react_1 = __importStar(require("react"));
var WorkflowAuditCheckbox = function (_a) {
    var checked = _a.checked, component = _a.component, onChange = _a.onChange;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var handleChange = (0, react_1.useCallback)(function () {
        onChange(!checked);
    }, [checked, onChange]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { style: { marginBottom: (0, components_1.grid)(2) }, row: true },
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handleChange }, checked ? (react_1.default.createElement(components_1.MBIconV2, { name: "check-filled", color: colors.accent })) : (react_1.default.createElement(components_1.MBIconV2, { name: "check-task", color: colors.input.borderInactive }))),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1.25, flex: true, paddingLeft: 1 },
                react_1.default.createElement(components_1.MBTextBody1, { flex: true }, component === null || component === void 0 ? void 0 : component.label)))));
};
exports.WorkflowAuditCheckbox = WorkflowAuditCheckbox;
exports.WorkflowAuditCheckbox.displayName = 'WorkflowAuditCheckbox';
