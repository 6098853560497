/* @flow */

import { updateAuth } from '@src/core/Store';
import { setToken } from '@src/core/AuthTokenManager';

type AuthResponse = {
  token: string,
  businessId: number,
  sendToWelcome: boolean
};

export default function authenticate(createOrLoginAPIResponse: AuthResponse) {
  setToken(createOrLoginAPIResponse.token);
  updateAuth('/');
}
