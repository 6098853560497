import * as React from 'react';
function SvgPotion(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.873 23.451A7 7 0 0020 22a7 7 0 01-8.873-1.451"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M15 9h10"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M17 9v4.523a9 9 0 106 0V9"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path d="M17.5 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill={props.color} />
    </svg>
  );
}
export default SvgPotion;

