/* @flow */
import React from 'react';

import styles from './Loading.css';

type Props = {|
  isCentered?: boolean,
  isWhite?: boolean,
|};

export default function Loading({ isCentered, isWhite }: Props) {
  return (
    <div
      className={`${isCentered ? styles.absolute : styles.static} ${isWhite ? styles.white : ''}`}
    />
  );
}
