import * as React from 'react';
function SvgExclamationFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 8C13.383 8 8 13.383 8 20s5.383 12 12 12 12-5.383 12-12S26.617 8 20 8zm1.645 5L21 22h-2l-.608-9h3.253zM20 28a2 2 0 11-.001-3.999A2 2 0 0120 28z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgExclamationFilled;

