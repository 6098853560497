import * as React from 'react';
function SvgLinkAdd(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.673 23.89l-5.564-5.563 4.173-4.173a3.929 3.929 0 015.564 0 3.929 3.929 0 010 5.564l-4.173 4.173zM14.155 30.846a3.929 3.929 0 010-5.564l4.172-4.173 5.564 5.564-4.173 4.173a3.929 3.929 0 01-5.563 0zM25.977 19.023l-6.954 6.954"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M9 14h8M13 10v8"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgLinkAdd;

