import * as React from 'react';
function SvgDotsTall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 20a2 2 0 10-4 0 2 2 0 004 0zM22 29a2 2 0 10-4 0 2 2 0 004 0zM22 11a2 2 0 10-4 0 2 2 0 004 0z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgDotsTall;

