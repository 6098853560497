"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelFragmentTemplateList = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var i18n_1 = require("@shared/util/i18n");
var summary_1 = require("./summary");
var listWrapper_1 = require("@shared/components/wrapper/listWrapper");
var MBChannelFragmentTemplateList = function (_a) {
    var templates = _a.templates, onPressCreate = _a.onPressCreate, onPressTemplate = _a.onPressTemplate, error = _a.error;
    var _b = (0, react_1.useState)(''), query = _b[0], setQuery = _b[1];
    var filteredTemplates = (0, react_1.useMemo)(function () {
        return templates === null || templates === void 0 ? void 0 : templates.filter(function (_a) {
            var name = _a.name;
            return name.toLowerCase().includes(query.toLowerCase());
        });
    }, [templates, query]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(listWrapper_1.MBListLoadingWrapper, { loading: !templates, error: error, empty: !(templates === null || templates === void 0 ? void 0 : templates.length), renderEmptyOptions: {
                title: (0, i18n_1.i18n)('channel.options.fragmentTemplates.emptyStateTitle'),
                description: (0, i18n_1.i18n)('channel.options.fragmentTemplates.emptyStateDescription'),
            }, renderData: function () { return (react_1.default.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag", style: { height: '100%' } },
                react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 1 },
                    react_1.default.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, placeholder: (0, i18n_1.i18n)('channel.create.searchUsersPlaceholder') })),
                filteredTemplates.map(function (template) { return (react_1.default.createElement(summary_1.MBFragmentTemplateSummary, { key: template.id, template: template, onPress: onPressTemplate })); }))); } }),
        templates && !templates.length && (react_1.default.createElement(components_1.MBView, { center: true, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBButton, { leftIconSize: (0, components_1.grid)(3), onPress: onPressCreate, title: (0, i18n_1.i18n)('channel.options.fragmentTemplates.createTitle'), leftIcon: "plus", size: "small" })))));
};
exports.MBChannelFragmentTemplateList = MBChannelFragmentTemplateList;
exports.MBChannelFragmentTemplateList.displayName = 'MBChannelFragmentTemplateList';
