import * as React from 'react';
function SvgFaceSmile(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 31c6.075 0 11-4.925 11-11S26.075 9 20 9 9 13.925 9 20s4.925 11 11 11z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M15.5 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM24.5 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill={props.color}
      />
      <path d="M17 23a3 3 0 006 0" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
    </svg>
  );
}
export default SvgFaceSmile;

