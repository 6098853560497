"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.UnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
exports.UnvalidatedStyles = {
    listHeight: 120,
};
exports.Styles = react_native_1.StyleSheet.create({
    removeButtonPosition: {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'white',
        width: 24,
        height: 24,
        margin: 4,
        borderRadius: 12,
    },
    removeButtonIcon: {
        width: 24,
        height: 24,
        padding: 2,
    },
});
