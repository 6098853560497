import * as React from 'react';
function SvgMoneyBag(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17 14l-3-5h12l-3 5" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M23 14h-6s-6 6.364-6 11c0 3.091 2.251 6 9 6 6.749 0 9-2.909 9-6 0-4.636-6-11-6-11z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M22.999 24.868c.01.348-.047.695-.166 1.013-.119.317-.297.598-.521.82-.556.506-1.228.795-1.925.829V29h-.818v-1.442c-.878 0-1.747-.21-2.557-.617v-1.893c.4.23.82.412 1.25.545a5.71 5.71 0 001.306.28v-2.224l-.401-.186a4.397 4.397 0 01-1.676-1.216 2.63 2.63 0 01-.49-1.625 2.484 2.484 0 01.171-.95c.118-.298.291-.558.507-.76.55-.49 1.208-.772 1.891-.812V17h.818v1.069a5.84 5.84 0 012.474.66l-.561 1.678a5.593 5.593 0 00-1.916-.559v2.116a8.56 8.56 0 011.658.932c.293.22.54.516.723.868.159.336.24.718.233 1.104zm-4.2-4.246a.8.8 0 00.044.284c.031.09.078.17.138.236.176.177.375.319.59.42v-1.686c-.515.091-.772.34-.772.746zm2.402 4.354a.756.756 0 00-.053-.282.641.641 0 00-.15-.227 2.587 2.587 0 00-.61-.431v1.786c.542-.11.813-.392.813-.846z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgMoneyBag;

