import * as React from 'react';
function SvgPartyCelebrate(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 30l5-15 10 10-15 5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M18 13l1-3M27 22l3-1M25 13a2 2 0 110-4 2 2 0 010 4zM31 11a6 6 0 01-6 6"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
      />
      <path
        d="M12.762 21.714l2.21 6.629M15.786 15.786l3.685 11.057"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgPartyCelebrate;

