"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateEntityFields = useCreateEntityFields;
var react_1 = require("react");
var CardField_1 = require("@shared/data/cards/CardField");
var lodash_1 = __importStar(require("lodash"));
function useCreateEntityFields(workflowTemplate, viewTemplate) {
    var _a = (0, react_1.useState)({}), fields = _a[0], setFields = _a[1];
    var updateField = function (componentId, field) {
        // Could do local validation here
        setFields(function (previousFields) {
            var newFields = __assign({}, previousFields);
            newFields[componentId] = field;
            return newFields;
        });
    };
    var isDirty = (0, react_1.useMemo)(function () {
        return Object.values(fields).some(function (field) { return !(field === null || field === void 0 ? void 0 : field.isEmpty); });
    }, [fields]);
    (0, react_1.useEffect)(function () {
        var fieldsBuilder = {};
        workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.forEach(function (component) {
            var _a, _b, _c;
            var fieldOverrides = (_b = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) {
                return option.componentId === component.id;
            })) === null || _b === void 0 ? void 0 : _b.overrides;
            var defaultData = null;
            if (!lodash_1.default.isNil(component) &&
                !lodash_1.default.isNil(fieldOverrides) &&
                'default' in component &&
                'default' in fieldOverrides) {
                defaultData = (_c = fieldOverrides.default) !== null && _c !== void 0 ? _c : component.default;
            }
            var builtField = (0, CardField_1.buildCardField)(null, component, {
                personRefs: null,
                workflowEntityRefs: null,
                workflowTemplateRefs: null,
                recurringEntitiesScheduleRefs: null,
            }, defaultData !== null && defaultData !== void 0 ? defaultData : null);
            if (!(0, lodash_1.isNil)(builtField)) {
                fieldsBuilder[component.id] = builtField;
            }
        });
        setFields(fieldsBuilder);
    }, [viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions, workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components]);
    return {
        fields: fields,
        isDirty: isDirty,
        updateField: updateField,
    };
}
