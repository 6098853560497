import * as React from 'react';
function SvgChat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 9C13.928 9 9 13.62 9 19.313c0 3.34.688 5.5 3.438 8.25L20 33.75v-4.125c6.072 0 11-4.62 11-10.313C31 13.62 26.072 9 20 9z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgChat;

