"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBReturnToBottomButton = void 0;
var React = __importStar(require("react"));
var ms = __importStar(require("milliseconds"));
var i18n_1 = require("@shared/util/i18n");
var styles_1 = require("@shared/styles");
var animation_1 = require("@shared/components/animation");
var components_1 = require("@shared/components");
var styles_2 = require("./styles");
var MBReturnToBottomButton = function (_a) {
    var onPress = _a.onPress;
    return (React.createElement(animation_1.MBAnimation, { opacity: { from: 0, to: 1 }, translateY: { from: 20, to: 0 }, delay: ms.seconds(1), style: styles_2.Styles.position },
        React.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
            React.createElement(components_1.MBView, { fill: true, center: true, paddingBottom: "small" },
                React.createElement(components_1.MBView, { row: true, centerVertically: true, paddingHorizontal: "normal", style: [styles_2.Styles.container, styles_1.SharedStyles.shadow] },
                    React.createElement(components_1.MBIcon, { color: styles_1.Colors.v2.purple, noPadding: true, size: "small", source: require('@shared/assets/icons/arrows/down-border.png') }),
                    React.createElement(components_1.MBView, { paddingLeft: "small" }),
                    React.createElement(components_1.MBText, { bold: true, color: styles_1.Colors.v2.purple }, (0, i18n_1.i18n)('chat.returnToBottom')))))));
};
exports.MBReturnToBottomButton = MBReturnToBottomButton;
