import * as React from 'react';
function SvgArrowsCollapse(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30 10l-8 8" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M10 22h8v8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M10 30l8-8" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M22 10v8h8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgArrowsCollapse;

