import * as React from 'react';
function SvgImageSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 24h-21C.6 24 0 23.4 0 22.5v-21C0 .6.6 0 1.5 0h21c.9 0 1.5.6 1.5 1.5v21c0 .9-.6 1.5-1.5 1.5zM3 21h18V3H3v18z"
        fill={props.color}
      />
      <path
        d="M9.5 6c.9 0 1.5.6 1.5 1.5S10.4 9 9.5 9 8 8.4 8 7.5 8.6 6 9.5 6zM5 18l3-6 3 3 4.5-6 4.5 9H5z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgImageSmall;

