import * as React from 'react';
function SvgBell(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 28H10V18c0-5.52 4.48-10 10-10s10 4.48 10 10v10zM20 32c-1.66 0-3-1.34-3-3v-1h6v1c0 1.66-1.34 3-3 3z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgBell;

