import * as React from 'react';
function SvgHeartBroken(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 11.7L17 17l5 3-2 4" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M25 9a6.068 6.068 0 00-5 2.7A5.993 5.993 0 009 15c0 6 11 15 11 15s11-9 11-15a6.018 6.018 0 00-6-6v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgHeartBroken;

