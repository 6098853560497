import * as React from 'react';
function SvgAvatar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 17.25C29.761 12.501 25.387 8.991 20.173 9c-5.918.01-10.931 4.748-11.164 10.56C8.757 25.84 13.855 31 20.182 31c4.868 0 9-3.063 10.533-7.333"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M13.659 28.938c.121-3.438 2.991-6.188 6.523-6.188 3.15 0 5.787 2.2 6.393 5.124.075.34.112.697.13 1.054M20.182 20c1.544 0 2.796-1.231 2.796-2.75s-1.252-2.75-2.796-2.75c-1.544 0-2.795 1.231-2.795 2.75S18.638 20 20.181 20z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgAvatar;

