import * as React from 'react';
function SvgMobileAdd(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.182 30h-6.364A1.819 1.819 0 0113 28.182V11.818A1.819 1.819 0 0114.818 10h10.91a1.818 1.818 0 011.817 1.818V20M27.546 24.545V30M24.818 27.273h5.455"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMobileAdd;

