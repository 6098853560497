"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewAllRelatedCards = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var RelatedCardsList_1 = require("../RelatedCardsList");
var react_native_1 = require("react-native");
var ViewAllRelatedCards = function (_a) {
    var block = _a.block, cards = _a.cards, onEditCards = _a.onEditCards, onRemoveCard = _a.onRemoveCard, onPressCard = _a.onPressCard;
    var searchInputRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var cardsToRender = (0, react_1.useMemo)(function () {
        if (searchTerm) {
            return cards.filter(function (card) { var _a; return (_a = card.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchTerm.toLowerCase()); });
        }
        else {
            return cards;
        }
    }, [cards, searchTerm]);
    return (react_1.default.createElement(components_1.MBView, { padding: 2, fill: true },
        react_1.default.createElement(components_1.MBSearchBar, { style: { paddingLeft: (0, components_1.grid)(2), paddingBottom: (0, components_1.grid)(1) }, forwardRef: searchInputRef, value: searchTerm, onChangeText: setSearchTerm, returnKeyType: "go", autoFocus: react_native_1.Platform.OS === 'web' }),
        react_1.default.createElement(components_1.MBView, { flex: true },
            react_1.default.createElement(RelatedCardsList_1.RelatedCardsList, { totalCardsNubmer: cards.length, cards: cardsToRender, block: block, onEditCards: onEditCards, onPressCard: onPressCard, onRemoveCard: onRemoveCard })),
        react_native_1.Platform.OS !== 'web' && react_1.default.createElement(components_1.MBView, { paddingBottom: 1 })));
};
exports.ViewAllRelatedCards = ViewAllRelatedCards;
exports.ViewAllRelatedCards.displayName = 'ViewAllRelatedCards';
