"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBToolLinkRow = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var actionSheet_1 = require("@shared/util/actionSheet");
var alert_1 = require("@shared/util/alert");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var linking_1 = require("@shared/util/linking");
var MBToolLinkRow = /** @class */ (function (_super) {
    __extends(MBToolLinkRow, _super);
    function MBToolLinkRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.openLink = function () { return linking_1.MBLinking.open(_this.props.link.url); };
        _this.confirmAndDelete = function () {
            return alert_1.MBAlert.confirm({
                title: 'Delete Link',
                message: "Remove \"".concat(_this.props.link.name, "\" from the list?"),
                confirmText: 'Yes, remove',
                confirmStyle: 'destructive',
                onConfirm: function () { return _this.props.onDelete(_this.props.link); },
            });
        };
        _this.onOptions = function (node) {
            return actionSheet_1.MBActionSheet.show({
                title: _this.props.link.url,
                parent: node,
                webHorizontalAlignment: 'right',
                options: [
                    {
                        title: 'Open Link',
                        onPress: _this.openLink,
                    },
                    {
                        title: 'Delete Link',
                        hidden: !_this.props.onDelete,
                        destructive: true,
                        onPress: _this.confirmAndDelete,
                    },
                ],
            });
        };
        return _this;
    }
    MBToolLinkRow.prototype.render = function () {
        var _a = this.props, link = _a.link, showOptions = _a.showOptions;
        return (React.createElement(components_1.MBListRow, { title: link.name, onPress: this.openLink, isHidingPressableIndicator: true, image: React.createElement(components_1.MBIcon, { source: require('@shared/assets/icons/v2/link.png'), color: styles_1.Colors.v2.purple, noPadding: true }), icon: showOptions && (React.createElement(components_1.MBTouchableOpacity, { onPress: this.onOptions },
                React.createElement(components_1.MBIcon, { source: require('@shared/assets/icons/v2/dots.png'), color: styles_1.Colors.v2.gray30, noPadding: true }))) }));
    };
    return MBToolLinkRow;
}(React.PureComponent));
exports.MBToolLinkRow = MBToolLinkRow;
