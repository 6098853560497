"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noCodeValidate = void 0;
// @ts-strict-ignore
var WorkflowEmailComponent_1 = require("@shared/scenes/workflows/views/view-components/WorkflowEmailComponent");
var i18n_1 = require("@shared/util/i18n");
var noCodeValidate = function (_a) {
    var componentType = _a.componentType, value = _a.value;
    var isValid = true;
    var errorMessage = null;
    switch (componentType) {
        case 'EMAIL':
            if (!(0, WorkflowEmailComponent_1.validateEmail)(value)) {
                isValid = false;
                errorMessage = (0, i18n_1.i18n)('workflowTemplate.component.error.email.invalid');
            }
            break;
        default:
            break;
    }
    return {
        isValid: isValid,
        errorMessage: errorMessage,
    };
};
exports.noCodeValidate = noCodeValidate;
