import * as React from 'react';
function SvgStackCards(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.833 15.833L20 12.5l9.167 3.333L20 19.167l-9.167-3.334zM29.167 20.833L20 24.167l-9.167-3.334M29.167 25.833L20 29.167l-9.167-3.334"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgStackCards;

