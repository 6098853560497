import * as React from 'react';
function SvgAvatar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85 35C80.495 17.732 64.59 4.969 45.629 5 24.11 5.033 5.879 22.267 5.032 43.4 4.118 66.233 22.654 85 45.662 85c17.702 0 32.728-11.137 38.3-26.667"
        stroke="#DAF6F0"
        strokeWidth={10}
        strokeMiterlimit={10}
      />
      <path
        d="M21.942 77.5C22.382 65 32.819 55 45.662 55c11.454 0 21.044 8 23.247 18.633.27 1.234.406 2.534.474 3.834M45.663 45c5.614 0 10.166-4.477 10.166-10s-4.552-10-10.166-10c-5.615 0-10.166 4.477-10.166 10s4.551 10 10.166 10z"
        stroke="#DAF6F0"
        strokeWidth={10}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgAvatar;

