"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CARDS_PER_GROUP_PAGE = exports.CARDS_PER_PAGE = void 0;
exports.useUpdateList = useUpdateList;
// @ts-strict-ignore
var api_1 = require("@shared/api");
var lodash_1 = require("lodash");
var client_1 = require("@apollo/client");
var useCardIsInFilterGroup_1 = require("./useCardIsInFilterGroup");
exports.CARDS_PER_PAGE = 50;
exports.CARDS_PER_GROUP_PAGE = 10;
var TOTAL_RESULTS_COUNT_UPDATE_DELAY_MS = 2500;
var getNewCardsList = function (existingCards, incomingCardGroup, getCardIsInFilterGroup) {
    var entities = __spreadArray([], existingCards.list.entities, true);
    var totalResults = existingCards.list.totalResults;
    incomingCardGroup === null || incomingCardGroup === void 0 ? void 0 : incomingCardGroup.forEach(function (card) {
        var isCardInFilterGroup = getCardIsInFilterGroup(card);
        var existingCardIndex = entities.findIndex(function (c) { return c.id === card.id; });
        var cardExistsInGroup = existingCardIndex > -1;
        // matches filters and is already in group
        if (cardExistsInGroup && isCardInFilterGroup) {
            entities[existingCardIndex] = __assign({}, card);
            // matches filters but is not in group
        }
        else if (!cardExistsInGroup && isCardInFilterGroup) {
            totalResults += 1;
            entities = __spreadArray([__assign({}, card)], entities, true);
            // is in group but doesn't match filters
        }
        else if (cardExistsInGroup) {
            totalResults = Math.max(0, totalResults - 1);
            entities = __spreadArray([], entities.filter(function (nc) { return nc.id !== card.id; }), true);
        }
    });
    return {
        entities: entities,
        totalResults: totalResults,
    };
};
function useUpdateList(variables) {
    var getCardIsInFilterGroup = (0, useCardIsInFilterGroup_1.useCardIsInFilterGroup)();
    var onUpdate = function (options) {
        if ((0, lodash_1.isNil)(options.data.data)) {
            return;
        }
        var workflowTemplateId = variables.input.workflowTemplateId;
        var _a = options.data.data.event, type = _a.type, cards = _a.entities, refs = _a.refs;
        var incomingWorkflowEntityRefs = refs.workflowEntityRefs, incomingPersonRefs = refs.personRefs, incomingRecurringEntitiesScheduleRefs = refs.recurringEntitiesScheduleRefs, incomingWorkflowTemplateRefs = refs.workflowTemplateRefs;
        var client = options.client;
        var getNewCards = function () {
            var groupedCards = (0, lodash_1.groupBy)(cards, 'workflowTemplateId');
            return groupedCards[workflowTemplateId];
        };
        var getExistingCards = function () {
            return client.readQuery({
                query: api_1.Queries.workflow.listWorkflowEntities,
                variables: variables,
            });
        };
        var updateList = function (data) {
            return client.writeQuery({
                query: api_1.Queries.workflow.listWorkflowEntities,
                variables: variables,
                data: data,
            });
        };
        var mergeRefs = function (existingCards, refName, incomingRefs) {
            var existingRefs = existingCards.list.refs[refName] || [];
            var mergedRefs = __spreadArray([], existingRefs, true);
            incomingRefs === null || incomingRefs === void 0 ? void 0 : incomingRefs.forEach(function (ref) {
                if (!mergedRefs.find(function (r) { return r.id === ref.id; })) {
                    mergedRefs.push(ref);
                }
            });
            return mergedRefs;
        };
        // we optimistically update totalResults based on everything we know,
        // but also query for just the count (limit: 0) on a delay.
        // if there is a discrepancy, the cache will update from that query.
        // we can't do this immediately (via async/await) because the query hasn't had time to update yet
        // and will return the old count.
        // TODO use ListWorkflowEntitiesCountQuery
        var updateCount = function () {
            return client.query({
                query: api_1.Queries.workflow.listWorkflowEntities,
                variables: __assign(__assign({}, variables), { input: __assign(__assign({}, variables.input), { limit: 0 }) }),
                fetchPolicy: 'network-only',
            });
        };
        setTimeout(updateCount, TOTAL_RESULTS_COUNT_UPDATE_DELAY_MS);
        switch (type) {
            case 'WORKFLOW_ENTITY_CREATED': {
                var cardGroup = getNewCards();
                var existingCards = getExistingCards();
                if ((0, lodash_1.isNil)(existingCards)) {
                    return;
                }
                if ((0, lodash_1.isEmpty)(cardGroup)) {
                    return;
                }
                updateList(__assign(__assign({}, existingCards), { list: __assign(__assign(__assign({}, existingCards.list), getNewCardsList(existingCards, cardGroup, getCardIsInFilterGroup)), { refs: __assign(__assign({}, existingCards.list.refs), { workflowEntityRefs: mergeRefs(existingCards, 'workflowEntityRefs', incomingWorkflowEntityRefs), personRefs: mergeRefs(existingCards, 'personRefs', incomingPersonRefs), recurringEntitiesScheduleRefs: mergeRefs(existingCards, 'recurringEntitiesScheduleRefs', incomingRecurringEntitiesScheduleRefs), workflowTemplateRefs: mergeRefs(existingCards, 'workflowTemplateRefs', incomingWorkflowTemplateRefs) }) }) }));
                break;
            }
            case 'WORKFLOW_ENTITY_UPDATED': {
                var cardGroup = getNewCards();
                var existingCards = getExistingCards();
                if ((0, lodash_1.isNil)(existingCards)) {
                    return;
                }
                updateList(__assign(__assign({}, existingCards), { list: __assign(__assign(__assign({}, existingCards.list), getNewCardsList(existingCards, cardGroup, getCardIsInFilterGroup)), { refs: __assign(__assign({}, existingCards.list.refs), { workflowEntityRefs: mergeRefs(existingCards, 'workflowEntityRefs', incomingWorkflowEntityRefs), personRefs: mergeRefs(existingCards, 'personRefs', incomingPersonRefs), recurringEntitiesScheduleRefs: mergeRefs(existingCards, 'recurringEntitiesScheduleRefs', incomingRecurringEntitiesScheduleRefs), workflowTemplateRefs: mergeRefs(existingCards, 'workflowTemplateRefs', incomingWorkflowTemplateRefs) }) }) }));
                break;
            }
            case 'WORKFLOW_ENTITY_DELETED': {
                var cardGroup = getNewCards();
                var existingCards = getExistingCards();
                if ((0, lodash_1.isNil)(existingCards)) {
                    return;
                }
                var newCards_1 = __spreadArray([], existingCards.list.entities, true);
                var totalResults_1 = existingCards.list.totalResults;
                cardGroup.forEach(function (card) {
                    var existingCardIndex = newCards_1.findIndex(function (c) { return c.id === card.id; });
                    if (existingCardIndex > -1) {
                        totalResults_1 = Math.max(0, totalResults_1 - 1);
                        newCards_1 = newCards_1.filter(function (c) { return c.id !== card.id; });
                    }
                });
                updateList(__assign(__assign({}, existingCards), { list: __assign(__assign({}, existingCards.list), { entities: newCards_1, totalResults: totalResults_1, refs: __assign(__assign({}, existingCards.list.refs), { workflowEntityRefs: mergeRefs(existingCards, 'workflowEntityRefs', incomingWorkflowEntityRefs), personRefs: mergeRefs(existingCards, 'personRefs', incomingPersonRefs), recurringEntitiesScheduleRefs: mergeRefs(existingCards, 'recurringEntitiesScheduleRefs', incomingRecurringEntitiesScheduleRefs), workflowTemplateRefs: mergeRefs(existingCards, 'workflowTemplateRefs', incomingWorkflowTemplateRefs) }) }) }));
                break;
            }
        }
    };
    (0, client_1.useSubscription)(api_1.Subscriptions.workflow.event, {
        onData: onUpdate,
        variables: variables,
        shouldResubscribe: true,
    });
}
