/* @flow */
import React from 'react';

import PureComponent from '@src/components/PureComponent';

import styles from './RoundIcon.css';

type Props = {
  url: string,
};

export default class RoundIcon extends PureComponent {
  props: Props;
  render() {
    return (
      <div
        className={styles.icon}
        style={{ backgroundImage: `url(${this.props.url})` }}
      />
    );
  }
}
