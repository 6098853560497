import * as React from 'react';
function SvgReceiptLong(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 28v3h12v-2.583C21 27.036 22.119 26 23.5 26H11a2 2 0 00-2 2z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M23.5 26c1.294 0 2.5.924 2.5 2.5a2.5 2.5 0 105 0V9H15v17"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 15h6M20 20h6"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgReceiptLong;

