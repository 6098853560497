"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInviteAction = useInviteAction;
// @ts-strict-ignore
var react_1 = require("react");
var react_native_1 = require("react-native");
var actionSheet_1 = require("@shared/util/actionSheet");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var invite_1 = require("@shared/util/invite");
var linking_1 = require("@shared/util/linking");
var analytics_1 = require("@shared/util/analytics");
var react_redux_1 = require("react-redux");
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var context_1 = require("../containers/context");
function useInviteAction() {
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var data = (0, client_1.useQuery)(api_1.Queries.user.directory, { variables: { businessId: businessId } }).data;
    var me = data === null || data === void 0 ? void 0 : data.me;
    var navigateToInviteContacts = (0, useNavigator_1.useNavigateTo)('InviteContacts');
    var inviteByEmail = (0, react_1.useCallback)(function () {
        var _a, _b, _c;
        analytics_1.MBAnalytics.track('EmailInvite', { inviterId: me === null || me === void 0 ? void 0 : me.id, businessId: (_a = me === null || me === void 0 ? void 0 : me.business) === null || _a === void 0 ? void 0 : _a.id });
        linking_1.MBLinking.open((0, invite_1.buildEmailInviteUrl)((_b = me === null || me === void 0 ? void 0 : me.business) === null || _b === void 0 ? void 0 : _b.name, me === null || me === void 0 ? void 0 : me.fullName, me === null || me === void 0 ? void 0 : me.shareCode, (_c = me === null || me === void 0 ? void 0 : me.business) === null || _c === void 0 ? void 0 : _c.users.filter(context_1.isStaff), me === null || me === void 0 ? void 0 : me.id));
    }, [me]);
    var inviteByText = (0, react_1.useCallback)(function () {
        var _a, _b;
        analytics_1.MBAnalytics.track('SmsInvite', { inviterId: me === null || me === void 0 ? void 0 : me.id, businessId: businessId });
        linking_1.MBLinking.open((0, invite_1.buildSmsInviteUrl)((_a = me === null || me === void 0 ? void 0 : me.business) === null || _a === void 0 ? void 0 : _a.name, me === null || me === void 0 ? void 0 : me.fullName, me === null || me === void 0 ? void 0 : me.shareCode, (_b = me === null || me === void 0 ? void 0 : me.business) === null || _b === void 0 ? void 0 : _b.users.filter(context_1.isStaff), me === null || me === void 0 ? void 0 : me.id));
    }, [me]);
    return (0, react_1.useCallback)(function (node) {
        if (react_native_1.Platform.OS === 'web') {
            navigateToInviteContacts();
        }
        else {
            actionSheet_1.MBActionSheet.show({
                title: (0, i18n_1.i18n)('contacts.inviteAction.title'),
                webVerticalAlignment: 'above',
                options: [
                    {
                        title: (0, i18n_1.i18n)('contacts.inviteAction.contacts'),
                        onPress: navigateToInviteContacts,
                    },
                    {
                        title: (0, i18n_1.i18n)('contacts.inviteAction.txt'),
                        onPress: inviteByText,
                    },
                    {
                        title: (0, i18n_1.i18n)('contacts.inviteAction.email'),
                        onPress: inviteByEmail,
                    },
                ],
                parent: node,
            });
        }
    }, [navigateToInviteContacts, inviteByEmail, inviteByText]);
}
