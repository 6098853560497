"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBNotifyBarWithDetailsModal = void 0;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var bar_1 = require("./bar");
var details_1 = require("./details");
var MBNotifyBarWithDetailsModal = /** @class */ (function (_super) {
    __extends(MBNotifyBarWithDetailsModal, _super);
    function MBNotifyBarWithDetailsModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { isShowingDetails: false };
        _this.showDetails = function () { return _this.setState({ isShowingDetails: true }); };
        _this.hideDetails = function () { return _this.setState({ isShowingDetails: false }); };
        _this.send = function () {
            _this.hideDetails();
            _this.props.onSend();
        };
        return _this;
    }
    MBNotifyBarWithDetailsModal.prototype.render = function () {
        var _a = this.props, users = _a.users, onHide = _a.onHide;
        return (React.createElement(components_1.MBView, null,
            React.createElement(bar_1.MBNotifyBar, { users: users, onHide: onHide, onPress: this.showDetails }),
            React.createElement(components_1.MBModal, { title: "Send by SMS", animationType: "slide", visible: this.state.isShowingDetails, onClose: this.hideDetails },
                React.createElement(details_1.MBNotifyDetails, { users: users, onSend: this.send }))));
    };
    return MBNotifyBarWithDetailsModal;
}(React.PureComponent));
exports.MBNotifyBarWithDetailsModal = MBNotifyBarWithDetailsModal;
