"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    dot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: styles_1.Colors.gray.normal,
    },
    dotMarginLeft: { marginLeft: 3 },
    bubbleHeight: {
        // The height of a 1 line chat message bubble (empirically derived)
        height: react_native_1.Platform.select({ web: 38, default: 36 }),
    },
    bar: {
        paddingVertical: 3,
    },
});
