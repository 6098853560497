import * as React from 'react';
function SvgMusicNotes(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 15h15"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M31 25.5V9H16v18.5" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M12.5 31a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM27.5 29a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMusicNotes;

