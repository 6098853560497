"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedCardLookupOptions = exports.isComponentSupportedForLookup = void 0;
// @ts-strict-ignore
var context_1 = require("@shared/scenes/workflows/context/context");
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var rc_menu_1 = require("rc-menu");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var defaultOptions_1 = require("../defaultOptions");
var lodash_1 = __importStar(require("lodash"));
var NestedOptionsRow_1 = require("../NestedOptionsRow");
var SelectableItemList_1 = require("@shared/scenes/workflows/edit/components/optionsMenu/referencedInOptions/SelectableItemList");
var react_native_1 = require("react-native");
var isComponentSupportedForLookup = function (component) {
    return ((0, hooks_1.isTextComponent)(component) ||
        (0, hooks_1.isNumberComponent)(component) ||
        (0, hooks_1.isDateComponent)(component) ||
        (0, hooks_1.isAddressComponent)(component) ||
        (0, hooks_1.isEmailComponent)(component) ||
        (0, hooks_1.isTagComponent)(component));
};
exports.isComponentSupportedForLookup = isComponentSupportedForLookup;
var RelatedCardLookupOptions = function (_a) {
    var component = _a.component, workflowTemplateId = _a.workflowTemplateId;
    var extraSectionsGenerator = useOptions(workflowTemplateId, component);
    return (react_1.default.createElement(defaultOptions_1.MBWorkflowDefaultComponentOptions, { component: component, extraSections: extraSectionsGenerator }));
};
exports.RelatedCardLookupOptions = RelatedCardLookupOptions;
function useOptions(workflowTemplateId, component) {
    var _a, _b, _c;
    var dispatch = (0, context_1.useWorkflowDispatch)();
    var currentWorkflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var availableRelatedCards = currentWorkflowTemplate.workflowTemplate.components.filter(function (c) { return c.type === 'RELATED_CARD'; });
    var relatedWorkflowTemplate = (0, hooks_1.useWorkflowTemplate)(component.lookupWorkflowTemplateId);
    var lookupComponents = (_b = (_a = relatedWorkflowTemplate === null || relatedWorkflowTemplate === void 0 ? void 0 : relatedWorkflowTemplate.workflowTemplate) === null || _a === void 0 ? void 0 : _a.components) === null || _b === void 0 ? void 0 : _b.filter(exports.isComponentSupportedForLookup);
    var isLookupEnabled = !(0, lodash_1.isEmpty)(lookupComponents);
    var savedComponent = currentWorkflowTemplate === null || currentWorkflowTemplate === void 0 ? void 0 : currentWorkflowTemplate.workflowTemplate.components.find(function (value) {
        return value.id === component.id && (0, hooks_1.isRelatedCardLookupComponent)(value);
    });
    var shouldAllowSelection = lodash_1.default.isNil(savedComponent);
    var selectedComponent = currentWorkflowTemplate === null || currentWorkflowTemplate === void 0 ? void 0 : currentWorkflowTemplate.workflowTemplate.components.find(function (c) {
        return c.id === component.relatedCardComponentId && (0, hooks_1.isRelatedCardComponent)(c);
    });
    var selectedLookupComponent = (_c = relatedWorkflowTemplate === null || relatedWorkflowTemplate === void 0 ? void 0 : relatedWorkflowTemplate.workflowTemplate) === null || _c === void 0 ? void 0 : _c.components.find(function (c) {
        return c.id === component.lookupComponentId && (0, exports.isComponentSupportedForLookup)(c);
    });
    var onRelatedCardComponentSelected = function (chosenComponentId, setOpenKeys) {
        var chosenComponent = availableRelatedCards.find(function (c) { return c.id === chosenComponentId; });
        setOpenKeys(function (prev) { return __spreadArray(__spreadArray([], prev.filter(function (k) { return !['relatedCardRow', 'relatedCardLookup'].includes(k); }), true), [
            'relatedCardLookup',
        ], false); });
        var alreadySelected = component.relatedCardComponentId === chosenComponent.id;
        dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, component), { relatedCardComponentId: !alreadySelected ? chosenComponent.id : null, lookupWorkflowTemplateId: !alreadySelected ? chosenComponent.workflowTemplateId : null }),
        });
    };
    var onLookupComponentSelected = function (chosenComponentId, setOpenKeys) {
        setOpenKeys(function (prev) { return __spreadArray([], prev.filter(function (k) { return k !== 'relatedCardLookup'; }), true); });
        var chosenComponent = lookupComponents.find(function (c) { return c.id === chosenComponentId; });
        var alreadySelected = component.lookupComponentId === chosenComponent.id;
        var label = !alreadySelected
            ? "".concat(chosenComponent.label, " (from ").concat(selectedComponent.label, ")")
            : (0, i18n_1.i18n)('workflowTemplate.component.type.label.relatedCardLookup');
        dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, component), { label: label, placeholder: label, icon: !alreadySelected ? chosenComponent.icon : 'search-look-up', lookupComponentId: !alreadySelected ? chosenComponent.id : null }),
        });
    };
    var linkableComponentsListItems = lookupComponents === null || lookupComponents === void 0 ? void 0 : lookupComponents.map(function (item) {
        return { id: item.id, label: item.label, icon: item.icon };
    });
    var availableRelatedCardsListItems = availableRelatedCards === null || availableRelatedCards === void 0 ? void 0 : availableRelatedCards.map(function (item) {
        return { id: item.id, label: item.label, icon: item.icon };
    });
    return function (_a) {
        var setOpenKeys = _a.setOpenKeys;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.relatedCardLookup.selectedRelatedCardOptions'))),
            shouldAllowSelection && (react_1.default.createElement(rc_menu_1.SubMenu, { key: "relatedCardRow", style: { zIndex: 999 }, popupClassName: "test-popup", title: react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: (selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.label) ||
                        (0, i18n_1.i18n)('builder.relatedCardLookup.selectedRelatedCardOptionsRow'), defaultIcon: "duplicate-squares", selectedIcon: "duplicate-squares" }) },
                react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(1) } },
                    react_1.default.createElement(SelectableItemList_1.SelectableItemList, { items: availableRelatedCardsListItems, onSelectItem: function (componentId) {
                            onRelatedCardComponentSelected(componentId, setOpenKeys);
                        }, enabled: true, selectedItem: selectedComponent === null || selectedComponent === void 0 ? void 0 : selectedComponent.id })))),
            !shouldAllowSelection && (react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: selectedComponent.label, defaultIcon: "duplicate-squares", selectedIcon: "duplicate-squares", enabled: false })),
            isLookupEnabled && (react_1.default.createElement(react_1.default.Fragment, null,
                shouldAllowSelection && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                        react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.relatedCardLookup.selectedRelatedCardLookupOptions'))),
                    react_1.default.createElement(rc_menu_1.SubMenu, { key: "relatedCardLookup", style: { zIndex: 999 }, forceSubMenuRender: true, title: react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: (selectedLookupComponent === null || selectedLookupComponent === void 0 ? void 0 : selectedLookupComponent.label) ||
                                (0, i18n_1.i18n)('builder.relatedCardOptions.singleFieldLookup'), defaultIcon: ((selectedLookupComponent === null || selectedLookupComponent === void 0 ? void 0 : selectedLookupComponent.icon) || 'search-look-up'), selectedIcon: ((selectedLookupComponent === null || selectedLookupComponent === void 0 ? void 0 : selectedLookupComponent.icon) || 'search-look-up') }) },
                        react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(1) } },
                            react_1.default.createElement(SelectableItemList_1.SelectableItemList, { items: linkableComponentsListItems, onSelectItem: function (componentId) {
                                    onLookupComponentSelected(componentId, setOpenKeys);
                                }, enabled: true, selectedItem: selectedLookupComponent === null || selectedLookupComponent === void 0 ? void 0 : selectedLookupComponent.id }))))),
                !shouldAllowSelection && (react_1.default.createElement(NestedOptionsRow_1.NestedOptionsRow, { name: selectedLookupComponent === null || selectedLookupComponent === void 0 ? void 0 : selectedLookupComponent.label, defaultIcon: ((selectedLookupComponent === null || selectedLookupComponent === void 0 ? void 0 : selectedLookupComponent.icon) || 'search-look-up'), selectedIcon: ((selectedLookupComponent === null || selectedLookupComponent === void 0 ? void 0 : selectedLookupComponent.icon) || 'search-look-up'), enabled: false }))))));
    };
}
