import { getCurrentBusinessId } from '@src/core/AuthTokenManager';
import isTouchDevice from '@src/core/isTouchDevice';
import getViewportLayout from '@src/core/getViewportLayout';

// const initialState: AppStateReducer = {
const initialState = {
  isTouchDevice,
  isHidden: false,
  viewportHeight: 0,
  viewportWidth: 0,
  viewportSizeDesktop: true,
  viewportSizeTablet: false,
  viewportSizeMobile: false,
  toastr: {
    message: '',
    type: 'success',
  },
  currentBusinessId: getCurrentBusinessId(),
  currentUserId: null,
};

// export default function appStateReducer(state: AppStateReducer = initialState, action: Action) {
export default function appStateReducer(state = initialState, action) {
  switch (action.type) {
    case 'VIEWPORT_DIMENSION_CHANGE': {
      const { viewportWidth, viewportHeight } = action;
      if (viewportWidth === state.viewportWidth && viewportHeight === state.viewportHeight) {
        return state;
      }
      const viewportLayout = getViewportLayout(viewportWidth);
      return {
        ...state,
        viewportHeight,
        viewportWidth,
        viewportSizeDesktop: viewportLayout === 'desktop',
        viewportSizeTablet: viewportLayout === 'tablet',
        viewportSizeMobile: viewportLayout === 'mobile',
        // used by shared components to determine if
        // desktop/tabled specific layout should be applied
        isDesktop: viewportLayout !== 'mobile',
      };
    }
    case 'VISIBILITY_CHANGE': {
      return {
        ...state,
        isHidden: action.isHidden,
      };
    }

    case 'UPDATE_TOASTR': {
      const { message, toastrType } = action;
      return {
        ...state,
        toastr: {
          message,
          type: toastrType,
        },
      };
    }

    case 'UPDATE_CURRENT_BUSINESS_ID': {
      return {
        ...state,
        currentBusinessId: action.businessId,
      };
    }

    case 'SET_USER_ID': {
      return {
        ...state,
        currentUserId: action.id,
      };
    }

    default:
      return state;
  }
}
