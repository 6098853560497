import * as React from 'react';
function SvgSun(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 20h1M12.2 12.2l.7.7M20 9v1M27.8 12.2l-.7.7M31 20h-1M27.8 27.8l-.7-.7M20 31v-1M12.2 27.8l.7-.7M20 26a6 6 0 100-12 6 6 0 000 12z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgSun;

