"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetWorkflowAutomation = useGetWorkflowAutomation;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var api_1 = require("@shared/api");
function useGetWorkflowAutomation(_a) {
    var automationId = _a.automationId;
    var _b = (0, client_1.useQuery)(api_1.Queries.automation.getAutomation, {
        variables: {
            id: automationId !== null && automationId !== void 0 ? automationId : '',
        },
        skip: (0, lodash_1.isNil)(automationId),
    }), data = _b.data, loading = _b.loading, error = _b.error;
    return {
        automation: data === null || data === void 0 ? void 0 : data.getAutomation,
        loading: loading,
        error: error,
    };
}
