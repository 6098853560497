exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".fdPlGi67Ekr9-1w7sZZcI {\n  height: 100%;\n  overflow: scroll;\n}\n\n._3pDc6lYXnkqebssaxhtkBd {\n  padding-bottom: 48px;\n}\n\n._2Jy7fRG44VKwPu45D71Bky {\n  background-color: #F8F8F8;\n  padding: 32px 0;\n}\n\n._1M2QWyRE_zE_DLj1Y0Kmn- {\n  background-color: #FFF;\n  padding: 48px 0;\n}\n\n._3IwDPAxnLG_3eF26Zqdfyg {\n  margin: 16px auto;\n}\n\n.nVKCAbpAxdwI3lb4Q3GDz {\n  width: 100%;\n}\n\n._3DU90x3kL7V1OLOGGP0z1Y {\n  width: 560px;\n}\n\n._1igmXZWZAsxvWaaXbWv1nQ {\n  padding: 16px;\n  background-color: #FFF;\n}\n\n._1Kt_zfBB3pIMdS7B9zm4Pk {\n  margin: 16px 0;\n  border: 1px solid #E9E9E9;\n}\n\n._3wlNV9jnccodaV6uG3kEKw {\n  padding: 16px;\n}\n\n._2WdSX6JSgDswpoeN0CJ4LI {\n  margin: 0 auto;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "fdPlGi67Ekr9-1w7sZZcI",
	"rootNavBar": "_3pDc6lYXnkqebssaxhtkBd fdPlGi67Ekr9-1w7sZZcI",
	"rootNotMobile": "_2Jy7fRG44VKwPu45D71Bky fdPlGi67Ekr9-1w7sZZcI",
	"rootMobile": "_1M2QWyRE_zE_DLj1Y0Kmn- fdPlGi67Ekr9-1w7sZZcI",
	"container": "_3IwDPAxnLG_3eF26Zqdfyg",
	"containerMobile": "nVKCAbpAxdwI3lb4Q3GDz _3IwDPAxnLG_3eF26Zqdfyg",
	"containerNotMobile": "_3DU90x3kL7V1OLOGGP0z1Y _3IwDPAxnLG_3eF26Zqdfyg",
	"content": "_1igmXZWZAsxvWaaXbWv1nQ",
	"contentNotMobile": "_1Kt_zfBB3pIMdS7B9zm4Pk _1igmXZWZAsxvWaaXbWv1nQ",
	"footer": "_3wlNV9jnccodaV6uG3kEKw",
	"navbar": "_2WdSX6JSgDswpoeN0CJ4LI"
};