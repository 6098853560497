import * as React from 'react';
function SvgSparkle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 25a5 5 0 005-5 5 5 0 005 5M20 25a5 5 0 015 5 5 5 0 015-5M10 15a5 5 0 005-5 5 5 0 005 5M10 15a5 5 0 015 5 5 5 0 015-5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M11 27h4M13 25v4M25 13h4M27 11v4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgSparkle;

