"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBReactionList = void 0;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var index_1 = require("./index");
var styles_1 = require("./styles");
// TODO(fant): use MBFlatList instead of MBScrollView. Need to upgrade to RN 0.56 first
// https://github.com/facebook/react-native/issues/19466
var MBReactionList = function (_a) {
    var reactions = _a.reactions;
    return (React.createElement(components_1.MBScrollView, { style: styles_1.ListStyles.container },
        React.createElement(components_1.MBView, { padding: "small" },
            React.createElement(components_1.MBView, { paddingTop: "small" }),
            reactions.map(function (reaction, index) { return (React.createElement(components_1.MBView, { key: index, row: true, centerVertically: true, paddingBottom: "small" },
                React.createElement(components_1.MBUserImage, { size: "small", user: reaction.user, style: styles_1.ListStyles.image }),
                React.createElement(components_1.MBView, { paddingRight: "small" }),
                React.createElement(components_1.MBText, { size: "large" }, reaction.user.fullName),
                React.createElement(components_1.MBView, { paddingRight: "small" }),
                React.createElement(components_1.MBText, { style: styles_1.ListStyles.emoji }, index_1.reactionTypeToEmoji[reaction.type]))); }))));
};
exports.MBReactionList = MBReactionList;
