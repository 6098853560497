import * as React from 'react';
function SvgWaterDrop(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.75 23C11.75 17.825 20 9.5 20 9.5s8.25 8.325 8.25 13.5c0 5.25-4.2 8.25-8.25 8.25s-8.25-3-8.25-8.25zM20 26s-3 0-3-3"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgWaterDrop;

