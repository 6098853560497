"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    messageContainer: {
        backgroundColor: 'white',
        width: '40%',
        borderRadius: 2,
        borderColor: styles_1.Colors.v2.gray10,
        borderWidth: 0.5,
        shadowOpacity: 0.1,
        shadowRadius: 2,
        shadowOffset: { width: 0, height: 2 },
    },
    messageAppIcon: {
        width: 15,
        height: 15,
        marginRight: 5,
        padding: 0,
    },
    messageRow: {
        padding: 5,
    },
    descriptionRow: {
        backgroundColor: styles_1.Colors.gray.touch,
        // We want a consistent height for all notification setting descriptions.
        // 120px is a height that fits the items with the most text.
        height: 120,
    },
});
