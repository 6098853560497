import * as React from 'react';
function SvgFaceCat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 27v4" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M16 21a1 1 0 100-2 1 1 0 000 2zM24 21a1 1 0 100-2 1 1 0 000 2zM18 25h4l-2 2-2-2z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M30 25s-4.375 6-10 6-10-6-10-6l1-15 5 4s1.813-1 4-1c2.188 0 4 1 4 1l5-4 1 15z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgFaceCat;

