"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.UnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.UnvalidatedStyles = {
    systemMessageIconSize: 16,
};
exports.Styles = react_native_1.StyleSheet.create({
    // Note(fant): desired radius: 9px (but 1px border makes that 9+1px)
    stackedUserImage: {
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'white',
        overflow: 'hidden',
    },
    stackedUserImageSmall: {
        width: 14,
        height: 14,
        borderRadius: 7,
        borderWidth: 1,
        borderColor: 'white',
        overflow: 'hidden',
    },
    stackedUserCountText: {
        marginLeft: 4,
    },
    stackedUserOverlap: {
        marginLeft: -7,
    },
    stackedUserOverlapSmall: {
        marginLeft: -4,
    },
    systemMessageIcon: {
        width: exports.UnvalidatedStyles.systemMessageIconSize,
        height: exports.UnvalidatedStyles.systemMessageIconSize,
        borderRadius: exports.UnvalidatedStyles.systemMessageIconSize / 2,
        overflow: 'hidden',
    },
    urgentBadge: {
        // Urgent will be rendered before the message content (because it's
        // over it). We set z index to make it appear on top.
        zIndex: 1,
        backgroundColor: styles_1.Colors.v2.red,
        height: 16,
        // Make the badge overlap 50% if its height with the content below
        marginBottom: -8,
        borderRadius: 8,
        paddingHorizontal: 4,
    },
    urgentBadgeAlignRight: { alignSelf: 'flex-end' },
    // Note: we hardcode the height of the delivery status. The reason is
    // that we want it to be equally tall no matter what content. When we
    // have text visible (eg "Delivered"), that is slightly higher (16px)
    // which makes the content jump a little when transitioning between
    // sending and sent/failed. This fixed height (which is as high as
    // the icon), solves that.
    deliveryStatusContent: {
        height: 16,
    },
    deliveryStatusIcon: {
        width: 10,
        height: 10,
        borderRadius: 5,
    },
    deliveryStatusText: {
        color: styles_1.Colors.gray.normal,
        marginRight: 4,
    },
    deliveryStatusIconWaitingToSend: {
        opacity: 0.5,
    },
    urgentIcon: {
        width: 10,
        height: 10,
    },
    urgentText: {
        marginHorizontal: 4,
    },
});
