"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAnimationBounceIn = void 0;
var React = __importStar(require("react"));
var ms = __importStar(require("milliseconds"));
var base_1 = require("./base");
// Note(fant): why from 0.01 ?
// On Android, setting scale to 0 has no effect; it renders as if the scale
// was set to 1. Setting the scale to 0.01 is a hacky workaround.
// For reference: https://github.com/facebook/react-native/issues/6278
var MBAnimationBounceIn = function (props) { return (React.createElement(base_1.MBAnimation, __assign({ scale: { from: 0.01, to: 1 }, duration: ms.seconds(0.2), type: "spring" }, props))); };
exports.MBAnimationBounceIn = MBAnimationBounceIn;
exports.MBAnimationBounceIn.displayName = 'MBAnimationBounceIn';
