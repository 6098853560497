"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetRelatedCardDataFromSelection = void 0;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var useGetRelatedCardDataFromSelection = function (selections) {
    var _a = (0, react_1.useState)([]), selectionsWithData = _a[0], setSelectionsWithData = _a[1];
    var selectionsWithoutEmpty = selections.filter(function (_a) {
        var value = _a.value;
        return value !== 'empty';
    });
    var needToLoadData = !(0, lodash_1.isNil)(selections.find(function (_a) {
        var value = _a.value, label = _a.label;
        return value !== 'empty' && !label;
    }));
    (0, client_1.useQuery)(api_1.Queries.card.getCards, {
        variables: {
            input: {
                ids: selectionsWithoutEmpty.map(function (_a) {
                    var value = _a.value;
                    return value;
                }),
            },
        },
        skip: !needToLoadData,
        onCompleted: function (data) {
            setSelectionsWithData(selections.map(function (_a) {
                var _b, _c;
                var value = _a.value, label = _a.label, filter = _a.filter;
                return ({
                    filter: filter,
                    value: value,
                    label: label || ((_c = (_b = data.getCards) === null || _b === void 0 ? void 0 : _b.find(function (card) { return card.id === value; })) === null || _c === void 0 ? void 0 : _c.name) || '',
                });
            }));
        },
    });
    return needToLoadData ? selectionsWithData : selections;
};
exports.useGetRelatedCardDataFromSelection = useGetRelatedCardDataFromSelection;
