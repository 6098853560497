"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockTextarea = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var utils_1 = require("./input/utils");
var styles_1 = require("@shared/styles");
var inputBottomSheet_1 = require("./inputBottomSheet");
var i18n_1 = require("@shared/util/i18n");
var react_native_1 = require("react-native");
var styles_2 = require("@shared/components/text/styles");
var react_redux_1 = require("react-redux");
var MBCardInputBlockTextarea = function (_a) {
    var block = _a.block;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    return (react_1.default.createElement(inputBottomSheet_1.MBBottomSheetInputBlock, { block: block, renderInput: function (_a) {
            var value = _a.value, setValue = _a.setValue, onBlur = _a.onBlur, mode = _a.mode;
            return (react_1.default.createElement(react_1.default.Fragment, null,
                isDesktop && (react_1.default.createElement(components_1.MBAutosizeTextInput, { padding: "none", autoFocus: mode === 'CREATE' ? block.autoFocus : false, value: value, onChangeText: setValue, onBlur: onBlur, size: (0, utils_1.getTextInputSize)(block.textStyle), bold: (0, utils_1.isInputBold)(block.textStyle), placeholder: block.placeholder })),
                !isDesktop && (react_1.default.createElement(react_native_1.TextInput, { autoFocus: mode === 'CREATE' ? block.autoFocus : false, value: value, onChangeText: setValue, onBlur: onBlur, placeholder: block.placeholder, multiline: true, style: [
                        components_1.MBTypographyStyles.body1,
                        { paddingTop: 0, paddingBottom: 0 },
                        (0, utils_1.isInputBold)(block.textStyle) && styles_2.Styles.bold,
                    ] }))));
        }, renderEditForm: function (_a) {
            var onSave = _a.onSave, setValue = _a.setValue, value = _a.value;
            return (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(react_native_1.ScrollView, { style: { flexGrow: 0 } },
                    react_1.default.createElement(components_1.MBView, { padding: 2 },
                        react_1.default.createElement(components_1.MBAutosizeTextInput, { style: { backgroundColor: colors.backgroundInactive, borderRadius: (0, components_1.grid)(1) }, onChangeText: setValue, value: value, placeholder: block.placeholder, autoFocus: true, padding: "normal" }))),
                react_1.default.createElement(components_1.MBView, { padding: 2 },
                    react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('common.ok'), onPress: onSave }))));
        } }));
};
exports.MBCardInputBlockTextarea = MBCardInputBlockTextarea;
