"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateWorkflowEntity = toCreateWorkflowEntity;
function toCreateWorkflowEntity(entity) {
    var allFields = entity.allFields, workflowTemplateId = entity.workflowTemplateId;
    var fields = {};
    allFields.forEach(function (value, key) {
        var _a;
        fields[key] = (_a = value === null || value === void 0 ? void 0 : value.toFieldJson()) !== null && _a !== void 0 ? _a : undefined;
    });
    return {
        fields: fields,
        workflowTemplateId: workflowTemplateId,
    };
}
