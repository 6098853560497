import * as React from 'react';
function SvgAvatarInvite(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.008 20.5a4.249 4.249 0 004.248-4.25 4.249 4.249 0 10-8.496 0 4.249 4.249 0 004.248 4.25z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M10 27.21c3.307-3.31 8.678-3.31 12 0"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M24 21h8M28 17v8"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgAvatarInvite;

