import * as React from 'react';
function SvgCutleryForkKnife(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 9v8a2 2 0 01-2 2h-4a2 2 0 01-2-2V9M14 9v6M14 19v12M29 24h-5V13a4 4 0 014-4h1v15zM29 24v7"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgCutleryForkKnife;

