"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeolocationField = void 0;
var lodash_1 = require("lodash");
var GeolocationField = /** @class */ (function () {
    function GeolocationField(jsonBlob) {
        this.fieldType = 'GeolocationField';
        this.jsonBlob = jsonBlob;
        this.geolocation = jsonBlob;
    }
    GeolocationField.prototype.validate = function () {
        return null;
    };
    GeolocationField.prototype.isEqual = function (field) {
        return field instanceof GeolocationField && (0, lodash_1.isEqual)(this.jsonBlob, field.jsonBlob);
    };
    GeolocationField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(GeolocationField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return GeolocationField;
}());
exports.GeolocationField = GeolocationField;
