import * as React from 'react';
function SvgClockOut(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.83 21.941c.112-.63.17-1.279.17-1.941 0-6.075-4.925-11-11-11S9 13.925 9 20s4.925 11 11 11c.662 0 1.311-.058 1.941-.17"
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M17.288 23.51l3.111-3.111v-6.223"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
      />
      <path d="M22.823 26.294h7.412" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M27.765 28.765l2.47-2.47-2.47-2.472"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgClockOut;

