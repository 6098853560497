/* @flow */
import {useQuery} from '@apollo/client';
import {MBLoadingIndicator} from '@shared/components';
import {useChannelListOnboarding} from '@shared/scenes/onboarding/useChannelListOnboarding';
import {useNavigator} from '@shared/util/navigation/useNavigator';
import gql from 'graphql-tag';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

const appLoaderQuery = gql`
  query AppLoaderQuery($businessId: Int) {
    me {
      id
      business(businessId: $businessId) {
        id
        channels {
          id
          slug
          type
        }
        defaultChannel {
          id
          slug
          type
        }
      }
    }
  }
`;

const AppLoaderContainer = () => {
  const businessId = useSelector(state => state.appState.currentBusinessId);
  const isDesktop = useSelector(state => state.appState.isDesktop);

  const {data} = useQuery(appLoaderQuery, {
    skip: !businessId,
    variables: {businessId}
  });
  const me = !!data ? data.me : undefined;
  const channelListOnboarding = useChannelListOnboarding();

  const navigator = useNavigator();
  useEffect(() => {
    if (!businessId || !me || !me.business) return;
    if (isDesktop) {
      const defaultChannel = me.business.defaultChannel;
      const highlightedChannel = !_.isEmpty(channelListOnboarding.highlightedChannelIds)
        ? me.business.channels.find(c => channelListOnboarding.highlightedChannelIds[0] === c.id)
        : undefined;

      const channel = highlightedChannel || defaultChannel;
      if (!!channel) {
        navigator.navigate('Channel', {channelSlug: channel.slug});
      } else {
        navigator.navigate('BaseCardsTab');
      }
    } else {
      navigator.replace('BaseChannelsTab');
    }
  }, [businessId, me, channelListOnboarding]);

  return <MBLoadingIndicator fill />;
};

export default AppLoaderContainer;
