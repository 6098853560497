"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardActionsSheetRow = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var MBCardActionsSheetRow = function (_a) {
    var title = _a.title, icon = _a.icon, active = _a.active, onPress = _a.onPress;
    var theme = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: onPress, style: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: (0, components_1.grid)(3),
            height: (0, components_1.grid)(7),
        } },
        react_1.default.createElement(react_native_1.View, { style: { marginRight: (0, components_1.grid)(1) } },
            react_1.default.createElement(components_1.MBIconV2, { name: icon, color: active ? theme.accent : theme.foregroundActive, size: (0, components_1.grid)(4) })),
        react_1.default.createElement(components_1.MBTextBody1Bold, null, title),
        react_1.default.createElement(components_1.MBView, { style: { flex: 1, alignItems: 'flex-end' } },
            react_1.default.createElement(components_1.MBIconV2, { name: "arrow-point-right-heavy", color: styles_1.Colors.v2.gray30, size: (0, components_1.grid)(2) }))));
};
exports.MBCardActionsSheetRow = MBCardActionsSheetRow;
exports.MBCardActionsSheetRow.displayName = 'MBCardActionsSheetRow';
