import * as React from 'react';
function SvgDuplicateSquares(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 16h6v14H16v-6"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M24 10H10v14h14V10z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M18 13h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3z" fill={props.color} />
    </svg>
  );
}
export default SvgDuplicateSquares;

