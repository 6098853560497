"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTouchableOpacity = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
/*
    This component wraps a touchable opacity and returns the component reference
    in the onPress call. Very useful when showing MBActionSheet on press. Makes
    it easy to pass in the parent component required for MBActionSheet to render
    its content in the right place on web.
*/
var MBTouchableOpacity = /** @class */ (function (_super) {
    __extends(MBTouchableOpacity, _super);
    function MBTouchableOpacity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // wrap onPress so that we can return "this" as parameter
        _this.onPress = function () { return _this.props.onPress(_this); };
        _this.onLongPress = function () { return _this.props.onLongPress(_this); };
        return _this;
    }
    MBTouchableOpacity.prototype.render = function () {
        return (React.createElement(react_native_1.TouchableOpacity, __assign({}, this.props, { disabled: this.props.disabled || !this.props.onPress, style: [
                // react-native-web gives all views z-index 0 as default.
                // This causes issues when trying to overlay content in nested
                // views. Resetting to no z-index makes the HTML renderer
                // figure out the view hierarchy better.
                react_native_1.Platform.OS === 'web' && { zIndex: 'unset' },
                this.props.style,
                this.props.fill && styles_1.SharedStyles.fill,
                this.props.center && styles_1.SharedStyles.centerChildren,
            ], onPress: !!this.props.onPress ? this.onPress : undefined, onLongPress: !!this.props.onLongPress ? this.onLongPress : undefined, onLayout: this.props.onLayout })));
    };
    return MBTouchableOpacity;
}(React.PureComponent));
exports.MBTouchableOpacity = MBTouchableOpacity;
