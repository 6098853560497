import * as React from 'react';
function SvgColorReversed(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 164 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M78.017 38.088c-1.904 2.341-4.433 3.496-7.555 3.496-2.84 0-5.214-.874-7.087-2.622-1.873-1.748-2.778-4.059-2.778-6.93 0-2.904.936-5.246 2.778-6.994 1.873-1.78 4.152-2.654 6.962-2.654 3.06 0 5.526 1.155 7.4 3.497l-2.654 2.716c-1.093-1.561-2.904-2.529-4.933-2.529-1.468 0-2.748.53-3.84 1.592-1.093 1.03-1.655 2.467-1.655 4.277 0 1.842.562 3.278 1.655 4.34 1.093 1.061 2.435 1.592 4.058 1.592 2.092 0 3.778-.874 5.058-2.591l2.591 2.81zM82.482 25.132c-1.842 1.81-2.748 4.09-2.748 6.837 0 2.778.937 5.057 2.748 6.9 1.842 1.81 4.12 2.715 6.806 2.715 2.716 0 4.995-.905 6.837-2.716 1.842-1.842 2.778-4.12 2.778-6.9 0-2.747-.936-5.057-2.778-6.836-1.874-1.842-4.153-2.748-6.837-2.748-2.716 0-4.996.906-6.806 2.748zm2.81 11.02c-1-1.155-1.5-2.529-1.5-4.183 0-1.655.5-3.029 1.5-4.152 1.03-1.156 2.403-1.686 4.09-1.686 1.623 0 2.934.593 3.964 1.717 1.03 1.155 1.561 2.529 1.561 4.152 0 1.686-.5 3.06-1.498 4.183-.968 1.155-2.342 1.686-3.997 1.686-1.78 0-3.122-.562-4.12-1.717zM106.395 22.665l-7.274 18.607h4.09l1.467-3.653h7.181l1.498 3.653h4.09l-7.274-18.607h-3.778zm4.184 11.458h-4.621l2.31-6.088 2.311 6.088zM127.313 33.998c1.717.312 2.591.968 2.591 1.967 0 1.155-1.217 2.03-3.247 2.03-2.123 0-3.871-.906-5.245-2.686l-2.716 2.748c1.686 2.091 4.465 3.559 7.712 3.559 2.216 0 3.996-.531 5.369-1.561 1.343-1.03 2.03-2.404 2.03-4.121 0-3.216-2.092-5.152-6.276-5.807l-1.842-.281c-1.779-.312-2.653-.968-2.653-1.967 0-1.093 1.061-1.842 2.747-1.842 1.748 0 3.309.718 4.714 2.154l2.654-2.778c-1.873-1.998-4.277-2.966-7.181-2.966-1.873 0-3.496.5-4.87 1.53-1.342.999-2.029 2.372-2.029 4.09 0 3.09 2.217 4.963 5.869 5.588l2.373.343zM135.898 26.318h5.308v14.954h3.933V26.318h5.339v-3.653h-14.58v3.653z"
        fill="#fff"
      />
      <path
        d="M50.919 35.122a19.18 19.18 0 01-18.95 16.047c-10.584 0-19.2-8.586-19.2-19.2 0-10.615 8.616-19.17 19.2-19.17 9.521 0 17.451 6.963 18.95 16.048h-4.683C44.8 22.29 38.962 17.389 32 17.389c-8.055 0-14.58 6.525-14.58 14.58 0 8.054 6.525 14.58 14.58 14.58 6.993 0 12.831-4.902 14.236-11.459h4.683v.032zm-12.582 0c-1.155 2.341-3.559 3.965-6.337 3.965-2.779 0-5.183-1.624-6.338-3.965h-4.964c1.374 4.932 5.9 8.554 11.27 8.554 5.37 0 9.897-3.622 11.27-8.554h-4.9z"
        fill="#00CAAC"
      />
    </svg>
  );
}
export default SvgColorReversed;

