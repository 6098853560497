import * as React from 'react';
function SvgTableGrid(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 9.6h12M6 13.8h12M9.6 6v12" stroke="#281E38" strokeMiterlimit={10} />
      <path
        d="M16.8 18H7.2A1.2 1.2 0 016 16.8V7.2A1.2 1.2 0 017.2 6h9.6A1.2 1.2 0 0118 7.2v9.6a1.2 1.2 0 01-1.2 1.2z"
        stroke="#281E38"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgTableGrid;

