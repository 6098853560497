import * as React from 'react';
function SvgBuildingHotel(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 31H9v-9h4M27 31h4v-9h-4M27 9H13v22h14V9zM17 13h1M22 13h1M17 17h1M22 17h1M17 21h1M22 21h1M17 25h1M22 25h1M20 31v-2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgBuildingHotel;

