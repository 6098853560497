import * as React from 'react';
function SvgPercent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 29l12-18M14.5 17a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM25.5 30a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPercent;

