import * as React from 'react';
function SvgArrowMoveLeft(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M31 20H15" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M21 14l-6 6 6 6M10 10v20"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgArrowMoveLeft;

