import * as React from 'react';
function SvgClockNoPadding(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path clipRule="evenodd" d="M6 11A5 5 0 106 1a5 5 0 000 10z" stroke={props.color} />
      <path d="M4 6h2l2-2" stroke={props.color} strokeLinecap="square" />
    </svg>
  );
}
export default SvgClockNoPadding;

