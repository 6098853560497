import * as React from 'react';
function SvgQrCode(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 18H9V9h9v9zM31 18h-9V9h9v9zM18 31H9v-9h9v9zM31 27v-5h-4v3h-4v-3M31 31h-8v-2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M14 14h-1v-1h1v1zM27 14h-1v-1h1v1zM14 27h-1v-1h1v1z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgQrCode;

