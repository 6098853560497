import * as React from 'react';
function SvgBellHotel(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 13V9" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M31 24c0-6.1-4.9-11-11-11S9 17.9 9 24v3h22v-3zM13.1 23c.3-2 1.4-3.7 2.9-4.7M9 31h22M24 9h-8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgBellHotel;

