import * as React from 'react';
function SvgSyringe(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7 25.3l-5 5M19.6 16.1l1.5 1.4M17.5 18.2l1.4 1.4M15.4 20.4l1.4 1.4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M26 16.8l3.5-3.5M26.7 10.5L23.2 14"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M19.6 10.5l9.9 9.9M26 9.7l4.3 4.3M26.7 17.5l-9.9 9.9-4.2-4.2 9.9-9.9"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgSyringe;

