/* @flow */
import vars from '@src/breakpointsVariables';

export default function getViewportSize(width) {
  if (width >= vars.desktop) {
    return 'desktop';
  }
  if (width >= vars.tablet) {
    return 'tablet';
  }
  return 'mobile';
}
