"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityEditField = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var RelatedEntityField_1 = require("@shared/data/entity/fields/RelatedEntityField");
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var RelatedCardsList_1 = require("@shared/components/blocks/lib/inputCards/RelatedCardsList");
var styles_1 = require("@shared/styles");
var lodash_1 = require("lodash");
var EditFieldContainer_1 = require("./EditFieldContainer");
var multiSelect_1 = require("@shared/components/multiSelect");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var searchEmpty_1 = require("@shared/components/blocks/lib/inputCards/searchEmpty");
var noCards_1 = require("@shared/components/blocks/lib/inputCards/noCards");
var useSearchWorkflowEntities_1 = require("@shared/components/blocks/lib/cards/useSearchWorkflowEntities");
var cardSelectItem_1 = require("@shared/components/blocks/lib/inputCards/cardSelectItem");
var i18n_1 = require("@shared/util/i18n");
var useWorkspaceDetails_1 = require("@shared/scenes/workflows/hooks/useWorkspaceDetails");
var useOpenByEntityId_1 = require("@shared/scenes/cards/collectionView/hooks/useOpenByEntityId");
var SelectCardsBottomSheet = function (_a) {
    var values = _a.values, fieldValues = _a.fieldValues, containerRef = _a.containerRef, onChange = _a.onChange, onClose = _a.onClose, relatedCardComponent = _a.relatedCardComponent;
    var _b = (0, useSearchWorkflowEntities_1.useSearchWorkflowEntities)(relatedCardComponent, fieldValues), onSearch = _b.onSearch, searchResults = _b.searchResults, loading = _b.loading, hasMore = _b.hasMore;
    var renderTitleBar = (0, react_1.useCallback)(function () {
        var _a;
        return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (_a = relatedCardComponent.label) !== null && _a !== void 0 ? _a : '', rightIcon: "exit", onRightIconPress: onClose }));
    }, [onClose, relatedCardComponent.label]);
    var match = (0, react_1.useCallback)(function (card, query, exact) {
        if (exact) {
            return card.name === query;
        }
        return true;
    }, []);
    var renderEmptyState = (0, react_1.useCallback)(function (query) {
        return (react_1.default.createElement(components_1.MBView, { paddingVertical: 3, paddingHorizontal: 2, center: true },
            query.length > 0 && react_1.default.createElement(searchEmpty_1.SearchEmpty, { keyword: query }),
            query.length === 0 && !loading && react_1.default.createElement(noCards_1.NoCards, null),
            query.length === 0 && loading && react_1.default.createElement(components_1.MBLoadingIndicator, null)));
    }, [loading]);
    var renderItem = (0, react_1.useCallback)(function (card, index) { return (react_1.default.createElement(cardSelectItem_1.CardSelectItem, { card: card, testID: "card-select-item-".concat(index) })); }, []);
    var fixedWidth = (0, contentWrapper_1.useIsDesktop)() ? { width: (0, components_1.grid)(46) } : null;
    return (react_1.default.createElement(components_1.MBBottomSheet, { containerRef: containerRef, renderTitleBar: renderTitleBar, layout: {
            offset: { x: (0, components_1.grid)(10), y: 0 },
            minWidth: fixedWidth === null || fixedWidth === void 0 ? void 0 : fixedWidth.width,
            minHeight: (0, components_1.grid)(48),
        }, onClose: onClose },
        react_1.default.createElement(components_1.MBView, { style: fixedWidth, fill: true },
            react_1.default.createElement(multiSelect_1.MBMultiSelect, { onChange: onChange, keyExtractor: function (card) { return card.cardId; }, match: match, renderItem: renderItem, renderEmptyState: renderEmptyState, values: searchResults, selectedValues: values, onSearch: onSearch, helpText: hasMore ? (0, i18n_1.i18n)('card.blocks.cardsInput.cardListLimitHelp') : undefined, displaySelected: true }))));
};
var EntityEditFieldContent = function (_a) {
    var component = _a.component, cards = _a.cards, loading = _a.loading, onEditCards = _a.onEditCards, onShowMore = _a.onShowMore, onRemoveCard = _a.onRemoveCard, onPressCard = _a.onPressCard;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var content = (function () {
        var _a;
        if (loading) {
            return react_1.default.createElement(components_1.MBLoadingIndicator, { style: { alignItems: 'flex-start' } });
        }
        if ((0, lodash_1.isEmpty)(cards)) {
            return react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundInactive }, component.placeholder);
        }
        return (react_1.default.createElement(RelatedCardsList_1.RelatedCardsList, { cards: cards, totalCardsNubmer: cards.length, block: { label: (_a = component.label) !== null && _a !== void 0 ? _a : '' }, onEditCards: onEditCards, onRemoveCard: onRemoveCard, onPressCard: onPressCard, onShowMore: onShowMore }));
    })();
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onEditCards },
        react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: (0, lodash_1.isEmpty)(cards) }, content)));
};
var RelatedEntityEditField = function (_a) {
    var _b, _c, _d, _e, _f;
    var component = _a.component, field = _a.field, updateField = _a.updateField;
    var containerRef = (0, react_1.useRef)(null);
    var workspaceDetails = (0, useWorkspaceDetails_1.useWorkspaceDetails)();
    var cardItems = field === null || field === void 0 ? void 0 : field.cards;
    var linkedCards = (_b = cardItems === null || cardItems === void 0 ? void 0 : cardItems.map(function (item) {
        var _a, _b;
        return {
            cardId: item.id,
            quantity: !(0, lodash_1.isNil)(item.quantity) ? parseFloat(item.quantity) : undefined,
            name: (_b = (_a = item === null || item === void 0 ? void 0 : item.fields) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
        };
    })) !== null && _b !== void 0 ? _b : [];
    var _g = (0, react_1.useState)(false), showingPicker = _g[0], setShowingPicker = _g[1];
    var openEntity = (0, useOpenByEntityId_1.useOpenEntityById)((_e = (_d = workspaceDetails.get((_c = component.workflowTemplateId) !== null && _c !== void 0 ? _c : '')) === null || _d === void 0 ? void 0 : _d.baseNavigationPath) !== null && _e !== void 0 ? _e : '', component.workflowTemplateId);
    var openLinkedCard = (0, react_1.useCallback)(function (linkedCard) {
        openEntity(linkedCard.cardId, null);
    }, [openEntity]);
    var onChange = (0, react_1.useCallback)(function (updatedCards) {
        var updatedCardItems = updatedCards.map(function (item) {
            var _a;
            return ({
                id: item.cardId,
                quantity: (_a = item.quantity) !== null && _a !== void 0 ? _a : undefined,
            });
        });
        var relatedEntities = updatedCards.map(function (item) {
            var _a, _b;
            return {
                id: item.cardId,
                workflowTemplateId: component.workflowTemplateId,
                createdAt: '',
                updatedAt: '',
                quantity: (_b = (_a = item.quantity) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : undefined,
                fields: { name: item.name },
            };
        });
        updateField(component.id, (0, lodash_1.isEmpty)(updatedCardItems)
            ? null
            : new RelatedEntityField_1.RelatedEntityField(updatedCardItems, relatedEntities));
    }, [component.id, component.workflowTemplateId, updateField]);
    var onRemoveCard = (0, react_1.useCallback)(function (card) {
        var _a;
        var updatedItems = cardItems === null || cardItems === void 0 ? void 0 : cardItems.filter(function (item) { return item.id !== card.cardId; });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField((_a = updatedItems === null || updatedItems === void 0 ? void 0 : updatedItems.map(function (item) {
            var _a;
            return { id: item.id, quantity: (_a = item.quantity) !== null && _a !== void 0 ? _a : null };
        })) !== null && _a !== void 0 ? _a : {}, updatedItems !== null && updatedItems !== void 0 ? updatedItems : []));
    }, [cardItems, component.id, updateField]);
    var showPicker = (0, react_1.useCallback)(function () { return setShowingPicker(true); }, []);
    var hidePicker = (0, react_1.useCallback)(function () { return setShowingPicker(false); }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef }),
        react_1.default.createElement(EntityEditFieldContent, { component: component, cards: linkedCards, loading: false, onEditCards: showPicker, onChange: onChange, onRemoveCard: onRemoveCard, onPressCard: openLinkedCard, onShowMore: showPicker }),
        showingPicker && (react_1.default.createElement(SelectCardsBottomSheet, { fieldValues: (_f = field === null || field === void 0 ? void 0 : field.items) !== null && _f !== void 0 ? _f : [], values: linkedCards, onChange: onChange, onClose: hidePicker, containerRef: containerRef, relatedCardComponent: component }))));
};
exports.RelatedEntityEditField = RelatedEntityEditField;
