import * as React from 'react';
function SvgMath(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 9v8M17 13H9M16 24l-6 6M16 30l-6-6M31 13h-8M31 25h-8M31 29h-8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMath;

