import * as React from 'react';
function SvgEyeShow(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 19.308S12.8 11 20 11s12 8.308 12 8.308-4.8 8.307-12 8.307-12-8.307-12-8.307z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 23a3.692 3.692 0 100-7.385A3.692 3.692 0 0020 23z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgEyeShow;

