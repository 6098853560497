import * as React from 'react';
function SvgArrowFullUpFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 20c0 6.627-5.373 12-12 12S8 26.627 8 20 13.373 8 20 8s12 5.373 12 12zm-13-3.586v10.817h2V16.414l4.293 4.293 1.414-1.414L21 13.586v-.125h-.124l-.169-.168-.707-.707-.707.707-.169.168H19v.125l-5.707 5.707 1.414 1.414L19 16.414z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgArrowFullUpFilled;

