/* @flow */
import React from 'react';

import PureComponent from '@src/components/PureComponent';
import Button from '@src/components/Button';
import LoginBusinessHeader from '@src/components/LoginBusinessHeader';
import LoginUserRow from '@src/components/LoginUserRow';

import styles from './LoginPeopleList.css';

type Props = {
  isMobile: boolean,
  error: ?string,
  onUserSelect: (ev: BrowserEvent, userId: number) => void,
  onCreateAccount: (ev: BrowserEvent) => void,
  onLogOut: Function,
  business: {
    img: string,
    name: string,
    users: Array<{
      id: number,
      firstName: string,
      lastName: string,
      img: string,
      hasPasscode: boolean,
      lastVisitedAt: ?string,
      createdAt: string,
      lastRemindedAt: ?string,
    }>
  },
};

export default class LoginPeopleList extends PureComponent {
  props: Props;
  render() {
    const maxUserIndex = this.props.business.users.length - 1;
    return (
      <div className={styles.root}>
        <LoginBusinessHeader
          img={this.props.business.img}
          name={this.props.business.name}
        />
        {this.props.error &&
          <div className={styles.error}>{this.props.error}</div>
        }
        <div className={this.props.isMobile ? styles.listFullWidth : styles.listInContainer}>
          {this.props.business.users.map((user, i) => (
            <LoginUserRow
              key={user.id}
              id={user.id}
              firstName={user.firstName}
              lastName={user.lastName}
              img={user.img}
              isNew={!user.lastVisitedAt}
              isLast={i === maxUserIndex}
              onClick={this.props.onUserSelect}
            />
          ))}
        </div>
        <div className={this.props.isMobile ? styles.footerFullWidth : styles.footerInContainer}>
          <div className={styles.text}>
            Don&lsquo;t see your name?
          </div>
          <div>
            <Button
              btnStyle="secondaryTransparent"
              btnSize="large"
              onClick={this.props.onCreateAccount}
            >
              Create an account
            </Button>
          </div>
        </div>
        <div className={styles.logoutLinkWrapper}>
          <a className={styles.logoutLink} onClick={this.props.onLogOut}>Log out of business</a>
        </div>
      </div>
    );
  }
}
