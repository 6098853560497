import * as React from 'react';
function SvgPencil(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20.7 11.7l3.6 3.6" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M14.4 25.2l-4.5.9.9-4.5L24.3 8.1l3.6 3.6-13.5 13.5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPencil;

