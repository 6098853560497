import * as React from 'react';
function SvgStethoscope(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 24v7h12v-6"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M28 25a3 3 0 100-6 3 3 0 000 6zM19 10h2.8c.6 0 1.1.5 1 1.1l-1.6 11.1c-.1 1-1 1.7-2 1.7h-6.5c-1 0-1.8-.7-2-1.7L9.2 11.1c-.1-.6.3-1.1 1-1.1H13M13 9v2M19 9v2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgStethoscope;

