"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBOnboardingDone = void 0;
var react_1 = __importStar(require("react"));
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var component_1 = require("./component");
var theme_1 = require("./theme");
var blurredDesktopBackground_1 = require("../blurredDesktopBackground");
var hooks_1 = require("../popovers/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var hooks_2 = require("../lastStep/hooks");
var MBOnboardingDone = function () {
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var chatOnboarding = (0, hooks_1.useOnboardingStep)(hooks_1.ChatOnboardingStep.Start);
    var lastStep = (0, hooks_2.useOnboardingLastStepState)();
    (0, react_1.useEffect)(function () {
        chatOnboarding.markSeen();
        lastStep.setShouldShow(false);
    }, []);
    var navigator = (0, useNavigator_1.useNavigator)();
    var resetToHome = (0, react_1.useCallback)(function () { return navigator.reset('Home'); }, [navigator]);
    return (react_1.default.createElement(contentWrapper_1.MBAuthContentWrapper, { logo: false, theme: theme_1.theme, shadow: false, background: react_1.default.createElement(blurredDesktopBackground_1.MBOnboardingBlurredDesktopBackground, null) },
        react_1.default.createElement(component_1.MBOnboardingDone, { isDesktop: isDesktop, onNext: resetToHome })));
};
exports.MBOnboardingDone = MBOnboardingDone;
