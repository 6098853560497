"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    urgentImage: {
        // Note(fant): px values taken from the original SVG from Figma
        width: 87,
        height: 145,
        alignSelf: 'center',
    },
    urgentBanner: {
        backgroundColor: styles_1.Colors.gray.touch,
    },
});
