import * as React from 'react';
function SvgSendFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.33 36a1.336 1.336 0 01-1.195-.737l-3.956-7.917a1.338 1.338 0 01.095-1.357l6.389-8.654-8.654 6.386a1.336 1.336 0 01-1.356.096l-7.92-3.957a1.333 1.333 0 01.135-2.441l25.333-9.336a1.333 1.333 0 011.712 1.711l-9.335 25.334a1.331 1.331 0 01-1.178.87l-.07.002z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgSendFilled;

