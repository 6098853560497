"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCardListView = useCardListView;
var useCollectionViewData_1 = require("@shared/scenes/cards/collectionView/hooks/useCollectionViewData");
function useCardListView(workflowTemplateId, viewTemplateId, workspaceId, baseNavigationPath) {
    return (0, useCollectionViewData_1.useCollectionViewData)({
        workflowTemplateId: workflowTemplateId,
        viewTemplateId: viewTemplateId,
        workspaceId: workspaceId,
        baseNavigationPath: baseNavigationPath,
    });
}
