import * as React from 'react';
function SvgCheckBoxEmpty(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 31H12a3 3 0 01-3-3V12a3 3 0 013-3h16a3 3 0 013 3v16a3 3 0 01-3 3zM12 11a1 1 0 00-1 1v16a1 1 0 001 1h16a1 1 0 001-1V12a1 1 0 00-1-1H12z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgCheckBoxEmpty;

