"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.ANIMATION_TIME_MS = exports.WEB_MAX_WIDTH = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
exports.WEB_MAX_WIDTH = 375;
exports.ANIMATION_TIME_MS = 200;
var ANIMATION_TIME = '0.2s';
exports.Styles = react_native_1.StyleSheet.create({
    splitWidthWide: {
        width: "calc(100% - ".concat(exports.WEB_MAX_WIDTH, "px)"),
    },
    fullWidth: {
        width: '100%',
    },
    splitWidthSmall: {
        width: exports.WEB_MAX_WIDTH,
    },
    noWidth: {
        width: 0,
    },
    transitionWidth: react_native_1.Platform.select({
        web: { transition: "width ".concat(ANIMATION_TIME) },
    }),
    sidebar: {
        borderLeftColor: styles_1.Colors.v2.gray10,
        borderLeftWidth: 1,
    },
    alignTopRight: {
        alignSelf: 'flex-end',
        position: 'absolute',
        zIndex: 2,
    },
    roundCornerButton: {
        height: (0, components_1.grid)(4),
        width: (0, components_1.grid)(4),
        borderRadius: (0, components_1.grid)(2),
    },
    rightNotificationButton: {
        height: (0, components_1.grid)(6),
        width: (0, components_1.grid)(9),
        borderBottomLeftRadius: (0, components_1.grid)(2),
        borderTopLeftRadius: (0, components_1.grid)(2),
    },
    notificationBadge: {
        marginTop: (0, components_1.grid)(0.5),
        marginRight: (0, components_1.grid)(1),
    },
});
