"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUploadUtil = void 0;
// @ts-strict-ignore
var uuid = __importStar(require("@shared/util/uuid"));
__exportStar(require("./types"), exports);
function getOrCreateInput(id) {
    var container = document.getElementById(id);
    if (!!container) {
        return container;
    }
    var newContainer = document.createElement('input');
    newContainer.setAttribute('id', id);
    document.getElementsByTagName('body')[0].appendChild(newContainer);
    return newContainer;
}
function openFileSelector(_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.multiple, multiple = _c === void 0 ? true : _c, _d = _b.acceptedContentTypes, acceptedContentTypes = _d === void 0 ? [] : _d;
    return new Promise(function (resolve) {
        // https://stackoverflow.com/a/40971885
        var input = getOrCreateInput('shared-components-filepicker');
        input.type = 'file';
        input.multiple = multiple;
        input.style.display = 'none';
        // https://stackoverflow.com/a/6225815
        input.accept = acceptedContentTypes.join(',');
        // Note(fant): we reset the value of the input so that
        // it's possible to upload the same file multiple times
        // in a row (eg when sending it in multiple messages).
        // https://stackoverflow.com/a/20552042
        input.value = null;
        // TODO(fant): TS types
        input.onchange = function (event) {
            var files = Array.from(event.target.files);
            resolve(files);
        };
        input.click();
    });
}
exports.MBUploadUtil = {
    getWebFileDimensions: function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, imageDimensions, videoDimensions;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            new Promise(function (resolve) {
                                // https://stackoverflow.com/a/13572240/9117995
                                var image = new Image();
                                image.src = URL.createObjectURL(file);
                                image.onerror = function () { return resolve(null); };
                                image.onload = function () {
                                    return resolve({
                                        width: image.naturalWidth,
                                        height: image.naturalHeight,
                                    });
                                };
                            }),
                            new Promise(function (resolve) {
                                // https://stackoverflow.com/a/43298842/9117995 (a less ugly version of this)
                                var video = document.createElement('video');
                                video.src = URL.createObjectURL(file);
                                video.onerror = function () { return resolve(null); };
                                video.addEventListener('loadedmetadata', function () {
                                    return resolve({
                                        width: video.videoWidth,
                                        height: video.videoHeight,
                                    });
                                });
                            }),
                        ])];
                    case 1:
                        _a = _b.sent(), imageDimensions = _a[0], videoDimensions = _a[1];
                        if (!!imageDimensions) {
                            return [2 /*return*/, imageDimensions];
                        }
                        if (!!videoDimensions) {
                            return [2 /*return*/, videoDimensions];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    },
    fileToAttachment: function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var dimensions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, exports.MBUploadUtil.getWebFileDimensions(file)];
                    case 1:
                        dimensions = _a.sent();
                        return [2 /*return*/, __assign(__assign({ name: file.name, fileKey: uuid.v4(), 
                                // clean way to get the relative path to a file upload url
                                // https://medium.com/@650egor/2d534f8eaaa
                                url: URL.createObjectURL(file), contentType: file.type }, dimensions), { size: file.size, thumbnails: null })];
                }
            });
        });
    },
    selectAttachments: function (_a) {
        return __awaiter(this, arguments, void 0, function (_b) {
            var files, ps;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, openFileSelector()];
                    case 1:
                        files = _c.sent();
                        ps = files.map(exports.MBUploadUtil.fileToAttachment);
                        return [2 /*return*/, Promise.all(ps)];
                }
            });
        });
    },
    selectFiles: function (_a) {
        return __awaiter(this, arguments, void 0, function (_b) {
            var files, filePs;
            var _this = this;
            var _c = _b.multiple, multiple = _c === void 0 ? false : _c, acceptedContentTypes = _b.acceptedContentTypes;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, openFileSelector({ multiple: multiple, acceptedContentTypes: acceptedContentTypes })];
                    case 1:
                        files = _d.sent();
                        filePs = files.map(function (file) { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = [{ id: uuid.v4(), url: URL.createObjectURL(file), name: file.name, contentType: file.type, size: file.size }];
                                        return [4 /*yield*/, exports.MBUploadUtil.getWebFileDimensions(file)];
                                    case 1: return [2 /*return*/, (__assign.apply(void 0, _a.concat([(_b.sent())])))];
                                }
                            });
                        }); });
                        return [2 /*return*/, Promise.all(filePs)];
                }
            });
        });
    },
    selectImage: function () {
        return __awaiter(this, void 0, void 0, function () {
            var image;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, exports.MBUploadUtil.selectFiles({
                            multiple: false,
                            acceptedContentTypes: ['image/*'],
                        })];
                    case 1:
                        image = (_a.sent())[0];
                        return [2 /*return*/, image];
                }
            });
        });
    },
};
