/* @flow */
import React from 'react';

import styles from './LoginBusinessHeader.css';

type Props = {
  img: string,
  name: string,
};

export default function LoginBusinessHeader({ img, name }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.imgWrapper}>
        <div className={styles.img} style={{ backgroundImage: `url(${img})` }} />
      </div>
      <div className={styles.header}>{name}</div>
    </div>
  );
}
