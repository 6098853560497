"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
// @ts-strict-ignore
var react_native_1 = require("react-native");
var default_1 = require("./default");
var getBuilderHost = function () {
    if (react_native_1.Platform.OS === 'web' && window.location.hostname === 'localhost') {
        return 'http://localhost:5173';
    }
    return 'https://staging.coastapp.com/builder';
};
exports.Config = __assign(__assign({}, default_1.Config), { APP_URL: "https://staging.coastapp.com", API_URL: "https://api.staging.coastapp.com", BUILDER_APP_URL: getBuilderHost(), WEBSOCKET_URL: "wss://api.staging.coastapp.com", IMGIX_URL: 'https://coast-staging.imgix.net', FORM_SUBMITTED_REDIRECT_URL: 'https://coastdev.wpengine.com/success', SEGMENT_API_KEY: 'lMJb4Q6F71ShGZ0ikCp25YLHmqU4ZekS', INTERCOM_APP_ID: 'c727b9fl', ONESIGNAL_APP_ID: '2e31da7c-d7a1-4f1d-be99-a6af5615d1d2', BRANCH_API_KEY: 'key_test_miDHzjxDEiBCbL7cWEwu0miiCuomYxro', STRIPE_API_KEY: 'pk_test_sXdfX0kUywpMgtEse5xMTcV700bQMQdSuD', STRIPE_PLANS: {
        starter: {
            monthly: { price: 5, priceId: 'price_1HpIA5HcHJNZqWD3oHGeaT0Z' },
            yearly: { price: 4, priceId: 'price_1HpIA5HcHJNZqWD3X9xiaJfJ' },
        },
        plus: {
            monthly: { price: 10, priceId: 'price_1HpICxHcHJNZqWD35BN9ZsZh' },
            yearly: { price: 8, priceId: 'price_1HpICxHcHJNZqWD3inc1t8Cp' },
        },
    }, LAUNCH_DARKLY_CLIENT_KEY: '6596c515d2f65310428fee41', LAUNCH_DARKLY_MOBILE_KEY: 'mob-3e6a4cfa-4343-4cee-bcfe-a3ce37666c36' });
