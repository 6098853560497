import * as React from 'react';
function SvgLeaf(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 16c4.2-4.2 15-6 15-6s-1.4 10.4-6 15c-4.9 4.9-10.7 1.7-10.7 1.7S10 21 15 16zM21 19L10 30"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgLeaf;

