import gql from 'graphql-tag';
import { apolloClient } from '@shared/api/client';
import { MBSentry } from '@shared/util/sentry';
import { isNil } from 'lodash';

// TODO: this is a MAJOR hack...
export default async function fetchAndInsertUserIdIntoStore(dispatch) {
  try {
    const query = gql`query FetchMyUserId { me { id } }`;
    // Note: we need to set fetchPolicy to "network-only".
    // Otherwise Apollo throws an error because it's not sure
    // whether to use the cached result or do a network query.
    // This is specific to querying directly. If this were used
    // in a component, Apollo would go to the cache first and
    // then to the network.
    const result = await apolloClient.query({ query, fetchPolicy: 'network-only' });
    if (!isNil(result?.data?.me?.id)) {
      dispatch({ type: 'SET_USER_ID', id: result.data.me.id });
    }
  } catch (error) {
    MBSentry.exception({ error });
    // As long as we fail, wait a while and retry. We really need to get the
    // user's id into the store... 😞
    await new Promise(resolve => setTimeout(resolve, 1000));
    fetchAndInsertUserIdIntoStore(dispatch);
  }
}
