import * as React from 'react';
function SvgDeliveryCart(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 9h3v15h19M14 31a2 2 0 100-4 2 2 0 000 4zM29 31a2 2 0 100-4 2 2 0 000 4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M31 9H16v11h15V9zM16 13h15"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgDeliveryCart;

