"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSearchBarWithBackButton = void 0;
var react_1 = __importStar(require("react"));
var ms = __importStar(require("milliseconds"));
var react_native_1 = require("react-native");
var analytics_1 = require("@shared/util/analytics");
var animation_1 = require("@shared/components/animation");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function startLayoutAnimation() {
    if (!!react_native_1.LayoutAnimation) {
        react_native_1.LayoutAnimation.configureNext(react_native_1.LayoutAnimation.Presets.easeInEaseOut);
    }
}
var MBSearchBarWithBackButton = function (_a) {
    var _b = _a.autoFocus, autoFocus = _b === void 0 ? false : _b, _c = _a.placeholder, placeholder = _c === void 0 ? (0, i18n_1.i18n)('search.searchChats') : _c, text = _a.text, onChangeText = _a.onChangeText, onFocus = _a.onFocus, onBlur = _a.onBlur, onSubmitEditing = _a.onSubmitEditing;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _d = react_1.default.useState(text), value = _d[0], setValue = _d[1];
    var _e = react_1.default.useState(autoFocus), focused = _e[0], setFocused = _e[1];
    var handleChangeText = react_1.default.useCallback(function (updatedValue) {
        setValue(updatedValue);
        onChangeText(updatedValue);
    }, [setValue, onChangeText]);
    // When the clear button is pressed, we just want to empty the text input
    var handleClear = react_1.default.useCallback(function () { return handleChangeText(''); }, [handleChangeText]);
    // But when pressing the back button, we both want to empty the text input
    // and dismiss the keyboard. This will call onBlur, which in turn will
    // call onChangeSearching(false);
    var handleBack = react_1.default.useCallback(function () {
        analytics_1.MBAnalytics.track('Search::Bar::Back');
        handleChangeText('');
        react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss();
    }, [handleChangeText]);
    var handleBlur = react_1.default.useCallback(function () {
        setFocused(false);
        // When we start or stop searching we want to animate the
        // back button appearing/disappearing. Ideally this would
        // work using "React.useEffect(..., [isSearching]);"
        // However, that doesn't seem to be the case. Presumably
        // because configureNext should be called before the render function.
        startLayoutAnimation();
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    }, [setFocused, onBlur]);
    var handleFocus = react_1.default.useCallback(function () {
        analytics_1.MBAnalytics.track('Search::Bar::Focus');
        setFocused(true);
        // Note(fant): see the comment about startLayoutAnimation in handleBlur
        startLayoutAnimation();
        onFocus === null || onFocus === void 0 ? void 0 : onFocus();
    }, [setFocused, onFocus]);
    // When we start/stop searching, call onChangeSearching.
    // On native, this will toggle the channel list header
    // from showing.
    var isSearching = focused || !!value;
    // Note(fant): why Style.statusBarMarginTop ?
    // On native, we hide the native navigation header when searching. We call
    // this "search focus mode". When this happens, react-navigation will not
    // render any header OR status bar, which means that the content would be
    // underneath the native status bar. To avoid this, we add a small top
    // margin while in focus more so that the search bar does not collide with
    // the native status bar.
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, padding: 2 },
        isSearching && (react_1.default.createElement(animation_1.MBAnimationBounceIn, { delay: ms.seconds(0.2) },
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handleBack },
                react_1.default.createElement(components_1.MBView, { paddingRight: 0.5 },
                    react_1.default.createElement(components_1.MBIcon, { color: colors.foregroundActive, noPadding: true, size: "small", source: require('@shared/assets/icons/arrows/left-border.png') }))))),
        react_1.default.createElement(components_1.MBView, { flex: true },
            react_1.default.createElement(components_1.MBSearchBar, { value: value, autoFocus: autoFocus, hideIcon: isSearching, onBlur: handleBlur, onFocus: handleFocus, onClear: handleClear, onChangeText: handleChangeText, onSubmitEditing: onSubmitEditing, placeholder: placeholder, returnKeyType: "search" }))));
};
exports.MBSearchBarWithBackButton = MBSearchBarWithBackButton;
