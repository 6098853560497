import * as React from 'react';
function SvgConstructionSign(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 11H9a1 1 0 00-1 1v9a1 1 0 001 1h22a1 1 0 001-1v-9a1 1 0 00-1-1zm-1 7l-5-5h5v5zm-3 2h-7l-7-7h7l7 7zm-17-5l5 5h-5v-5zM28 9h-2v2h2V9zM14 9h-2v2h2V9zM14 23h-2v6.018H9V31h8v-1.982h-3V23zM28 29.018V23h-2v6.018h-3V31h8v-1.982h-3z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgConstructionSign;

