import * as React from 'react';
function SvgChest(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 198 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 52.432h93.136v88.593H9a2 2 0 01-2-2V52.432zM102.407 52.432H191v86.593a2 2 0 01-2 2h-86.593V52.432z"
        stroke="#DAF6F0"
        strokeWidth={13}
      />
      <path
        d="M100.136 53.568a46.568 46.568 0 00-93.136 0h93.136z"
        stroke="#DAF6F0"
        strokeWidth={13}
      />
      <path
        d="M103.543 53.568H191A46.565 46.565 0 00144.432 7H54.704"
        stroke="#DAF6F0"
        strokeWidth={13}
      />
      <circle cx={147.84} cy={84.235} r={4.543} fill="#DAF6F0" stroke="#DAF6F0" strokeWidth={13} />
      <path stroke="#DAF6F0" strokeWidth={13} d="M147.525 91.049v15.902" />
    </svg>
  );
}
export default SvgChest;

