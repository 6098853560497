/* @flow */

import type { RouteType, ParamsType, RouteOptions } from '@src/types/RouterTypes';
import type { ChannelTypes } from '@src/types/ChannelTypes';

function popRoute() {
  history.back();
}

function pushRoute(options: RouteOptions) {
  window.location = options;
}

function replaceRoute(options: RouteOptions) {
  history.replace(options);
}

function getRoutePrefix(routes: Array<RouteType>) {
  const route = (routes.find(r => r.prefix));
  const prefix = (route && route.prefix) || '/';
  return prefix;
}

type PartialChannel = {
  type: ChannelTypes,
  slug: string,
};

function createURLFromChannel(channel: PartialChannel) {
  return `/${channel.slug}`;
}

function createURLFromParams(params: ParamsType, routes: Array<RouteType>) {
  const { channelSlug, parentChannelSlug } = params;
  if (channelSlug || parentChannelSlug) {
    const prefix = getRoutePrefix(routes);
    const parentSlug = parentChannelSlug ? `${parentChannelSlug}/` : '';
    const slug = channelSlug ? `${channelSlug}/` : '';
    return `${prefix}${parentSlug}${slug}`;
  }
  return '/';
}

const actions = { popRoute, pushRoute, replaceRoute };
const utils = { getRoutePrefix, createURLFromChannel, createURLFromParams };

export {
  utils,
  actions,
};
