"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mutations = void 0;
var auth = __importStar(require("./auth"));
var business = __importStar(require("./business"));
var billing = __importStar(require("./billing"));
var channel = __importStar(require("./channel"));
var chat = __importStar(require("./chat"));
var thread = __importStar(require("./thread"));
var tool = __importStar(require("./tool"));
var user = __importStar(require("./user"));
var cards = __importStar(require("./cards"));
var entity = __importStar(require("./entity"));
var viewTemplate = __importStar(require("./viewTemplate"));
var workflow = __importStar(require("./workflow"));
var dashboard = __importStar(require("./dashboard"));
var _export = __importStar(require("./export"));
var automation = __importStar(require("./automation"));
/*
    In the shared components API layer, we try to make the business logic code as clean
    as possible. Therefore we have multiple params in the query instead of just passing
    in a mysterious "input" prop to variables.

    This:
    { variables: { id, ...update } }

    is nicer than this:
    { variables: { input: { id, ...update} } }

*/
exports.Mutations = {
    auth: auth,
    business: business,
    billing: billing,
    channel: channel,
    chat: chat,
    thread: thread,
    tool: tool,
    user: user,
    cards: cards,
    entity: entity,
    viewTemplate: viewTemplate,
    workflow: workflow,
    dashboard: dashboard,
    _export: _export,
    automation: automation,
};
