import * as React from 'react';
function SvgAddReactions(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 20h8M30 16v8"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.96 22.976a2.937 2.937 0 01-2.636-1.638h-2.04a4.853 4.853 0 009.35 0h-2.04a2.937 2.937 0 01-2.635 1.638z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.96 26.011a6.052 6.052 0 115.91-7.35h1.942A7.96 7.96 0 006 19.959a7.96 7.96 0 0015.812 1.299H19.87a6.053 6.053 0 01-5.91 4.753z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgAddReactions;

