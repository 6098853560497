import * as React from 'react';
function SvgSendOutline(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30 10L15 22v7l3.6-4.3" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M10 18l20-8-4 20-16-12z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgSendOutline;

