"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputButton = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var CardCollectionViewFieldContainer_1 = require("../../fields/CardCollectionViewFieldContainer");
var provider_1 = require("./provider");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var styles_2 = require("@shared/components/button/styles");
var SingleComponentForm_1 = require("@shared/scenes/cards/singleComponentForms/SingleComponentForm");
var provider_2 = require("@shared/components/bottomSheet/provider");
var InputButton = function (props) {
    if (!props.canUpdateCard) {
        return null;
    }
    return react_1.default.createElement(InputButtonInner, __assign({}, props));
};
exports.InputButton = InputButton;
var InputButtonForm = function (_a) {
    var fieldName = _a.fieldName, buttonRef = _a.buttonRef, workflowTemplate = _a.workflowTemplate, onClose = _a.onClose;
    var loading = (0, provider_1.useLoadingAndError)().loading;
    var field = (0, provider_1.useField)(fieldName);
    var onUpdateField = (0, provider_1.useUpdateField)();
    return (react_1.default.createElement(SingleComponentForm_1.SingleComponentForm, { fieldName: fieldName, field: field, buttonRef: buttonRef, workflowTemplate: workflowTemplate, onClose: onClose, loading: loading, onUpdateField: onUpdateField }));
};
var InputButtonInner = function (_a) {
    var component = _a.component, style = _a.style, workflowTemplateId = _a.workflowTemplateId, entityId = _a.entityId, analytics = _a.analytics, _b = _a.paddingTop, paddingTop = _b === void 0 ? 0 : _b, isFullWidth = _a.isFullWidth;
    var buttonRef = (0, react_1.useRef)(null);
    var _c = (0, react_1.useState)(false), showForm = _c[0], setShowForm = _c[1];
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var onPress = function () {
        analytics.trackButtonClick(entityId);
        setShowForm(function (prev) { return !prev; });
    };
    return (react_1.default.createElement(CardCollectionViewFieldContainer_1.CardCollectionViewFieldContainer, { style: [style, { paddingTop: paddingTop }] },
        react_1.default.createElement(react_native_1.View, { style: { width: '100%' }, ref: buttonRef },
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
                react_1.default.createElement(components_1.MBView, { center: true, paddingHorizontal: 'normal', style: [
                        styles_2.Styles.button,
                        styles_2.Styles.buttonSizeSmall,
                        {
                            backgroundColor: colors.button.backgroundInverted,
                            borderColor: showForm ? styles_1.Colors.v2.gray30 : colors.button.foregroundInverted,
                            alignSelf: 'flex-start',
                            width: isFullWidth ? '100%' : 'auto',
                        },
                    ] },
                    react_1.default.createElement(components_1.MBTextBody2Bold, { numberOfLines: 1, ellipsizeMode: 'tail', color: showForm ? styles_1.Colors.v2.gray30 : colors.button.foregroundInverted }, component.buttonText))),
            showForm && (react_1.default.createElement(provider_2.MBBottomSheetProvider, null,
                react_1.default.createElement(provider_1.CardProvider, { cardId: entityId },
                    react_1.default.createElement(InputButtonForm, { fieldName: component.inputComponentId, buttonRef: buttonRef, onClose: function () { return setShowForm(false); }, workflowTemplate: workflowTemplate })))))));
};
