"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBIconButton = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var MBIconButton = function (_a) {
    var source = _a.source, inverted = _a.inverted, onPress = _a.onPress, _b = _a.size, size = _b === void 0 ? 'normal' : _b, overrideColor = _a.color, style = _a.style, overrideBackgroundColor = _a.backgroundColor, testID = _a.testID;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var color = !!overrideColor
        ? overrideColor
        : inverted
            ? colors.button.foregroundInverted
            : colors.button.foreground;
    var backgroundColor = !!overrideBackgroundColor
        ? overrideBackgroundColor
        : inverted
            ? colors.button.backgroundInverted
            : colors.button.background;
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !onPress, onPress: onPress, testID: testID },
        react_1.default.createElement(components_1.MBBoxedIcon, { size: size, source: source, color: color, backgroundColor: backgroundColor, style: style })));
};
exports.MBIconButton = MBIconButton;
exports.MBIconButton.displayName = 'MBIconButton';
