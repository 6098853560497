/* @flow */
/* eslint no-console: 0 */
import { MBSentry } from '@shared/util/sentry';

type Level = 'critical' | 'error' | 'warning' | 'info' | 'debug';

function getFunctionNameFromLevel(level) {
  switch (level) {
    case 'critical':
    case 'error':
    case 'warning':
    case 'info':
    case 'debug':
      return level;

    default:
      return 'error';
  }
}

export function logError(error: Error, context?: Object, level?: Level = 'error'): void {
  MBSentry.exception({
    error,
    data: context,
  });

  if (__DEV__) {
    console.error('[ErrorLogger]', level, error, context);
  }
}

export function logInfo(info: string, context?: Object, level?: Level = 'info'): void {
  MBSentry.breadcrumb({
    level,
    data: context,
    message: info,
    category: 'legacy-logger',
  });

  if (__DEV__) {
    console.info('[ErrorLogger]', level, info, context);
  }
}

export function logTelemetry(info: Object, level?: Level = 'info'): void {
  if (__DEV__) {
    console.info('[ErrorLogger] telemetry:', level, info);
  }
}
