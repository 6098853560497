import * as React from 'react';
function SvgArrowRepeat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 20c0-5.523 4.477-10 10-10a9.999 9.999 0 018.933 5.501M30 20c0 5.523-4.477 10-10 10a9.999 9.999 0 01-8.933-5.501"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M29.791 9.673l-.796 5.947-5.947-.796M10.209 30.327l.796-5.947 5.947.796"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M20 22a2 2 0 100-4 2 2 0 000 4z" fill={props.color} />
    </svg>
  );
}
export default SvgArrowRepeat;

