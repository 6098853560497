import * as React from 'react';
function SvgTimeHourglass(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 9h18M11 31h18"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M16 28v-.789a1 1 0 01.167-.553l3-4.5a.998.998 0 011.664 0l3 4.5a1 1 0 01.168.555V28H16z"
        fill={props.color}
      />
      <path
        d="M13 31v-3.789a4 4 0 01.672-2.219L17 20l-3.328-4.992A4 4 0 0113 12.789V9M27 9v3.789a4 4 0 01-.672 2.219L23 20l3.328 4.992A4 4 0 0127 27.211V31"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgTimeHourglass;

