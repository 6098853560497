import * as React from 'react';
function SvgBoard(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.8 7H9v11.733h8.8V7zM17.8 23.133H9V29h8.8v-5.867zM31 7h-8.8v7.333H31V7zM31 18.733h-8.8V29H31V18.733z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgBoard;

