/* @flow */
import React from 'react';

export default function CloseIcon() {
  return (
    <svg width="9" height="10" viewBox="0 0 9 10">
      <g stroke="inherit" fill="inherit" fillRule="evenodd">
        <path d="M8.867 9.098L.382.612M8.867.612L.382 9.098" />
      </g>
    </svg>
  );
}
