"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBConnectionBanner = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var i18n_1 = require("@shared/util/i18n");
var ms = __importStar(require("milliseconds"));
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var bannerIcon_1 = require("./bannerIcon");
var styles_1 = require("./styles");
/*
    Connection banner than shows the current connection status. When
    changing to connected, the banner will show for a while before hiding.

    Note that the animations aren't CSS animations on web, so they
    don't look as smooth as they could.
*/
var MBConnectionBanner = /** @class */ (function (_super) {
    __extends(MBConnectionBanner, _super);
    function MBConnectionBanner() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animation = new react_native_1.Animated.Value(0);
        _this.hideBanner = function () {
            return react_native_1.Animated.timing(_this.animation, {
                toValue: 0,
                duration: ms.seconds(0.3),
                useNativeDriver: false,
            }).start();
        };
        _this.showBanner = function () {
            return react_native_1.Animated.timing(_this.animation, {
                toValue: 1,
                duration: ms.seconds(0.3),
                useNativeDriver: false,
            }).start();
        };
        return _this;
    }
    MBConnectionBanner.prototype.componentDidMount = function () {
        this.willUpdateStatus(this.props.status);
    };
    // TODO(fant): move this over to getDerivedStateFromProps
    MBConnectionBanner.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        if (nextProps.status !== this.props.status) {
            this.willUpdateStatus(nextProps.status);
        }
    };
    MBConnectionBanner.prototype.willUpdateStatus = function (nextStatus) {
        var wasConnected = this.props.status === 'online';
        var willBeConnected = nextStatus === 'online';
        if (!wasConnected && willBeConnected) {
            // If the banner was visible and is no longer, set a timeout to hide it in 1s
            if (!this.hideTimeout) {
                this.hideTimeout = global.setTimeout(this.hideBanner, ms.seconds(1));
            }
        }
        else {
            // Otherwise, clear the timeout if it's set. Otherwise the banner might be
            // hidden although it shouldn't when switching between connection statuses
            // quickly.
            if (!!this.hideTimeout) {
                clearTimeout(this.hideTimeout);
                this.hideTimeout = null;
            }
        }
        // Show the animation if we will become connected
        if (!willBeConnected) {
            this.showBanner();
        }
    };
    MBConnectionBanner.prototype.render = function () {
        var _a = this.props, status = _a.status, animationsDisabled = _a.animationsDisabled;
        var isDisconnected = this.props.status === 'offline';
        var height = this.animation.interpolate({
            inputRange: [0, 1],
            outputRange: [0, styles_1.UnvalidatedStyles.banner.height],
        });
        var textColor = styles_1.UnvalidatedStyles.bannerTextColor[status];
        return (React.createElement(react_native_1.Animated.View, { style: [
                styles_1.Styles.overflowWrapper,
                !animationsDisabled && { height: height },
                !!animationsDisabled && { height: styles_1.UnvalidatedStyles.banner.height },
            ] },
            React.createElement(components_1.MBView, { center: true, row: true, fill: true, style: [
                    status === 'online' && styles_1.Styles.bannerConnected,
                    status === null && styles_1.Styles.bannerConnecting,
                    status === 'offline' && styles_1.Styles.bannerDisconnected,
                ] },
                React.createElement(components_1.MBText, { bold: isDisconnected, color: textColor }, (0, i18n_1.i18n)("connection.title.".concat(status))),
                React.createElement(bannerIcon_1.MBConnectionBannerIcon, { color: textColor, status: this.props.status }))));
    };
    return MBConnectionBanner;
}(React.PureComponent));
exports.MBConnectionBanner = MBConnectionBanner;
