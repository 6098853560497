import * as React from 'react';
function SvgIdSymbol(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 11a2 2 0 012 2v13a2 2 0 01-2 2H11a2 2 0 01-2-2V13a2 2 0 012-2h18zM15 24v-8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M21.494 25h-3.61V14.818h3.64c1.024 0 1.906.204 2.645.612.739.404 1.307.986 1.705 1.745.401.759.602 1.667.602 2.724 0 1.06-.2 1.972-.602 2.735a4.134 4.134 0 01-1.715 1.755c-.742.407-1.63.611-2.665.611zm-1.456-1.845h1.367c.636 0 1.171-.112 1.605-.338.438-.228.766-.581.985-1.058.222-.481.333-1.1.333-1.86 0-.752-.111-1.367-.333-1.844a2.157 2.157 0 00-.98-1.054c-.434-.226-.969-.338-1.605-.338h-1.372v6.492z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgIdSymbol;

