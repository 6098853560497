import * as React from 'react';
function SvgGlassesToast(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 15h8v6a4 4 0 01-4 4v0a4 4 0 01-4-4v-6z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M14 25v6M11 31h6"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M22 15h8v6a4 4 0 11-8 0v-6z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M26 25v6M23 31h6M20 9v2M16 12l-1-1M24 12l1-1M10 19h8M22 19h8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgGlassesToast;

