import * as React from 'react';
function SvgFaceRobot(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13 9v5" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M11 9h4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M27 9v5" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M25 9h4M26 23H14v4h12v-4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M18 27v-4M22 27v-4" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M14 19h2M24 19h2M31 14H9v17h22V14z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgFaceRobot;

