import * as React from 'react';
function SvgDepartments(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 27h-4v4h4v-4zM22 9h-4v4h4V9zM31 27h-4v4h4v-4zM13 27H9v4h4v-4zM20 17v6M11 23v-3h18v3"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgDepartments;

