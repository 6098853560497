import * as React from 'react';
function SvgStoreShop(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 21v9h16v-9M12 10l-3 5a2.992 2.992 0 005.667 1.347 2.977 2.977 0 005.333 0 2.977 2.977 0 005.333 0A2.992 2.992 0 0031 15l-3-5H12z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M18 30v-6h4v6" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
    </svg>
  );
}
export default SvgStoreShop;

