import * as React from 'react';
function SvgArrowOutlineLeft(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={20}
        cy={20}
        r={12}
        transform="rotate(-180 20 20)"
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M26 20H14.23"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M19.77 14l-6 6 6 6" stroke={props.color} strokeWidth={2} />
    </svg>
  );
}
export default SvgArrowOutlineLeft;

