import * as React from 'react';
function SvgCalendarSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={props.color}
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M2 2h20v20H2z"
      />
      <path stroke={props.color} strokeWidth={3} d="M2 7.5h20" />
    </svg>
  );
}
export default SvgCalendarSmall;

