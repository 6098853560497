"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedStyles = exports.SharedUnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
var colors_1 = require("./colors");
__exportStar(require("./colors"), exports);
__exportStar(require("./theme"), exports);
__exportStar(require("./constants"), exports);
exports.SharedUnvalidatedStyles = {
    borderRadius: {
        borderRadius: 10,
    },
    shadow: react_native_1.Platform.select({
        web: {
            boxShadow: '2px 2px 10px rgba(0,0,0,0.2)',
        },
        android: {
            // Shadow on Android is tricky. One reason is the view clipping.
            // https://stackoverflow.com/questions/41320131
            // This solution is not optimal as the shadow will still be clipped.
            elevation: 2,
            // To hackily solve the view clipping, we add a margin so that the
            // shadow isn't clipped directly. Note: if this interferes badly
            // with layout, we should remove it. This is hacky...
            margin: 2,
        },
        default: {
            shadowColor: 'rgba(0,0,0,0.3)',
            shadowRadius: 4,
            shadowOpacity: 1,
            shadowOffset: { width: 2, height: 2 },
        },
    }),
};
exports.SharedStyles = react_native_1.StyleSheet.create({
    fill: {
        flex: 1,
        alignSelf: 'stretch',
    },
    full: {
        width: '100%',
        height: '100%',
    },
    centerChildren: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    backdrop: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    absoluteCover: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    topRight: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    bottomRight: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    topLeft: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    bottomLeft: {
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    dashedBorder: {
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: colors_1.Colors.gray.border,
    },
    shadow: exports.SharedUnvalidatedStyles.shadow,
    borderRadius: exports.SharedUnvalidatedStyles.borderRadius,
});
