"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeveloperTools = exports.DEV_TOOLS_FEATURE_FLAG = void 0;
var util_1 = require("@shared/scenes/auth/util");
var react_1 = require("react");
var hooks_1 = require("../cards/hooks");
exports.DEV_TOOLS_FEATURE_FLAG = 'DevTools';
var useDeveloperTools = function (channel) {
    var definitionsData = (0, hooks_1.useCardDefinitions)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId);
    return (0, react_1.useMemo)(function () { return ({
        definitions: definitionsData.cardDefinitions,
        localToken: util_1.MBAuthUtil.getAuthToken(),
    }); }, [definitionsData]);
};
exports.useDeveloperTools = useDeveloperTools;
