import * as React from 'react';
function SvgTruckFront(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 28v3h-4v-3M16 28v3h-4v-3M28 20V9H12v11l-2 2v6h20v-6l-2-2z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M25 16H15v4h10v-4z" fill={props.color} />
      <path
        d="M9 16v2M31 16v2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M14 25a1 1 0 100-2 1 1 0 000 2zM26 25a1 1 0 100-2 1 1 0 000 2z" fill={props.color} />
      <path d="M12 13h16" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
    </svg>
  );
}
export default SvgTruckFront;

