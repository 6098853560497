"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatInputMentionList = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var rowWithOnPress_1 = require("../rowWithOnPress");
var styles_1 = require("../styles");
var keyExtractor = function (item) { return String(item.id); };
/*
    This mention list is somewhat complex and does multiple things:

    * On mount we start intercepting keystrokes. When the user presses
      ⬆️ or ⬇️ we navigate in the list of mentions . If we press <enter>,
      we call onPress{User|Group}
    * When the selected index updates, we make sure to scroll to that
      index so that the selected item is always visible.
    * When hovering over an index, we don't show the selected index
      to avoid confusion. Only one item should look selected at a time.
    * When updating the content of the list, we reset the selected index
      so that it always starts at the top.
*/
var MBChatInputMentionList = /** @class */ (function (_super) {
    __extends(MBChatInputMentionList, _super);
    function MBChatInputMentionList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.sectionList = React.createRef();
        _this.state = {
            selectedIndex: 0,
            hovering: false,
        };
        // We keep track of if we're currently hovering over an item.
        // If we are, we don't want to show the currently selected
        // index (controlled by the keyboard arrows).
        _this.onHoverStart = function () { return _this.setState({ hovering: true }); };
        _this.onHoverEnd = function () { return _this.setState({ hovering: false }); };
        _this.renderGroupItem = function (_a) {
            var index = _a.index, item = _a.item;
            var group = item;
            return (React.createElement(rowWithOnPress_1.MBChatInputMentionRowWithOnPress, { key: group.id, title: "".concat(group.name), description: group.description, item: group, onPress: _this.props.onPressGroup, image: React.createElement(components_1.MBUserCollage, { size: "small", users: group.users }), selected: !_this.state.hovering && _this.state.selectedIndex === index, onHoverEnd: _this.onHoverEnd, onHoverStart: _this.onHoverStart }));
        };
        _this.renderUserItem = function (_a) {
            var index = _a.index, item = _a.item;
            var user = item;
            // Note(fant): for users, we need to find the currently
            // hovered index in the user list.
            var selectedIndexInSection = _this.state.selectedIndex - _this.props.groups.length;
            return (React.createElement(rowWithOnPress_1.MBChatInputMentionRowWithOnPress, { key: user.id, title: "@".concat(user.username), item: user, onPress: _this.props.onPressUser, image: React.createElement(components_1.MBUserImage, { size: "small", user: user }), selected: !_this.state.hovering && selectedIndexInSection === index, onHoverEnd: _this.onHoverEnd, onHoverStart: _this.onHoverStart }));
        };
        _this.handleWebKeyDown = function (event) {
            var listLength = _this.props.groups.length + _this.props.users.length;
            // In all events below, we prevent the chat input from being affected.
            switch (event.key) {
                case 'ArrowUp':
                    event.preventDefault();
                    // We add listLength so that pressing up when at index 0 goes to the last item
                    var prevIndex = (_this.state.selectedIndex + listLength - 1) % listLength;
                    _this.setState({ selectedIndex: prevIndex });
                    break;
                case 'ArrowDown':
                    event.preventDefault();
                    var nextIndex = (_this.state.selectedIndex + 1) % listLength;
                    _this.setState({ selectedIndex: nextIndex });
                    break;
                case 'Enter':
                case 'NumpadEnter':
                    event.preventDefault();
                    if (_this.state.selectedIndex < _this.props.groups.length) {
                        var group = _this.props.groups[_this.state.selectedIndex];
                        _this.props.onPressGroup(group);
                    }
                    else {
                        var index = _this.state.selectedIndex - _this.props.groups.length;
                        var user = _this.props.users[index];
                        _this.props.onPressUser(user);
                    }
                    break;
            }
        };
        return _this;
    }
    MBChatInputMentionList.prototype.componentDidMount = function () {
        if (react_native_1.Platform.OS === 'web') {
            // Why do we use "keydown" and not eg "keypress"?
            // Because arrow events aren't fired for "keypress".
            document.addEventListener('keydown', this.handleWebKeyDown);
        }
    };
    MBChatInputMentionList.prototype.componentWillUnmount = function () {
        if (react_native_1.Platform.OS === 'web') {
            document.removeEventListener('keydown', this.handleWebKeyDown);
        }
    };
    MBChatInputMentionList.prototype.componentWillUpdate = function (nextProps) {
        // We reset the selected index every time the list updates.
        // In this way, the selected item (controlled by up/down arrows
        // is always visible at the top when updating the list).
        if (nextProps.users.length !== this.props.users.length ||
            nextProps.groups.length !== this.props.groups.length) {
            this.setState({ selectedIndex: 0 });
        }
    };
    MBChatInputMentionList.prototype.componentDidUpdate = function (_a, prevState) {
        // When the selected index changes, we want to make sure that
        // the selected row is currently visible. viewPosition 0.5
        // makes the list scroll the item to the middle of the list.
        if (this.state.selectedIndex !== prevState.selectedIndex && !!this.sectionList.current) {
            if (this.state.selectedIndex < this.props.groups.length) {
                // If we're in the group section, scroll to the group item
                this.sectionList.current.scrollToLocation({
                    sectionIndex: 0,
                    itemIndex: this.state.selectedIndex,
                    viewPosition: 0.5,
                });
            }
            else {
                // Otherwise, translate the selected index to the index in the user list
                this.sectionList.current.scrollToLocation({
                    sectionIndex: 1,
                    itemIndex: this.state.selectedIndex - this.props.groups.length,
                    viewPosition: 0.5,
                });
            }
        }
    };
    MBChatInputMentionList.prototype.render = function () {
        // Keyboard prop below are to prevent the keyboard from being dismissed
        // when pressing an item in the list: https://stackoverflow.com/a/45884998/9117995
        // extraData makes the list re-render when the state is updated:
        // https://stackoverflow.com/a/47562302/9117995
        return (React.createElement(react_native_1.SectionList, { ref: this.sectionList, style: styles_1.Styles.mentionList, keyExtractor: keyExtractor, sections: [
                { data: this.props.groups, renderItem: this.renderGroupItem },
                { data: this.props.users, renderItem: this.renderUserItem },
            ], keyboardShouldPersistTaps: "always", extraData: this.state }));
    };
    return MBChatInputMentionList;
}(React.PureComponent));
exports.MBChatInputMentionList = MBChatInputMentionList;
