"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelTypeUtil = void 0;
var threadRegex = /^thread:([A-Za-z0-9-]*)$/;
/*
    Helper functions to hackily parse thread "fake" channel slugs
*/
exports.MBChannelTypeUtil = {
    isThread: function (channelSlug) {
        var match = channelSlug.match(threadRegex);
        return !!match;
    },
    parseThreadIdFromChannelSlug: function (channelSlug) {
        // [fullMatch: string, threadId: string]
        var match = channelSlug.match(threadRegex);
        if (!match) {
            return undefined;
        }
        return match[1];
    },
};
