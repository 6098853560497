"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/scenes/channel/create/components/styles");
exports.Styles = react_native_1.StyleSheet.create({
    iconNormal: {
        width: 48,
        height: 48,
        padding: 12,
    },
    iconSmall: {
        width: 24,
        height: 24,
        padding: 4,
    },
    iconLarge: {
        width: 64,
        height: 64,
        padding: 12,
    },
    boxedIconSmall: {
        borderRadius: 12,
        padding: 0,
    },
    boxedIconNormal: {
        borderRadius: 24,
        padding: 5,
    },
    boxedIconLarge: {
        borderRadius: 32,
        padding: 5,
    },
    noPadding: {
        padding: 0,
    },
    channelImage: {
        width: 48,
        height: 48,
        borderRadius: 24,
        overflow: 'hidden',
    },
    channelImageSmall: {
        width: 24,
        height: 24,
        borderRadius: 12,
        overflow: 'hidden',
    },
    channelImageLarge: {
        width: styles_1.SELECTED_CHANNEL_IMAGE_SIZE,
        height: styles_1.SELECTED_CHANNEL_IMAGE_SIZE,
        borderRadius: styles_1.SELECTED_CHANNEL_IMAGE_SIZE / 2,
        overflow: 'hidden',
    },
    businessImage: {
        width: 98,
        height: 98,
        borderRadius: 98 / 2,
        overflow: 'hidden',
    },
    userNormal: {
        width: 44,
        height: 44,
    },
    userSmall: {
        width: 24,
        height: 24,
    },
    collageNormal: {
        borderRadius: 22,
        overflow: 'hidden',
    },
    collageSmall: {
        borderRadius: 12,
        overflow: 'hidden',
    },
    userPlaceholderTextLarge: { fontSize: 28 },
    userPlaceholderTextSmall: { fontSize: 10 },
    userPlaceholderTextTiny: { fontSize: 7 },
    iconv2: {
        width: 40,
        height: 40,
    },
});
