import * as React from 'react';
function SvgGroup(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.3 18.7V5.3h5v13.4h-5z"
        fill="#281E38"
        stroke="#281E38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2 18.2V5.8h4v12.4h-4z"
        stroke="#281E38"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgGroup;

