"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardActions = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var button_1 = require("@shared/scenes/cards/actions/button");
var sheets_1 = require("@shared/scenes/cards/actions/sheets");
var i18n_1 = require("@shared/util/i18n");
var provider_1 = require("@shared/scenes/cards/actions/provider");
var react_redux_1 = require("react-redux");
var ScreenSize_1 = require("@shared/scenes/channel/webLayout/ScreenSize");
var MBCardActions = function () {
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var theme = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _a = (0, react_1.useState)(false), sheetActive = _a[0], setSheetActive = _a[1];
    var _b = (0, react_1.useContext)(provider_1.CardActionsStateContext), actionsPanelActive = _b.active, setActionsPanelActive = _b.setActive;
    var appliedFilters = (0, react_1.useContext)(provider_1.CardFilterStateContext).appliedFilters;
    var screenSize = (0, ScreenSize_1.useScreenSize)();
    var handleActivate = (0, react_1.useCallback)(function () { return setSheetActive(true); }, []);
    var handleDeactivate = (0, react_1.useCallback)(function () {
        setSheetActive(false);
        setActionsPanelActive(false);
    }, [setActionsPanelActive]);
    var popoverAnchorRef = (0, react_1.useRef)();
    var filtersButton = (0, react_1.useMemo)(function () {
        var title = isDesktop && screenSize !== 'large'
            ? ''
            : (0, i18n_1.i18n)('card.actions.filters.title', { count: appliedFilters.length });
        return (react_1.default.createElement(button_1.MBCardActionButton, { title: title, icon: "filter-funnel", active: appliedFilters.length > 0, onPress: handleActivate }));
    }, [appliedFilters, handleActivate, isDesktop, screenSize]);
    if (isDesktop) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(react_native_1.View, { style: styles.buttonRowHeader, testID: "header-filter-button" },
                filtersButton,
                react_1.default.createElement(react_native_1.View, { ref: popoverAnchorRef })),
            sheetActive && (react_1.default.createElement(sheets_1.MBCardActionsSheet, { onClose: handleDeactivate, containerRef: popoverAnchorRef }))));
    }
    if (appliedFilters.length === 0 && !(actionsPanelActive || sheetActive)) {
        return null;
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.View, { style: [styles.container, { backgroundColor: theme.background }] },
            react_1.default.createElement(react_native_1.View, { style: styles.title },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: theme.foregroundInactive }, (0, i18n_1.i18n)('card.actions.title'))),
            react_1.default.createElement(react_native_1.View, { style: styles.buttonRow }, filtersButton)),
        react_1.default.createElement(react_native_1.View, { ref: popoverAnchorRef }),
        (actionsPanelActive || sheetActive) && (react_1.default.createElement(sheets_1.MBCardActionsSheet, { onClose: handleDeactivate, containerRef: popoverAnchorRef }))));
};
exports.MBCardActions = MBCardActions;
exports.MBCardActions.displayName = 'MBCardActions';
var styles = react_native_1.StyleSheet.create({
    container: {
        flexDirection: 'column',
        padding: (0, components_1.grid)(2),
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: (0, components_1.grid)(1),
        elevation: (0, components_1.grid)(1),
    },
    title: {
        paddingLeft: (0, components_1.grid)(1),
        paddingBottom: (0, components_1.grid)(1),
    },
    buttonRow: {
        flexDirection: 'row',
        marginBottom: (0, components_1.grid)(2),
    },
    buttonRowHeader: {
        flexDirection: 'row',
        paddingRight: (0, components_1.grid)(2),
    },
});
