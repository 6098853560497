"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnvalidatedStyles = exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    container: {
        borderRadius: 20,
        overflow: 'hidden',
        backgroundColor: styles_1.Colors.v2.gray05,
    },
    square: {
        width: 120,
        height: 120,
        borderRadius: 20,
        backgroundColor: styles_1.Colors.v2.gray05,
    },
    border: {
        borderWidth: 1,
        borderColor: styles_1.Colors.v2.gray10,
        flexShrink: 1,
    },
    semitransparent: { opacity: 0.5 },
    wrap: { alignSelf: 'flex-start' },
});
exports.UnvalidatedStyles = {
    fallbackFileNameText: __assign({ alignSelf: 'stretch' }, react_native_1.Platform.select({
        web: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    })),
};
