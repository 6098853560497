"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTodoAddRow = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var i18n_1 = require("@shared/util/i18n");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var render_1 = require("@shared/components/blocks/contexts/render");
var uuid = __importStar(require("@shared/util/uuid"));
var hooks_1 = require("./hooks");
var react_redux_1 = require("react-redux");
var createTodo = function (summary) { return ({
    summary: summary,
    status: 'NEEDS-ACTION',
    id: uuid.v4(),
}); };
function useAddUnsubmittedValueInPresubmitHook(value, block) {
    var _this = this;
    var _a = (0, react_1.useContext)(form_1.CardFormContext), registerPresubmitHook = _a.registerPresubmitHook, unregisterPresubmitHook = _a.unregisterPresubmitHook;
    (0, react_1.useEffect)(function () {
        registerPresubmitHook(block.name, function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                if (!value) {
                    return [2 /*return*/];
                }
                return [2 /*return*/, (_a = {},
                        _a[block.name] = __spreadArray(__spreadArray([], (values[block.name] || []), true), [createTodo(value)], false),
                        _a)];
            });
        }); });
        return function () { return unregisterPresubmitHook(block.name); };
    }, [value, block.name]);
}
var MBTodoAddRow = function (_a) {
    var block = _a.block, onAdd = _a.onAdd, onBlur = _a.onBlur;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var _b = (0, react_1.useState)(''), value = _b[0], setValue = _b[1];
    useAddUnsubmittedValueInPresubmitHook(value, block);
    var scrollTextInputIntoView = (0, hooks_1.useScrollTextInputIntoView)();
    var handleAdd = (0, react_1.useCallback)(function () {
        if (!!(value === null || value === void 0 ? void 0 : value.trim())) {
            onAdd([createTodo(value.trim())]);
            setTimeout(scrollTextInputIntoView);
        }
        // https://github.com/necolas/react-native-web/issues/1013#issuecomment-402548880
        setTimeout(function () { return setValue(''); }, 1);
    }, [onAdd, value]);
    // Inspired by:
    // https://github.com/necolas/react-native-web/blob/7ef070195bbdec4d776d1fae8f98914fe2574d58/packages/react-native-web/src/exports/TextInput/index.js#L281
    var handleNativeKeyPress = (0, react_1.useCallback)(function (event) {
        var _a;
        if (event.nativeEvent.key === 'Enter' && !event.shiftKey) {
            (_a = event.preventDefault) === null || _a === void 0 ? void 0 : _a.call(event);
            handleAdd();
        }
    }, [handleAdd]);
    var handlePaste = (0, react_1.useCallback)(function (pastedValue) {
        var lines = pastedValue.split('\n').filter(function (line) { return !!line; });
        if (lines.length === 1) {
            return true; // will not prevent paste event default
        }
        else if (lines.length >= 2) {
            onAdd(lines.map(createTodo));
            setValue('');
            scrollTextInputIntoView();
        }
        return false;
    }, [onAdd]);
    return (react_1.default.createElement(components_1.MBView, { row: true, paddingHorizontal: 3 },
        react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !value, onPress: handleAdd },
            react_1.default.createElement(components_1.MBIconV2, { name: "plus-circle", color: colors.accent })),
        react_1.default.createElement(components_1.MBView, { paddingRight: renderContext === 'form' ? 2 : 1 }),
        isDesktop && (react_1.default.createElement(components_1.MBAutosizeTextInput, { autoFocus: block.autoFocus, blurOnSubmit: false, value: value, returnKeyType: "done", padding: "none", placeholder: block.placeholder || (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder'), style: { flex: 1, alignSelf: 'center', marginTop: 4, marginBottom: 4 }, color: colors.foregroundActive, onFocus: scrollTextInputIntoView, onChangeText: setValue, onPaste: handlePaste, onBlur: onBlur, onKeyPress: handleNativeKeyPress })),
        !isDesktop && (react_1.default.createElement(components_1.MBView, { style: { flex: 1, alignSelf: 'center' } },
            react_1.default.createElement(react_native_1.TextInput, { autoFocus: block.autoFocus, blurOnSubmit: false, textAlignVertical: 'center', style: [components_1.MBTypographyStyles.body1, { paddingTop: 0, paddingBottom: 0 }], value: value, returnKeyType: "done", placeholder: block.placeholder || (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder'), onFocus: scrollTextInputIntoView, onChangeText: setValue, onBlur: onBlur, multiline: true, onSubmitEditing: function () {
                    handleAdd();
                } })))));
};
exports.MBTodoAddRow = MBTodoAddRow;
