/* @flow */
import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { setSharedReduxStore } from '@shared/store/setSharedReduxStore';

import * as allReducers from './reducers';

// TODO(fant): how is the store being persisted?
// TODO(fant): src/redux/configureStore.js for web and
// native look basically the same. Port this to shared
export function configureStore() {
  const reducer = combineReducers(allReducers);
  // TODO(fant): do we want to reenable the logger?
  // const middlewares = applyMiddleware(thunk, logger);
  const middlewares = applyMiddleware(thunk);
  const store = createStore(reducer, middlewares);

  if (window.Cypress) {
    window.store = store;
  }

  setSharedReduxStore(store);
  return store;
}
