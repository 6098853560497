import * as React from 'react';
function SvgCar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M29 18l-2-8H13l-2 8" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M15 26v4h-4v-4M29 26v4h-4v-4M31 26H9v-6l2-2h18l2 2v6zM13 22h2M25 22h2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgCar;

