"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelDetailsEditor = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var i18n_1 = require("@shared/util/i18n");
var selectChannelImage_1 = require("../../create/containers/selectChannelImage");
var useChannelDetails_1 = require("../../detailsForm/hooks/useChannelDetails");
var react_native_1 = require("react-native");
var MBChannelDetailsEditor = function (_a) {
    var isWorkflow = _a.isWorkflow, _b = _a.autoFocusField, autoFocusField = _b === void 0 ? 'name' : _b;
    var _c = (0, useChannelDetails_1.useChannelDetails)(), name = _c.name, description = _c.description, emoji = _c.emoji, imageUrl = _c.imageUrl, workflowTemplateName = _c.workflowTemplateName, hasWorkflowTemplate = _c.hasWorkflowTemplate, updateName = _c.updateName, updateDescription = _c.updateDescription, updateEmoji = _c.updateEmoji, updateImage = _c.updateImage, removeImage = _c.removeImage, removeEmoji = _c.removeEmoji, startImageUpload = _c.startImageUpload, endImageUpload = _c.endImageUpload, updateWorkflowTemplateName = _c.updateWorkflowTemplateName;
    var onNameEntered = function (updatedName) {
        updateName(updatedName);
        if (react_native_1.Platform.OS === 'web') {
            updateWorkflowTemplateName(updatedName);
        }
    };
    var removeName = (0, react_1.useCallback)(function () { return updateName(''); }, [updateName]);
    var removeDescription = (0, react_1.useCallback)(function () { return updateDescription(''); }, [updateDescription]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !isWorkflow && (react_1.default.createElement(selectChannelImage_1.MBSelectChannelImage, { emoji: emoji, onChangeEmoji: updateEmoji, imageUrl: imageUrl, onChangeImageUrl: updateImage, onRemoveImage: removeImage, onRemoveEmoji: removeEmoji, onImageUploadStart: startImageUpload, onImageUploadEnd: endImageUpload })),
        react_1.default.createElement(components_1.MBView, { paddingTop: 2 }),
        react_1.default.createElement(components_1.MBFormField, { type: "string", value: name, onChange: onNameEntered, placeholder: isWorkflow
                ? (0, i18n_1.i18n)('channel.create.details.workflow.namePlaceholder')
                : (0, i18n_1.i18n)('channel.create.details.namePlaceholder'), icon: require('@shared/assets/icons/v2/avatar-add-group.png'), label: !!name
                ? isWorkflow
                    ? (0, i18n_1.i18n)('channel.options.workflowNameInputLabel')
                    : (0, i18n_1.i18n)('channel.options.groupNameInputLabel')
                : undefined, onClear: removeName, autoFocus: autoFocusField === 'name' }),
        hasWorkflowTemplate && react_native_1.Platform.OS === 'web' && (react_1.default.createElement(components_1.MBFormField, { type: "string", value: workflowTemplateName, onChange: updateWorkflowTemplateName, placeholder: (0, i18n_1.i18n)('channel.create.details.workflowPlaceholder'), icon: require('@shared/assets/icons/v2/avatar-add-group.png'), label: (0, i18n_1.i18n)('channel.options.workflowNameInputLabel'), onClear: function () { return updateWorkflowTemplateName(''); }, autoFocus: autoFocusField === 'name' })),
        !isWorkflow && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(components_1.MBView, { paddingTop: 2 }),
            react_1.default.createElement(components_1.MBFormField, { type: "multiline", value: description, onChange: updateDescription, placeholder: (0, i18n_1.i18n)('channel.create.details.descriptionPlaceholder'), icon: require('@shared/assets/icons/v2/multi-line.png'), label: !!description ? (0, i18n_1.i18n)('channel.options.groupDescriptionInputLabel') : undefined, onClear: removeDescription, autoFocus: autoFocusField === 'description' })))));
};
exports.MBChannelDetailsEditor = MBChannelDetailsEditor;
