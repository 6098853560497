/* @flow */
import { isValidPhoneNumber } from '@common/core/formatPhoneNumber';
import splitFirstLastName from '@common/core/splitFirstLastName';

import type {
  NewUser,
  UserCreateInputType as InputType,
  Errors as UserErrors,
} from '@src/types/UserTypes';

export function formatRawUser(draft: NewUser) {
  const { name, email, phone, img } = draft;

  const { firstName, lastName } = splitFirstLastName(name);
  const formattedDraft = {
    firstName,
    lastName,
    phoneNumber: phone || '',
    email: email || '',
    img: img || '',
  };

  return formattedDraft;
}

export function validateValue(draft: NewUser, type: InputType) {
  const { name, email, phone } = draft;

  switch (type) {
    case 'phone':
      return phone.length === 0 || isValidPhoneNumber(phone) ? null : 'Add a valid phone number';
    case 'email':
      return email.length === 0 || /\S+@\S+\.\S+/.test(email) ? null : 'Add a valid email';
    case 'name':
      if (name.length > 0 && email.length === 0 && phone.length === 0) {
        return 'Please add a phone number or email';
      }
      if (name.length === 0 && (email.length > 0 || phone.length > 0)) {
        return 'Please add a name';
      }
      return null;
    default:
      return null;
  }
}

export function getErrors(draft: NewUser) {
  return {
    name: validateValue(draft, 'name'),
    phone: validateValue(draft, 'phone'),
    email: validateValue(draft, 'email'),
    img: null,
  };
}

export function canSubmitUser(draft: NewUser, existingErrors: ?UserErrors = null) {
  const { name, email, phone } = draft;
  const errors = existingErrors || getErrors(draft);
  return (
    name.length > 0 &&
    !Object.keys(errors).some(err => errors[err]) &&
    (phone.length > 0 || email.length > 0)
  );
}
