import * as React from 'react';
function SvgIdBadge(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 20h-6v6h6v-6z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M25 14h4a2 2 0 012 2v13a2 2 0 01-2 2H11a2 2 0 01-2-2V16a2 2 0 012-2h4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M22 16h-4v-5c0-1.1.9-2 2-2s2 .9 2 2v5zM23 21h4M23 25h4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgIdBadge;

