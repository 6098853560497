"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockAddress = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var base_1 = require("../input/base");
var utils_1 = require("../input/utils");
var hooks_1 = require("@shared/util/hooks");
var styles_1 = require("@shared/styles");
var utils_2 = require("../../utils");
var useGooglePlacesAutocomplete_1 = require("@shared/util/googleMaps/useGooglePlacesAutocomplete");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var form_1 = require("../../contexts/form");
var use_layout_animation_1 = require("use-layout-animation");
var i18n_1 = require("@shared/util/i18n");
var content_1 = require("./content");
var MBCardInputBlockAddress = function (_a) {
    var block = _a.block;
    var containerRef = (0, react_1.useRef)();
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _b.setFieldValue, values = _b.values;
    var address = values[block.name];
    var modalVisible = (0, hooks_1.useToggle)();
    var _c = (0, react_1.useState)(''), searchQuery = _c[0], setSearchQuery = _c[1];
    var _d = (0, useGooglePlacesAutocomplete_1.useGooglePlacesAutocomplete)(!!searchQuery ? searchQuery : undefined), suggestions = _d.suggestions, loading = _d.loading;
    var fetchGooglePlacesCoords = (0, useGooglePlacesAutocomplete_1.useFetchGooglePlacesCoords)();
    var handleSelect = (0, react_1.useCallback)(function (suggestion) { return __awaiter(void 0, void 0, void 0, function () {
        var coords, updatedAddress;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    modalVisible.toggleOff();
                    return [4 /*yield*/, fetchGooglePlacesCoords(suggestion.googlePlaceId)];
                case 1:
                    coords = _a.sent();
                    updatedAddress = __assign(__assign({}, suggestion), { coords: coords });
                    setFieldValue(block.name, updatedAddress);
                    setSearchQuery('');
                    return [2 /*return*/];
            }
        });
    }); }, [fetchGooglePlacesCoords, block.name, setFieldValue]);
    var clearValue = (0, react_1.useCallback)(function () { return setFieldValue(block.name, undefined); }, [setFieldValue, block.name]);
    (0, use_layout_animation_1.useImmediateLayoutAnimation)([!!address]);
    var TypoComponent = (0, utils_2.getTypoComponent)(block.textStyle);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBTouchableOpacity, { ref: containerRef, onPress: modalVisible.toggleOn },
            react_1.default.createElement(base_1.MBCardInputBlockBase, { block: block, renderLabel: utils_1.renderLabel }, !address ? (react_1.default.createElement(TypoComponent, { color: colors.foregroundInactive }, block.placeholder)) : (react_1.default.createElement(content_1.AddressContent, { textStyle: block.textStyle, address: address, onClear: clearValue })))),
        modalVisible.isOn && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { containerRef: containerRef, layout: { offset: { x: (0, components_1.grid)(8), y: 0 } }, initialSize: "full", onClose: modalVisible.toggleOff },
            react_1.default.createElement(components_1.MBView, { padding: 1 },
                react_1.default.createElement(components_1.MBSearchBar, { value: searchQuery, onChangeText: setSearchQuery, autoFocus: true, placeholder: (0, i18n_1.i18n)("card.blocks.addressInput.searchPlaceholder") })),
            suggestions.map(function (suggestion) { return (react_1.default.createElement(components_1.MBListRow, { image: react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundInactive, name: "pin-location" }), paddingLeft: 1, key: suggestion.googlePlaceId, title: suggestion.title, subtitle: suggestion.subtitle, isHidingPressableIndicator: true, onPress: function () { return handleSelect(suggestion); } })); }),
            loading && react_1.default.createElement(components_1.MBLoadingIndicator, { padding: 2 })))));
};
exports.MBCardInputBlockAddress = MBCardInputBlockAddress;
