import * as React from 'react';
function SvgArmStrong(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 15c-.026 1.247.06 2.494.257 3.725.371 1.642 1.988 3.355 2.281 4.109 1.587-1.88 7.123-4 10.525-2.219 3.9 2.517 3.571 6.75 1.027 8.593a12.973 12.973 0 01-6.557 1.782 13.456 13.456 0 01-5.346-.619c-1.362.174-4.24.154-5.415-1.043-.806-.821-2.455-5.528-1.464-10.555C10.854 13.466 12.6 9 14.492 9c1.08-.1 2.165.109 3.131.6.7.569.2 2.267-.023 2.5a1.2 1.2 0 01.241 1.617 4.59 4.59 0 01-2.141 1.367c-.401.057-.81.029-1.2-.084v0zM19.5 26a9.259 9.259 0 007-1"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgArmStrong;

