"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAddFormRow = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var formField_1 = require("@shared/components/form/formField");
var i18n_1 = require("@shared/util/i18n");
var context_1 = require("../context");
var MBAddFormRow = function (_a) {
    var data = _a.data, index = _a.index, onChange = _a.onChange;
    var _b = (0, react_1.useContext)(context_1.InviteContext), onFocusNameInput = _b.onFocusNameInput, onFocusContactInput = _b.onFocusContactInput;
    var changeName = (0, react_1.useCallback)(function (name) { return onChange(__assign(__assign({}, data), { name: name, nameError: undefined }), index); }, [onChange, data, index]);
    var changePhoneNumberOrEmail = (0, react_1.useCallback)(function (phoneNumberOrEmail) {
        return onChange(__assign(__assign({}, data), { phoneNumberOrEmail: phoneNumberOrEmail, phoneNumberOrEmailError: undefined }), index);
    }, [onChange, data, index]);
    return (react_1.default.createElement(components_1.MBView, { row: true, paddingBottom: "normal" },
        react_1.default.createElement(components_1.MBView, { flex: true },
            react_1.default.createElement(formField_1.MBFormField, { error: data.nameError ? (0, i18n_1.i18n)('inviteSomeone.addForm.name.errorMessage') : undefined, children: function (props) { return (react_1.default.createElement(components_1.MBTextInput, __assign({}, props, { value: data.name || '', onChangeText: changeName, onFocus: onFocusNameInput, placeholder: (0, i18n_1.i18n)('inviteSomeone.addForm.name.placeholder', { index: index + 1 }) }))); } })),
        react_1.default.createElement(components_1.MBView, { paddingLeft: "normal" }),
        react_1.default.createElement(components_1.MBView, { flex: true },
            react_1.default.createElement(formField_1.MBFormField, { error: data.phoneNumberOrEmailError
                    ? (0, i18n_1.i18n)('inviteSomeone.addForm.phoneNumberOrEmail.errorMessage')
                    : undefined, children: function (props) { return (react_1.default.createElement(components_1.MBTextInput, __assign({}, props, { value: data.phoneNumberOrEmail || '', onChangeText: changePhoneNumberOrEmail, onFocus: onFocusContactInput, placeholder: (0, i18n_1.i18n)('inviteSomeone.addForm.phoneNumberOrEmail.placeholder') }))); } }))));
};
exports.MBAddFormRow = MBAddFormRow;
