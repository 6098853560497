import * as React from 'react';
function SvgPaperclip(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 32c-4.1 0-7.5-3.4-7.5-7.5v-11c0-3 2.5-5.5 5.5-5.5s5.5 2.5 5.5 5.5v10c0 1.9-1.6 3.5-3.5 3.5S16 25.4 16 23.5V15c0-.6.4-1 1-1s1 .4 1 1v8.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-10c0-1.9-1.6-3.5-3.5-3.5S14 11.6 14 13.5v11c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V12c0-.6.4-1 1-1s1 .4 1 1v12.5c0 4.1-3.4 7.5-7.5 7.5z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgPaperclip;

