"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderCardListSectionHeader = exports.MBSectionHeaderToggle = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var util_1 = require("@shared/scenes/cards/util");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var react_redux_1 = require("react-redux");
var MBSectionHeaderToggle = function (_a) {
    var section = _a.section;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (!section.title) {
        return null;
    }
    var collapsible = Boolean(section.toggleCollapsed);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !collapsible, onPress: section.toggleCollapsed, style: { backgroundColor: isDesktop ? colors.backgroundInactive : colors.background } },
        react_1.default.createElement(components_1.MBView, { row: true, paddingTop: 3, paddingBottom: 1, paddingHorizontal: 2, centerVertically: true, style: { justifyContent: 'flex-start', opacity: section.isArchived ? 0.5 : undefined } },
            react_1.default.createElement(components_1.MBView, null, section.title),
            !section.loading && (react_1.default.createElement(components_1.MBTextBody1, null, (0, util_1.getSectionCardCountString)(section.cardCount, section.hasMore))),
            collapsible && (react_1.default.createElement(components_1.MBView, { paddingLeft: 1 },
                react_1.default.createElement(components_1.MBIconV2, { color: styles_1.Colors.v2.purple, size: (0, components_1.grid)(2), name: section.collapsed ? 'arrow-point-down-heavy' : 'arrow-point-up-heavy' }))))));
};
exports.MBSectionHeaderToggle = MBSectionHeaderToggle;
exports.MBSectionHeaderToggle.displayName = 'MBSectionHeaderToggle';
var renderCardListSectionHeader = function (_a) {
    var section = _a.section;
    return react_1.default.createElement(exports.MBSectionHeaderToggle, { section: section });
};
exports.renderCardListSectionHeader = renderCardListSectionHeader;
