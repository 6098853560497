import * as React from 'react';
function SvgFlowerTulip(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 28v-5" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M28 23l-8 5-8-5v4a4 4 0 004 4h8a4 4 0 004-4v-4zM20 20a6 6 0 01-6-6v-4l3 2 3-2 3 2 3-2v4a6 6 0 01-6 6z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgFlowerTulip;

