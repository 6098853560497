"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardSelectionActionsProvider = exports.useCardSelectionActions = exports.CardSelectionStateProvider = exports.useCardSelectionState = void 0;
var context_1 = require("@shared/util/context");
var reducer_1 = require("./reducer");
exports.useCardSelectionState = (_a = (0, context_1.createCtx)(reducer_1.initialState), _a[0]), exports.CardSelectionStateProvider = _a[1];
exports.useCardSelectionActions = (_b = (0, context_1.createCtx)({
    addCard: function () { },
    removeCard: function () { },
    removeCardById: function () { },
    loadingCards: [],
    setLoadingCard: function () { },
    setActive: function () { },
    reset: function () { },
}), _b[0]), exports.CardSelectionActionsProvider = _b[1];
