/* @flow */
import { apolloClient } from '@shared/api/client';
import type { ActionCreator } from '@src/types/ReduxTypes';
import { setCurrentBusinessId } from '@src/core/AuthTokenManager';

export default function updateCurrentBusinessId(businessId: ?number, resetStore = true): ActionCreator {
  setCurrentBusinessId(businessId);
  if (resetStore) apolloClient.resetStore();
  return { type: 'UPDATE_CURRENT_BUSINESS_ID', businessId };
}
