/* @flow */
import React from 'react';

import type { Type } from '@src/types/ToastrTypes';

import PureComponent from '@src/components/PureComponent';


import errorIconURL from './ErrorIcon.svg';
import styles from './Toastr.css';

type Props = {
  message: string,
  type: Type,
  isMobile: boolean,
};

export default class Toastr extends PureComponent {
  props: Props;

  render() {
    if (!this.props.message) {
      return null;
    }
    const isError = this.props.type === 'error';
    return (
      <div className={styles.root}>
        <div className={`${styles.container} ${isError ? styles.rootError : ''}`}>
          {isError ?
            <img src={errorIconURL} className={styles.iconWrapper} alt="" /> :
            <div className={styles.checkWrapper}>
              <div className={styles.check} />
            </div>
          }
          <div className={styles.message}>
            {this.props.message}
          </div>
        </div>
      </div>
    );
  }
}
