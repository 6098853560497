"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonEditField = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var PersonField_1 = require("@shared/data/entity/fields/PersonField");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/components/blocks/lib/fieldAssignee/hooks");
var useWorkspaceDetails_1 = require("@shared/scenes/workflows/hooks/useWorkspaceDetails");
var fieldAssignee_1 = require("@shared/components/blocks/lib/fieldAssignee");
var modal_1 = require("@shared/components/multiSelect/modal");
var EditFieldContainer_1 = require("./EditFieldContainer");
var i18n_1 = require("@shared/util/i18n");
var inputAssignee_1 = require("@shared/components/blocks/lib/inputAssignee");
var PersonEditField = function (_a) {
    var _b, _c, _d;
    var field = _a.field, workflowTemplateId = _a.workflowTemplateId, component = _a.component, updateField = _a.updateField;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var selectedUserIds = (0, react_1.useMemo)(function () { return (field === null || field === void 0 ? void 0 : field.jsonBlob) || []; }, [field === null || field === void 0 ? void 0 : field.jsonBlob]);
    var selectedUsers = (0, hooks_1.useResolveAssignees)(selectedUserIds);
    var workspaceDetails = (0, useWorkspaceDetails_1.useWorkspaceDetails)();
    var onChange = (0, react_1.useCallback)(function (users) {
        return updateField(component.id, new PersonField_1.PersonField(users.map(function (user) { return user.id; }), selectedUsers.map(function (user) {
            return {
                id: user.id,
                fullName: user.fullName,
                username: user.username,
                imageUrl: user.imageUrl,
                isMe: user.isMe,
                isPending: user.isPending,
                lastRemindedAt: user.lastRemindedAt,
                firstName: '',
            };
        })));
    }, [component.id, selectedUsers, updateField]);
    var users = (_b = workspaceDetails.get(workflowTemplateId)) === null || _b === void 0 ? void 0 : _b.users.map(function (user) {
        return {
            id: user.id,
            fullName: user.fullName,
            username: user.username,
            imageUrl: user.imageUrl,
            isMe: user.isMe,
            isPending: user.isPending,
            lastRemindedAt: user.lastRemindedAt,
            firstName: '',
        };
    });
    var workspaceImageUrl = (_c = workspaceDetails.get(workflowTemplateId)) === null || _c === void 0 ? void 0 : _c.imageUrl;
    var contentColor = selectedUsers.length > 0 ? colors.foregroundActive : colors.foregroundInactive;
    var contentString = selectedUsers.length > 0 ? (0, fieldAssignee_1.assigneesToString)(selectedUsers) : component.placeholder;
    var match = (0, react_1.useCallback)(function (user, query) {
        var lowercaseQuery = query.toLowerCase();
        return (user.username.toLowerCase().includes(lowercaseQuery) ||
            user.fullName.toLowerCase().includes(lowercaseQuery));
    }, []);
    return (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: (_d = component.label) !== null && _d !== void 0 ? _d : '', onChange: onChange, selectAll: true, renderSelectAll: function () { return (react_1.default.createElement(components_1.MBView, { flex: true, row: true, centerVertically: true, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBImage, { style: styles.workflowImage, source: {
                    uri: workspaceImageUrl !== null && workspaceImageUrl !== void 0 ? workspaceImageUrl : '',
                } }),
            react_1.default.createElement(components_1.MBView, { paddingLeft: 2 }),
            react_1.default.createElement(components_1.MBTextBody1, null, (0, i18n_1.i18n)('form.placeholder.everyone')))); }, renderItem: function (user) { return react_1.default.createElement(inputAssignee_1.MultiSelectUserRow, { user: user }); }, values: users !== null && users !== void 0 ? users : [], selectedValues: selectedUsers !== null && selectedUsers !== void 0 ? selectedUsers : [], match: match, keyExtractor: function (user) { return String(user.id); } },
        react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { onPress: undefined, component: component },
            react_1.default.createElement(components_1.MBTextBody1, { color: contentColor }, contentString))));
};
exports.PersonEditField = PersonEditField;
var styles = react_native_1.StyleSheet.create({
    workflowImage: {
        height: (0, components_1.grid)(5),
        width: (0, components_1.grid)(5),
        borderRadius: (0, components_1.grid)(2.5),
    },
});
