"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
exports.Styles = react_native_1.StyleSheet.create({
    // We add the row's padding as negative margin to the flatlist,
    // to make it visually look like the rows bleed into the edges.
    // Otherwise there would be 2px padding around the list content
    // and 4px padding between every cell.
    flatlist: {
        margin: -2,
    },
    row: {
        padding: 2,
    },
    // Make the background black so that pressed GIFs get darkened
    // (because they're in a TouchableOpacity)
    rowBackground: {
        backgroundColor: 'black',
    },
});
