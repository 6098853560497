"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMegaModal = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/util/navigation/hooks");
var containers_1 = require("@shared/scenes/navigation/menu/containers");
var container_1 = require("@shared/scenes/user/edit/container");
var containers_2 = require("@shared/scenes/user/notificationSettings/containers");
var containers_3 = require("@shared/scenes/tools/list/containers");
var containers_4 = require("@shared/scenes/tools/create/containers");
var containers_5 = require("@shared/scenes/directory/containers");
var container_2 = require("@shared/scenes/business/edit/container");
var containers_6 = require("@shared/scenes/business/invite/containers");
var container_3 = require("@shared/scenes/business/settings/container");
var styles_2 = require("./styles");
var useNavigator_web_1 = require("@shared/util/navigation/useNavigator.web");
var container_4 = require("@shared/scenes/directory/profile/container");
var provider_1 = require("@shared/components/bottomSheet/provider");
var container_5 = require("@shared/scenes/billing/plans/container");
var Route = react_router_1.Route;
var MegaModalLayout = function (_a) {
    var _b;
    var children = _a.children, router = _a.router, routes = _a.routes, params = _a.params;
    var route = routes[routes.length - 1];
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var navigator = (0, useNavigator_web_1.useNavigator)();
    var close = (0, react_1.useCallback)(function () { return setTimeout(navigator.closeMenu); }, [navigator.closeMenu]);
    var routerContext = (0, react_1.useMemo)(function () { return ({ router: router, routes: routes, params: params }); }, [router, routes, params]);
    if (!children) {
        return null;
    }
    if (!isDesktop) {
        return (react_1.default.createElement(hooks_1.ReactRouterContext.Provider, { value: routerContext },
            react_1.default.createElement(components_1.MBView, { style: [styles_1.SharedStyles.absoluteCover, styles_2.Styles.whiteBackground] },
                react_1.default.createElement(components_1.MBWebNavigationHeader, { leftIcon: require('@shared/assets/icons/v2/arrow-point-left.png'), onPressLeft: close, title: (_b = route.props) === null || _b === void 0 ? void 0 : _b.title }),
                children)));
    }
    var selectedMenuItem = routes[1].path; // eg "profile", "notifications", "billing", "links"
    return (react_1.default.createElement(hooks_1.ReactRouterContext.Provider, { value: routerContext },
        react_1.default.createElement(provider_1.MBBottomSheetProvider, null,
            react_1.default.createElement(components_1.MBModal, { visible: true, padding: "none", onClose: close, isCloseButtonHidden: true, webOverflow: true, webContainerStyle: styles_2.Styles.modalContainer },
                react_1.default.createElement(components_1.MBView, { row: true, fill: true, style: styles_2.Styles.container },
                    react_1.default.createElement(components_1.MBView, { style: styles_2.Styles.menuContainer },
                        react_1.default.createElement(styles_1.ColorThemeContext.Provider, { value: styles_1.grayTheme },
                            react_1.default.createElement(containers_1.MBMenu, { selectedItem: selectedMenuItem }))),
                    react_1.default.createElement(components_1.MBView, { fill: true, style: styles_2.Styles.desktopContentContainer }, children)),
                react_1.default.createElement(components_1.MBView, { padding: "small", style: { position: 'absolute', top: 0, right: 0 } },
                    react_1.default.createElement(components_1.MBIconButton, { inverted: true, onPress: close, source: require('@shared/assets/icons/v2/exit.png') }))))));
};
function useCloseMegaModalOnNavigation() {
    var navigator = (0, useNavigator_web_1.useNavigator)();
    (0, react_1.useEffect)(function () {
        var _a;
        var history = (_a = navigator.getHistory) === null || _a === void 0 ? void 0 : _a.call(navigator);
        if (!history && process.env.NODE_ENV === 'test') {
            console.warn('useBrowserHistory: cannot listen to history - not set!');
            return;
        }
        if (history) {
            return history.listen(navigator.closeMenu);
        }
    }, [navigator]);
}
var MBMegaModal = function () {
    useCloseMegaModalOnNavigation();
    var routes = (0, react_1.useMemo)(function () { return (react_1.default.createElement(Route, { path: "/", component: MegaModalLayout },
        react_1.default.createElement(Route, { path: "profile", component: container_1.MBEditUser, props: { title: (0, i18n_1.i18n)('menu.editProfile') } }),
        react_1.default.createElement(Route, { path: "notifications", component: containers_2.MBUserNotificationSettings, props: { title: (0, i18n_1.i18n)('menu.notifications') } }),
        react_1.default.createElement(Route, { path: "billing", component: container_5.MBBillingPlans, props: { title: (0, i18n_1.i18n)('menu.billing') } }),
        react_1.default.createElement(Route, { path: "links" },
            react_1.default.createElement(react_router_1.IndexRoute, { component: containers_3.MBTools, props: { title: (0, i18n_1.i18n)('menu.sharedLinks') } }),
            react_1.default.createElement(Route, { path: "create", component: containers_4.MBCreateLinkForm, props: { title: (0, i18n_1.i18n)('tools.create.title') } })),
        react_1.default.createElement(Route, { path: "directory" },
            react_1.default.createElement(react_router_1.IndexRoute, { component: containers_5.MBDirectory, props: { title: (0, i18n_1.i18n)('menu.directory') } }),
            react_1.default.createElement(Route, { path: "user/:userId", component: function (_a) {
                    var params = _a.params;
                    return react_1.default.createElement(container_4.MBDirectoryProfile, { userId: Number(params.userId) });
                } })),
        react_1.default.createElement(Route, { path: "business", component: container_2.MBBusinessEdit, props: { title: (0, i18n_1.i18n)('menu.editBusiness') } }),
        react_1.default.createElement(Route, { path: "invite", component: containers_6.MBBusinessInvite, props: { title: (0, i18n_1.i18n)('menu.inviteSomeone') } }),
        react_1.default.createElement(Route, { path: "settings", component: container_3.MBBusinessSettings, props: { title: (0, i18n_1.i18n)('menu.businessSettings') } }),
        react_1.default.createElement(Route, { path: "menu", component: containers_1.MBMenu, props: { title: (0, i18n_1.i18n)('menu.title') } }),
        react_1.default.createElement(react_router_1.Redirect, { from: "*", to: "/profile" }))); }, []);
    return react_1.default.createElement(react_router_1.Router, { history: useNavigator_web_1.megaModalHistory, routes: routes });
};
exports.MBMegaModal = MBMegaModal;
