import * as React from 'react';
function SvgWaitingToSend(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        stroke={props.color}
        strokeWidth={3}
        cx={12}
        cy={12}
        r={10.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}
export default SvgWaitingToSend;

