"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityField = void 0;
var lodash_1 = require("lodash");
var RelatedEntityField = /** @class */ (function () {
    function RelatedEntityField(jsonBlob, relatedEntities) {
        this.fieldType = 'RelatedCardField';
        this.items = jsonBlob;
        this.cards = this.items.reduce(function (cards, item) {
            var _a;
            var match = relatedEntities.find(function (rc) { return rc.id === item.id; });
            if ((0, lodash_1.isNil)(match)) {
                return cards;
            }
            var quantity = (_a = item.quantity) !== null && _a !== void 0 ? _a : match.quantity;
            var relatedEntity = __assign(__assign({}, match), { quantity: quantity });
            return __spreadArray(__spreadArray([], cards, true), [relatedEntity], false);
        }, []);
    }
    RelatedEntityField.prototype.validate = function () {
        return null;
    };
    RelatedEntityField.prototype.isEqual = function (field) {
        return field instanceof RelatedEntityField && (0, lodash_1.isEqual)(this.items, field.items);
    };
    RelatedEntityField.prototype.toFieldJson = function () {
        return this.items;
    };
    Object.defineProperty(RelatedEntityField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.cards);
        },
        enumerable: false,
        configurable: true
    });
    return RelatedEntityField;
}());
exports.RelatedEntityField = RelatedEntityField;
