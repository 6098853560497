/* @flow */
import React from 'react';

import { MBIcon } from '@shared/components';

import PureComponent from '@src/components/PureComponent';
import RoundIcon from '@src/components/RoundIcon';

import logoUrl from './LogoSquare.svg';
import styles from './AuthHeader.css';

type Props = {
  url?: ?string,
  text: string,
  subText?: ?string,
  error?: ?string,
};

export default class AuthHeader extends PureComponent<*, Props, void> {
  static defaultProps = {
    url: logoUrl,
  };

  render() {
    return (
      <div className={styles.root}>
        <MBIcon
          style={{ width: 100, height: 100, borderRadius: 50, overflow: 'hidden', padding: 0, margin: 20 }}
          source={require('@shared/assets/icons/v2/logo.png')}
        />
        <div className={styles.text}>{this.props.text}</div>
        {this.props.subText &&
          <div className={styles.subText}>{this.props.subText}</div>
        }
        {this.props.error &&
          <div className={styles.error}>{this.props.error}</div>
        }
      </div>
    );
  }
}
