/* @flow */
import React from 'react';

import { MBButton } from '@shared/components';
import PureComponent from '@src/components/PureComponent';

import styles from './Button.css';

export type BtnStyles =
  'primary' |
  'primaryTransparent' |
  'secondaryTransparent' |
  'ternaryTransparent' |
  'secondaryBorderless' |
  'ternaryBorderless' |
  'chat';

export type Props = {|
  children?: React.Element<*>,
  tabIndex?: number,
  isDisabled?: boolean,
  isFullWidth?: boolean,
  isStretchWidth?: boolean,
  isTall?: boolean,
  isFlex?: boolean,
  isDanger?: boolean,
  btnSize?: 'large',
  isIconButton?: boolean,
  title?: string,
  btnStyle: BtnStyles,
  type?: 'submit' | 'reset' | 'button' | 'menu',
  onClick?: Function,
|};


const styleMapping = {
  primary: styles.rootPrimary,
  primaryTransparent: styles.rootPrimaryTransparent,
  secondaryTransparent: styles.rootSecondaryTransparent,
  ternaryTransparent: styles.rootTernaryTransparent,
  secondaryBorderless: styles.rootSecondaryBorderless,
  ternaryBorderless: styles.rootTernaryBorderless,
  chat: styles.rootChat,
};

const sizeMapping = {
  normal: styles.normal,
  large: styles.large,
};

export default class Button extends PureComponent {
  props: Props;
  render() {
    return (
      <MBButton
        title={this.props.children}
        disabled={this.props.isDisabled}
        size={(() => {
          if (this.props.btnSize === 'normal') return 'small';
          if (this.props.btnSize === 'large') return 'normal';
          if (this.props.isTall) return 'normal';
          return 'small';
        })()}
        wrap={this.props.flex === false}
        theme={(() => {
          if (this.props.btnStyle === 'ternaryTransparent') return 'transparent';
          if (this.props.btnStyle === 'ternaryBorderless') return 'outline';
          return 'normal';
        })()}
        onPress={this.props.onClick}
      />
    );

    const {
      children, isDisabled, isDanger,
      btnStyle, btnSize, isFullWidth, isStretchWidth, isTall,
      isIconButton, isFlex, title, tabIndex, type, onClick,
    } = this.props;
    const baseClassName = isDisabled ?
      styles.rootDisabled :
      styleMapping[btnStyle];

    const size = sizeMapping[btnSize || 'normal'];

    const flex = isFlex ? styles.flex : '';
    const withIcon = isIconButton ? styles.justIcon : '';
    const width = isFullWidth ? styles.fullWidth : (isStretchWidth ? styles.stretchWidth : '');
    const tall = isTall ? styles.tall : '';
    const danger = isDanger ? styles.danger : '';
    return (
      <button
        className={`${baseClassName} ${width} ${tall} ${withIcon} ${flex} ${size} ${danger}`}
        title={title}
        type={type || 'submit'}
        onClick={!isDisabled ? onClick : undefined}
        tabIndex={tabIndex || undefined}
      >
        {children}
      </button>
    );
  }
}
