"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cardThemes = void 0;
var styles_1 = require("@shared/styles");
exports.cardThemes = {
    default: { highlightActive: styles_1.Colors.v2.gray10, highlightInactive: styles_1.Colors.v2.gray05 },
    limitImposed: {
        background: styles_1.Colors.v2.gray10,
        highlightInactive: styles_1.Colors.v2.gray10,
        foregroundActive: styles_1.Colors.v2.gray50,
        foregroundInactive: styles_1.Colors.v2.gray05,
        accent: styles_1.Colors.v2.gray05,
        accentInactive: styles_1.Colors.v2.gray05,
        input: { borderInactive: styles_1.Colors.v2.gray05 },
    },
};
