"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCreateOrJoinBusiness = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var analytics_1 = require("@shared/util/analytics");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var animation_1 = require("@shared/components/animation");
var useLogout_1 = require("@shared/scenes/navigation/menu/containers/useLogout");
var styles = react_native_1.StyleSheet.create({
    capWidth: {
        width: '100%',
        maxWidth: 350,
        alignSelf: 'center',
    },
    row: {
        minHeight: 52,
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 6,
        boxShadow: '2px 2px 8px 0px #00000033',
    },
});
var MBCreateOrJoinBusiness = function (_a) {
    var isDesktop = _a.isDesktop, onCreateBusiness = _a.onCreateBusiness;
    var logout = (0, useLogout_1.useLogout)();
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(false), isJoinMessageShowing = _b[0], setIsJoinMessageShowing = _b[1];
    var showJoinOnboarding = function () {
        analytics_1.MBAnalytics.track('Onboarding::JoinBusiness');
        setIsJoinMessageShowing(function (prev) { return !prev; });
    };
    var createBusiness = (0, react_1.useCallback)(function () {
        analytics_1.MBAnalytics.track('Onboarding::CreateBusiness');
        onCreateBusiness();
    }, [onCreateBusiness]);
    var align = isDesktop ? 'center' : 'left';
    return (react_1.default.createElement(react_native_1.ScrollView, { alwaysBounceVertical: false },
        isDesktop && react_1.default.createElement(components_1.MBView, { paddingBottom: 4 }),
        react_1.default.createElement(typography_1.MBTextH1, { align: align, color: colors.foregroundActive }, (0, i18n_1.i18n)('onboarding.createOrJoinBusiness.title')),
        react_1.default.createElement(components_1.MBView, { paddingBottom: 2 }),
        react_1.default.createElement(typography_1.MBTextBody1, { align: align, color: colors.foregroundActive }, (0, i18n_1.i18n)('onboarding.createOrJoinBusiness.description')),
        react_1.default.createElement(components_1.MBView, { paddingTop: 6 }),
        react_1.default.createElement(components_1.MBListRow, { title: (0, i18n_1.i18n)('onboarding.createOrJoinBusiness.create.cta'), isHidingPressableIndicator: true, style: [styles.row, styles.capWidth], onPress: createBusiness, paddingLeft: 2, image: react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundInactive, name: "storefront" }), imageStyle: [{ marginRight: (0, components_1.grid)(1) }] }),
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, padding: 2, style: styles.capWidth },
            react_1.default.createElement(components_1.MBView, { flex: true, paddingRight: 1, children: react_1.default.createElement(components_1.MBSeparator, null) }),
            react_1.default.createElement(typography_1.MBTextOverlineBold, { color: colors.foregroundInactive }, "OR"),
            react_1.default.createElement(components_1.MBView, { flex: true, paddingLeft: 1, children: react_1.default.createElement(components_1.MBSeparator, null) })),
        react_1.default.createElement(components_1.MBListRow, { title: (0, i18n_1.i18n)('onboarding.createOrJoinBusiness.join.cta'), isHidingPressableIndicator: false, style: [styles.row, styles.capWidth], onPress: showJoinOnboarding, showChildren: isJoinMessageShowing, paddingLeft: 1, imageStyle: [{ marginRight: (0, components_1.grid)(1) }], icon: react_1.default.createElement(components_1.MBView, { row: true, center: true, style: { height: 44 } },
                react_1.default.createElement(animation_1.MBAnimation, { duration: 200, easing: react_native_1.Easing.inOut(react_native_1.Easing.quad), disabled: !isJoinMessageShowing, rotate: { from: '0deg', to: '-180deg' } },
                    react_1.default.createElement(components_1.MBIconV2, { color: styles_1.Colors.v2.purple, name: "arrow-point-down-heavy", size: 12 }))), image: react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundInactive, name: "pin-location" }) },
            react_1.default.createElement(components_1.MBView, { center: true, padding: 2 },
                react_1.default.createElement(components_1.MBView, { paddingBottom: 2, fullWidth: true },
                    react_1.default.createElement(typography_1.MBTextBody1, null, (0, i18n_1.i18n)('onboarding.createOrJoinBusiness.join.description_line_1'))),
                react_1.default.createElement(components_1.MBView, { fullWidth: true },
                    react_1.default.createElement(typography_1.MBTextBody1, null, (0, i18n_1.i18n)('onboarding.createOrJoinBusiness.join.description_line_2'))),
                react_1.default.createElement(components_1.MBView, { style: [{ marginTop: (0, components_1.grid)(3) }] },
                    react_1.default.createElement(components_1.MBButton, { theme: "outline", size: "normal", paddingHorizontal: "large", onPress: function () { return logout(); }, title: "Start Over" }))))));
};
exports.MBCreateOrJoinBusiness = MBCreateOrJoinBusiness;
