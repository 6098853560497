import * as React from 'react';
function SvgAllCardsEmptyMobile(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 163 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#all-cards-empty-mobile_svg__filter0_d)">
        <mask
          id="all-cards-empty-mobile_svg__a"
          maskUnits="userSpaceOnUse"
          x={7}
          y={7}
          width={141}
          height={244}
        >
          <rect x={7} y={7} width={141} height={244} rx={8} fill="#fff" />
        </mask>
        <g mask="url(#all-cards-empty-mobile_svg__a)">
          <path fill="#F7F7F7" d="M7 7.924h141V251H7z" />
          <path fill="#fff" d="M7.001 7h141v24h-141z" />
          <g filter="url(#all-cards-empty-mobile_svg__filter1_d)">
            <rect x={18} y={36} width={120} height={50} rx={4} fill="#fff" />
          </g>
          <rect x={38} y={44} width={27} height={4} rx={2} fill="#E6E6E6" />
          <rect x={38} y={57} width={84} height={4} rx={2} fill="#E6E6E6" />
          <rect x={38} y={70} width={84} height={4} rx={2} fill="#E6E6E6" />
          <circle cx={28.426} cy={46.426} r={5.426} fill="#DAF6F0" />
          <g filter="url(#all-cards-empty-mobile_svg__filter2_d)">
            <rect x={18} y={89} width={120} height={50} rx={4} fill="#fff" />
          </g>
          <rect x={38} y={97} width={27} height={4} rx={2} fill="#E6E6E6" />
          <rect x={38} y={110} width={84} height={4} rx={2} fill="#E6E6E6" />
          <rect x={38} y={123} width={84} height={4} rx={2} fill="#E6E6E6" />
          <circle cx={28.426} cy={99.426} r={5.426} fill="#DAF6F0" />
          <g filter="url(#all-cards-empty-mobile_svg__filter3_d)">
            <rect x={18} y={142} width={120} height={50} rx={4} fill="#fff" />
          </g>
          <rect x={38} y={150} width={27} height={4} rx={2} fill="#E6E6E6" />
          <rect x={38} y={163} width={84} height={4} rx={2} fill="#E6E6E6" />
          <rect x={38} y={176} width={84} height={4} rx={2} fill="#E6E6E6" />
          <circle cx={28.426} cy={152.426} r={5.426} fill="#DAF6F0" />
          <g filter="url(#all-cards-empty-mobile_svg__filter4_d)">
            <rect x={18} y={195} width={120} height={50} rx={4} fill="#fff" />
          </g>
          <rect x={38} y={203} width={27} height={4} rx={2} fill="#E6E6E6" />
          <rect x={38} y={216} width={84} height={4} rx={2} fill="#E6E6E6" />
          <rect x={38} y={229} width={84} height={4} rx={2} fill="#E6E6E6" />
          <circle cx={28.426} cy={205.426} r={5.426} fill="#DAF6F0" />
          <g filter="url(#all-cards-empty-mobile_svg__filter5_d)">
            <path fill="#fff" d="M7 219h141v32H7z" />
          </g>
          <circle cx={33} cy={234} r={7} fill="#E6E6E6" />
          <circle cx={63} cy={234} r={7} fill="#E6E6E6" />
          <circle cx={93} cy={234} r={7} fill="#E6E6E6" />
          <circle cx={123} cy={234} r={7} fill="#E6E6E6" />
        </g>
      </g>
      <circle cx={27.426} cy={20.426} r={5.426} fill="#8DE1CF" />
      <rect x={39} y={19} width={53} height={3} rx={1.5} fill="#E6E6E6" />
      <defs>
        <filter
          id="all-cards-empty-mobile_svg__filter0_d"
          x={0}
          y={0}
          width={163}
          height={266}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={4} dy={4} />
          <feGaussianBlur stdDeviation={5.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-mobile_svg__filter1_d"
          x={12}
          y={30}
          width={136}
          height={66}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-mobile_svg__filter2_d"
          x={12}
          y={83}
          width={136}
          height={66}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-mobile_svg__filter3_d"
          x={12}
          y={136}
          width={136}
          height={66}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-mobile_svg__filter4_d"
          x={12}
          y={189}
          width={136}
          height={66}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-mobile_svg__filter5_d"
          x={1}
          y={213}
          width={157}
          height={48}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgAllCardsEmptyMobile;

