"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
exports.Styles = react_native_1.StyleSheet.create({
    container: {
        height: 40,
        borderRadius: 20,
        backgroundColor: 'white',
    },
    position: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
    },
});
