/* @flow */
import React from 'react';
import { Config } from '@shared/config';

import createProfilePictureURL from '@common/core/createProfilePictureURL';

import uploadFile from '@src/core/uploadFile';

import PureComponent from '@src/components/PureComponent';
import Loading from '@src/components/Loading';
import UserImage from '@src/components/UserImage';

import cameraFilledIconURL from './cameraIcon.svg';
import businessURL from './Business.svg';
import peopleURL from './People.svg';

import styles from './ImageEditable.css';

type Props = {
  type: 'business' | 'people',
  url: ?string,
  size: number,
  onUpload: (img: string) => Promise<any>,
};

export default class ImageEditable extends PureComponent {
  props: Props;
  fileInput: ?HTMLElement;

  static defaultProps = {
    size: 32,
  };

  state = {
    tempProfileImage: '',
    profileImageLoading: false,
  };

  handleClick = (ev: BrowserEvent) => {
    ev && typeof ev.preventDefault === 'function' && ev.preventDefault();
    if (this.fileInput) {
      this.fileInput.click();
    }
  };

  handleRef = (elem: HTMLElement) => {
    this.fileInput = elem;
  };

  handlePickImage = async (event: BrowserEvent) => {
    const file = event.target.files[0];
    this.setState({ profileImageLoading: true });
    const fileKey = await this.uploadImageFile(file);
    if (!fileKey) {
      return;
    }
    const size = 128;
    const imgURL = createProfilePictureURL(fileKey, size);
    this.setState({ tempProfileImage: imgURL });
    await this.props.onUpload(imgURL);
    this.setState({ tempProfileImage: '', profileImageLoading: false });
  };

  render() {
    const { url, size, type } = this.props;
    const imgUrl = url || (type === 'business' ? businessURL : peopleURL);

    const borderRadius = Math.ceil(size / 2);
    return this.state.profileImageLoading ? (
      <div
        className={styles.imageWrapper}
        style={{ width: size + 2, height: size + 2, borderRadius: borderRadius + 1 }}
      >
        {!!this.state.tempProfileImage &&
          <UserImage url={this.state.tempProfileImage} size={size} noBorder={true} />
        }
        <div
          style={{ width: size, height: size, borderRadius }}
          className={styles.loadingWrapper}
        >
          <Loading isCentered={true} />
        </div>
      </div>
    ) : (
      <div>
        <button
          title="Change your picture"
          type="button"
          style={{ width: size + 2, height: size + 2, borderRadius: borderRadius + 1 }}
          className={styles.button}
          onClick={this.handleClick}
        >
          <UserImage url={imgUrl} size={size} noBorder={true} />
          <div className={styles.cameraIconWrapper}>
            <img src={cameraFilledIconURL} alt="" />
          </div>
        </button>
        <input
          type="file"
          ref={this.handleRef}
          className={styles.input}
          accept="image/gif, image/jpeg, image/png"
          onChange={this.handlePickImage}
        />
      </div>
    );
  }

  async uploadImageFile(file: File) { // eslint-disable-line class-methods-use-this
    const policy = await uploadFile(file);
    if (policy) {
      return encodeURIComponent(policy.fileKey);
    }
    return null;
  }
}
