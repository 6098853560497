"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoField = void 0;
var lodash_1 = require("lodash");
var TodoField = /** @class */ (function () {
    function TodoField(jsonBlob) {
        this.fieldType = 'TodoField';
        this.jsonBlob = jsonBlob;
    }
    TodoField.prototype.validate = function () {
        return null;
    };
    TodoField.prototype.isEqual = function (field) {
        return field instanceof TodoField && (0, lodash_1.isEqual)(this.jsonBlob, field.jsonBlob);
    };
    TodoField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(TodoField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return TodoField;
}());
exports.TodoField = TodoField;
