import * as React from 'react';
function SvgKey(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 9l-8.234 8.234A6.953 6.953 0 0016 17a7 7 0 107 7c0-.891-.173-1.74-.476-2.524L25 19v-3h3l3-3V9h-5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M16 26a2 2 0 100-4 2 2 0 000 4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgKey;

