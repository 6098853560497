/* @flow */
import runtime from 'serviceworker-webpack-plugin/lib/runtime';
import { actions } from '@src/core/Router';
import { store } from '@shared/store';
import { apolloClient } from '@shared/api/client';
import updateCurrentBusinessId from '@src/redux/actions/updateCurrentBusinessId';
import Segment from '@src/core/Segment';
import { logError } from '@src/core/ErrorLogger';

import type { DataAction } from '@src/types/WorkerTypes';

const log = (...args) => __DEV__ && console.log('[SWC]', ...args); // eslint-disable-line no-console

type MessageEvent = {
  data: DataAction,
};

let worker = null;

async function handleMessage(event: MessageEvent) {
  const { type, payload } = event.data;
  log('message', type, payload);
  Segment.track('MyBiz::PushNotificationClick::Web', {
    data: payload,
  });
  if (type === 'redirect') {
    console.error('redirect',
    payload.bid,
    store.getState().appState.currentBusinessId,
    typeof payload.bid,
    typeof store.getState().appState.currentBusinessId,
    );
    if (payload.bid && store.getState().appState.currentBusinessId !== payload.bid) {
      store.dispatch(updateCurrentBusinessId(payload.bid, false));
      await apolloClient.resetStore();
    }

    actions.pushRoute(payload.path);
  }
}

if ('serviceWorker' in navigator && navigator.serviceWorker) {
  try {
    const serviceWorker = navigator.serviceWorker;
    serviceWorker.addEventListener('message', handleMessage);
    worker = runtime.register();
    const registerChannel = () => {
      log('Registering Channel');
      const msgChan = new MessageChannel();
      // Handler for recieving message reply from service worker
      // http://craig-russell.co.uk/2016/01/29/service-worker-messaging.html#.WiiYc7SpnUI
      // $FlowFixMe
      msgChan.port1.onmessage = handleMessage;
      serviceWorker.controller.postMessage({ type: 'SWRegistrationReady' }, [msgChan.port2]);
    };
    if (serviceWorker.controller) {
      registerChannel();
    } else {
      serviceWorker.addEventListener('controllerchange', registerChannel);
    }

    if (__DEV__) {
      worker
        .then(reg => reg.update())
        .then(() => log('Checked update'));
    }
  } catch (error) {
    logError(error, { from: 'ServiceWorkerRegistration' });
  }
}

export default function getWorker() {
  return worker;
}
