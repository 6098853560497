"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.UnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.UnvalidatedStyles = {
    form: {
        flex: 1,
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
};
exports.Styles = react_native_1.StyleSheet.create({
    overlayContainer: {
        flex: 1,
        alignSelf: 'stretch',
        justifyContent: 'flex-end',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    overlayBorder: {
        borderWidth: 1,
        borderColor: styles_1.Colors.v2.green,
        boxShadow: "inset 0px 0px 20px -6px ".concat(styles_1.Colors.v2.green),
    },
    whiteBackground: {
        backgroundColor: 'white',
    },
});
