"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildFieldRecord = buildFieldRecord;
var CardField_1 = require("@shared/data/cards/CardField");
function buildFieldRecord(apiCard, components, refs, defaultData) {
    return components.reduce(function (record, component) {
        record[component.id] = (0, CardField_1.buildCardField)(apiCard, component, refs, defaultData);
        return record;
    }, {});
}
