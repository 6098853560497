"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardListCell = exports.getIndexesFromDroppableId = exports.getDroppableId = exports.getCardIdFromDraggableId = exports.getCardDraggableId = void 0;
var components_1 = require("@shared/components");
var blocks_1 = require("@shared/components/blocks");
var form_1 = require("@shared/components/blocks/contexts/form");
var base_1 = require("@shared/components/card/base");
var styles_1 = require("@shared/styles");
var react_1 = __importStar(require("react"));
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var ChannelEventHandlers_1 = require("../../../../../workflows/hooks/ChannelEventHandlers");
var getCardDraggableId = function (droppableId, cardId) {
    return "".concat(droppableId, "/").concat(cardId);
};
exports.getCardDraggableId = getCardDraggableId;
var getCardIdFromDraggableId = function (draggableId) { return draggableId.split('/')[1]; };
exports.getCardIdFromDraggableId = getCardIdFromDraggableId;
var getDroppableId = function (rowIndex, columnIndex) {
    return "".concat(rowIndex, ",").concat(columnIndex);
};
exports.getDroppableId = getDroppableId;
var getIndexesFromDroppableId = function (droppableId) {
    return droppableId.split(',').map(function (value) { return parseInt(value, 10); });
};
exports.getIndexesFromDroppableId = getIndexesFromDroppableId;
var MBCardListCell = function (_a) {
    var cards = _a.cards, style = _a.style, blocks = _a.blocks, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var onOpenCard = (0, ChannelEventHandlers_1.useChannelEventHandlers)().onOpenCard;
    var mergedStyle = (0, react_1.useMemo)(function () { return (__assign(__assign({}, style), { borderRightColor: colors.input.borderInactive, borderRightWidth: 1, borderRightStyle: 'solid', minHeight: 100 })); }, [style, colors]);
    var droppableId = (0, react_1.useMemo)(function () { return (0, exports.getDroppableId)(rowIndex, columnIndex); }, [rowIndex, columnIndex]);
    var renderDraggableCard = (0, react_1.useCallback)(function (provided, snapshot, rubric) {
        var card = cards[rubric.source.index];
        var cardStyle = __assign(__assign({}, provided.draggableProps.style), { opacity: snapshot.isDragging ? 0.5 : 1 });
        return (react_1.default.createElement("div", __assign({}, provided.draggableProps, provided.dragHandleProps, { style: cardStyle, ref: provided.innerRef }),
            react_1.default.createElement(base_1.MBCardBase, { card: card, onPress: onOpenCard },
                react_1.default.createElement(form_1.MBCardFormProvider, { card: card, blocks: blocks, cardDefinitionId: card === null || card === void 0 ? void 0 : card.cardDefinitionId },
                    react_1.default.createElement(blocks_1.MBCardBlocks, { card: card, blocks: blocks })))));
    }, [cards, onOpenCard, blocks]);
    return (react_1.default.createElement("div", { style: mergedStyle },
        react_1.default.createElement(react_beautiful_dnd_1.Droppable, { droppableId: droppableId, renderClone: renderDraggableCard, children: function (provided, snapshot) { return (react_1.default.createElement("div", { ref: provided.innerRef, style: __assign({ height: '100%', paddingLeft: (0, components_1.grid)(0.5), paddingRight: (0, components_1.grid)(0.5), transition: 'background-color 100ms ease-in-out' }, (snapshot.isDraggingOver && { backgroundColor: colors.input.borderInactive })) },
                cards.map(function (card, i) { return (react_1.default.createElement(components_1.MBView, { paddingBottom: 1, key: card.id },
                    react_1.default.createElement(react_beautiful_dnd_1.Draggable, { isDragDisabled: card.planLimitImposed, draggableId: (0, exports.getCardDraggableId)(droppableId, card.id), index: i, children: renderDraggableCard }))); }),
                provided.placeholder)); } })));
};
exports.MBCardListCell = MBCardListCell;
exports.MBCardListCell.displayName = 'MBCardListCell';
