import * as React from 'react';
function SvgUnfilledCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M20 31c6.075 0 11-4.925 11-11S26.075 9 20 9 9 13.925 9 20s4.925 11 11 11z"
        stroke={props.color}
        strokeWidth={2}
      />
    </svg>
  );
}
export default SvgUnfilledCircle;

