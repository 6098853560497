"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
exports.Styles = react_native_1.StyleSheet.create({
    container: {
        borderRadius: 4,
        height: 40,
    },
    textInput: {
        fontSize: 16,
        alignSelf: 'stretch',
    },
});
