"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFunctionState = useFunctionState;
var react_1 = require("react");
// to store a fn in state, we need to wrap it in a fn
// https://stackoverflow.com/a/55621325/9117995
function useFunctionState(initialValue) {
    var _a = (0, react_1.useState)(function () { return initialValue; }), fn = _a[0], setState = _a[1];
    var setFunction = (0, react_1.useCallback)(function (updatedFn) { return setState(function () { return updatedFn; }); }, []);
    return [fn, setFunction];
}
