"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddView = void 0;
var toast_1 = require("@shared/components/toast");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_web_1 = require("@shared/util/navigation/useNavigator.web");
var react_1 = require("react");
var workflow_1 = require("@shared/util/workflow");
var config_1 = require("../../config");
var lodash_1 = require("lodash");
var useAddView = function (_a) {
    var _b;
    var workflowTemplateId = _a.workflowTemplateId, channelSlug = _a.channelSlug, active = _a.active, onFinishAddView = _a.onFinishAddView;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var _c = (0, react_1.useState)("".concat((0, config_1.getViewSubtypeName)(config_1.defaultViewSubtypeSelected), " View")), viewName = _c[0], _setViewName = _c[1];
    var _d = (0, react_1.useState)(''), viewNameError = _d[0], setViewNameError = _d[1];
    var _e = (0, react_1.useState)(false), loading = _e[0], setLoading = _e[1];
    var _f = (0, react_1.useState)(config_1.defaultViewSubtypeSelected), subtype = _f[0], setSubtype = _f[1];
    var _g = (0, react_1.useState)('status'), groupBy = _g[0], setGroupBy = _g[1];
    var _h = (0, react_1.useState)((_b = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(hooks_1.isDateComponent)[0]) === null || _b === void 0 ? void 0 : _b.id), calendarComponentId = _h[0], setCalendarComponentId = _h[1];
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var navigator = (0, useNavigator_web_1.useNavigator)();
    var createViewTemplate = (0, hooks_1.useCreateViewTemplate)(workflowTemplateId);
    var onAddView = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var view, createView;
        return __generator(this, function (_a) {
            view = (0, config_1.prepareViewData)({
                name: viewName,
                subtype: subtype,
                groupByComponentId: groupBy,
                calendarDateComponentId: calendarComponentId,
            }, (0, config_1.getDefaultListViewComponentIds)(workflowTemplate));
            createView = __assign(__assign({ workflowTemplateId: workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id }, (0, lodash_1.omit)(view, 'id')), { componentsViewOptions: view.componentsViewOptions.map(function (viewOption) {
                    return {
                        componentId: viewOption.componentId,
                        isHidden: viewOption.isHidden,
                        overrides: (0, workflow_1.toComponentOneOf)(viewOption.overrides),
                    };
                }) });
            setLoading(true);
            createViewTemplate(createView).then(function (result) {
                setLoading(false);
                if (!(0, lodash_1.isNil)(result.data)) {
                    var id = result.data.createViewTemplate.id;
                    onFinishAddView(__assign(__assign({}, view), { id: id }));
                    toast.show({
                        icon: 'check',
                        message: (0, i18n_1.i18n)('workflowTemplate.viewCreated', { viewName: view.name }),
                    });
                    navigator.replace('Channel', { channelSlug: channelSlug, templateSlug: id });
                    navigator.navigate('ChannelCardList', { channelSlug: channelSlug, templateSlug: id });
                }
            });
            setLoading(false);
            onFinishAddView(view);
            return [2 /*return*/];
        });
    }); }, [
        channelSlug,
        createViewTemplate,
        groupBy,
        navigator,
        onFinishAddView,
        subtype,
        toast,
        viewName,
        workflowTemplate,
        calendarComponentId,
    ]);
    var groupByOptions = (0, react_1.useMemo)(function () {
        if (subtype === 'TABLE') {
            return [];
        }
        var groupableComponents = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(config_1.isComponentGroupable).map(function (component) { return component.id; });
        return groupableComponents;
    }, [subtype, workflowTemplate]);
    var dateComponents = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(hooks_1.isDateComponent).map(function (component) { return component.id; });
    var getGroupByIcon = (0, react_1.useCallback)(function (componentId) {
        var component = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(hooks_1.isInputComponent).find(function (filteredComponent) { return filteredComponent.id === componentId; });
        return component === null || component === void 0 ? void 0 : component.icon;
    }, [workflowTemplate]);
    var getGroupByLabel = (0, react_1.useCallback)(function (componentId) {
        var component = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(hooks_1.isInputComponent).find(function (filteredComponent) { return filteredComponent.id === componentId; });
        return component === null || component === void 0 ? void 0 : component.label;
    }, [workflowTemplate]);
    var setViewName = (0, react_1.useCallback)(function (updatedViewName) {
        _setViewName(updatedViewName);
        if ((updatedViewName === null || updatedViewName === void 0 ? void 0 : updatedViewName.length) === 0) {
            setViewNameError((0, i18n_1.i18n)('workflowTemplate.viewNameError'));
        }
        else {
            setViewNameError('');
        }
    }, [_setViewName]);
    (0, react_1.useEffect)(function () {
        if (subtype !== 'CALENDAR') {
            setCalendarComponentId(undefined);
        }
    }, [subtype]);
    (0, react_1.useEffect)(function validateDataAfterSubtypeChange() {
        var _a;
        if (!active) {
            return;
        }
        if (subtype === 'CALENDAR') {
            setViewName("".concat((0, config_1.getViewSubtypeName)(subtype), " View"));
            if ((0, lodash_1.isNil)(calendarComponentId)) {
                setCalendarComponentId((_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(hooks_1.isDateComponent)[0]) === null || _a === void 0 ? void 0 : _a.id);
            }
            return;
        }
        setViewName("".concat((0, config_1.getViewSubtypeName)(subtype), " View"));
    }, [
        active,
        groupBy,
        groupByOptions,
        subtype,
        workflowTemplate,
        setSubtype,
        setGroupBy,
        setViewName,
        calendarComponentId,
        setCalendarComponentId,
    ]);
    return (0, react_1.useMemo)(function () { return ({
        onAddView: onAddView,
        loading: loading,
        viewName: viewName,
        viewNameError: viewNameError,
        setViewName: setViewName,
        setSubtype: setSubtype,
        setGroupBy: setGroupBy,
        calendarComponentId: calendarComponentId,
        setCalendarComponentId: setCalendarComponentId,
        subtype: subtype,
        groupBy: groupBy,
        getGroupByIcon: getGroupByIcon,
        getGroupByLabel: getGroupByLabel,
        groupByOptions: groupByOptions,
        dateComponents: dateComponents,
        isGroupingDisabled: ['CALENDAR', 'TABLE'].includes(subtype),
        groupByLabel: subtype === 'CALENDAR' ? (0, i18n_1.i18n)('workflowTemplate.sortBy') : (0, i18n_1.i18n)('workflowTemplate.groupBy'),
        isValid: !viewNameError,
    }); }, [
        onAddView,
        loading,
        viewName,
        setViewName,
        viewNameError,
        subtype,
        groupBy,
        getGroupByIcon,
        getGroupByLabel,
        calendarComponentId,
        setCalendarComponentId,
        groupByOptions,
        dateComponents,
    ]);
};
exports.useAddView = useAddView;
