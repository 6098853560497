import * as React from 'react';
function SvgApple(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 14.461c0-2 .646-3.773 2.479-4.461"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M28.868 14.937a5.167 5.167 0 00-5.847-1.608L20 14.461l-3.021-1.132a5.164 5.164 0 00-6.916 5.637l1.686 8.788a3.847 3.847 0 005.361 2.912L20 29.383l2.89 1.283a3.847 3.847 0 005.361-2.912l1.686-8.788a5.161 5.161 0 00-1.069-4.029v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgApple;

