"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Switch = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var lodash_1 = require("lodash");
var Switch = function (props) {
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var trackColorEnabled = {
        true: react_native_1.Platform.OS === 'ios' ? colors.accent : colors.accentInactive,
        false: colors.input.borderInactive,
    };
    var trackColorDisabled = {
        true: colors.accentInactiveDeemphasized,
        false: colors.input.borderInactive,
    };
    var thumbColor = function () {
        if (react_native_1.Platform.OS === 'ios') {
            if (props.value) {
                return colors.background;
            }
            else {
                return !!props.disabled ? colors.backgroundInactive : colors.background;
            }
        }
        if (props.value) {
            return !!props.disabled ? colors.accentInactive : colors.accent;
        }
        else {
            return !!props.disabled ? colors.backgroundInactive : colors.accentInactive;
        }
    };
    var switchProps = {
        thumbColor: thumbColor(),
        trackColor: !(0, lodash_1.isNil)(props.trackColor)
            ? props.trackColor
            : !!props.disabled
                ? trackColorDisabled
                : trackColorEnabled,
        // Make the Switch component consume touch events so that the
        // MBTouchableOpacity from MBFormFieldBase doesn't _also_ receive
        // them. Otherwise we'd both toggle the switch and call onPress above.
        onStartShouldSetResponder: function () { return true; },
        style: { cursor: props.disabled ? 'default' : 'pointer' },
        onValueChange: props.disabled ? function () { } : props.onValueChange,
        disabled: false,
    };
    var switchWebProps = {
        activeThumbColor: !!props.disabled ? colors.accentInactive : colors.accent,
        activeTrackColor: !!props.disabled ? colors.accentInactiveDeemphasized : colors.accentInactive,
    };
    return react_1.default.createElement(react_native_1.Switch, __assign({}, props, switchProps, switchWebProps));
};
exports.Switch = Switch;
exports.Switch.displayName = 'Switch';
