import * as React from 'react';
function SvgCoffee(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.478 7v3.13M14.26 9.087v3.13M24.696 9.087v3.13"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M28.87 16.391H32v5.479c0 1.008-.7 1.826-1.565 1.826H28.87"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M25.113 29.956h-11.27c-2.074 0-3.756-1.538-3.756-3.437V15.348H28.87v11.171c0 1.899-1.682 3.438-3.757 3.438z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M30.956 29.956s-.459 3.131-2.754 3.131H10.755c-2.296 0-2.755-3.13-2.755-3.13h22.956z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgCoffee;

