import * as React from 'react';
function SvgSurfboard(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 94 94" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.65 68.278L76.49 10.44c-5.88.548-13.085 2.842-21.237 7.83-12.644 7.736-27.212 21.775-41.78 44.83l5.178 5.18zM83.56 17.51L25.723 75.35l5.179 5.178c23.055-14.57 37.094-29.137 44.83-41.781 4.988-8.152 7.282-15.357 7.83-21.237zM50.035 9.738C64.898.644 78.74-1.535 89.016 1.735l2.465.784.784 2.465c3.27 10.276 1.09 24.118-8.004 38.982-9.142 14.943-25.48 31.329-51.492 47.17l-3.357 2.045L.819 64.588l2.044-3.357C18.705 35.22 35.091 18.881 50.034 9.738z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  );
}
export default SvgSurfboard;

