"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAccessSheet = void 0;
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var featureGateBanner_1 = require("@shared/scenes/channel/userList/components/featureGateBanner");
var styles_1 = require("@shared/styles");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var hooks_1 = require("@shared/util/permissions/hooks");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var MBAccessSheet = function (_a) {
    var accessLevel = _a.accessLevel, accessLevels = _a.accessLevels, style = _a.style, onShowUpgradeBanner = _a.onShowUpgradeBanner, onUpdateAccessLevel = _a.onUpdateAccessLevel;
    var context = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var permission = (0, hooks_1.usePermission)({
        action: 'update',
        subject: 'ChannelMembership',
        field: 'accessLevel',
        channelId: context === null || context === void 0 ? void 0 : context.channelId,
    });
    return (react_1.default.createElement(components_1.MBView, { style: style },
        !permission.enabled && permission.reason === hooks_1.PermissionReasonCode.STARTER_PLAN_REQUIRED && (react_1.default.createElement(featureGateBanner_1.FeatureGateBanner, null)),
        (!!permission.enabled || !!permission.reason) &&
            accessLevels.map(function (al) { return (react_1.default.createElement(components_1.MBSelectableRow, { key: al.type, style: styles.row, title: react_1.default.createElement(typography_1.MBTextBody1Bold, { color: colors.foregroundActive }, al.title), subtitle: react_1.default.createElement(typography_1.MBTextBody2, { color: colors.foregroundActive }, al.description), selected: al.type === accessLevel.type, onPress: permission.enabled ? function () { return onUpdateAccessLevel(al); } : onShowUpgradeBanner })); })));
};
exports.MBAccessSheet = MBAccessSheet;
var styles = react_native_1.StyleSheet.create({
    row: {
        paddingVertical: (0, components_1.grid)(1),
        paddingHorizontal: (0, components_1.grid)(1),
    },
});
