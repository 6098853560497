import * as React from 'react';
function SvgItalic(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23.183 10l-5.455 20" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M20.456 10h5.455M15 30h5.456"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgItalic;

