import * as React from 'react';
function SvgSearchLookUp(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M31 27l-4.172-4.172" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M16 17H9M16 23H9M24 29H9M24 11H9M24 24a4 4 0 100-8 4 4 0 000 8z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgSearchLookUp;

