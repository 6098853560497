"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowAddressComponent = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var react_1 = __importStar(require("react"));
var hooks_1 = require("../../hooks");
var mapPreview_1 = require("@shared/components/blocks/lib/inputGeolocation/mapPreview");
var styles_1 = require("@shared/styles");
var WorkflowComponentWrapper_1 = require("./WorkflowComponentWrapper");
var lodash_1 = require("lodash");
/*
  TODO
  This component is only used as a readOnly lookup component.
  Will need changes to use in forms. See inputAddress.
*/
var WorkflowAddressComponent = function (_a) {
    var initialData = _a.initialData, component = _a.component, showLabel = _a.showLabel, paddingHorizontal = _a.paddingHorizontal, iconPad = _a.iconPad;
    var data = initialData;
    var displayableData = data === null || data === void 0 ? void 0 : data.filter(function (d) { return !(0, lodash_1.isNil)(d); });
    if (!(0, hooks_1.isInputComponent)(component) || (0, lodash_1.isNil)(displayableData) || (0, lodash_1.isEmpty)(displayableData)) {
        return null;
    }
    var firstAddress = displayableData[0];
    var remainingAddresses = displayableData.slice(1);
    var extraChildren = (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(AddressMapPreview, { address: firstAddress }),
        remainingAddresses.map(function (address, index) { return (react_1.default.createElement(components_1.MBView, { style: { marginTop: (0, components_1.grid)(4), paddingHorizontal: paddingHorizontal }, key: index },
            react_1.default.createElement(AddressHeading, { address: address }),
            react_1.default.createElement(AddressMapPreview, { address: address }))); })));
    return (react_1.default.createElement(WorkflowComponentWrapper_1.WorkflowComponentWrapper, { extraChildren: extraChildren, paddingHorizontal: paddingHorizontal, iconPad: iconPad, component: component, showLabel: showLabel },
        react_1.default.createElement(AddressHeading, { address: firstAddress })));
};
exports.WorkflowAddressComponent = WorkflowAddressComponent;
var AddressHeading = function (_a) {
    var address = _a.address;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: 0.5 },
        react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, address.title),
        react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundInactive }, address.subtitle)));
};
var AddressMapPreview = function (_a) {
    var address = _a.address;
    return (react_1.default.createElement(components_1.MBView, { style: { marginTop: (0, components_1.grid)(2) } },
        react_1.default.createElement(mapPreview_1.GeolocationMapPreview, { lat: address.coords.lat, lon: address.coords.lon, googlePlaceId: address.googlePlaceId, iconUrl: "https://assets.coastapp.com/address-pin.png" })));
};
