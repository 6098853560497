import * as React from 'react';
function SvgLoading(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 1.5C6.201 1.5 1.5 6.201 1.5 12c0 1.078.163 2.119.465 3.098C3.287 19.385 7.279 22.5 12 22.5c5.799 0 10.5-4.701 10.5-10.5 0-3.566-1.778-6.717-4.496-8.615"
        stroke={props.color}
        strokeWidth={3}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default SvgLoading;

