"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelSingleFragmentTemplate = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var index_1 = require("./form/index");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var formContext_1 = require("../containers/formContext");
var MBChannelSingleFragmentTemplate = function (_a) {
    var readOnly = _a.readOnly;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useContext)(formContext_1.FragmentLoadingContext), loading = _b.loading, error = _b.error;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag", webContentStyle: { minHeight: '100%' }, nativeContentContainerStyle: { minHeight: '100%' }, onScrollBeginDrag: react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss },
            error && (react_1.default.createElement(components_1.MBView, { padding: 2, fullWidth: true, center: true, style: { backgroundColor: colors.error.background } },
                react_1.default.createElement(components_1.MBTextBody1Bold, null, (0, i18n_1.i18n)('channel.options.fragmentTemplates.loadingError')))),
            !loading ? (react_1.default.createElement(index_1.MBFragmentTemplateForm, { readOnly: readOnly })) : (react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, center: true, padding: 4 }))),
        react_1.default.createElement(components_1.MBNativeKeyboardSpacer, null)));
};
exports.MBChannelSingleFragmentTemplate = MBChannelSingleFragmentTemplate;
exports.MBChannelSingleFragmentTemplate.displayName = 'MBChannelSingleFragmentTemplate';
