"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTextInput = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var styles_2 = require("@shared/components/text/styles");
var styles_3 = require("./styles");
var MBTextInput = /** @class */ (function (_super) {
    __extends(MBTextInput, _super);
    function MBTextInput(props) {
        var _this = _super.call(this, props) || this;
        _this.textInput = React.createRef();
        // TODO probably unused since don't seem to work
        _this.focus = function () { return _this.textInput.current.focus(); };
        _this.blur = function () { return _this.textInput.current.blur(); };
        _this.isFocused = function () { return _this.textInput.current.isFocused(); };
        // end TODO
        _this.onKeyPress = function (event) {
            var _a, _b, _c, _d;
            (_b = (_a = _this.props).nativeOnKeyPress) === null || _b === void 0 ? void 0 : _b.call(_a, event.nativeEvent.key);
            (_d = (_c = _this.props).onKeyPress) === null || _d === void 0 ? void 0 : _d.call(_c, event);
        };
        _this.onPaste = function (event) {
            if (!!_this.props.onPaste) {
                var pastedText = event.clipboardData.getData('text');
                var shouldNotPreventDefault = _this.props.onPaste(pastedText);
                if (!shouldNotPreventDefault) {
                    event.preventDefault();
                }
            }
        };
        _this.state = {
            isFocused: false,
        };
        return _this;
    }
    MBTextInput.prototype.onFocus = function () {
        this.setState({ isFocused: true });
    };
    MBTextInput.prototype.onBlur = function () {
        this.setState({ isFocused: false });
    };
    MBTextInput.prototype.render = function () {
        var _this = this;
        var _a = this.props, style = _a.style, noBorder = _a.noBorder, noPadding = _a.noPadding, align = _a.align, bold = _a.bold, nativeOnContentSizeChange = _a.nativeOnContentSizeChange, nativeOnSelectionChange = _a.nativeOnSelectionChange, _b = _a.placeholderColor, placeholderColor = _b === void 0 ? styles_1.Colors.v2.gray50 : _b, _c = _a.size, size = _c === void 0 ? 'normal' : _c, _d = _a.spellCheck, spellCheck = _d === void 0 ? false : _d, autoCorrect = _a.autoCorrect, onBlur = _a.onBlur, onFocus = _a.onFocus, otherProps = __rest(_a, ["style", "noBorder", "noPadding", "align", "bold", "nativeOnContentSizeChange", "nativeOnSelectionChange", "placeholderColor", "size", "spellCheck", "autoCorrect", "onBlur", "onFocus"]);
        var platformSpecificProps = react_native_1.Platform.select({
            web: { onPaste: this.onPaste },
        });
        return (React.createElement(react_native_1.TextInput, __assign({ rejectResponderTermination: false, ref: this.textInput }, otherProps, platformSpecificProps, { onFocus: function () {
                onFocus === null || onFocus === void 0 ? void 0 : onFocus();
                _this.onFocus();
            }, onBlur: function (e) {
                onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
                _this.onBlur();
            }, spellCheck: spellCheck, autoCorrect: autoCorrect, style: [
                styles_3.Styles.default,
                !noBorder && styles_3.Styles.border,
                !noBorder && this.state.isFocused && styles_3.Styles.focusedBorder,
                noPadding ? styles_3.Styles.removeAndroidDefaultPadding : styles_3.Styles.padding,
                !!align && { textAlign: align },
                size === 'normal' && components_1.MBTypographyStyles.body1,
                size === 'normal' && bold && styles_2.Styles.bold,
                size === 'large' && components_1.MBTypographyStyles.h4,
                size === 'xlarge' && components_1.MBTypographyStyles.h3,
                // Strip the lineHeight, which causes alignment issues on React Native
                { lineHeight: undefined },
                style,
            ], placeholderTextColor: placeholderColor, underlineColorAndroid: "transparent", onContentSizeChange: nativeOnContentSizeChange, onSelectionChange: nativeOnSelectionChange, onKeyPress: this.onKeyPress })));
    };
    return MBTextInput;
}(React.PureComponent));
exports.MBTextInput = MBTextInput;
