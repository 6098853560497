// @flow
import React, { useCallback } from 'react';
import { MBAnalytics } from '@shared/util/analytics';
import { MBModalOrMobile } from '@shared/components';

import { actions } from '@src/core/Router';
import { BackableTopNavBar } from '@src/components/TopNavBar';
import { useNavigator } from '@shared/util/navigation/useNavigator';
import { Constants } from '@shared/styles/constants';

const onBack = () => {
  MBAnalytics.track('Modal::Back');
  actions.popRoute();
};

type WrapProps = {
  title: string,
  backgroundColor?: string,
  closeDisabled?: boolean,
  paddingOnWeb?: string,
  webOverflow?: boolean,
  button?: any,
  children?: any,
  onClose?: () => void
};

export function Wrap({
  title,
  button,
  children,
  paddingOnWeb,
  webOverflow,
  closeDisabled,
  backgroundColor,
  onClose
}: WrapProps) {
  const navigator = useNavigator();
  const handleClose = useCallback(() => {
    if (onClose) onClose();
    else navigator.goBack();
  }, [onClose]);
  return (
    <MBModalOrMobile
      title={title}
      webOverflow={webOverflow}
      headerOnMobile={<BackableTopNavBar title={title} rightElement={button} />}
      desktopModalRightButton={button}
      onClose={closeDisabled ? undefined : handleClose}
      onBack={onBack}
      paddingOnWeb={paddingOnWeb}
      backgroundColor={backgroundColor}
      desktopModalWidth={Constants.pageModalWidth}
    >
      {children}
    </MBModalOrMobile>
  );
}
