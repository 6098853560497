"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectionContexts = void 0;
exports.useCollectionCardTemplate = useCollectionCardTemplate;
exports.useCollectionViewTemplate = useCollectionViewTemplate;
exports.useResetCardActionFiltersWhenTemplateChanges = useResetCardActionFiltersWhenTemplateChanges;
exports.useNavigateToTemplate = useNavigateToTemplate;
// @ts-strict-ignore
var provider_1 = require("@shared/scenes/cards/actions/provider");
var hooks_1 = require("@shared/scenes/cards/hooks");
var alert_1 = require("@shared/util/alert");
var hooks_2 = require("@shared/util/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var hooks_3 = require("@shared/util/storage/hooks");
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_4 = require("@shared/scenes/workflows/hooks");
exports.collectionContexts = ['list', 'kanban', 'agenda'];
var buildKey = function (channelSlug) {
    return ['CardCollection', 'previousTemplateSlug', 'v1', channelSlug].join('.');
};
function usePreviousTemplateSlug(channelSlug, templateSlug) {
    var key = (0, react_1.useMemo)(function () { return buildKey(channelSlug); }, [channelSlug]);
    var stored = (0, hooks_3.useFromStorage)(key);
    var persistInStorage = (0, hooks_3.usePersistInStorage)(key);
    (0, react_1.useEffect)(function () {
        if (!!templateSlug) {
            persistInStorage(templateSlug);
            stored.refetch();
        }
    }, [persistInStorage, stored, templateSlug]);
    return stored.value;
}
function useCollectionCardTemplate(_a) {
    var channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, context = _a.context;
    var previousTemplateSlug = usePreviousTemplateSlug(channelSlug, templateSlug);
    var channel = (0, hooks_2.useGetChannelInfo)({ channelSlug: channelSlug });
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var templateQuery = (0, react_1.useMemo)(function () {
        var isQueryingSpecificTemplate = !!templateSlug || !!context;
        if (!isQueryingSpecificTemplate) {
            return {
                channelSlug: channelSlug,
                contexts: exports.collectionContexts,
                templateSlug: previousTemplateSlug,
                cardDefinition: cardDefinition,
            };
        }
        return {
            channelSlug: channelSlug,
            cardDefinition: cardDefinition,
            templateSlug: templateSlug,
            contexts: !!context ? [context] : exports.collectionContexts,
        };
    }, [channelSlug, previousTemplateSlug, templateSlug, context, cardDefinition]);
    return (0, hooks_1.useCardTemplate)(templateQuery);
}
function useCollectionViewTemplate(workflowTemplateId, channelSlug, templateSlug) {
    var _a;
    var previousTemplateSlug = usePreviousTemplateSlug(channelSlug, templateSlug);
    var isQueryingSpecificTemplate = !(0, lodash_1.isNil)(templateSlug) || !(0, lodash_1.isNil)(previousTemplateSlug);
    var _b = (0, hooks_4.useViewTemplates)(workflowTemplateId, 'COLLECTION', undefined, undefined, undefined, 1, isQueryingSpecificTemplate), viewTemplates = _b.viewTemplates, loadingViewTemplates = _b.loading;
    var _c = (0, hooks_4.useViewTemplate)(workflowTemplateId, templateSlug !== null && templateSlug !== void 0 ? templateSlug : previousTemplateSlug), viewTemplate = _c.viewTemplate, loadingViewTemplate = _c.loading;
    var loading = loadingViewTemplate || loadingViewTemplates;
    return {
        viewTemplate: (_a = viewTemplate !== null && viewTemplate !== void 0 ? viewTemplate : viewTemplates === null || viewTemplates === void 0 ? void 0 : viewTemplates[0]) !== null && _a !== void 0 ? _a : null,
        loading: loading,
    };
}
function useResetCardActionFiltersWhenTemplateChanges(template) {
    var _a = (0, react_1.useContext)(provider_1.CardFilterStateContext), setAppliedFilters = _a.setAppliedFilters, setExcludedFieldNames = _a.setExcludedFieldNames;
    var previousTemplate = (0, hooks_2.usePrevious)(template);
    (0, react_1.useEffect)(function () {
        if (template && previousTemplate !== template) {
            setAppliedFilters([]);
            var excludedFieldNames = [];
            var groupBy = template.version === 'v2' &&
                (template.context === 'list' || template.context === 'kanban') &&
                template.groupBy;
            if (groupBy) {
                if (groupBy.type === 'tag') {
                    excludedFieldNames.push(groupBy.tagName);
                }
                else if (groupBy.type === 'card') {
                    excludedFieldNames.push(groupBy.cardName);
                }
                else if (groupBy.type === 'assignees') {
                    excludedFieldNames.push('assignees');
                }
            }
            if ((template.version === 'v2' &&
                (template.context === 'list' || template.context === 'kanban')) ||
                template.context === 'agenda') {
                // We just need to know if the template is filtering by "{me}"; pass a stub find function
                var filterByMe = (0, hooks_1.getCardPaginationAssigneeFilter)(template, function () { return ({}); });
                if (filterByMe) {
                    excludedFieldNames.push('assignees');
                }
            }
            if (template.context === 'agenda' ||
                ((template.context === 'list' || template.context === 'kanban') &&
                    template.version === 'v1')) {
                excludedFieldNames.push('startDate');
            }
            setExcludedFieldNames(excludedFieldNames);
        }
    }, [previousTemplate, template, setExcludedFieldNames, setAppliedFilters]);
}
function useNavigateToTemplate(channelSlug) {
    var navigator = (0, useNavigator_1.useNavigator)();
    return (0, react_1.useCallback)(function (template) {
        if (!template.slug) {
            alert_1.MBAlert.alert({
                message: "Cannot show the template \"".concat(template.title, "\". Please provide a template slug."),
            });
        }
        else {
            navigator.replace('Channel', { channelSlug: channelSlug, templateSlug: template.slug });
            navigator.navigate('ChannelCardList', { channelSlug: channelSlug, templateSlug: template.slug });
        }
    }, [channelSlug, navigator]);
}
