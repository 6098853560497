"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    mentionRowImage: {
        width: 28,
        height: 28,
        borderRadius: 54,
        overflow: 'hidden',
    },
    mentionRowHover: {
        backgroundColor: styles_1.Colors.v2.gray05,
    },
    mentionList: __assign({ backgroundColor: 'white', borderRadius: 10, overflow: 'hidden', maxHeight: 48 * 3.5, 
        // hack to get border radius to hide overflow (eg on item hover => changes bg color)
        // https://stackoverflow.com/a/25206004/9117995
        zIndex: 1 }, react_native_1.Platform.select({ web: styles_1.SharedUnvalidatedStyles.shadow })),
});
