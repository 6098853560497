import * as React from 'react';
function SvgChatSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 5.4c-3.643 0-6.6 2.772-6.6 6.188 0 2.004.413 3.3 2.063 4.95L12 20.25v-2.475c3.643 0 6.6-2.772 6.6-6.187C18.6 8.171 15.643 5.4 12 5.4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgChatSmall;

