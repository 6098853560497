"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectableDay = void 0;
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var react_1 = __importStar(require("react"));
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var SelectableDay = function (props) {
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var styleSheets = react_native_1.StyleSheet.create({
        containerNotSelected: {
            borderRadius: 20,
            borderWidth: 2,
            height: 40,
            width: 40,
            maxWidth: 40,
            alignContent: 'center',
            justifyContent: 'center',
            borderColor: colors.button.background,
            backgroundColor: colors.button.backgroundInverted,
        },
        containerSelected: {
            borderRadius: 20,
            height: 40,
            width: 40,
            alignContent: 'center',
            justifyContent: 'center',
            borderColor: colors.button.backgroundInverted,
            backgroundColor: colors.button.background,
        },
    });
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { style: props.isSelected ? styleSheets.containerSelected : styleSheets.containerNotSelected, onPress: props.onSelect },
        react_1.default.createElement(typography_1.MBTextBody2Bold, { align: "center", color: props.isSelected ? colors.button.foreground : colors.button.foregroundInverted }, props.day)));
};
exports.SelectableDay = SelectableDay;
