import * as React from 'react';
function SvgPie(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.51 18a2.43 2.43 0 00-.816.156 10.931 10.931 0 00-15.367 0A2.44 2.44 0 0011.51 18a2.5 2.5 0 102.125 3.8 2.483 2.483 0 004.25 0 2.483 2.483 0 004.25 0 2.483 2.483 0 004.25 0A2.5 2.5 0 1028.51 18v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M11.51 23l.781 6.248A2 2 0 0014.276 31h11.468a2 2 0 001.985-1.752L28.51 23"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20.01 9v2M14.01 10v2M26.01 10v2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPie;

