"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBottomDrawerDiscovery = void 0;
var react_1 = require("react");
var lodash_1 = require("lodash");
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/util/storage/hooks");
var useBottomDrawerDiscovery = function (translateY, discoveryKey) {
    if (discoveryKey === void 0) { discoveryKey = 'global'; }
    var key = "bottomDrawer.".concat(discoveryKey);
    var seenDiscovery = (0, hooks_1.useFromStorage)(key, null);
    var persistSeenDiscovery = (0, hooks_1.usePersistInStorage)(key);
    var bounceAnim = (0, react_1.useRef)();
    var bounce = (0, react_1.useCallback)((0, lodash_1.once)(function () {
        bounceAnim.current = react_native_1.Animated.sequence([
            react_native_1.Animated.timing(translateY, {
                delay: 2000,
                duration: 750,
                toValue: -(0, components_1.grid)(5),
                useNativeDriver: false,
                easing: react_native_1.Easing.elastic(1.25),
            }),
            react_native_1.Animated.timing(translateY, {
                delay: 500,
                duration: 750,
                toValue: 0,
                useNativeDriver: false,
                easing: react_native_1.Easing.elastic(1.25),
            }),
        ]);
        bounceAnim.current.start();
    }), [translateY]);
    (0, react_1.useEffect)(function () {
        if (!seenDiscovery.loading && seenDiscovery.value !== 'true') {
            bounce();
        }
    }, [bounce, seenDiscovery]);
    var setAsDiscovered = (0, react_1.useCallback)(function () {
        persistSeenDiscovery('true');
    }, [persistSeenDiscovery]);
    var cancel = (0, react_1.useCallback)(function () { var _a; return (_a = bounceAnim.current) === null || _a === void 0 ? void 0 : _a.stop(); }, []);
    return { setAsDiscovered: setAsDiscovered, cancel: cancel };
};
exports.useBottomDrawerDiscovery = useBottomDrawerDiscovery;
