"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowComponentStyles = void 0;
var react_native_1 = require("react-native");
var components_1 = require("../../../../components");
exports.WorkflowComponentStyles = react_native_1.StyleSheet.create({
    container: {
        marginVertical: (0, components_1.grid)(1),
        paddingTop: (0, components_1.grid)(2),
        paddingHorizontal: (0, components_1.grid)(1),
        marginHorizontal: (0, components_1.grid)(2),
        borderTopWidth: 1,
        borderTopColor: '#E6E6E6',
    },
    textarea: {
        backgroundColor: '#F7F7F7',
        borderRadius: (0, components_1.grid)(1),
        padding: (0, components_1.grid)(1),
        paddingLeft: (0, components_1.grid)(2),
        paddingRight: (0, components_1.grid)(2),
        marginBottom: (0, components_1.grid)(1),
    },
    label: {
        minHeight: 60,
    },
});
