"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectoryContext = exports.isDeactivated = exports.isPending = exports.isStaff = void 0;
var react_1 = require("react");
var isStaff = function (user) { return !user.isPending && !user.isRemoved; };
exports.isStaff = isStaff;
var isPending = function (user) { return user.isPending; };
exports.isPending = isPending;
var isDeactivated = function (user) { return user.isRemoved; };
exports.isDeactivated = isDeactivated;
exports.DirectoryContext = (0, react_1.createContext)({});
