import * as React from 'react';
function SvgArrowOutlineRight(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={20} cy={20} r={12} stroke={props.color} strokeWidth={2} />
      <path
        d="M14 20h11.77"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M20.23 26l6-6-6-6" stroke={props.color} strokeWidth={2} />
    </svg>
  );
}
export default SvgArrowOutlineRight;

