"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOverflowMenu = useOverflowMenu;
var react_1 = require("react");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var lodash_1 = __importStar(require("lodash"));
var actionSheet_1 = require("@shared/util/actionSheet");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("../../hooks");
var toast_1 = require("@shared/components/toast");
var react_native_1 = require("react-native");
var alert_1 = require("@shared/util/alert");
var react_dom_1 = require("react-dom");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var entitySelection_1 = require("@shared/api/providers/entitySelection");
var Entity_1 = require("@shared/data/entity/Entity");
var hooks_2 = require("../../entityBulkActions/hooks");
function useRemoveCardWithConfirmation(entityId) {
    var removeCard = (0, hooks_1.useRemoveCard)();
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    return (0, react_1.useCallback)(function (anchor) {
        function remove() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, removeCard(entityId, 'ENTITY')];
                        case 1:
                            _a.sent();
                            toast === null || toast === void 0 ? void 0 : toast.show({
                                icon: 'check-filled',
                                message: (0, i18n_1.i18n)('card.details.deleteCompletedNoName'),
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (react_native_1.Platform.OS === 'web') {
            actionSheet_1.MBActionSheet.show({
                parent: anchor,
                title: (0, i18n_1.i18n)('card.details.deleteConfirmation.title'),
                webHorizontalAlignment: 'right',
                options: [
                    {
                        title: (0, i18n_1.i18n)('card.details.deleteConfirmation.confirm'),
                        destructive: true,
                        onPress: function () {
                            remove();
                        },
                    },
                    {
                        title: (0, i18n_1.i18n)('common.cancel'),
                    },
                ],
            });
        }
        else {
            alert_1.MBAlert.confirm({
                title: (0, i18n_1.i18n)('card.details.deleteConfirmation.title'),
                message: (0, i18n_1.i18n)('card.details.deleteConfirmation.messageNoName'),
                confirmText: (0, i18n_1.i18n)('card.details.deleteConfirmation.confirm'),
                confirmStyle: 'destructive',
                onConfirm: function () {
                    remove();
                },
            });
        }
    }, [entityId, removeCard, toast]);
}
// magic number based on height of overflow menu
// on web. This is used to determine if the menu
// should be displayed above or below the anchor.
// adjust if adding/removing menu options
var OVERFLOW_MENU_HEIGHT_WEB = 300;
function useOverflowMenu(_a) {
    var entity = _a.entity, workflowTemplate = _a.workflowTemplate, onShare = _a.onShare, onDownload = _a.onDownload, openEntity = _a.openEntity, _b = _a.webHorizontalAlignment, webHorizontalAlignment = _b === void 0 ? 'right' : _b;
    var workflowTemplateId = workflowTemplate.id;
    // - TODO: this somewhat hook is coupled with entity selection, so need to
    //   refactor a bit
    // - TODO: after duplicating, the duplicate doesn't automatically appear in
    //   the UI
    var duplicateEntities = (0, hooks_2.useDuplicateEntities)({ workflowTemplateId: workflowTemplateId });
    var removeCardWithConfirmation = useRemoveCardWithConfirmation(entity.id);
    var _c = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext), canCreateCard = _c.canCreateCard, canUpdateCard = _c.canUpdateCard, canDeleteCard = _c.canDeleteCard;
    var isWeb = react_native_1.Platform.OS === 'web';
    var entitySelection = (0, entitySelection_1.useEntitySelection)();
    var isAuditTrailEnabled = workflowTemplate.isAuditTrailEnabled;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var navigateToCardHistory = useNavigateToCardHistory(entity, channel === null || channel === void 0 ? void 0 : channel.slug);
    var showOverflowMenu = (0, react_1.useCallback)(function (anchor) {
        var y = (isWeb ? (0, react_dom_1.findDOMNode)(anchor).getBoundingClientRect() : { y: 0 }).y;
        return actionSheet_1.MBActionSheet.show({
            parent: anchor,
            webVerticalAlignment: y + OVERFLOW_MENU_HEIGHT_WEB > window.innerHeight ? 'above' : 'below',
            webHorizontalAlignment: webHorizontalAlignment,
            onClose: function () { },
            options: [
                {
                    title: (0, i18n_1.i18n)('common.edit'),
                    hidden: !canUpdateCard.enabled,
                    onPress: openEntity,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.duplicate'),
                    hidden: !canCreateCard.enabled,
                    onPress: function () {
                        duplicateEntities([entity].map(Entity_1.toCreateWorkflowEntity));
                    },
                },
                {
                    title: (0, i18n_1.i18n)('common.share'),
                    onPress: onShare,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.selectMultiple'),
                    hidden: !isWeb,
                    onPress: function () {
                        entitySelection.addEntity(entity);
                    },
                },
                {
                    title: (0, i18n_1.i18n)('common.download'),
                    hidden: lodash_1.default.isNil(onDownload),
                    onPress: onDownload,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.cardHistory'),
                    hidden: !isAuditTrailEnabled || (0, lodash_1.isNil)(navigateToCardHistory),
                    onPress: navigateToCardHistory,
                },
                {
                    title: (0, i18n_1.i18n)('common.delete'),
                    destructive: true,
                    hidden: !canDeleteCard.enabled,
                    onPress: function () { return removeCardWithConfirmation(anchor); },
                },
            ],
        });
    }, [
        canUpdateCard.enabled,
        openEntity,
        canCreateCard.enabled,
        onShare,
        onDownload,
        isAuditTrailEnabled,
        navigateToCardHistory,
        canDeleteCard.enabled,
        duplicateEntities,
        entity,
        removeCardWithConfirmation,
        isWeb,
        webHorizontalAlignment,
        entitySelection,
    ]);
    return {
        shouldShowOverflowMenu: canCreateCard.enabled || canDeleteCard.enabled || canUpdateCard.enabled,
        showOverflowMenu: showOverflowMenu,
    };
}
function useNavigateToCardHistory(entity, channelSlug) {
    var navigateToCardHistory = (0, useNavigator_1.useNavigateTo)('CardHistory', {
        cardId: entity.id,
        channelSlug: channelSlug !== null && channelSlug !== void 0 ? channelSlug : '',
    });
    if ((0, lodash_1.isNil)(channelSlug)) {
        return undefined;
    }
    return navigateToCardHistory;
}
