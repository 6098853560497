import * as React from 'react';
function SvgTreeEvergreen(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 27v4M15 31h10" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M29 27l-5.25-7H27l-7-10-7 10h3.25L11 27h18z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgTreeEvergreen;

