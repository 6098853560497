"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAlert = void 0;
exports.MBAlert = {
    confirm: function (_a) {
        var message = _a.message, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
        if (window.confirm(message)) {
            onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm();
            return Promise.resolve(true);
        }
        onCancel === null || onCancel === void 0 ? void 0 : onCancel();
        return Promise.resolve(false);
    },
    alert: function (_a) {
        var title = _a.title, message = _a.message, onConfirm = _a.onConfirm;
        alert(!!message ? message : title);
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm();
        return Promise.resolve(true);
    },
};
