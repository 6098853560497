"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelChatDesktop = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var fileDropListener_1 = require("@shared/scenes/chat/input/containers/fileDropListener");
var containers_1 = require("@shared/scenes/chat/actionButtons/containers");
var channel_1 = require("@shared/scenes/chat/input/containers/input/channel");
var channel_2 = require("@shared/scenes/chat/thread/containers/thread/channel");
var tooltipOverlay_1 = require("@shared/scenes/chat/input/containers/tooltipOverlay");
var channelWithSearch_1 = require("@shared/scenes/chat/thread/containers/thread/channelWithSearch");
var hooks_1 = require("@shared/scenes/channel/chat/components/hooks");
var component_1 = require("@shared/scenes/billing/banners/component");
var react_redux_1 = require("react-redux");
var react_native_safe_area_context_1 = require("react-native-safe-area-context");
var MBChannelChatDesktop = function (_a) {
    var channelSlug = _a.channelSlug, isFocused = _a.isFocused, autoFocus = _a.autoFocus, selectedMessage = _a.selectedMessage, onSend = _a.onSend, disableNativeKeyboardSpacer = _a.disableNativeKeyboardSpacer;
    var _b = React.useState(null), scrollRef = _b[0], setScrollRef = _b[1];
    var handleSend = (0, hooks_1.useHandleSend)(scrollRef, onSend);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var safeAreaInsets = (0, react_native_safe_area_context_1.useSafeAreaInsets)();
    // If we want to jump to a specific message, we
    // want to use another chat thread container
    var Component = !!selectedMessage ? channelWithSearch_1.MBChannelWithSearchChatThread : channel_2.MBChannelChatThread;
    return (React.createElement(fileDropListener_1.MBChatInputFileDropListener, { fill: true, draftId: channelSlug, style: { paddingBottom: safeAreaInsets.bottom } },
        React.createElement(components_1.MBView, { fill: true },
            React.createElement(Component, { key: channelSlug, slug: channelSlug, selectedMessage: selectedMessage, isFocused: isFocused, scrollRef: setScrollRef }),
            React.createElement(tooltipOverlay_1.MBChatInputTooltipOverlay, null),
            !isDesktop && React.createElement(component_1.MBBillingLimitBanners, null)),
        React.createElement(components_1.MBView, { paddingBottom: 1 },
            React.createElement(channel_1.MBChannelChatInput, { channelSlug: channelSlug, autoFocus: autoFocus, onSend: handleSend }),
            !disableNativeKeyboardSpacer && React.createElement(components_1.MBNativeKeyboardSpacer, null)),
        React.createElement(containers_1.MBChatActionButtons, { channelSlug: channelSlug, visible: true })));
};
exports.MBChannelChatDesktop = MBChannelChatDesktop;
