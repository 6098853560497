"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelList = void 0;
var React = __importStar(require("react"));
var i18n_1 = require("@shared/util/i18n");
var components_1 = require("@shared/components");
// TODO(fant): abstract this component. Consider moving
// some of the subtitle i18n logic into MBChannelRow
var MBChannelRowWithSubtitle = function (_a) {
    var channel = _a.channel, onPress = _a.onPress;
    var press = React.useCallback(function () { return onPress(channel); }, [onPress, channel]);
    return (React.createElement(components_1.MBChannelRow, { channel: channel, onPress: press, showUnreadCount: false, subtitle: (function () {
            if (channel.type === 'DM') {
                // For DMs we don't show the subtitle "@username". The reason
                // is because we'd otherwise would have to keep in mind removedUsers.
                // When users are removed, they will no longer be among the users
                // of channels (incl DM channels). So if we would want to show the
                // subtitle like the design ("@username"), we'd have to sort through
                // channel.removedUsers, which is hacky. TODO(fant): figure out how
                // to make this less bad.
                return null;
            }
            // Otherwise, show "n members"
            return (0, i18n_1.i18n)('channel.create.nMembers', { count: channel.users.length });
        })() }));
};
var MBChannelList = function (_a) {
    var title = _a.title, channels = _a.channels, onPressChannel = _a.onPressChannel;
    if (!channels.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(components_1.MBListHeader, { title: title }),
        channels.map(function (channel) { return (React.createElement(MBChannelRowWithSubtitle, { key: channel.slug, channel: channel, onPress: onPressChannel })); })));
};
exports.MBChannelList = MBChannelList;
