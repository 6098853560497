import * as React from 'react';
function SvgSupportGray(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
        fill="#E6E6E6"
      />
      <path
        d="M12 18.5a.49.49 0 00.5-.48.49.49 0 00-.5-.48.49.49 0 00-.5.48c0 .265.224.48.5.48z"
        fill="#E6E6E6"
        stroke="gray"
      />
      <path
        d="M9.5 7.054c2.04-.866 4.681-.763 5.583.638.902 1.4.28 3.03-1.267 4.288-1.546 1.257-2.103 1.94-2.103 2.93"
        fill="#E6E6E6"
      />
      <path
        d="M9.5 7.054c2.04-.866 4.681-.763 5.583.638.902 1.4.28 3.03-1.267 4.288-1.546 1.257-2.103 1.94-2.103 2.93"
        stroke="gray"
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgSupportGray;

