"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduledAutomationFieldOption = void 0;
var hooks_1 = require("../../hooks");
var ScheduledAutomationFieldOption = /** @class */ (function () {
    function ScheduledAutomationFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    ScheduledAutomationFieldOption.prototype.matchesComponent = function (component, _reservedIds) {
        return (0, hooks_1.isScheduledAutomationComponent)(component);
    };
    ScheduledAutomationFieldOption.prototype.canBeAdded = function (_components) {
        return true;
    };
    return ScheduledAutomationFieldOption;
}());
exports.ScheduledAutomationFieldOption = ScheduledAutomationFieldOption;
