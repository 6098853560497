"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTitleBar = exports.useKeyboardHeight = exports.useDimensions = exports.useKeyboardShowCallback = exports.useKeyboard = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var hooks_1 = require("@shared/util/hooks");
var components_1 = require("@shared/components");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var useKeyboard = function (onShow, onHide) {
    var handleKeyboardShow = (0, react_1.useCallback)(function (event) { return onShow === null || onShow === void 0 ? void 0 : onShow(event.endCoordinates.height); }, [onShow]);
    var handleKeyboardHide = (0, react_1.useCallback)(function () { return onHide === null || onHide === void 0 ? void 0 : onHide(); }, [onHide]);
    (0, hooks_1.useComponentDidMount)(function () {
        var didShowListener = react_native_1.Keyboard.addListener('keyboardDidShow', handleKeyboardShow);
        var didHideListener = react_native_1.Keyboard.addListener('keyboardDidHide', handleKeyboardHide);
        return function () {
            didShowListener.remove();
            didHideListener.remove();
        };
    });
};
exports.useKeyboard = useKeyboard;
var useKeyboardShowCallback = function (callback) {
    var callbackRef = (0, react_1.useRef)();
    (0, exports.useKeyboard)(function () {
        setTimeout(function () {
            var _a;
            (_a = callbackRef.current) === null || _a === void 0 ? void 0 : _a.call(callbackRef);
            callbackRef.current = null;
        }, 1);
    });
    return (0, react_1.useCallback)(function () {
        callbackRef.current = callback;
    }, [callback]);
};
exports.useKeyboardShowCallback = useKeyboardShowCallback;
var useDimensions = function () {
    return (0, react_1.useMemo)(function () {
        var windowHeight = react_native_1.Dimensions.get('window').height - Number(react_native_1.StatusBar.currentHeight || 0);
        var initialTop = windowHeight / 2;
        return {
            fullTop: (0, components_1.grid)(6),
            initialTop: initialTop,
        };
    }, []);
};
exports.useDimensions = useDimensions;
var useKeyboardHeight = function () {
    var _a = (0, react_1.useState)(0), keyboardHeight = _a[0], setKeyboardHeight = _a[1];
    (0, exports.useKeyboard)(function (height) { return setKeyboardHeight(react_native_1.Platform.select({ ios: height, android: 0 })); }, function () { return setKeyboardHeight(0); });
    return keyboardHeight;
};
exports.useKeyboardHeight = useKeyboardHeight;
var useTitleBar = function (title, renderTitleBar) {
    return (0, react_1.useMemo)(function () {
        if (title) {
            return react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: title });
        }
        if (renderTitleBar) {
            return renderTitleBar();
        }
        if (react_native_1.Platform.OS === 'web') {
            return;
        }
        // provide whitespace as a drag target
        // NOTE: android gesture do not work unless a solid background is set
        return react_1.default.createElement(react_native_1.View, { style: { backgroundColor: 'white', paddingTop: (0, components_1.grid)(2) } });
    }, [renderTitleBar, title]);
};
exports.useTitleBar = useTitleBar;
