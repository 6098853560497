/* @flow */
/* eslint react/no-multi-comp: 0 */
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import PureComponent from '@src/components/PureComponent';

import styles from './FormComponents.css';

type FormProps<Callback> = {
  onSubmit: Callback,
  children?: React.Element<*>,
  className?: string,
  hasError?: boolean,
};
export function Form<Callback>({ children, className, hasError, onSubmit }: FormProps<Callback>) {
  return (
    <form
      className={`${hasError ? styles.formError : styles.form} ${className || ''}`}
      action="#"
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
}

type LabelProps = {
  htmlFor: string,
  children?: React.Element<*>,
};
export class Label extends PureComponent {
  props: LabelProps;

  render() {
    return (
      <label htmlFor={this.props.htmlFor} className={styles.label}>
        {this.props.children}
      </label>
    );
  }
}

type InputProps = {
  id: string,
  type: string,
  value: string,
  onChange: (ev: BrowserEvent) => void,
  onClear?: (ev: BrowserEvent) => void,
  className?: string,
  wrapperClassName?: string,
};
export class Input extends PureComponent {
  props: InputProps;

  render() {
    const {
      id,
      type,
      value,
      onChange,
      onClear,
      className,
      wrapperClassName,
      ...props
    } = this.props;

    return (
      <div className={`${styles.inputWrapper} ${wrapperClassName || ''}`}>
        <input
          {...props}
          id={id}
          type={type}
          className={`${styles.input} ${className || ''}`}
          value={value}
          onChange={onChange}
        />
        {onClear &&
          <span
            className={`${styles.closeIcon} ${value.trim() ? '' : styles.hidden}`}
            onClick={onClear}
          />
        }
      </div>
    );
  }
}

type TextareaProps = {
  id: string,
  value: string,
  onChange: (ev: BrowserEvent) => void,
};
export class Textarea extends PureComponent {
  props: TextareaProps;

  render() {
    const { id, value, onChange, ...props } = this.props;
    return (
      <TextareaAutosize
        {...props}
        id={id}
        className={styles.textarea}
        value={value}
        onChange={onChange}
      />
    );
  }
}

export class EmptyInput extends React.Component<*, { className?: string, children: any }, *> {
  render() {
    return (
      <div className={`${styles.baseInput} ${this.props.className || ''}`}>
        {this.props.children}
      </div>
    );
  }
}

export type SelectProps = {
  id: string,
  value: string,
  onChange: (ev: BrowserEvent) => void,
  children?: React.Element<*>,
  className?: string,
};

export class Select extends PureComponent {
  props: SelectProps;

  render() {
    const { id, value, children, onChange, className, ...props } = this.props;
    return (
      <select
        {...props}
        id={id}
        value={value}
        className={`${styles.select} ${className || ''}`}
        onChange={onChange}
      >
        {children}
      </select>
    );
  }

}

type HelpTextProps = {
  children?: React.Element<*>,
  isError?: boolean,
  isInfo?: boolean,
};
export function HelpText({ children, isError, isInfo }: HelpTextProps) {
  const className = isInfo ? styles.helpInfo : styles.help;
  return (
    <div className={`${className} ${isError ? styles.error : ''}`}>
      {children}
    </div>
  );
}

type FormRowProps = {
  children?: React.Element<*>,
  hasError?: boolean,
  isFocused?: boolean,
};
export function FormRow({ children, hasError, isFocused }: FormRowProps) {
  const className = hasError ?
    styles.formRowError :
      isFocused ?
        styles.formRowFocused :
        styles.formRow;

  return <div className={className}>{children}</div>;
}


type SendSMSHelpTextProps = {
  notifyCount: number,
};
export function SendSMSHelpText({ notifyCount }: SendSMSHelpTextProps) {
  return (
    <HelpText isInfo={true}>
      Send text message to {notifyCount} {notifyCount > 1 ? 'people' : 'person'}
    </HelpText>
  );
}
