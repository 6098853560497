import * as React from 'react';
function SvgMoneyDollarCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 31c6.075 0 11-4.925 11-11S26.075 9 20 9 9 13.925 9 20s4.925 11 11 11zM20 13v14"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M23.375 15.219c-1.621-.891-6.727-1.556-6.727 1.513 0 3.674 6.484 2.377 6.484 5.619S18.918 25.349 16 24.296"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMoneyDollarCircle;

