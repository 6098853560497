import * as React from 'react';
function SvgCheckFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 32c6.627 0 12-5.373 12-12 0-2.206-.595-4.273-1.634-6.049l-10.48 10.481-.707.707-.707-.706-4.379-4.37 1.414-1.416 3.67 3.665 10.02-10.02A11.975 11.975 0 0020 8C13.373 8 8 13.373 8 20s5.373 12 12 12z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgCheckFilled;

