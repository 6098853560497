exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--7-2!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../composes.css"), "");
// Module
exports.push([module.id, "._2J-OwmxM0WqmTTsfC64flO {\n  padding: 0 32px;\n  text-align: center;\n}\n\n._18d4cN5lg8LpmSfhoI2OQB {\n  font-size: 1.25rem;\n  color: #242424;\n}\n\n._1TYoKMN2PBpD-rQXwb2l1W {\n  font-size: 0.875rem;\n  color: #242424;\n  padding-top: 16px;\n  line-height: 1.5rem;\n}\n\n._3tfniquzdmMPKjdIHJUuYI {\n  color: #EB0902;\n  font-size: 0.875rem;\n  padding-top: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_2J-OwmxM0WqmTTsfC64flO " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--7-2!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../composes.css").locals["flexColumnCenter"] + "",
	"text": "_18d4cN5lg8LpmSfhoI2OQB",
	"subText": "_1TYoKMN2PBpD-rQXwb2l1W",
	"error": "_3tfniquzdmMPKjdIHJUuYI"
};