// Note(fant): hack to get rid of the following error:
// "Error: only one instance of babel-polyfill is allowed"
// https://github.com/babel/babel-loader/issues/401#issuecomment-374766241
import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import { MBToastProvider } from '@shared/components/toast';

import { MBView } from '@shared/components';
import { MBMegaModal } from '@shared/scenes/navigation/megamodal/components/index';
import { Config, setupConfig } from '@shared/config';
import { createApolloClient } from '@shared/api/client';
import { useReconnectWebsocketOnBusinessChange } from '@shared/api/useReconnectWebsocketOnBusinessChange';
import {
  useDocumentTitle,
  useFavicon,
  useReturnQueryHandler,
} from '@shared/scenes/navigation/hooks';
import { useComponentDidMount } from '@shared/util/hooks';
import { useIdentifySegment, useIdentifySentry } from '@shared/scenes/auth/identifyHooks';
import { useDispatchViewportDimensions } from '@shared/store/useDispatchViewportDimensions';

import { configureStore } from '@src/redux/configureStore';
import fetchAndInsertUserIdIntoStore from '@src/redux/actions/fetchAndInsertUserIdIntoStore';

import Segment from '@src/core/Segment';
import { init as initFacebookPixel } from '@src/core/FacebookPixel';
import '@src/core/ServiceWorkerRegistration';

import { MBSentry } from '@shared/util/sentry';

import { IntercomProvider } from 'react-use-intercom';
import { LDProvider, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { AppRouter } from './routes';
import { addOnVisibilityChange, getVisibility } from '@src/core/VisibilityAPI';

// https://stackoverflow.com/questions/42119878
import 'normalize.css';
import './index.css';
import './composes.css'; // To get it prefixed
import '@shared/styles/vars.css';
import '@shared/components/carousel/styles.css';
import { ResponseHeadersContext, useResponseHeaders } from '@shared/api/headers';
import { KeyValueProvider } from '@shared/util/storage/keyValueContext';
import moment from 'moment';
import { MBBillingProvider } from '@shared/scenes/billing/banners/provider';
import { MBCardActionsProvider } from '@shared/scenes/cards/actions/provider';
import { CallProvider } from '@shared/scenes/calling/provider/index';
import { MBUnhandledError } from '@shared/scenes/error/unhandled';
import { CardSelectionProvider } from '@shared/api/providers/cardSelection';
import { EntitySelectionProvider } from '@shared/api/providers/entitySelection';
import { ConnectionProvider } from '@shared/scenes/connection/provider';
import { ConnectivityNotifier } from '@shared/scenes/connection/connectivity';
import { useResendMessages } from '@shared/api/useResendMessages';
import { GoogleMapsProvider } from '@shared/util/googleMaps/GoogleMapsContext';
import { useBranchListener } from '@shared/util/branch/hooks';
import { useInitLaunchDarkly } from '@shared/util/featureFlags/launchDarkly';
import { ForcedLogout } from './components/ForcedLogout';
import { useDataFreshness } from './hooks/useDataFreshness';

moment.locale(navigator.language);
global._babelPolyfill = false;

// When code changes, re-render the whole app
if (module.hot) {
  module.hot.accept('./routes.js', function () {
    ReactDOM.render(<App />, document.getElementById('root'));
  });
}

const HooksThatNeedProviders = ({ children }) => {
  useFavicon();
  useDocumentTitle();
  useIdentifySentry();
  useIdentifySegment();
  useReconnectWebsocketOnBusinessChange();
  useReturnQueryHandler();
  useDispatchViewportDimensions();
  useResendMessages();
  useBranchListener();
  useInitLaunchDarkly();

  return children;
};

const DataFreshness = ({client}) => {
  useDataFreshness(client);
  return null;
}
const safeAreaInsets = { top: 0, left: 0, right: 0, bottom: 0 };

const App = () => {
  const [store, setStore] = useState(undefined);
  const [client, setClient] = useState(undefined);

  const { link, headers } = useResponseHeaders();
  const notifier = new ConnectivityNotifier();

  useEffect(() => {
    window.addEventListener('offline', () => notifier.offline('window::offline'));
    window.addEventListener('online', () => notifier.online('window::online'));

    const init = async () => {
      MBSentry.init();
      Segment.init();
      initFacebookPixel();

      const newStore = configureStore();
      const client = createApolloClient([link], notifier);

      await fetchAndInsertUserIdIntoStore(newStore.dispatch);

      setStore(newStore);
      setClient(client);

      function onVisibilityChange({ isHidden }) {
        newStore.dispatch({ type: 'VISIBILITY_CHANGE', isHidden });
      }

      onVisibilityChange(getVisibility());
      addOnVisibilityChange(onVisibilityChange);
    };

    init();
  }, []);

  if (!store || !client) return null;

  return (
    <ConnectionProvider notifier={notifier}>
      <SafeAreaInsetsContext.Provider value={safeAreaInsets}>
        <MBUnhandledError>
          <ResponseHeadersContext.Provider value={headers}>
            <Provider store={store}>
              <ApolloProvider client={client}>
                <GoogleMapsProvider>
                  <IntercomProvider autoBoot appId={Config.INTERCOM_APP_ID}>
                    <CallProvider>
                      <MBBillingProvider>
                        <MBCardActionsProvider>
                          <KeyValueProvider>
                            <MBToastProvider>
                              <CardSelectionProvider>
                                <EntitySelectionProvider>
                                  <MBView fill>
                                    <AppRouter />
                                    <MBMegaModal />
                                    <HooksThatNeedProviders>
                                      <ForcedLogout />
                                      <DataFreshness client={client} />
                                    </HooksThatNeedProviders>
                                  </MBView>
                                </EntitySelectionProvider>
                              </CardSelectionProvider>
                            </MBToastProvider>
                          </KeyValueProvider>
                        </MBCardActionsProvider>
                      </MBBillingProvider>
                    </CallProvider>
                  </IntercomProvider>
                </GoogleMapsProvider>
              </ApolloProvider>
            </Provider>
          </ResponseHeadersContext.Provider>
        </MBUnhandledError>
      </SafeAreaInsetsContext.Provider>
    </ConnectionProvider>
  );
};

(async () => {
  await setupConfig();

  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: Config.LAUNCH_DARKLY_CLIENT_KEY,
    options: {
      bootstrap: 'localStorage',
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  ReactDOM.render(
    <LaunchDarklyProvider>
      <App />
    </LaunchDarklyProvider>,
    document.getElementById('root'),
  );
})();
