"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionViewAnalytics = void 0;
var analytics_1 = require("@shared/util/analytics");
var CollectionViewAnalytics = /** @class */ (function () {
    function CollectionViewAnalytics(workflowTemplateId, viewTemplateId) {
        this.workflowTemplateId = workflowTemplateId;
        this.viewTemplateId = viewTemplateId;
    }
    CollectionViewAnalytics.prototype.trackButtonClick = function (entityId) {
        analytics_1.MBAnalytics.track('CollectionView::ActionButton::Click', {
            workflowTemplateId: this.workflowTemplateId,
            viewTemplateId: this.viewTemplateId,
            entityId: entityId,
        });
    };
    return CollectionViewAnalytics;
}());
exports.CollectionViewAnalytics = CollectionViewAnalytics;
