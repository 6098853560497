import * as React from 'react';
function SvgPresent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 11.5A2.587 2.587 0 0114.667 9C18.611 9 20 14 20 14h-5.333A2.587 2.587 0 0112 11.5v0zM25.333 14H20s1.389-5 5.333-5A2.587 2.587 0 0128 11.5a2.587 2.587 0 01-2.667 2.5zM29 21v8a2 2 0 01-2 2H13a2 2 0 01-2-2v-8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M31 14H9v4h22v-4zM20 14v17"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPresent;

