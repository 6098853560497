"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.search = exports.loadMoreTrending = exports.fetchTrending = exports.SET_IS_ERROR = exports.UPDATE_SEARCH_QUERY = exports.APPEND_SEARCH_RESULTS = exports.SET_TRENDING = exports.APPEND_TRENDING = void 0;
var api_1 = require("@shared/scenes/gifs/util/api");
exports.APPEND_TRENDING = 'GIFS:APPEND_TRENDING';
exports.SET_TRENDING = 'GIFS:SET_TRENDING';
exports.APPEND_SEARCH_RESULTS = 'GIFS:APPEND_SEARCH_RESULTS';
exports.UPDATE_SEARCH_QUERY = 'GIFS:UPDATE_SEARCH_QUERY';
exports.SET_IS_ERROR = 'GIFS:SET_IS_ERROR';
var fetchTrending = function (limit) {
    if (limit === void 0) { limit = 24; }
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var gifs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api_1.MBGiphyAPI.trending(limit)];
                case 1:
                    gifs = _a.sent();
                    dispatch({ type: exports.SET_TRENDING, value: gifs });
                    // Remove error, if set
                    dispatch({ type: exports.SET_IS_ERROR, value: false });
                    return [2 /*return*/];
            }
        });
    }); };
};
exports.fetchTrending = fetchTrending;
var loadMoreTrending = function (limit) {
    if (limit === void 0) { limit = 24; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var offset, gifs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    offset = getState().gifs.trending.length;
                    return [4 /*yield*/, api_1.MBGiphyAPI.trending(limit, offset)];
                case 1:
                    gifs = _a.sent();
                    dispatch({ type: exports.APPEND_TRENDING, value: gifs });
                    // Remove error, if set
                    dispatch({ type: exports.SET_IS_ERROR, value: false });
                    return [2 /*return*/];
            }
        });
    }); };
};
exports.loadMoreTrending = loadMoreTrending;
// TODO(fant): if we already have search results and just search (aka not load more)
// we shouldn't request new search results (unnecessarily many reqs to Giphy)
var search = function (query, loadMore, limit) {
    if (loadMore === void 0) { loadMore = false; }
    if (limit === void 0) { limit = 24; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var offset, previousResultsForQuery, gifs, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!!query) return [3 /*break*/, 4];
                    offset = 0;
                    previousResultsForQuery = getState().gifs.searchResults[query];
                    if (previousResultsForQuery) {
                        offset = previousResultsForQuery.length;
                    }
                    if (!(!previousResultsForQuery || loadMore)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api_1.MBGiphyAPI.search(query, limit, offset)];
                case 2:
                    gifs = _a.sent();
                    dispatch({ type: exports.APPEND_SEARCH_RESULTS, value: { gifs: gifs, query: query } });
                    // Remove error, if set
                    dispatch({ type: exports.SET_IS_ERROR, value: false });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    dispatch({ type: exports.SET_IS_ERROR, value: true });
                    console.warn(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    // We don't want to update the query in the redux state until after the
                    // search results have been returned. By doing this, the old GIF results
                    // still appear until there are new ones. Otherwise we will have a white
                    // screen while loading
                    dispatch({ type: exports.UPDATE_SEARCH_QUERY, value: query });
                    return [2 /*return*/];
            }
        });
    }); };
};
exports.search = search;
