"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSelectUsersForNewChannel = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var analytics_1 = require("@shared/util/analytics");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var index_1 = require("../../addUsers/components/index");
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var Actions = __importStar(require("../store/actions"));
var hooks_1 = require("@shared/util/hooks");
var MBSelectUsersForNewChannel = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigateToInvite = (0, useNavigator_1.useNavigateTo)('CreateChannelInviteContacts');
    var pressInviteContacts = react_1.default.useCallback(function () {
        navigateToInvite();
        analytics_1.MBAnalytics.track('CreateChannel::New::PressInviteContacts');
    }, [navigateToInvite]);
    var allUsersInBusiness = (0, hooks_1.useCurrentBusinessUsers)();
    var users = (0, react_1.useMemo)(function () { return allUsersInBusiness === null || allUsersInBusiness === void 0 ? void 0 : allUsersInBusiness.filter(function (u) { return !u.isMe; }); }, [allUsersInBusiness]);
    var accessLevels = (_a = (0, client_1.useQuery)(api_1.Queries.channel.accessLevels).data) === null || _a === void 0 ? void 0 : _a.accessLevels;
    var accessLevel = (0, react_redux_1.useSelector)(function (state) { return state.channelCreate.accessLevel; });
    var setAccessLevel = (0, react_1.useCallback)(function (updatedAccessLevel) { return dispatch(Actions.setAccessLevel(updatedAccessLevel)); }, [dispatch]);
    (0, react_1.useEffect)(function () {
        if (!accessLevel && accessLevels) {
            setAccessLevel(accessLevels.find(function (al) { return al.type === 'FULL'; }));
        }
    }, [accessLevel, accessLevels, setAccessLevel]);
    var selectedUsers = (0, react_redux_1.useSelector)(function (state) { return state.channelCreate.selectedUsers; });
    var setSelectedUsers = (0, react_1.useCallback)(function (updatedUsers) { return dispatch(Actions.resetSelectedUser(updatedUsers)); }, [dispatch]);
    var onToggleSelectedUser = (0, react_1.useCallback)(function (user) {
        if (selectedUsers.some(function (u) { return u.id === user.id; })) {
            setSelectedUsers(selectedUsers.filter(function (u) { return u.id !== user.id; }));
        }
        else {
            setSelectedUsers(__spreadArray(__spreadArray([], selectedUsers, true), [user], false));
        }
    }, [selectedUsers, setSelectedUsers]);
    var onToggleAllUsers = (0, react_1.useCallback)(function () {
        if (selectedUsers.length === users.length) {
            setSelectedUsers([]);
        }
        else {
            setSelectedUsers(users);
        }
    }, [selectedUsers.length, setSelectedUsers, users]);
    return (react_1.default.createElement(index_1.MBSelectUsersForChannel, { selectedUsers: selectedUsers, accessLevel: accessLevel, setAccessLevel: setAccessLevel, accessLevels: accessLevels, onToggleAllUsers: onToggleAllUsers, users: users, onToggleSelectedUser: onToggleSelectedUser, onInviteContacts: pressInviteContacts }));
};
exports.MBSelectUsersForNewChannel = MBSelectUsersForNewChannel;
