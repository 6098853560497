import * as React from 'react';
function SvgHeartbeat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 19h7l2-3 4 6 2-3h7M13 22.2a58.937 58.937 0 007 7.8 58.937 58.937 0 007-7.8M30 15.429A5.458 5.458 0 0024.545 10 5.521 5.521 0 0020 12.443 5.52 5.52 0 0015.455 10 5.458 5.458 0 0010 15.429"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgHeartbeat;

