import * as React from 'react';
function SvgPinLocation(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 17.143C29 22.743 20 32 20 32s-9-9.257-9-14.857C11 11.314 15.613 8 20 8s9 3.314 9 9.143z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20.143 20.571a3.429 3.429 0 100-6.857 3.429 3.429 0 000 6.857z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPinLocation;

