import * as React from 'react';
function SvgMicrophone(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 23c-2.8 0-5-2.2-5-5v-4c0-2.8 2.2-5 5-5s5 2.2 5 5v4c0 2.8-2.2 5-5 5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M29 18c0 5-4 9-9 9s-9-4-9-9M15 31h10M20 27v4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMicrophone;

