"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMenuDropdown = void 0;
exports.useShowMenuDropdown = useShowMenuDropdown;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var containers_1 = require("@shared/scenes/navigation/menu/containers");
var containers_2 = require("@shared/scenes/business/switcher/containers");
var styles_2 = require("./styles");
var provider_1 = require("@shared/components/bottomSheet/provider");
var react_native_1 = require("react-native");
var react_dom_1 = require("react-dom");
var useNavigator_web_1 = require("@shared/util/navigation/useNavigator.web");
function useCallbackOnNavigation(callback) {
    (0, react_1.useEffect)(function () {
        return useNavigator_web_1.megaModalHistory.listen(callback);
    }, [callback]);
}
var MBMenuDropdown = function (_a) {
    var onClose = _a.onClose;
    useCallbackOnNavigation(onClose);
    var _b = (0, react_1.useState)(false), isShowingSwitcher = _b[0], setShowingSwitcher = _b[1];
    var toggleSwitcher = (0, react_1.useCallback)(function () { return setShowingSwitcher(!isShowingSwitcher); }, [isShowingSwitcher]);
    var navigateToHome = (0, useNavigator_web_1.useNavigateTo)('Home');
    var handleSwitchedToBusiness = (0, react_1.useCallback)(function () {
        onClose();
        navigateToHome();
    }, [onClose, navigateToHome]);
    return (react_1.default.createElement(react_native_1.View, { style: { maxHeight: '100%' } },
        react_1.default.createElement(components_1.MBView, { row: true, wrap: true, flex: true, style: styles_2.Styles.container },
            react_1.default.createElement(styles_1.ColorThemeContext.Provider, { value: styles_1.grayTheme },
                react_1.default.createElement(styles_1.ColorThemeContext.Consumer, { children: function (_a) {
                        var background = _a.background;
                        return (react_1.default.createElement(components_1.MBView, { style: [styles_2.Styles.menuContainer, { backgroundColor: background }] },
                            react_1.default.createElement(containers_1.MBMenu, { selectedItem: isShowingSwitcher ? 'business-switcher' : undefined, onSwitchBusiness: toggleSwitcher })));
                    } })),
            isShowingSwitcher && (react_1.default.createElement(components_1.MBView, { style: styles_2.Styles.businessSwitcherContainer },
                react_1.default.createElement(containers_2.MBBusinessSwitcher, { onSelectedBusiness: handleSwitchedToBusiness }))))));
};
exports.MBMenuDropdown = MBMenuDropdown;
function useShowMenuDropdown() {
    var bottomSheet = (0, react_1.useContext)(provider_1.BottomSheetContext);
    return (0, react_1.useCallback)(function (parent) {
        var y = 20; // default position if we can't measure parent height
        try {
            var node = (0, react_dom_1.findDOMNode)(parent);
            if (node) {
                var rect = node.getBoundingClientRect();
                y = rect.height;
            }
        }
        catch (e) { }
        bottomSheet.setContentAt(react_1.default.createElement(exports.MBMenuDropdown, { onClose: bottomSheet.reset }), { x: 20, y: y }, null);
    }, [bottomSheet]);
}
