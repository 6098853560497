import * as React from 'react';
function SvgFileResume(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M29 25h-6v6" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M29 25V9H11v22h12l6-6z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M19.71 16.835a1.917 1.917 0 100-3.834 1.917 1.917 0 000 3.835z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M17 21.12c1.654-1.493 4.339-1.493 6 0"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgFileResume;

