import * as React from 'react';
function SvgDoughnut(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 20.435c1.659 1.476 5.625 1.496 7.5-.435M24.5 20c1.436 2.367 4.209 3.205 7.5 2.959"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 32c6.627 0 12-5.373 12-12S26.627 8 20 8 8 13.373 8 20s5.373 12 12 12z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 24.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM14.75 12.5l1.5 1.5M25.25 13.25L26 14M20 11.75l1.5-.75M27.5 18.5l.75-1.5M11.75 17.75l1.5-1.5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgDoughnut;

