"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    container: __assign(__assign(__assign({ backgroundColor: styles_1.Colors.gray.light, borderTopRightRadius: 8, borderTopLeftRadius: 8 }, styles_1.SharedUnvalidatedStyles.shadow), { shadowColor: 'rgba(0,0,0,0.5)', alignSelf: 'stretch' }), react_native_1.Platform.select({ web: {}, default: { height: 64 } })),
    icon: {
        width: 32,
        height: 32,
        padding: 0,
    },
});
