"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAttachmentRow = exports.MBRemoveAttachmentButton = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var index_1 = require("@shared/scenes/chat/thread/components/attachment/index");
var styles_2 = require("./styles");
var react_native_1 = require("react-native");
var RATIO_16_9 = 1.77;
var RATIO_1_2 = 0.5;
var MBRemoveAttachmentButton = function (_a) {
    var onPress = _a.onPress;
    return (React.createElement(components_1.MBTouchableOpacity, { onPress: onPress, style: styles_2.Styles.removeButtonPosition },
        React.createElement(components_1.MBIcon, { color: styles_1.Colors.gray.normal, style: styles_2.Styles.removeButtonIcon, source: require('@shared/assets/icons/clear.png') })));
};
exports.MBRemoveAttachmentButton = MBRemoveAttachmentButton;
var MBAttachmentRow = /** @class */ (function (_super) {
    __extends(MBAttachmentRow, _super);
    function MBAttachmentRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onRemove = function () { return _this.props.onRemove(_this.props.attachment); };
        return _this;
    }
    MBAttachmentRow.prototype.aspectRatio = function () {
        var att = this.props.attachment;
        if (!att.width || !att.height) {
            return 1;
        }
        return att.width / att.height;
    };
    MBAttachmentRow.prototype.render = function () {
        var dpr = react_native_1.PixelRatio.get();
        return (React.createElement(components_1.MBView, { wrap: true, style: { height: styles_2.UnvalidatedStyles.listHeight } },
            React.createElement(components_1.MBView, { style: {
                    height: styles_2.UnvalidatedStyles.listHeight,
                    width: this.aspectRatio() < 1
                        ? Math.min(this.props.attachment.width / dpr, styles_2.UnvalidatedStyles.listHeight * Math.max(this.aspectRatio(), RATIO_1_2))
                        : Math.min(this.props.attachment.width / dpr, styles_2.UnvalidatedStyles.listHeight * this.aspectRatio(), styles_2.UnvalidatedStyles.listHeight * RATIO_16_9),
                } },
                React.createElement(index_1.MBChatAttachment, { fallback: "square", attachment: this.props.attachment, maxHeight: styles_2.UnvalidatedStyles.listHeight, backgroundColor: styles_1.Colors.v2.grayBackground })),
            React.createElement(exports.MBRemoveAttachmentButton, { onPress: this.onRemove })));
    };
    return MBAttachmentRow;
}(React.PureComponent));
exports.MBAttachmentRow = MBAttachmentRow;
