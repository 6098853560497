import * as React from 'react';
function SvgTimeUrgent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.217 16.287v5.204l3.123 2.082"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
      />
      <path
        d="M15.072 11.572a1.08 1.08 0 01.38-.183l-.002.001-.007.005-.007.005-.006.005-.007.005-.006.005-.007.005-.007.005-.006.005-.007.005-.006.005-.007.005-.007.005-.006.005-.007.005-.007.005-.006.005-.007.005-.007.005-.006.005-.007.005-.007.005-.006.005-.007.005-.007.005-.006.005-.007.005-.007.005-.006.005-.007.005-.007.005-.007.005-.006.005-.007.005-.007.005-.007.006-.006.005-.007.005-.007.005-.006.005-.007.005-.007.005-.007.005-.007.005-.006.005-.007.006-.007.005-.007.005-.006.005-.007.005-.007.005-.007.005-.007.005-.007.006-.006.005-.007.005-.007.005-.007.005-.007.005-.007.005-.006.006-.007.005-.007.005-.007.005-.007.005-.007.005-.007.006-.006.005-.007.005-.007.005-.007.005-.007.006-.007.005-.007.005-.007.005-.006.005-.007.006-.007.005-.007.005-.007.005-.007.005-.007.006-.007.005-.007.005-.007.005-.007.006-.007.005-.007.005-.007.005-.007.006-.007.005-.007.005-.007.005-.006.006-.008.005-.006.005-.007.005-.007.005c.07-.118.164-.226.28-.314zM30.735 11.572a1.079 1.079 0 00-.38-.183l.003.001.006.005.007.005.007.005.006.005.007.005.006.005.007.005.006.005.007.005.007.005.006.005.007.005.007.005.006.005.007.005.006.005.007.005.007.005.006.005.007.005.007.005.006.005.007.005.007.005.006.005.007.005.007.005.007.005.006.005.007.005.007.005.006.005.007.005.007.005.007.006.006.005.007.005.007.005.007.005.007.005.006.005.007.005.007.005.007.005.006.006.007.005.007.005.007.005.007.005.006.005.007.005.007.005.007.006.007.005.006.005.007.005.007.005.007.005.007.005.007.006.006.005.007.005.007.005.007.005.007.005.007.006.007.005.006.005.007.005.007.005.007.006.007.005.007.005.007.005.007.005.007.006.007.005.006.005.007.005.007.005.007.006.007.005.007.005.007.005.007.006.007.005.007.005.007.005.007.006.007.005.007.005.007.005.007.006.007.005.007.005.007.005.006.005a1.077 1.077 0 00-.28-.314z"
        fill="#007AFF"
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        clipRule="evenodd"
        d="M23.217 30.86a9.369 9.369 0 100-18.737 9.369 9.369 0 000 18.737z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
      />
      <path d="M20.094 9h6.246" stroke={props.color} strokeWidth={2} strokeLinecap="square" />
      <path d="M23.217 9v3.123" stroke={props.color} strokeWidth={2} strokeLinecap="round" />
      <path
        d="M7.318 18H11M5.302 22H10M6.343 26H11"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="bevel"
      />
    </svg>
  );
}
export default SvgTimeUrgent;

