import * as React from 'react';
function SvgPeople(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 171 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="people_svg__a" maskUnits="userSpaceOnUse" x={0} y={0} width={171} height={171}>
        <circle cx={85.5} cy={85.5} r={85.5} fill="#F7F7F7" />
      </mask>
      <g mask="url(#people_svg__a)" stroke="#C5D2EC" strokeWidth={7} strokeMiterlimit={10}>
        <path d="M62.985 63.76c4.888 0 8.85-3.965 8.85-8.855 0-4.89-3.962-8.855-8.85-8.855s-8.85 3.965-8.85 8.855c0 4.89 3.962 8.855 8.85 8.855z" />
        <path d="M50.5 77.738c6.89-6.894 18.08-6.894 25.002 0" strokeLinecap="square" />
        <path d="M62.985 107.94c4.888 0 8.85-3.964 8.85-8.855 0-4.89-3.962-8.855-8.85-8.855s-8.85 3.965-8.85 8.855c0 4.891 3.962 8.855 8.85 8.855z" />
        <path d="M50.5 121.95c6.89-6.894 18.08-6.894 25.002 0" strokeLinecap="square" />
        <path d="M107.015 63.76c4.888 0 8.85-3.965 8.85-8.855 0-4.89-3.962-8.855-8.85-8.855s-8.85 3.965-8.85 8.855c0 4.89 3.962 8.855 8.85 8.855z" />
        <path d="M94.498 77.738c6.891-6.894 18.08-6.894 25.002 0" strokeLinecap="square" />
        <path d="M107.015 107.94c4.888 0 8.85-3.964 8.85-8.855 0-4.89-3.962-8.855-8.85-8.855s-8.85 3.965-8.85 8.855c0 4.891 3.962 8.855 8.85 8.855z" />
        <path d="M94.498 121.95c6.891-6.894 18.08-6.894 25.002 0" strokeLinecap="square" />
      </g>
      <circle cx={85.5} cy={85.5} r={81.5} stroke="#9685B1" strokeWidth={8} />
    </svg>
  );
}
export default SvgPeople;

