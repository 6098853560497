import * as React from 'react';
function SvgRemoveX(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.364 15.636l-8.728 8.728M24.364 24.364l-8.728-8.728"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
      />
      <path
        clipRule="evenodd"
        d="M20 32c6.627 0 12-5.373 12-12S26.627 8 20 8 8 13.373 8 20s5.373 12 12 12z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgRemoveX;

