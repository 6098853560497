/* @flow */
import React from 'react';
import { MBModalOrMobile } from '@shared/components/web/modalOrMobile';
import { MBChannelNotificationSettings } from '@shared/scenes/channel/notificationSettings/containers';
import { MBChannelOptions } from '@shared/scenes/channel/options/containers/index';
import { MBChannelUserList } from '@shared/scenes/channel/userList/container';
import { AdvancedChannelOptions } from '@shared/scenes/channel/options/components/advancedOptions';
import { ColorThemeContext } from '@shared/styles';
import { i18n } from '@shared/util/i18n';

import addMessageToastr from '@src/redux/actions/addMessageToastr';
import { BackableTopNavBar } from '@src/components/TopNavBar';
import { actions } from '@src/core/Router';

import { Wrap } from '../Wrap';
import { useNavigateTo, useNavigator } from '@shared/util/navigation/useNavigator';

const onBack = () => actions.popRoute();

export const ChannelOptionsContainer = ({ params }) => {
  const navigator = useNavigator();
  return (
    <Wrap
      title={i18n('channel.options.detailsTitle')}
      children={
        <MBChannelOptions
          slug={params.channelSlug}
          onSentReminderToUser={() => addMessageToastr('1 reminder sent!')}
          onRemovedUserFromChannel={() => addMessageToastr('1 member removed!')}
        />
      }
      backgroundColor={React.useContext(ColorThemeContext).backgroundInactive}
      onBack={navigator.goBack}
      onClose={null}
    />
  );
};

ChannelOptionsContainer.displayName = 'ChannelOptionsContainer';

export const ChannelUserListContainer = ({ params }) => {
  const navigator = useNavigator();
  return (
    <Wrap
      title={i18n('channel.userList.title')}
      children={<MBChannelUserList channelSlug={params.channelSlug} />}
      onBack={navigator.goBack}
      onClose={null}
    />
  );
};

ChannelUserListContainer.displayName = 'ChannelUserListContainer';

export const ChannelNotificationSettingsContainer = ({ params }: any) => {
  const openChannel = useNavigateTo('Channel', { channelSlug: params.channelSlug });
  return (
    <MBModalOrMobile
      title="Notification Settings"
      headerOnMobile={<BackableTopNavBar title="Notification Settings" />}
      onClose={openChannel}
      onBack={onBack}
    >
      <MBChannelNotificationSettings slug={params.channelSlug} />
    </MBModalOrMobile>
  );
};

ChannelNotificationSettingsContainer.displayName = 'ChannelNotificationSettingsContainer';

export const ChannelAdvancedOptionsContainer = ({ params }) => {
  const navigator = useNavigator();
  return (
    <Wrap
      title={i18n('channel.options.advanced.title')}
      children={<AdvancedChannelOptions {...params} />}
      onBack={navigator.goBack}
      onClose={null}
    />
  );
};
