import * as React from 'react';
function SvgAvatarPin(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 9H9v17h7.667L20 30l3.333-4H31V9z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20.992 17.737L23.2 19.4c.504.378.8.97.8 1.6v1h-8v-1c0-.63.296-1.222.8-1.6l2.208-1.663"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 18a2 2 0 01-2-2v-1a2 2 0 114 0v1a2 2 0 01-2 2z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgAvatarPin;

