"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShowIntercomMessenger = useShowIntercomMessenger;
var react_1 = require("react");
var react_use_intercom_1 = require("react-use-intercom");
function useShowIntercomMessenger() {
    var intercom = (0, react_use_intercom_1.useIntercom)();
    return (0, react_1.useCallback)(function (_a) {
        var _b = _a === void 0 ? {} : _a, message = _b.message;
        if (!!message) {
            intercom.showNewMessages(message);
        }
        else {
            intercom.show();
        }
    }, [intercom.show]);
}
