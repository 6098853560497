
const initialState = {
  drafts: {},
};

export default function channelReducer(state = initialState, action) {
// export default function channelReducer(state: ChannelReducer = initialState, action: Action) {
  switch (action.type) {
    case 'UPDATE_CHANNEL': {
      const { channelSlug, draft } = action;
      return {
        ...state,
        drafts: {
          ...state.drafts,
          [channelSlug]: draft || undefined,
        },
      };
    }

    default:
      return state;
  }
}
