import * as React from 'react';
function SvgArrowCircleUp(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={20} cy={20} r={12} stroke={props.color} strokeWidth={2} />
      <path
        d="M20.115 26.115V14.346"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M26.115 19.885l-6-6-6 6" stroke={props.color} strokeWidth={2} />
    </svg>
  );
}
export default SvgArrowCircleUp;

