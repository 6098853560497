"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockToggle = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var utils_1 = require("@shared/components/blocks/utils");
var utils_2 = require("./input/utils");
var base_1 = require("./input/base");
var form_1 = require("@shared/components/blocks/contexts/form");
var MBCardInputBlockToggle = function (_a) {
    var block = _a.block;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _b.setFieldValue, values = _b.values;
    var TypoComponent = (0, utils_1.getTypoComponent)(block.textStyle);
    var value = values[block.name];
    var onChange = (0, react_1.useCallback)(function (updatedValue) { return setFieldValue(block.name, updatedValue); }, [setFieldValue, block.name]);
    var renderRight = (0, react_1.useMemo)(function () { return function () { return (0, utils_2.renderToggle)(value, onChange); }; }, [value, onChange]);
    return (react_1.default.createElement(base_1.MBCardInputBlockBase, { block: block, renderLabel: null, renderRight: renderRight },
        react_1.default.createElement(TypoComponent, { color: colors.foregroundActive }, block.label)));
};
exports.MBCardInputBlockToggle = MBCardInputBlockToggle;
