"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebLayoutProvider = exports.WebLayoutContext = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var hooks_1 = require("@shared/util/storage/hooks");
var hooks_2 = require("@shared/util/hooks");
var hooks_3 = require("@shared/scenes/cards/hooks");
var buildKey = function (channelSlug) {
    return ['WebLayoutProvider', 'v1', 'mode', channelSlug].join('.');
};
var defaultValue = 'split-chat';
exports.WebLayoutContext = (0, react_1.createContext)({ mode: defaultValue });
var WebLayoutProvider = function (_a) {
    var _b;
    var channelSlug = _a.channelSlug, children = _a.children;
    var channel = (0, hooks_2.useChannel)(channelSlug);
    var cardDefinition = (0, hooks_3.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var key = buildKey(channelSlug);
    var workspaceFocus = (_b = channel === null || channel === void 0 ? void 0 : channel.defaultFocus) !== null && _b !== void 0 ? _b : cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.workspaceFocus;
    var defaultFocus = (0, react_1.useMemo)(function () {
        return workspaceFocus !== 'WORKFLOW' ? 'split-chat' : 'workflow';
    }, [workspaceFocus]);
    var mode = (0, hooks_1.useFromStorage)(key, defaultFocus);
    (0, react_1.useEffect)(function () {
        mode.refetch();
    }, [defaultFocus, mode]);
    var persistInStorage = (0, hooks_1.usePersistInStorage)(key);
    var onToggleMode = (0, react_1.useCallback)(function (newMode) {
        if (newMode !== mode.value) {
            persistInStorage(newMode);
            mode.refetch();
        }
    }, [persistInStorage, mode]);
    return (react_1.default.createElement(exports.WebLayoutContext.Provider, { value: (0, react_1.useMemo)(function () { return ({ mode: mode.value, onToggleMode: onToggleMode }); }, [mode.value, onToggleMode]) }, children));
};
exports.WebLayoutProvider = WebLayoutProvider;
