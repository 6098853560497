import * as React from 'react';
function SvgSendCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 20c0 6.627-5.373 12-12 12S8 26.627 8 20 13.373 8 20 8s12 5.373 12 12zm-11.686 7.9c.105.066.227.1.35.1l.036-.001a.665.665 0 00.589-.435l4.667-12.667a.667.667 0 00-.855-.856L12.434 18.71a.667.667 0 00-.067 1.221l3.96 1.978a.668.668 0 00.677-.047l4.327-3.194-3.194 4.328a.669.669 0 00-.048.678l1.979 3.959c.055.11.14.203.246.268z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgSendCircle;

