/* @flow */
import React from 'react';

import PureComponent from '@src/components/PureComponent';
import Button from '@src/components/Button';
import ImageEditable from '@src/components/ImageEditable';
import { Form, Input, FormRow, Label, HelpText } from '@src/components/FormComponents';

import { canSubmitUser, getErrors } from '@common/core/createUserUtils';
import type { By } from '@common/AuthTypes';
import { MBView } from '@shared/components';

import type {
  NewUser,
  UserCreateInputType as InputType,
} from '@src/types/UserTypes';

import styles from './UserCreate.css';

type Props = {
  draft: NewUser,
  isMobile: boolean,
  isSubmitting: boolean,
  onChange: Function, // @TODO
  onSubmit: () => Promise<?Object>,
  errorText?: ?string,
  block?: By,
};

export default class UserCreate extends PureComponent {
  props: Props;

  handleChange = (ev: BrowserEvent, type: InputType) => {
    ev && typeof ev.preventDefault === 'function' && ev.preventDefault();
    const value = ev.target.value;

    this.props.onChange(value, type);
  };

  handleImageUpload = (img: string) => this.props.onChange(img, 'img');

  handleSubmit = (ev: BrowserEvent) => {
    ev && typeof ev.preventDefault === 'function' && ev.preventDefault();
    if (!this.props.draft || !canSubmitUser(this.props.draft)) {
      return Promise.resolve();
    }

    return this.props.onSubmit();
  };

  render() {
    const errors = getErrors(this.props.draft);

    return (
      <div className={styles.root}>
        <Form
          className={this.props.isMobile ? styles.formFullWidth : styles.formInContainer}
          onSubmit={this.handleSubmit}
        >
          <div className={styles.pictureWrapper}>
            <ImageEditable
              type="people"
              url={this.props.draft.img}
              size={104}
              onUpload={this.handleImageUpload}
            />
            <div className={styles.text}>Add a profile picture</div>
          </div>
          <FormRow>
            <Label htmlFor="name">Full Name</Label>
            <Input
              id="name"
              type="text"
              value={this.props.draft.name}
              onChange={ev => this.handleChange(ev, 'name')}
            />
            <div className={styles.helper}>
              <HelpText isError={true}>{errors.name}</HelpText>
            </div>
          </FormRow>
          <FormRow>
            <Label htmlFor="phone">Cell Phone Number</Label>
            <Input
              id="phone"
              type="tel"
              value={this.props.draft.phone}
              onChange={ev => this.handleChange(ev, 'phone')}
              disabled={this.props.block === 'SMS'}
            />
            <div className={styles.helper}>
              <HelpText isError={true}>{errors.phone}</HelpText>
            </div>
          </FormRow>
          <FormRow>
            <Label htmlFor="email">Email (optional)</Label>
            <Input
              id="email"
              type="email"
              value={this.props.draft.email}
              onChange={ev => this.handleChange(ev, 'email')}
              disabled={this.props.block === 'EMAIL'}
            />
            <div className={styles.helper}>
              <HelpText isError={true}>{errors.email}</HelpText>
            </div>
          </FormRow>
          <MBView paddingTop="normal" paddingBottom="small">
            <Button
              btnStyle="primary"
              btnSize="large"
              isFlex={true}
              isDisabled={!canSubmitUser(this.props.draft, errors) || this.props.isSubmitting}
              onClick={this.handleSubmit}
            >
              Sign Up
            </Button>
          </MBView>
          <div className={styles.error}>
            <HelpText isError={true}>{this.props.errorText}</HelpText>
          </div>
        </Form>
      </div>
    );
  }
}
