"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardProvider = exports.useLoadingAndError = exports.useField = exports.useUpdateField = exports.CardContext = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var api_1 = require("@shared/api");
var hooks_1 = require("@shared/scenes/cards/hooks");
var hooks_2 = require("@shared/scenes/cards/form/hooks");
var client_1 = require("@shared/api/client");
var client_2 = require("@apollo/client");
exports.CardContext = (0, react_1.createContext)(null);
var useGetCard = function (cardId) {
    return (0, client_2.useQuery)(api_1.Queries.card.details, {
        variables: { id: cardId },
    });
};
var useUpdateCard = function () {
    var resolveCardDefinition = (0, hooks_1.useResolveCardDefinition)();
    return (0, react_1.useCallback)(function (card, fields) { return __awaiter(void 0, void 0, void 0, function () {
        var cardDefinition, input, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    cardDefinition = resolveCardDefinition(card.cardDefinitionId);
                    input = (0, hooks_2.getUpdateCardInput)(card, fields, cardDefinition, true);
                    return [4 /*yield*/, client_1.apolloClient.mutate({
                            mutation: api_1.Mutations.cards.update,
                            variables: { input: input },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.updateCard];
            }
        });
    }); }, [resolveCardDefinition]);
};
var useUpdateField = function () {
    var updateField = (0, react_1.useContext)(exports.CardContext).updateField;
    return (0, react_1.useCallback)(updateField, [updateField]);
};
exports.useUpdateField = useUpdateField;
var useField = function (fieldName) {
    var _a;
    var card = (0, react_1.useContext)(exports.CardContext).card;
    return (_a = card === null || card === void 0 ? void 0 : card.fields[fieldName]) !== null && _a !== void 0 ? _a : null;
};
exports.useField = useField;
var useLoadingAndError = function () {
    var _a = (0, react_1.useContext)(exports.CardContext), loading = _a.loading, error = _a.error;
    return { loading: loading, error: error };
};
exports.useLoadingAndError = useLoadingAndError;
var CardProvider = function (_a) {
    var _b;
    var cardId = _a.cardId, children = _a.children;
    var _c = useGetCard(cardId), data = _c.data, error = _c.error, loading = _c.loading;
    var updateCard = useUpdateCard();
    var card = (_b = data === null || data === void 0 ? void 0 : data.card) !== null && _b !== void 0 ? _b : null;
    var updateField = (0, react_1.useCallback)(function (field, value) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedCard;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, updateCard(card, __assign(__assign({}, card.fields), (_a = {}, _a[field] = value, _a)))];
                case 1:
                    updatedCard = _b.sent();
                    return [2 /*return*/, updatedCard];
            }
        });
    }); }, [card, updateCard]);
    return (react_1.default.createElement(exports.CardContext.Provider, { value: { card: card, loading: loading, error: error, updateField: updateField } }, children));
};
exports.CardProvider = CardProvider;
