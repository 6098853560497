"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurrencePickerRoutes = void 0;
var CustomSelectionType_1 = require("./CustomSelectionType");
var RecurrencePickerRoutes = /** @class */ (function () {
    function RecurrencePickerRoutes() {
        this.dailyRecurrence = 'dailyRecurrence';
        this.weeklyRecurrence = 'weeklyRecurrence';
        this.monthlyRecurrence = 'monthlyRecurrence';
        this.yearlyRecurrence = 'yearlyRecurrence';
    }
    RecurrencePickerRoutes.prototype.routeToSelectionType = function (route) {
        switch (route) {
            case this.dailyRecurrence:
                return CustomSelectionType_1.CustomSelectionType.DAILY;
            case this.weeklyRecurrence:
                return CustomSelectionType_1.CustomSelectionType.WEEKLY;
            case this.monthlyRecurrence:
                return CustomSelectionType_1.CustomSelectionType.MONTHLY;
            case this.yearlyRecurrence:
                return CustomSelectionType_1.CustomSelectionType.YEARLY;
        }
        return CustomSelectionType_1.CustomSelectionType.DAILY;
    };
    RecurrencePickerRoutes.prototype.selectionTypeToRoute = function (selectionType) {
        switch (selectionType) {
            case CustomSelectionType_1.CustomSelectionType.DAILY:
                return this.dailyRecurrence;
            case CustomSelectionType_1.CustomSelectionType.WEEKLY:
                return this.weeklyRecurrence;
            case CustomSelectionType_1.CustomSelectionType.MONTHLY:
                return this.monthlyRecurrence;
            case CustomSelectionType_1.CustomSelectionType.YEARLY:
                return this.yearlyRecurrence;
        }
    };
    return RecurrencePickerRoutes;
}());
exports.RecurrencePickerRoutes = RecurrencePickerRoutes;
