"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUserRowAdminOptions = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var context_1 = require("@shared/scenes/directory/containers/context");
var actionSheet_1 = require("@shared/util/actionSheet");
var i18n_1 = require("@shared/util/i18n");
var alert_1 = require("@shared/util/alert");
var toast_1 = require("@shared/components/toast");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var MBUserRowAdminOptions = function (_a) {
    var user = _a.user;
    var _b = (0, react_1.useContext)(context_1.DirectoryContext), currentUserId = _b.currentUserId, business = _b.business, onMessage = _b.onMessage, onUpdateRole = _b.onUpdateRole, onRemoveFromBusiness = _b.onRemoveFromBusiness, onResendInvite = _b.onResendInvite, onReaddToBusiness = _b.onReaddToBusiness;
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var navigateToMyProfile = (0, useNavigator_1.useNavigateTo)('ProfileEdit');
    var navigateToOtherProfile = (0, useNavigator_1.useNavigateTo)('DirectoryProfile', { userId: user.id });
    var handlePress = (0, react_1.useCallback)(function (parent) {
        var title = undefined;
        if ((0, context_1.isDeactivated)(user)) {
            title = (0, i18n_1.i18n)('directory.actions.deactivatedTitle', {
                name: user.firstName,
                business: business.name,
            });
        }
        else if ((0, context_1.isPending)(user)) {
            title = (0, i18n_1.i18n)('directory.actions.pendingTitle', { name: user.firstName });
        }
        actionSheet_1.MBActionSheet.show({
            title: title,
            parent: parent,
            webVerticalAlignment: 'above',
            webHorizontalAlignment: 'right',
            options: [
                {
                    title: (0, i18n_1.i18n)('directory.actions.resendInvite'),
                    onPress: function () {
                        return onResendInvite(user)
                            .then(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.success.resendInvite'),
                                style: 'confirmation',
                            });
                        })
                            .catch(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.error.resendInvite'),
                                style: 'warning',
                            });
                        });
                    },
                    hidden: !(0, context_1.isPending)(user),
                },
                {
                    title: (0, i18n_1.i18n)((0, context_1.isDeactivated)(user)
                        ? 'directory.actions.viewMessages'
                        : 'directory.actions.sendMessage'),
                    onPress: function () { return onMessage(user); },
                },
                {
                    title: (0, i18n_1.i18n)('directory.actions.viewProfile'),
                    onPress: user.isMe ? navigateToMyProfile : navigateToOtherProfile,
                    hidden: (user === null || user === void 0 ? void 0 : user.role) === 'OWNER' || (user === null || user === void 0 ? void 0 : user.role) === 'ADMIN',
                },
                {
                    title: (0, i18n_1.i18n)('directory.actions.readdToBusiness'),
                    onPress: function () {
                        return onReaddToBusiness(user)
                            .then(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.success.readdToBusiness', {
                                    name: user.firstName,
                                }),
                                style: 'confirmation',
                            });
                        })
                            .catch(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.error.readdToBusiness', {
                                    name: user.firstName,
                                }),
                                style: 'warning',
                            });
                        });
                    },
                    destructive: true,
                    hidden: !(0, context_1.isDeactivated)(user),
                },
                {
                    title: (0, i18n_1.i18n)('directory.actions.demoteToAdmin'),
                    onPress: function () {
                        return onUpdateRole(user, 'ADMIN')
                            .then(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.success.demoteToAdmin', {
                                    name: user.firstName,
                                }),
                                style: 'confirmation',
                            });
                        })
                            .catch(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.error.demoteToAdmin', {
                                    name: user.firstName,
                                }),
                                style: 'warning',
                            });
                        });
                    },
                    hidden: user.isPending || user.isRemoved || (user === null || user === void 0 ? void 0 : user.role) !== 'OWNER',
                },
                {
                    title: (0, i18n_1.i18n)('directory.actions.demoteToNonAdmin'),
                    onPress: function () {
                        return onUpdateRole(user, 'MEMBER')
                            .then(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.success.demoteToNonAdmin', {
                                    name: user.firstName,
                                }),
                                style: 'confirmation',
                            });
                        })
                            .catch(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.error.demoteToNonAdmin', {
                                    name: user.firstName,
                                }),
                                style: 'warning',
                            });
                        });
                    },
                    hidden: user.isPending || user.isRemoved || user.role === 'MEMBER',
                },
                {
                    title: (0, i18n_1.i18n)('directory.actions.makeOwner'),
                    onPress: function () {
                        return onUpdateRole(user, 'OWNER')
                            .then(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.success.makeOwner', { name: user.firstName }),
                                style: 'confirmation',
                            });
                        })
                            .catch(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.error.makeOwner', { name: user.firstName }),
                                style: 'warning',
                            });
                        });
                    },
                    hidden: user.isPending || user.isRemoved || user.role === 'OWNER',
                },
                {
                    title: (0, i18n_1.i18n)('directory.actions.makeAdmin'),
                    onPress: function () {
                        return onUpdateRole(user, 'ADMIN')
                            .then(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.success.makeAdmin', { name: user.firstName }),
                                style: 'confirmation',
                            });
                        })
                            .catch(function () {
                            return toast.show({
                                message: (0, i18n_1.i18n)('directory.actions.error.makeAdmin', { name: user.firstName }),
                                style: 'warning',
                            });
                        });
                    },
                    hidden: user.isPending || user.isRemoved || user.role === 'ADMIN',
                },
                {
                    title: (0, i18n_1.i18n)('directory.actions.removeFromBusiness'),
                    onPress: function () {
                        return alert_1.MBAlert.confirm({
                            title: (0, i18n_1.i18n)('directory.actions.removeConfirm.title', {
                                user: user.firstName,
                                business: business.name,
                            }),
                            message: (0, i18n_1.i18n)('directory.actions.removeConfirm.message', {
                                user: user.firstName,
                                business: business.name,
                            }),
                            confirmText: (0, i18n_1.i18n)('common.remove'),
                            confirmStyle: 'destructive',
                            onConfirm: function () {
                                return onRemoveFromBusiness(user)
                                    .then(function () {
                                    return toast.show({
                                        message: (0, i18n_1.i18n)('directory.actions.success.removeFromBusiness', {
                                            name: user.firstName,
                                        }),
                                        style: 'confirmation',
                                    });
                                })
                                    .catch(function () {
                                    return toast.show({
                                        message: (0, i18n_1.i18n)('directory.actions.error.removeFromBusiness', {
                                            name: user.firstName,
                                        }),
                                        style: 'warning',
                                    });
                                });
                            },
                        });
                    },
                    hidden: user.id === currentUserId || user.isRemoved || user.role === 'OWNER',
                    destructive: true,
                },
            ],
        });
    }, [user]);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handlePress },
        react_1.default.createElement(components_1.MBBoxedIcon, { color: styles_1.Colors.v2.gray30, backgroundColor: null, source: require('@shared/assets/icons/v2/dots.png') })));
};
exports.MBUserRowAdminOptions = MBUserRowAdminOptions;
