/* @flow */
import React from 'react';
import { connect } from 'react-redux';

import { MBImage } from '@shared/components';

import { updateAuth } from '@src/core/Store';
import { setBizToken } from '@src/core/AuthTokenManager';
import { apiFetch } from '@src/core/HTTPAPI';

import PureComponent from '@src/components/PureComponent';
import Button from '@src/components/Button';

import { Form, Input, FormRow, Label, HelpText } from '@src/components/FormComponents';

import type { BusinessData } from '@common/AuthTypes';
import type { StoreState } from '@src/types/StoreTypes';
import updateCurrentBusinessId from '@src/redux/actions/updateCurrentBusinessId';

import styles from './LoginBusinessContainer.css';

type Props = {
  viewportHeight: number,
  authData: ?BusinessData,
};

type State = {
  slug: string,
  password: string,
  errorText: string,
  isSubmitting: boolean,
  autoFocus: 'slug' | 'password',
};

class LoginBusinessContainer extends PureComponent {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);
    const slug = props.authData && props.authData.username;
    this.state = {
      slug: slug || '',
      password: '',
      isSubmitting: false,
      errorText: '',
      autoFocus: slug ? 'password' : 'slug',
    };
  }

  handleSubmit = (ev: Object) => {
    ev && typeof ev.preventDefault === 'function' && ev.preventDefault();
    if (!this.canSubmit() || this.state.isSubmitting) { return; }
    this.setState({ isSubmitting: true });
    apiFetch('api/auth/business', {
      method: 'POST',
      data: { slug: this.state.slug, password: this.state.password },
    })
    .then((response) => {
      updateCurrentBusinessId(response.businessId);
      setBizToken(response.token);
      updateAuth('/');
    }).catch((err) => {
      this.setState({ isSubmitting: false });
      if (err.response && err.response.error.match(/username password/)) {
        this.setState({ errorText: err.response.error });
        return Promise.resolve();
      }
      this.setState({ errorText: 'Unexpected Error Happened. Please try again' });
      return Promise.reject(err);
    });
  };

  handleSlugChange = (ev: BrowserEvent) => {
    this.setState({ slug: ev.target.value });
  };

  handlePasswordChange = (ev: BrowserEvent) => {
    this.setState({ password: ev.target.value });
  };

  render() {
    return (
      <div className={styles.root} style={{ height: this.props.viewportHeight }}>
        <MBImage
          style={{ width: 153, height: 60 }}
          source={require('@shared/assets/images/v2/logo/color-positive.png')}
        />
        <Form
          onSubmit={this.handleSubmit}
          hasError={!!this.state.errorText && !this.state.isSubmitting}
          className={styles.form}
        >
          <FormRow>
            <Label htmlFor="biz-slug">Username</Label>
            <Input
              id="biz-slug"
              type="text"
              autoCorrect="off"
              autoCapitalize="none"
              autoFocus={this.state.autoFocus === 'slug'}
              value={this.state.slug}
              onChange={this.handleSlugChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="biz-password">Password</Label>
            <Input
              id="biz-password"
              type="password"
              autoFocus={this.state.autoFocus === 'password'}
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
          </FormRow>
          {this.state.errorText &&
            <FormRow><HelpText isError={true}>{this.state.errorText}</HelpText></FormRow>
          }
          <FormRow>
            <Button
              btnStyle="primary"
              isDisabled={this.state.isSubmitting || !this.canSubmit()}
              onClick={this.handleSubmit}
            >
              Log In
            </Button>
          </FormRow>
        </Form>
      </div>
    );
  }

  canSubmit() {
    return this.state.password && this.state.slug;
  }
}

export default connect(
  (state: StoreState) => ({
    viewportHeight: state.appState.viewportHeight,
  }),
)(LoginBusinessContainer);
