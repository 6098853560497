import * as React from 'react';
function SvgThumbDown(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 9H9v11h5" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M14 20l3 11a3 3 0 003-3v-6h7.5a3 3 0 002.965-3.456l-1.077-7A3 3 0 0026.426 9H14v11z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgThumbDown;

