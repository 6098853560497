import { Config } from '@shared/config';

/* @flow */
export type Payload =
  | {|
      uri: string,
      type: string,
      name: string,
    |}
  | File;

export type Policy = {
  fileKey: string,
  publicUrl: string,
};

type Level = 'critical' | 'error' | 'warning' | 'info' | 'debug';
type LogError = (error: Error, context?: Object, level?: Level) => any;

export default function uploadFileFactory(baseURL: string, logError?: LogError) {
  return async function uploadFile(payload: Payload): Promise<Policy> {
    const { type: rawType, name } = payload;
    // When browser can't determine type, default to octet-stream which will leave it untouched.
    const type = rawType || 'application/octet-stream';
    const signingURL = `${Config.API_URL}/api/s3/sign?objectName=${name}&contentType=${type}`;
    const policyResponse = await fetch(signingURL);
    const policy = await policyResponse.json();
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(policy);
          } else {
            const err = new Error('Unable to upload file');
            if (logError) {
              logError(err, {
                policy,
                status: xhr.status,
                responseText: xhr.responseText,
              });
            }
            reject(err);
          }
        }
      };
      xhr.open('PUT', policy.signedUrl);
      xhr.setRequestHeader('X-Amz-ACL', 'public-read');
      xhr.setRequestHeader('Content-Type', type);
      xhr.send(payload);
    });
  };
}
