"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDebouncedState = useDebouncedState;
var react_1 = require("react");
var use_debounce_1 = require("use-debounce");
function useDebouncedState(initialValue, delay) {
    var _a = (0, react_1.useState)(initialValue), value = _a[0], setValue = _a[1];
    var debouncedValue = (0, use_debounce_1.useDebounce)(value, delay)[0];
    return [debouncedValue, setValue, value]; // include non-debounced value as third member
}
