import * as React from 'react';
function SvgTimeClock(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M20 32c6.627 0 12-5.373 12-12S26.627 8 20 8 8 13.373 8 20s5.373 12 12 12z"
        stroke={props.color}
        strokeWidth={2}
      />
      <path d="M15.2 20H20l4.8-4.8" stroke={props.color} strokeWidth={2} strokeLinecap="square" />
    </svg>
  );
}
export default SvgTimeClock;

