"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleReaction = void 0;
// @ts-strict-ignore
var api_1 = require("@shared/api");
var client_1 = require("@shared/api/client");
var channelType_1 = require("@shared/scenes/chat/thread/util/channelType");
var store_1 = require("@shared/store");
var analytics_1 = require("@shared/util/analytics");
var toggleReaction = function (message, channelSlug, reactionType, add) { return __awaiter(void 0, void 0, void 0, function () {
    var myId, hasReacted, optimisticReactions, me, meta;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                myId = store_1.store.getState().appState.currentUserId;
                hasReacted = message.meta.reactions.some(function (reaction) { return reaction.type === reactionType && reaction.user.id === myId; });
                if (hasReacted === add) {
                    return [2 /*return*/];
                }
                if (add) {
                    me = client_1.apolloClient.readFragment({
                        id: "User".concat(myId),
                        fragment: api_1.Fragments.user.short,
                    });
                    // Note(fant): we do not push to message's reactions, since that would
                    // make Apollo's cache confused (optimistic response wouldn't be rendered)
                    optimisticReactions = __spreadArray(__spreadArray([], message.meta.reactions, true), [
                        { __typename: 'Reaction', type: reactionType, user: me },
                    ], false);
                    analytics_1.MBAnalytics.track('Message::AddReaction', { reactionType: reactionType, messageId: message.id });
                }
                else {
                    optimisticReactions = message.meta.reactions.filter(function (reaction) { return !(reaction.user.id === myId && reaction.type === reactionType); });
                    analytics_1.MBAnalytics.track('Message::RemoveReaction', { reactionType: reactionType, messageId: message.id });
                }
                meta = client_1.apolloClient.readFragment({
                    id: "Meta".concat(message.id),
                    fragment: api_1.Fragments.chat.messageMeta,
                    fragmentName: 'ChatMessageMeta',
                });
                // 2. update the reactions
                if (!!meta) {
                    client_1.apolloClient.writeFragment({
                        id: "Meta".concat(meta.id),
                        fragment: api_1.Fragments.chat.messageMeta,
                        fragmentName: 'ChatMessageMeta',
                        data: __assign(__assign({}, meta), { reactions: optimisticReactions }),
                    });
                }
                if (!channelType_1.MBChannelTypeUtil.isThread(channelSlug)) return [3 /*break*/, 1];
                // TODO(nf): error is returned if calling below https://app.shortcut.com/coast/story/30746/threads-fix-reaction-mutation-in-chat-threads
                console.error('TODO(fant): toggleReaction');
                return [3 /*break*/, 3];
            case 1: return [4 /*yield*/, client_1.apolloClient.mutate({
                    mutation: api_1.Mutations.chat.updateMessageReaction,
                    variables: {
                        reactionType: reactionType,
                        add: add,
                        messageId: message.id,
                    },
                })];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.toggleReaction = toggleReaction;
