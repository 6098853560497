import * as React from 'react';
function SvgCakeBirthday(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 15a2 2 0 002-2c0-1.104-2-4-2-4s-2 2.896-2 4a2 2 0 002 2zM31 22H9v8h22v-8z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M31 22a4 4 0 01-4 4c-1.518 0-2.822-.855-3.5-2.101C22.822 25.145 21.518 26 20 26s-2.822-.855-3.5-2.101C15.822 25.145 14.518 26 13 26a4 4 0 01-4-4M28 22v-4H12v4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgCakeBirthday;

