import * as React from 'react';
function SvgColorPositive(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 154 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M73.141 35.707c-1.785 2.195-4.156 3.278-7.082 3.278-2.664 0-4.888-.82-6.644-2.458-1.756-1.64-2.605-3.805-2.605-6.498 0-2.722.878-4.917 2.605-6.556 1.756-1.668 3.892-2.488 6.527-2.488 2.868 0 5.18 1.083 6.936 3.278L70.39 26.81c-1.024-1.464-2.722-2.371-4.624-2.371-1.376 0-2.576.498-3.6 1.493-1.024.966-1.551 2.312-1.551 4.01 0 1.726.526 3.073 1.55 4.068 1.025.995 2.284 1.492 3.806 1.492 1.96 0 3.541-.82 4.741-2.429l2.43 2.634zM77.327 23.561c-1.727 1.698-2.576 3.834-2.576 6.41 0 2.605.878 4.741 2.576 6.468 1.727 1.698 3.863 2.546 6.38 2.546 2.547 0 4.683-.848 6.41-2.546 1.727-1.727 2.605-3.863 2.605-6.468 0-2.576-.878-4.742-2.605-6.41-1.756-1.727-3.892-2.576-6.41-2.576-2.546 0-4.682.85-6.38 2.576zm2.634 10.332c-.936-1.083-1.405-2.371-1.405-3.922 0-1.552.469-2.84 1.405-3.893.966-1.083 2.254-1.58 3.834-1.58 1.522 0 2.751.556 3.717 1.61.966 1.082 1.464 2.37 1.464 3.892 0 1.58-.469 2.868-1.405 3.922-.907 1.083-2.195 1.58-3.746 1.58-1.669 0-2.927-.526-3.864-1.61zM99.746 21.249l-6.82 17.444h3.835l1.376-3.425h6.731l1.405 3.425h3.834l-6.819-17.444h-3.542zm3.922 10.741h-4.331l2.165-5.707 2.166 5.707zM119.356 31.873c1.61.293 2.429.908 2.429 1.844 0 1.083-1.141 1.903-3.043 1.903-1.991 0-3.63-.85-4.918-2.517l-2.546 2.575c1.581 1.961 4.185 3.337 7.229 3.337 2.078 0 3.747-.498 5.035-1.464 1.258-.966 1.902-2.253 1.902-3.863 0-3.015-1.961-4.83-5.883-5.444l-1.727-.264c-1.668-.292-2.488-.907-2.488-1.843 0-1.025.996-1.727 2.576-1.727 1.639 0 3.102.673 4.42 2.02l2.487-2.606c-1.756-1.873-4.009-2.78-6.731-2.78-1.756 0-3.278.468-4.566 1.434-1.259.937-1.903 2.224-1.903 3.834 0 2.898 2.078 4.654 5.503 5.24l2.224.321zM127.405 24.673h4.976v14.02h3.687v-14.02h5.005V21.25h-13.668v3.424z"
        fill="#281E38"
      />
      <path
        d="M47.737 32.927A17.98 17.98 0 0129.97 47.97c-9.922 0-18-8.049-18-18S20.049 12 29.97 12c8.927 0 16.36 6.527 17.766 15.044h-4.39C42 20.898 36.526 16.302 30 16.302c-7.551 0-13.668 6.117-13.668 13.669 0 7.55 6.117 13.668 13.668 13.668 6.556 0 12.03-4.595 13.346-10.741h4.39v.029zm-11.796 0c-1.082 2.195-3.336 3.717-5.941 3.717s-4.858-1.522-5.941-3.717h-4.654c1.288 4.624 5.532 8.02 10.566 8.02 5.034 0 9.278-3.396 10.566-8.02H35.94z"
        fill="#00CAAC"
      />
    </svg>
  );
}
export default SvgColorPositive;

