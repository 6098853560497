"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericUntypedJsonBlobField = void 0;
var lodash_1 = require("lodash");
var GenericUntypedJsonBlobField = /** @class */ (function () {
    function GenericUntypedJsonBlobField(jsonBlob) {
        this.fieldType = 'GenericUntypedJsonBlob';
        this.jsonBlob = jsonBlob;
    }
    GenericUntypedJsonBlobField.prototype.validate = function () {
        return null;
    };
    GenericUntypedJsonBlobField.prototype.isEqual = function (field) {
        return field instanceof GenericUntypedJsonBlobField && (0, lodash_1.isEqual)(this.jsonBlob, field.jsonBlob);
    };
    GenericUntypedJsonBlobField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(GenericUntypedJsonBlobField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return GenericUntypedJsonBlobField;
}());
exports.GenericUntypedJsonBlobField = GenericUntypedJsonBlobField;
