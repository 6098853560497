import * as React from 'react';
function SvgArrowFullDown(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 8v23.538"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M8 20.462l12 12 12-12" stroke={props.color} strokeWidth={2} />
    </svg>
  );
}
export default SvgArrowFullDown;

