exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3QAwjK6SW5xa8PpeglQT-5 {\n  position: relative;\n  background-color: #F8F8F8;\n  border: 1px solid #E9E9E9;\n  overflow: visible;\n  margin: 1px;\n}\n\n._3KF-e_U0QP0wnZFp-MReBx {\n  position: absolute;\n  bottom: 2px;\n  width: 100%;\n  text-align: center;\n}\n\n._2A0QEJbxfeJ4Z_mR1MUZar {\n  padding: 0;\n}\n\n._1DDAKapWQA8gqROVYPI2Xc {\n  background-color: rgba(189, 189, 189, 0.5);\n  overflow: hidden;\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  left: 0px;\n  bottom: 0px;\n}\n\n._15xg428EOpWfTOZ8piB8ck {\n  display: none;\n}\n", ""]);
// Exports
exports.locals = {
	"imageWrapper": "_3QAwjK6SW5xa8PpeglQT-5",
	"cameraIconWrapper": "_3KF-e_U0QP0wnZFp-MReBx",
	"button": "_2A0QEJbxfeJ4Z_mR1MUZar _3QAwjK6SW5xa8PpeglQT-5",
	"loadingWrapper": "_1DDAKapWQA8gqROVYPI2Xc",
	"input": "_15xg428EOpWfTOZ8piB8ck"
};