"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scheduleRemoveUserTyping = exports.scheduler = void 0;
var typingScheduler_1 = require("@shared/scenes/chat/thread/util/typingScheduler");
// exported for testing purposes
exports.scheduler = new typingScheduler_1.MBUserTypingRemovalScheduler();
var scheduleRemoveUserTyping = function (user, channelSlug) {
    exports.scheduler.schedule(user, channelSlug);
};
exports.scheduleRemoveUserTyping = scheduleRemoveUserTyping;
