"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAccessLevel = exports.setWorkspaceListSection = exports.setWorkflowListItem = exports.resetSelectedUser = exports.removeSelectedUser = exports.addSelectedUser = exports.SET_ACCESS_LEVEL = exports.RESET_SELECTED_USERS = exports.SET_WORKSPACE_LIST_SECTION = exports.SET_WORKFLOW_LIST_ITEM = exports.REMOVE_SELECTED_USER = exports.ADD_SELECTED_USER = void 0;
exports.ADD_SELECTED_USER = 'CHANNEL:CREATE:ADD_SELECTED_USER';
exports.REMOVE_SELECTED_USER = 'CHANNEL:CREATE:REMOVE_SELECTED_USER';
exports.SET_WORKFLOW_LIST_ITEM = 'CHANNEL:CREATE:SET_WORKFLOW_LIST_ITEM';
exports.SET_WORKSPACE_LIST_SECTION = 'CHANNEL:CREATE:SET_WORKSPACE_LIST_SECTION';
exports.RESET_SELECTED_USERS = 'CHANNEL:CREATE:RESET_SELECTED_USERS';
exports.SET_ACCESS_LEVEL = 'CHANNEL:CREATE:SET_ACCESS_LEVEL';
var addSelectedUser = function (user) { return ({
    type: exports.ADD_SELECTED_USER,
    value: user,
}); };
exports.addSelectedUser = addSelectedUser;
var removeSelectedUser = function (user) { return ({
    type: exports.REMOVE_SELECTED_USER,
    value: user,
}); };
exports.removeSelectedUser = removeSelectedUser;
var resetSelectedUser = function (users) {
    if (users === void 0) { users = []; }
    return ({
        type: exports.RESET_SELECTED_USERS,
        value: users,
    });
};
exports.resetSelectedUser = resetSelectedUser;
var setWorkflowListItem = function (item) { return ({
    type: exports.SET_WORKFLOW_LIST_ITEM,
    value: item,
}); };
exports.setWorkflowListItem = setWorkflowListItem;
var setWorkspaceListSection = function (id) { return ({
    type: exports.SET_WORKSPACE_LIST_SECTION,
    value: id,
}); };
exports.setWorkspaceListSection = setWorkspaceListSection;
var setAccessLevel = function (accessLevel) { return ({
    type: exports.SET_ACCESS_LEVEL,
    value: accessLevel,
}); };
exports.setAccessLevel = setAccessLevel;
