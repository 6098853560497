import * as React from 'react';
function SvgHamburger(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 25v1a3 3 0 003 3h12a3 3 0 003-3v-1M11 16a7 7 0 017-7h4a7 7 0 017 7"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M21 20H11c-1.1 0-2-.9-2-2s.9-2 2-2h18c1.1 0 2 .9 2 2s-.9 2-2 2h-2l-3 3-3-3z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M11.5 20C10.1 20 9 21.1 9 22.5s1.1 2.5 2.5 2.5h17c1.4 0 2.5-1.1 2.5-2.5S29.9 20 28.5 20"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgHamburger;

