"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBOnboardingLastStep = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var analytics_1 = require("@shared/util/analytics");
var hooks_1 = require("@shared/util/analytics/hooks");
var i18n_1 = require("@shared/util/i18n");
var hook_1 = require("@shared/util/actionSheet/hook");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var header_1 = require("@shared/scenes/auth/contentWrapper/header");
var component_1 = require("./component");
var theme_1 = require("./theme");
var hooks_2 = require("./hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var blurredDesktopBackground_1 = require("../blurredDesktopBackground");
var MBOnboardingLastStep = function () {
    // Force the last onboarding step page to be shown until
    // skipped/completed once the user has seen it once.
    var _a = (0, hooks_2.useOnboardingLastStepState)(), setShouldShow = _a.setShouldShow, setRemindNextTime = _a.setRemindNextTime;
    (0, react_1.useEffect)(function () { return setShouldShow(true); }, []);
    var navigateToHome = (0, useNavigator_1.useNavigateTo)('Home');
    var navigateToOnboardingDone = (0, useNavigator_1.useNavigateTo)('OnboardingDone');
    var navigateToOnboardingInviteContacts = (0, useNavigator_1.useNavigateTo)('OnboardingInviteContacts');
    // TODO(fant): reenable this when this functionality is implemented
    var showSkipConfirmation = (0, hook_1.useActionSheet)({
        title: (0, i18n_1.i18n)('onboarding.lastStep.skipConfirmation.title'),
        webHorizontalAlignment: 'right',
        options: [
            {
                title: (0, i18n_1.i18n)('onboarding.lastStep.skipConfirmation.remindMe'),
                onPress: function () {
                    setRemindNextTime();
                    navigateToHome();
                    analytics_1.MBAnalytics.track('Onboarding::LastStep::Prompt::RemindNextTime');
                },
            },
            {
                title: (0, i18n_1.i18n)('onboarding.lastStep.skipConfirmation.skipCompletely'),
                onPress: function () {
                    setShouldShow(false);
                    navigateToOnboardingDone();
                    analytics_1.MBAnalytics.track('Onboarding::LastStep::Prompt::Skip');
                },
            },
        ],
    });
    return (react_1.default.createElement(contentWrapper_1.MBAuthContentWrapper, { logo: "white", theme: theme_1.theme, shadow: false, background: react_1.default.createElement(blurredDesktopBackground_1.MBOnboardingBlurredDesktopBackground, null), header: react_1.default.createElement(header_1.MBAuthContentWrapperHeader, { leftIcon: null, rightIcon: require('@shared/assets/icons/v2/exit.png'), onPressRight: showSkipConfirmation }) },
        react_1.default.createElement(component_1.MBOnboardingLastStep, { isDesktop: (0, contentWrapper_1.useIsDesktop)(), onNext: (0, hooks_1.useTrackEventWithCallback)('Onboarding::LastStep::Next', navigateToOnboardingInviteContacts) })));
};
exports.MBOnboardingLastStep = MBOnboardingLastStep;
