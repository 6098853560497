import * as React from 'react';
function SvgDotsWide(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 22a2 2 0 100-4 2 2 0 000 4zM11 22a2 2 0 100-4 2 2 0 000 4zM29 22a2 2 0 100-4 2 2 0 000 4z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgDotsWide;

