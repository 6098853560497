/* @flow */
import React from 'react';

import PureComponent from '@src/components/PureComponent';

import styles from './Overlay.css';

type Props = {
  onClose: Function,
};

export default class Overlay extends PureComponent {
  props: Props;
  render() {
    return <div className={styles.root} onClick={this.props.onClose} />;
  }
}
