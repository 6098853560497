"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVisibleComponents = useVisibleComponents;
var Utils_1 = require("@shared/data/cards/Utils");
var react_1 = require("react");
function useVisibleComponents(workflowTemplate, viewTemplate) {
    return (0, react_1.useMemo)(function () { var _a; return getVisibleComponents((_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) !== null && _a !== void 0 ? _a : [], viewTemplate); }, [workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components, viewTemplate]);
}
function getVisibleComponents(components, viewTemplate) {
    return components.filter(function (component) {
        var _a;
        var viewOptions = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.componentId === component.id; });
        return !(0, Utils_1.isComponentHidden)(viewTemplate, viewOptions);
    });
}
