"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTouchableHighlight = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var MBTouchableHighlight = /** @class */ (function (_super) {
    __extends(MBTouchableHighlight, _super);
    function MBTouchableHighlight() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { pressed: false };
        _this.onPress = function () { var _a, _b; return (_b = (_a = _this.props).onPress) === null || _b === void 0 ? void 0 : _b.call(_a, _this); };
        _this.onPressIn = function () { return _this.setState({ pressed: true }); };
        _this.onPressOut = function () { return _this.setState({ pressed: false }); };
        return _this;
    }
    MBTouchableHighlight.prototype.componentDidUpdate = function () {
        var isPressable = !this.props.disabled && !!this.props.onPress;
        if (!isPressable && this.state.pressed) {
            this.setState({ pressed: false });
        }
    };
    MBTouchableHighlight.prototype.render = function () {
        var _this = this;
        return (react_1.default.createElement(styles_1.ColorThemeContext.Consumer, null, function (colors) { return (react_1.default.createElement(react_native_1.TouchableOpacity, { style: _this.props.style, disabled: _this.props.disabled, activeOpacity: 1, onPress: !!_this.props.onPress ? _this.onPress : undefined, onPressIn: !!_this.props.onPress ? _this.onPressIn : undefined, onPressOut: !!_this.props.onPress ? _this.onPressOut : undefined },
            _this.state.pressed && (react_1.default.createElement(react_native_1.View, { style: [
                    styles_1.SharedStyles.absoluteCover,
                    _this.props.highlightStyle,
                    { backgroundColor: colors.highlightInactive },
                ] })),
            _this.props.children)); }));
    };
    return MBTouchableHighlight;
}(react_1.PureComponent));
exports.MBTouchableHighlight = MBTouchableHighlight;
