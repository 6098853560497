"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEntitySelection = void 0;
exports.EntitySelectionProvider = EntitySelectionProvider;
var react_1 = __importStar(require("react"));
var context_1 = require("@shared/util/context");
var hooks_1 = require("@shared/util/hooks");
var _a = (0, context_1.createCtx)(), useEntitySelection = _a[0], ContextProvider = _a[1];
exports.useEntitySelection = useEntitySelection;
function EntitySelectionProvider(_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)([]), selectedEntities = _b[0], setSelectedEntities = _b[1];
    var _c = (0, hooks_1.useBoolean)(), loading = _c[0], startLoading = _c[1], stopLoading = _c[2];
    var addEntity = (0, react_1.useCallback)(function (entity) {
        setSelectedEntities(function (prev) { return __spreadArray(__spreadArray([], prev.filter(function (item) { return item.id !== entity.id; }), true), [entity], false); });
    }, []);
    var removeEntity = (0, react_1.useCallback)(function (id) {
        setSelectedEntities(function (prev) { return prev.filter(function (item) { return item.id !== id; }); });
    }, []);
    var reset = (0, react_1.useCallback)(function () {
        setSelectedEntities([]);
        stopLoading();
    }, [stopLoading]);
    var isEntitySelected = (0, react_1.useCallback)(function (id) {
        return selectedEntities.some(function (item) { return item.id === id; });
    }, [selectedEntities]);
    var toggleEntity = (0, react_1.useCallback)(function (entity) {
        if (isEntitySelected(entity.id)) {
            removeEntity(entity.id);
        }
        else {
            addEntity(entity);
        }
    }, [addEntity, removeEntity, isEntitySelected]);
    var value = (0, react_1.useMemo)(function () {
        var selectedCount = selectedEntities.length;
        var selectionActive = selectedCount > 0;
        return {
            selectedEntities: selectedEntities,
            selectedCount: selectedCount,
            selectionActive: selectionActive,
            addEntity: addEntity,
            removeEntity: removeEntity,
            toggleEntity: toggleEntity,
            isEntitySelected: isEntitySelected,
            reset: reset,
            loading: loading,
            startLoading: startLoading,
            stopLoading: stopLoading,
        };
    }, [
        selectedEntities,
        addEntity,
        removeEntity,
        toggleEntity,
        reset,
        loading,
        startLoading,
        stopLoading,
        isEntitySelected,
    ]);
    return react_1.default.createElement(ContextProvider, { value: value }, children);
}
