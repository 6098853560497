import * as React from 'react';
function SvgExclamationOutline(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 31c6.075 0 11-4.925 11-11S26.075 9 20 9 9 13.925 9 20s4.925 11 11 11zM20 15v6"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M20 26a1 1 0 100-2 1 1 0 000 2z" fill={props.color} />
    </svg>
  );
}
export default SvgExclamationOutline;

