import * as React from 'react';
function SvgArrowShuffle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.115 18.638c.25-.246.503-.498.764-.759.218-.218.44-.439.664-.66C14.522 15.434 12.22 14 9 14H8v2h1c2.513 0 4.385 1.132 6.115 2.638zM28 16v3l4-4-4-4v3h-1c-4.414 0-7.105 2.69-9.707 5.293C14.872 21.714 12.586 24 9 24H8v2h1c4.414 0 7.105-2.69 9.707-5.293C21.128 18.286 23.414 16 27 16h1z"
        fill={props.color}
      />
      <path
        d="M28 24h-1c-2.513 0-4.385-1.132-6.115-2.638-.25.246-.503.498-.764.759-.218.218-.44.439-.664.66C21.478 24.566 23.78 26 27 26h1v3l4-4-4-4v3z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgArrowShuffle;

