import * as React from 'react';
function SvgSwap(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.946 5.716L20.248 5l-1.432 1.396.699.716 11.494 11.785H8.786l5.418-5.554.698-.716-1.432-1.396-.698.716-7.064 7.242-.708.69.017.018-.017.018.708.69 7.064 7.243.698.716 1.432-1.397-.698-.716-5.418-5.554H31.01L19.515 32.682l-.699.716 1.432 1.396.698-.715 13.142-13.474.708-.69-.018-.018.018-.017-.708-.69L20.946 5.715z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgSwap;

