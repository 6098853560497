import * as React from 'react';
function SvgDownBorder(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="matrix(0 1 1 0 1 1)"
        stroke={props.color}
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      >
        <circle cx={11} cy={11} r={11} />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M16.657 10.707H5.343M11 5.05l5.657 5.657L11 16.364" />
        </g>
      </g>
    </svg>
  );
}
export default SvgDownBorder;

