"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchControls = useSearchControls;
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var react_1 = require("react");
var use_debounce_1 = require("use-debounce");
function useSearchControls() {
    var search = (0, ControlsProvider_1.useControlsState)().search;
    var setSearch = (0, ControlsProvider_1.useControlsActions)().setSearch;
    var onChange = (0, use_debounce_1.useDebouncedCallback)(setSearch, 500)[0];
    return (0, react_1.useMemo)(function () { return ({ search: search, setSearch: setSearch, onChange: onChange }); }, [search, setSearch, onChange]);
}
