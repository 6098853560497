"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectableDays = void 0;
var react_1 = __importStar(require("react"));
var rrule_1 = require("rrule");
var components_1 = require("@shared/components");
var SelectableDay_1 = require("./SelectableDay");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var RRuleHelpers_1 = require("../RRuleHelpers");
var SelectableDays = function (_a) {
    var rruleString = _a.rruleString, onChange = _a.onChange;
    var rule = (0, react_1.useMemo)(function () { return (!!rruleString ? rrule_1.RRule.fromString(rruleString) : undefined); }, [rruleString]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingTop: 3 },
            react_1.default.createElement(components_1.MBTextBody2Bold, { color: colors.foregroundActive }, (0, i18n_1.i18n)('card.blocks.recurrenceInput.on'))),
        react_1.default.createElement(components_1.MBView, { row: true, paddingHorizontal: 3, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBView, { paddingRight: 0.5 },
                react_1.default.createElement(SelectableDay_1.SelectableDay, { isSelected: (0, RRuleHelpers_1.matchesWeekday)(rule, rrule_1.RRule.SU), day: (0, i18n_1.i18n)('common.datetime.abbrevations.sunday'), onSelect: function () { return onChange(rrule_1.RRule.SU); } })),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 0.5 },
                react_1.default.createElement(SelectableDay_1.SelectableDay, { isSelected: (0, RRuleHelpers_1.matchesWeekday)(rule, rrule_1.RRule.MO), day: (0, i18n_1.i18n)('common.datetime.abbrevations.monday'), onSelect: function () { return onChange(rrule_1.RRule.MO); } })),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 0.5 },
                react_1.default.createElement(SelectableDay_1.SelectableDay, { isSelected: (0, RRuleHelpers_1.matchesWeekday)(rule, rrule_1.RRule.TU), day: (0, i18n_1.i18n)('common.datetime.abbrevations.tuesday'), onSelect: function () { return onChange(rrule_1.RRule.TU); } })),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 0.5 },
                react_1.default.createElement(SelectableDay_1.SelectableDay, { isSelected: (0, RRuleHelpers_1.matchesWeekday)(rule, rrule_1.RRule.WE), day: (0, i18n_1.i18n)('common.datetime.abbrevations.wednesday'), onSelect: function () { return onChange(rrule_1.RRule.WE); } })),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 0.5 },
                react_1.default.createElement(SelectableDay_1.SelectableDay, { isSelected: (0, RRuleHelpers_1.matchesWeekday)(rule, rrule_1.RRule.TH), day: (0, i18n_1.i18n)('common.datetime.abbrevations.thursday'), onSelect: function () { return onChange(rrule_1.RRule.TH); } })),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 0.5 },
                react_1.default.createElement(SelectableDay_1.SelectableDay, { isSelected: (0, RRuleHelpers_1.matchesWeekday)(rule, rrule_1.RRule.FR), day: (0, i18n_1.i18n)('common.datetime.abbrevations.friday'), onSelect: function () { return onChange(rrule_1.RRule.FR); } })),
            react_1.default.createElement(components_1.MBView, { paddingLeft: 0.5 },
                react_1.default.createElement(SelectableDay_1.SelectableDay, { isSelected: (0, RRuleHelpers_1.matchesWeekday)(rule, rrule_1.RRule.SA), day: (0, i18n_1.i18n)('common.datetime.abbrevations.saturday'), onSelect: function () { return onChange(rrule_1.RRule.SA); } })))));
};
exports.SelectableDays = SelectableDays;
