import * as React from 'react';
function SvgHeart(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.243 11.757a6 6 0 00-9.242.927 6 6 0 10-9.242 7.558L20 29.485l9.243-9.243a6 6 0 000-8.485z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgHeart;

