import * as React from 'react';
function SvgPersonWoman(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 27h3"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M16.796 21.907l-5.825 3.51A2.002 2.002 0 0010 27.132V31h20v-3.868a2 2 0 00-.971-1.715l-5.825-3.51M25.999 15.106A7.008 7.008 0 0120.985 13a6.976 6.976 0 01-5 2.106c-.69 0-1.352-.124-1.982-.31"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M14 15a6 6 0 1112 0c0 5-2.686 8-6 8s-6-3-6-8z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M14 15s0 4-4 7c.844 2.057 2.751 2.345 2.751 2.345M25.938 15s0 4 4 7c-.844 2.057-2.751 2.345-2.751 2.345"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgPersonWoman;

