"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBComposeWithChatPreview = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var analytics_1 = require("@shared/util/analytics");
var components_1 = require("@shared/components");
var recipientInput_1 = require("./recipientInput");
var userList_1 = require("../containers/userList");
var chatPreview_1 = require("../containers/chatPreview");
var MBComposeWithChatPreview = function (_a) {
    var channel = _a.channel;
    var _b = React.useState(''), query = _b[0], setQuery = _b[1];
    var _c = React.useState(channel.users), selectedUsers = _c[0], setSelectedUsers = _c[1];
    var _d = React.useState(true), isChannelRecipient = _d[0], setIsChannelRecipient = _d[1];
    var removeUser = React.useCallback(function (userId) {
        var users = selectedUsers.filter(function (u) { return u.id !== userId; });
        setSelectedUsers(users);
    }, [selectedUsers, setSelectedUsers]);
    var removeUserByKeyboard = React.useCallback(function (userId) {
        removeUser(userId);
        analytics_1.MBAnalytics.track('CreateChannel::ChatPreview::Search::DeselectUser', {
            userId: userId,
            source: 'keyboard',
        });
    }, [removeUser]);
    var removeChannelByKeyboard = React.useCallback(function () {
        setSelectedUsers([]);
        setIsChannelRecipient(false);
    }, [setSelectedUsers, setIsChannelRecipient]);
    var toggleUser = React.useCallback(function (user) {
        if (selectedUsers.some(function (u) { return u.id === user.id; })) {
            removeUser(user.id);
            analytics_1.MBAnalytics.track('CreateChannel::ChatPreview::Search::DeselectUser', {
                userId: user.id,
                source: 'list',
            });
        }
        else {
            setSelectedUsers(__spreadArray(__spreadArray([], selectedUsers, true), [user], false));
            analytics_1.MBAnalytics.track('CreateChannel::ChatPreview::Search::SelectUser', {
                query: query,
                userId: user.id,
                name: user.fullName,
            });
        }
        setIsChannelRecipient(false);
        setQuery(''); // empty the query so that the chat preview is shown again
    }, [query, removeUser, selectedUsers, setIsChannelRecipient, setSelectedUsers, setQuery]);
    React.useEffect(
    // We track when someone starts searching
    function () {
        !!query && analytics_1.MBAnalytics.track('CreateChannel::ChatPreview::Search');
    }, [!!query]);
    // We want to show the list of users if the user
    // (1) is searching
    // (2) has deselected all users
    var isShowingUsers = !!query || !selectedUsers.length;
    return (React.createElement(React.Fragment, null,
        React.createElement(components_1.MBView, { paddingHorizontal: "normal", paddingVertical: "small" },
            React.createElement(recipientInput_1.MBRecipientInput, { value: query, users: selectedUsers, channel: isChannelRecipient ? channel : undefined, onRemoveUser: removeUserByKeyboard, onRemoveChannel: removeChannelByKeyboard, onChangeText: setQuery })),
        React.createElement(components_1.MBSeparator, null),
        !isShowingUsers && React.createElement(chatPreview_1.MBChatPreview, { initialChannelSlug: channel.slug, users: selectedUsers }),
        isShowingUsers && (React.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag" },
            React.createElement(userList_1.MBUserList, { query: query, selectedUsers: selectedUsers, onPressUser: toggleUser }))),
        React.createElement(components_1.MBNativeKeyboardSpacer, null)));
};
exports.MBComposeWithChatPreview = MBComposeWithChatPreview;
