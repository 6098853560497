"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForcedLogout = void 0;
var util_1 = require("@shared/scenes/auth/util");
var useLogout_1 = require("@shared/scenes/navigation/menu/containers/useLogout");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_idle_timer_1 = require("react-idle-timer");
var parseJwt = function (token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    }
    catch (e) {
        return null;
    }
};
var AbsoluteTimeout = function () {
    var logout = (0, useLogout_1.useLogout)();
    (0, react_1.useEffect)(function () {
        var checkAbsoluteTimeout = setInterval(function () {
            var authToken = util_1.MBAuthUtil.getAuthToken();
            if ((0, lodash_1.isEmpty)(authToken)) {
                return;
            }
            var decodedAuthToken = parseJwt(authToken);
            if ((0, lodash_1.isNil)(decodedAuthToken === null || decodedAuthToken === void 0 ? void 0 : decodedAuthToken.exp)) {
                return;
            }
            if (decodedAuthToken.exp * 1000 < Date.now()) {
                logout();
            }
        }, 1000);
        return function () {
            clearInterval(checkAbsoluteTimeout);
        };
    }, []);
    return null;
};
var IdleTimeout = function (_a) {
    var timeout = _a.timeout;
    var logout = (0, useLogout_1.useLogout)();
    (0, react_idle_timer_1.useIdleTimer)({
        timeout: timeout,
        onIdle: function () { return logout(); },
    });
    return null;
};
var ForcedLogout = function () {
    var inactivityTimeout = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.INACTIVITY_TIMEOUT);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        inactivityTimeout > 0 && react_1.default.createElement(IdleTimeout, { timeout: inactivityTimeout }),
        react_1.default.createElement(AbsoluteTimeout, null)));
};
exports.ForcedLogout = ForcedLogout;
