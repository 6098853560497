import * as React from 'react';
function SvgEventCreate(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 24v6M25 27h6M20 29H9V11h22v8M15 9v3M25 9v3"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M9 16h22" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
    </svg>
  );
}
export default SvgEventCreate;

