/* @flow */
import React from 'react';

import peopleURL from '@src/components/ImageEditable/People.svg';

import styles from './UserImage.css';

type Props = {
  url?: string,
  size?: number | string,
  noBorder?: boolean,
  showDefault?: boolean,
};

const UserImage = ({ url = '', showDefault, size, noBorder }: Props) => (
  <div
    className={noBorder ? styles.rootNoBorder : styles.root}
    style={{
      backgroundImage: `url(${(showDefault || !url) ? peopleURL : url})`,
      height: size,
      width: size,
    }}
  />
);
UserImage.defaultProps = {
  size: 32,
};

export default UserImage;
