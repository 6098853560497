import * as React from 'react';
function SvgVideoRecorder(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M24 18l7-3v10l-7-3" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M22 12H11a2 2 0 00-2 2v12a2 2 0 002 2h11a2 2 0 002-2V14a2 2 0 00-2-2z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgVideoRecorder;

