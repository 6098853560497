import * as React from 'react';
function SvgStoreLocation(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.374 19.997A2.5 2.5 0 0119 17.5a2.5 2.5 0 11-5 0 2.5 2.5 0 01-2.5 2.5C10.119 20 9 18.958 9 17l4-8h12l4 8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path d="M12 20v9h8" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M31 22c0 3.1-5 8.1-5 8.1s-5-5-5-8.1c0-3.2 2.6-5 5-5s5 1.8 5 5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M26 23a1 1 0 100-2 1 1 0 000 2z" fill={props.color} />
    </svg>
  );
}
export default SvgStoreLocation;

