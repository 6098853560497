"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBVerifyCodeInput = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var styles = react_native_1.StyleSheet.create({
    text: __assign(__assign({}, styles_1.SharedUnvalidatedStyles.borderRadius), { padding: 10, 
        // The MBTextH1Mono letter spacing will add padding to
        // the right. To make the content look somewhat centered
        // we avoid the right padding on the text container.
        paddingRight: 0, width: 310 }),
});
var PLACEHOLDER_DOT = '•';
var MBVerifyCodeInput = function (_a) {
    var length = _a.length, autoFocus = _a.autoFocus, style = _a.style, onSubmit = _a.onSubmit, onChangeText = _a.onChangeText, onFocus = _a.onFocus, onBlur = _a.onBlur;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(''), value = _b[0], setValue = _b[1];
    var handleChangeText = (0, react_1.useCallback)(function (newValue) {
        var code = newValue.replace(/[^0-9]/g, '').slice(0, length);
        // Don't update multiple times when user is typing
        // when 6 numbers have already been inputted
        if (code === value) {
            return;
        }
        setValue(code);
        !!onChangeText && onChangeText(code);
        if (code.length === length) {
            onSubmit(code);
        }
    }, [value, length, onSubmit, onChangeText]);
    var dots = Array(length)
        .fill(undefined)
        .map(function (_, index) { return (value.length > index ? ' ' : PLACEHOLDER_DOT); })
        .join('');
    return (react_1.default.createElement(components_1.MBView, null,
        react_1.default.createElement(react_native_1.TextInput, { value: value, autoFocus: autoFocus, selectTextOnFocus: value.length === length, onChangeText: handleChangeText, onFocus: onFocus, onBlur: onBlur, keyboardType: "numeric", blurOnSubmit: false, style: [
                styles.text,
                typography_1.MBTypographyStyles.h1Mono,
                {
                    color: colors.input.foregroundActive,
                    backgroundColor: colors.input.backgroundActive,
                },
                style,
            ] }),
        react_1.default.createElement(components_1.MBView, { pointerEvents: "none", style: [styles_1.SharedStyles.absoluteCover, styles.text] },
            react_1.default.createElement(typography_1.MBTextH1Mono, { color: colors.input.foregroundInactive }, dots))));
};
exports.MBVerifyCodeInput = MBVerifyCodeInput;
