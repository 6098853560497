"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardCollectionContainer = void 0;
exports.useUnreadCardCount = useUnreadCardCount;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var components_2 = require("@shared/scenes/cards/agenda/components");
var container_1 = require("@shared/scenes/cards/calendar/container");
var templateNotFound_1 = require("@shared/scenes/cards/components/templateNotFound");
var templateNotSupported_1 = require("@shared/scenes/cards/components/templateNotSupported");
var v2_1 = require("@shared/scenes/cards/kanban/containers/v2");
var v1_1 = require("@shared/scenes/cards/list/containers/v1");
var v2_2 = require("@shared/scenes/cards/list/containers/v2");
var context_1 = require("@shared/scenes/channel/webLayout/context");
var hooks_1 = require("@shared/util/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var hooks_3 = require("../../createButton/hooks");
var container_2 = require("../../table/container");
var hooks_4 = require("../hooks");
var viewTemplate_1 = require("@shared/components/blocks/contexts/viewTemplate");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var tableView_1 = require("@shared/scenes/cards/tableView");
var listView_1 = require("@shared/scenes/cards/listView");
var agendaView_1 = require("@shared/scenes/cards/agendaView");
var lodash_1 = require("lodash");
var isLowCodeViewTemplate_1 = require("@shared/scenes/cards/collection/components/isLowCodeViewTemplate");
var actions_1 = require("@shared/scenes/cards/actions");
function useRemoveCardUnreadCountInApolloCache(channelSlug) {
    var apolloClient = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function () {
        try {
            var channel = apolloClient.readFragment({
                id: "Channel".concat(channelSlug),
                fragment: api_1.Fragments.channel.listItem,
                fragmentName: 'ChannelListItem',
            });
            apolloClient.writeFragment({
                id: "Channel".concat(channelSlug),
                fragment: api_1.Fragments.channel.listItem,
                fragmentName: 'ChannelListItem',
                data: __assign(__assign({}, channel), { unreadCount: channel.unreadMessagesCount }),
            });
        }
        catch (error) {
            // Apollo crashes if it cannot find data in the cache
        }
    }, [channelSlug, apolloClient]);
}
function useUnreadCardCount(channelSlug) {
    var channel = (0, hooks_1.useChannel)(channelSlug);
    return (0, react_1.useMemo)(function () {
        if (!channel) {
            return 0;
        }
        return channel.unreadCount - channel.unreadMessagesCount;
    }, [channel]);
}
function useMarkCardsViewedForChannel(channelSlug) {
    var unreadCardCount = useUnreadCardCount(channelSlug);
    var channelId = (0, hooks_1.useChannelId)(channelSlug);
    var mutation = (0, client_1.useMutation)(api_1.Mutations.cards.markCardsViewed)[0];
    var removeCardUnreadCountInApolloCache = useRemoveCardUnreadCountInApolloCache(channelSlug);
    var mode = (0, react_1.useContext)(context_1.WebLayoutContext).mode;
    var focused = (0, useNavigator_1.useNavigationFocus)();
    (0, react_1.useEffect)(function () {
        if (!channelId || mode === 'chat' || !focused || !unreadCardCount) {
            return;
        }
        mutation({ variables: { channelId: channelId } });
        removeCardUnreadCountInApolloCache();
    }, [unreadCardCount, mutation, removeCardUnreadCountInApolloCache, focused, channelId, mode]);
}
var MBCardCollectionContainer = function (_a) {
    var channelSlug = _a.channelSlug, context = _a.context, templateSlug = _a.templateSlug;
    var channel = (0, hooks_1.useGetChannelInfo)({ channelSlug: channelSlug });
    var channelPermissions = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var _b = (0, hooks_4.useCollectionViewTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, channelSlug, templateSlug), initialViewTemplate = _b.viewTemplate, loadingInitialViewTemplate = _b.loading;
    var workflowTemplate = (0, hooks_2.useWorkflowTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId);
    var _c = (0, hooks_2.useViewTemplateWithUITemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, initialViewTemplate === null || initialViewTemplate === void 0 ? void 0 : initialViewTemplate.id), viewTemplate = _c.viewTemplate, isViewTemplateLoading = _c.loading;
    var isLowCodeView = (0, isLowCodeViewTemplate_1.isLowCodeViewTemplate)(viewTemplate);
    var template = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.cardUITemplate.template;
    useMarkCardsViewedForChannel(channelSlug);
    (0, hooks_4.useResetCardActionFiltersWhenTemplateChanges)(template);
    var onCreateCard = (0, hooks_3.useCreateCardPrompt)(channelSlug);
    var mode = (0, react_1.useContext)(context_1.WebLayoutContext).mode;
    var _d = (0, react_1.useState)(false), animating = _d[0], setAnimating = _d[1];
    (0, react_1.useEffect)(function () {
        setAnimating(true);
        setTimeout(function () { return setAnimating(false); }, 500);
    }, [mode]);
    if (mode === 'chat') {
        /* We return an empty view here instead of @scenes/channel/webLayout/containers to allow for
         * the functionality of adding/removing cards when in the chat only view.
         */
        return react_1.default.createElement(react_1.default.Fragment, null);
    }
    if ((0, lodash_1.isNil)(channel)) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, testID: "view-loading-spinner" });
    }
    if ((0, lodash_1.isNil)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId)) {
        return (react_1.default.createElement(LCCardCollectionContainer, { channelSlug: channelSlug, templateSlug: templateSlug, context: context }));
    }
    if (loadingInitialViewTemplate || (workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.loading) || isViewTemplateLoading) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, testID: "view-loading-spinner" });
    }
    if ((0, lodash_1.isNil)(viewTemplate)) {
        return react_1.default.createElement(templateNotFound_1.MBTemplateNotFound, null);
    }
    return (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: channelSlug },
        react_1.default.createElement(viewTemplate_1.ViewTemplateProvider, { template: viewTemplate },
            (function () {
                if ((viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'TABLE') {
                    return (react_1.default.createElement(tableView_1.CardTableView, { viewTemplate: viewTemplate, workflowTemplate: workflowTemplate.workflowTemplate, workspaceId: channel.id, baseNavigationPath: channelSlug }));
                }
                if ((viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'LIST') {
                    return (react_1.default.createElement(listView_1.CardListView, { viewTemplate: viewTemplate, workflowTemplate: workflowTemplate.workflowTemplate, baseNavigationPath: channelSlug, workspaceId: channel === null || channel === void 0 ? void 0 : channel.id }));
                }
                if ((viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'BOARD') {
                    return (react_1.default.createElement(v2_1.MBCardKanbanBoardV2, { template: viewTemplate.cardUITemplate.template }));
                }
                if ((viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) === 'CALENDAR') {
                    if (!(0, lodash_1.isNil)(viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.calendarDateComponentId)) {
                        return (react_1.default.createElement(agendaView_1.CardAgendaView, { viewTemplate: viewTemplate, workflowTemplate: workflowTemplate.workflowTemplate, workspaceId: channel === null || channel === void 0 ? void 0 : channel.id, baseNavigationPath: channelSlug }));
                    }
                    // TODO unneeded after migration to NC calendar
                    if (mode === 'workflow' || mode === 'split-workflow') {
                        if (animating) {
                            return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
                        }
                        return (react_1.default.createElement(container_1.MBCardCalendar, { template: viewTemplate.cardUITemplate.template }));
                    }
                    else {
                        return (react_1.default.createElement(components_2.MBCardAgenda, { template: viewTemplate.cardUITemplate.template }));
                    }
                }
                // TODO needed because migration to new list hasn't happened
                if (template.context === 'list') {
                    if (template.version === 'v1') {
                        return react_1.default.createElement(v1_1.MBCardListV1, { channel: channel, template: template });
                    }
                    else if (template.version === 'v2') {
                        if (animating) {
                            return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
                        }
                        if (mode === 'workflow' || mode === 'split-workflow') {
                            return react_1.default.createElement(container_2.MBCardTableContainer, { template: template });
                        }
                        else {
                            return react_1.default.createElement(v2_2.MBCardListV2, { template: template });
                        }
                    }
                }
                return react_1.default.createElement(templateNotSupported_1.MBTemplateNotSupported, null);
            })(),
            !isDesktop && (react_1.default.createElement(react_1.default.Fragment, null,
                isLowCodeView && react_1.default.createElement(actions_1.MBCardActions, null),
                channelPermissions.canCreateCard.enabled && (react_1.default.createElement(components_1.MBView, { padding: 4, style: { position: 'absolute', bottom: 0, right: 0, elevation: 999 } },
                    react_1.default.createElement(components_1.MBIconButton, { onPress: onCreateCard, source: require('@shared/assets/icons/v2/plus.png') }))))),
            react_1.default.createElement(components_1.MBNativeKeyboardSpacer, { wrappedInSafeAreaView: false }))));
};
exports.MBCardCollectionContainer = MBCardCollectionContainer;
var LCCardCollectionContainer = function (_a) {
    var context = _a.context, templateSlug = _a.templateSlug, channelSlug = _a.channelSlug;
    var mode = (0, react_1.useContext)(context_1.WebLayoutContext).mode;
    var _b = (0, react_1.useState)(false), animating = _b[0], setAnimating = _b[1];
    var channel = (0, hooks_1.useGetChannelInfo)({ channelSlug: channelSlug });
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var channelPermissions = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext);
    var onCreateCard = (0, hooks_3.useCreateCardPrompt)(channelSlug);
    (0, react_1.useEffect)(function () {
        setAnimating(true);
        setTimeout(function () { return setAnimating(false); }, 500);
    }, [mode]);
    var _c = (0, hooks_4.useCollectionCardTemplate)({
        context: context,
        templateSlug: templateSlug,
        channelSlug: channelSlug,
    }), template = _c.template, loading = _c.loading;
    if ((0, lodash_1.isNil)(channel) || loading) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, testID: "view-loading-spinner" });
    }
    if ((0, lodash_1.isNil)(template)) {
        return react_1.default.createElement(templateNotFound_1.MBTemplateNotFound, null);
    }
    return (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: channelSlug },
        (function () {
            if (template.context === 'list') {
                if (template.version === 'v1') {
                    return react_1.default.createElement(v1_1.MBCardListV1, { channel: channel, template: template });
                }
                else if (template.version === 'v2') {
                    if (animating) {
                        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
                    }
                    if (mode === 'workflow' || mode === 'split-workflow') {
                        return react_1.default.createElement(container_2.MBCardTableContainer, { template: template });
                    }
                    else {
                        return react_1.default.createElement(v2_2.MBCardListV2, { template: template });
                    }
                }
            }
            if (template.context === 'kanban') {
                if (template.version === 'v2') {
                    return react_1.default.createElement(v2_1.MBCardKanbanBoardV2, { template: template });
                }
            }
            if (template.context === 'agenda') {
                if (animating) {
                    return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
                }
                if (mode === 'workflow' || mode === 'split-workflow') {
                    if (template.version === 'v1') {
                        return react_1.default.createElement(container_1.MBCardCalendar, { template: template });
                    }
                }
                else {
                    if (template.version === 'v1') {
                        return react_1.default.createElement(components_2.MBCardAgenda, { template: template });
                    }
                }
            }
        })(),
        !isDesktop && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(actions_1.MBCardActions, null),
            channelPermissions.canCreateCard.enabled && (react_1.default.createElement(components_1.MBView, { padding: 4, style: { position: 'absolute', bottom: 0, right: 0, elevation: 999 } },
                react_1.default.createElement(components_1.MBIconButton, { onPress: onCreateCard, source: require('@shared/assets/icons/v2/plus.png') }))))),
        react_1.default.createElement(components_1.MBNativeKeyboardSpacer, { wrappedInSafeAreaView: false })));
};
