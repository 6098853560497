"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardEntity = void 0;
// @ts-strict-ignore
var schemas_1 = require("@coast-app/schemas");
var CardField_1 = require("./CardField");
var Utils_1 = require("./Utils");
var CardEntity = /** @class */ (function () {
    function CardEntity(apiCard, workflowTemplateId, refs, viewTemplate) {
        var _this = this;
        var _a, _b;
        this.id = apiCard.id;
        this.fields = new Map();
        this.allFields = new Map();
        this.workflowTemplateId = workflowTemplateId;
        var workflowTemplate = refs.workflowTemplateRefs.find(function (w) { return w.id === workflowTemplateId; });
        this.navigationViewTemplateId = (_b = (_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.behavior) === null || _a === void 0 ? void 0 : _a.onOpenCard.selectors.find(function (selector) { return (0, schemas_1.evaluateSelector)(selector.selector, apiCard.fields); })) === null || _b === void 0 ? void 0 : _b.viewTemplateId;
        workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.forEach(function (component) {
            var viewOptions = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions.find(function (options) { return options.componentId === component.id; });
            if (!(0, Utils_1.isComponentHidden)(viewTemplate, viewOptions)) {
                _this.fields.set(component.id, (0, CardField_1.buildCardField)(apiCard, component, refs, null));
            }
            _this.allFields.set(component.id, (0, CardField_1.buildCardField)(apiCard, component, refs, null));
        });
    }
    return CardEntity;
}());
exports.CardEntity = CardEntity;
