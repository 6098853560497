import * as React from 'react';
function SvgCoastSymbol(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.863 21.963A11.997 11.997 0 0120.01 32C13.39 32 8 26.63 8 19.99S13.39 8 20.01 8c5.956 0 10.916 4.355 11.853 10.037h-2.929a9.133 9.133 0 00-8.924-7.166 9.118 9.118 0 00-9.12 9.12 9.117 9.117 0 009.12 9.119 9.133 9.133 0 008.924-7.167h2.93v.02zm-7.87 0a4.427 4.427 0 01-3.964 2.48 4.427 4.427 0 01-3.964-2.48H12.96a7.322 7.322 0 007.05 5.35 7.322 7.322 0 007.05-5.35h-3.066z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgCoastSymbol;

