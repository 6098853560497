import * as React from 'react';
function SvgArrowsUpDownHeavy(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 8l5-5 5 5M17 16l-5 5-5-5"
        stroke={props.color}
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgArrowsUpDownHeavy;

