import * as React from 'react';
function SvgBookOpen(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 11l11 3 11-3v17l-11 3-11-3V11zM13 24.9l4 1.1M17 17.3l-4-1.1M17 21.5l-4-1M27 24.9L23 26M23 17.3l4-1.1M23 21.5l4-1"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgBookOpen;

