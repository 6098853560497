"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBImagePicker = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var styles_2 = require("./styles");
var Previewer = function (_a) {
    var emoji = _a.emoji, imageUrl = _a.imageUrl, imageUploadProgress = _a.imageUploadProgress, onRemove = _a.onRemove, onImageLoaded = _a.onImageLoaded;
    var isEmoji = !imageUrl && !!emoji;
    return (react_1.default.createElement(components_1.MBView, null,
        react_1.default.createElement(components_1.MBView, { center: true, style: [
                styles_2.Styles.selectedChannelImageWrapper,
                { backgroundColor: isEmoji ? styles_1.Colors.v2.greenSecondary90 : 'transparent' },
            ] },
            isEmoji ? (react_1.default.createElement(components_1.MBEmojiIcon, { size: "large", emoji: emoji })) : (react_1.default.createElement(components_1.MBImage, { onLoadEnd: onImageLoaded, fill: true, source: { uri: imageUrl } })),
            imageUploadProgress !== undefined && (react_1.default.createElement(components_1.MBView, { center: true, absolute: true },
                react_1.default.createElement(components_1.MBCircularProgressBar, { color: "white", unfilledColor: styles_1.Colors.overlay.white20, progress: imageUploadProgress })))),
        react_1.default.createElement(components_1.MBTouchableOpacity, { style: styles_2.Styles.channelImageRemoveButtonPosition, onPress: onRemove },
            react_1.default.createElement(components_1.MBView, { style: styles_2.Styles.channelImageRemoveButtonOutline },
                react_1.default.createElement(components_1.MBIcon, { style: styles_2.Styles.channelImageRemoveButton, size: "large", source: require('@shared/assets/icons/clear.png'), color: styles_1.Colors.v2.gray30 })))));
};
var Picker = function (_a) {
    var text = _a.text, placeholderIconName = _a.placeholderIconName, onPress = _a.onPress;
    return (react_1.default.createElement(components_1.MBView, { center: true },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
            react_1.default.createElement(components_1.MBView, { center: true, style: styles_2.Styles.selectChannelImageWrapper },
                react_1.default.createElement(components_1.MBIconV2, { color: styles_1.Colors.v2.white, size: (0, components_1.grid)(7), name: placeholderIconName }))),
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
            react_1.default.createElement(components_1.MBView, { padding: 1 },
                react_1.default.createElement(components_1.MBTextBody2, { color: styles_1.Colors.v2.gray50 }, text)))));
};
var MBImagePicker = function (_a) {
    var text = _a.text, emoji = _a.emoji, imageUrl = _a.imageUrl, placeholderIconName = _a.placeholderIconName, imageUploadProgress = _a.imageUploadProgress, onPress = _a.onPress, onRemove = _a.onRemove, onImageLoaded = _a.onImageLoaded;
    var isSelected = !!(emoji || imageUrl);
    var onPressHandler = (0, react_1.useCallback)(function (node) { return onPress(node); }, [onPress]);
    return isSelected ? (react_1.default.createElement(Previewer, { emoji: emoji, imageUrl: imageUrl, imageUploadProgress: imageUploadProgress, onImageLoaded: onImageLoaded, onRemove: onRemove })) : (react_1.default.createElement(Picker, { text: text, placeholderIconName: placeholderIconName, onPress: onPressHandler }));
};
exports.MBImagePicker = MBImagePicker;
