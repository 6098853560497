"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSystemMessage = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var messageReactionsAndSeenBy_1 = require("@shared/scenes/chat/thread/containers/messageReactionsAndSeenBy");
var channelType_1 = require("@shared/scenes/chat/thread/util/channelType");
var styles_1 = require("../bubble/styles");
var MBSystemMessageTemplates = __importStar(require("./all"));
/*
    Render a system message depending on its subtype. Every system message
    template will figure out what content to show depending on the system
    message and its meta.
*/
var MBSystemMessage = function (_a) {
    var message = _a.message, layout = _a.layout, channelSlug = _a.channelSlug, onOpenCard = _a.onOpenCard, isDM = _a.isDM, isDesktop = _a.isDesktop;
    var Component;
    var onPress = undefined;
    var _b = React.useState(false), isHovered = _b[0], setIsHovered = _b[1];
    // Note(fant): I opted for ifs instead of a huge switch block
    // since it's less wordy
    if (message.subType === 'CHANNEL_CREATED') {
        Component = MBSystemMessageTemplates.ChannelCreated;
    }
    if (message.subType === 'MESSAGE_DELETED' ||
        message.subType === 'COMMENT_DELETED' ||
        message.subType === 'THREAD_MESSAGE_DELETED') {
        Component = MBSystemMessageTemplates.MessageRemoved;
    }
    if (message.subType === 'USER_JOINED') {
        Component = MBSystemMessageTemplates.UserJoined;
    }
    if (message.subType === 'USERS_ADDED') {
        Component = MBSystemMessageTemplates.UsersAdded;
    }
    if (message.subType === 'USER_REMOVED_FROM_CHANNEL') {
        Component = MBSystemMessageTemplates.UsersRemoved;
    }
    if (message.subType === 'ME_REMOVED_FROM_CHANNEL') {
        Component = MBSystemMessageTemplates.MeRemoved;
    }
    if (message.subType === 'USER_NOTIFIED') {
        Component = MBSystemMessageTemplates.UserNotified;
    }
    // TODO(fant): should this show the same content as USER_NOTIFIED?
    if (message.subType === 'USERS_TEXTED') {
        Component = MBSystemMessageTemplates.UserNotified;
    }
    if (['CARD_CREATED', 'CARD_UPDATED', 'CARD_DELETED', 'CARD_REMINDER_SENT'].includes(message.subType)) {
        Component = MBSystemMessageTemplates.Card;
    }
    // If we have not set a component, message subtype hasn't
    // been found among the types above. We therefore can't
    // render it. Here we ideally tell the backend what the
    // frontend can render so that the backend can return
    // fallback messages if the frontend doesn't have specific
    // implementation. Just rendering message.content should
    // be a reasonable fallback.
    if (!Component) {
        return null;
    }
    if (message.subType === 'CARD_CREATED' &&
        !!message.meta.cardId &&
        !channelType_1.MBChannelTypeUtil.isThread(channelSlug)) {
        onPress = function () {
            onOpenCard(message.meta.cardId);
        };
    }
    return (React.createElement(react_native_1.TouchableOpacity, { onPress: onPress, disabled: !onPress },
        React.createElement(components_1.MBHover, { paddingVertical: 1, onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); } },
            React.createElement(Component, { message: message, layout: layout, isDesktop: isDesktop, channelSlug: channelSlug, hovering: isHovered }),
            React.createElement(components_1.MBView, { paddingHorizontal: 0, style: styles_1.Styles.alignRight },
                React.createElement(messageReactionsAndSeenBy_1.MBMessageReactionsAndSeenBy, { message: message, channelSlug: channelSlug, align: "right", layout: layout, isDM: isDM, pickerBottomMargin: 0 })))));
};
exports.MBSystemMessage = MBSystemMessage;
exports.MBSystemMessage.displayName = 'MBSystemMessage';
