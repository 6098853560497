"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.i18n = void 0;
var lodash_1 = require("lodash");
var strings = require('@shared/assets/i18n/strings.json');
/*
  - Return a string template for a given key and params.
  - Templates are stored in shared/assets/i18n/strings.json.
  - Templates may be objects with keys: zero, one, many, max.
  - If an object template is provided, the count and max params are used to
    determine which key to use.
  - e.g. { zero: "no one", one: "1 person", many: "{count} people", max: "a lot
      of people" }
*/
function getTemplate(key, params) {
    if (params === void 0) { params = {}; }
    var tmpl = (0, lodash_1.get)(strings, key);
    var count = params['count'];
    var max = params['max'];
    if ((0, lodash_1.isNil)(count) || typeof tmpl !== 'object') {
        return tmpl;
    }
    if (!(0, lodash_1.isNil)(tmpl.zero) && count === 0) {
        return tmpl.zero;
    }
    if (!(0, lodash_1.isNil)(tmpl.one) && count === 1) {
        return tmpl.one;
    }
    if (!(0, lodash_1.isNil)(tmpl.max) && (0, lodash_1.isNumber)(max) && count >= max) {
        return tmpl.max;
    }
    return tmpl.many;
}
var i18n = function (key, params) {
    if (params === void 0) { params = {}; }
    var templateString = getTemplate(key, params);
    if (!templateString) {
        return "[STRING MISSING: \"".concat(key, "\"]");
    }
    return exports.i18n.populate(templateString, params);
};
exports.i18n = i18n;
exports.i18n.populate = function (templateString, params) {
    if (params === void 0) { params = {}; }
    // interpolate template string with given params
    // https://stackoverflow.com/a/37650602/9117995
    return templateString.replace(/{([A-Za-z.]*)}/g, function (_, key) { return getFormattedValue((0, lodash_1.get)(params, key)); });
};
function getFormattedValue(value) {
    if (typeof value === 'number') {
        return value.toLocaleString();
    }
    else {
        return String(value);
    }
}
