import * as React from 'react';
function SvgBed(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 29V12M9 26h22M9 22h22v7M14 19a2 2 0 100-4 2 2 0 000 4zM31 19H19v-4h10a2 2 0 012 2v2z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgBed;

