import * as React from 'react';
function SvgHandHeart(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 23h1.838a3 3 0 012.846 2.052L30 26l-11.468 3.529a4 4 0 01-2.965-.246L9 26v-7h1c1.105 0 3.906.438 5 2h3a4 4 0 014 4h-6M27.2 9.812a2.7 2.7 0 00-4.2.428 2.7 2.7 0 00-4.2-.428 2.8 2.8 0 000 3.919L23 18l4.2-4.269a2.8 2.8 0 000-3.919v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgHandHeart;

