"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBusinessEdit = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var component_1 = require("./component");
var MBBusinessEdit = function () {
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var data = (0, client_1.useQuery)(api_1.Queries.business.find, { variables: { businessId: businessId } }).data;
    var updateMutation = (0, client_1.useMutation)(api_1.Mutations.business.update)[0];
    var update = (0, react_1.useCallback)(function (_a) {
        var name = _a.name, imageUrl = _a.imageUrl;
        return updateMutation({ variables: { name: name, imageUrl: imageUrl, businessId: businessId } });
    }, [updateMutation, businessId]);
    if (!(data === null || data === void 0 ? void 0 : data.business) || !(data === null || data === void 0 ? void 0 : data.me)) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    return react_1.default.createElement(component_1.MBBusinessEdit, { business: data.business, showHeaderBackground: !isDesktop, onSubmit: update });
};
exports.MBBusinessEdit = MBBusinessEdit;
