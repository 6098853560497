"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = exports.MBRecurrencePickerSelectCount = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var rrule_1 = require("rrule");
var react_native_1 = require("react-native");
var i18n_1 = require("@shared/util/i18n");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var typography_1 = require("@shared/components/typography");
var MBRecurrencePickerSelectCount = function (_a) {
    var _b, _c;
    var rruleString = _a.rruleString, maxCount = _a.maxCount, onChange = _a.onChange;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var rule = (0, react_1.useMemo)(function () { return (!!rruleString ? rrule_1.RRule.fromString(rruleString) : undefined); }, [rruleString]);
    var _d = (0, react_1.useState)((_c = (_b = rule === null || rule === void 0 ? void 0 : rule.options.count) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : ''), value = _d[0], setValue = _d[1];
    var _e = (0, react_1.useState)(false), showError = _e[0], setShowError = _e[1];
    var handleChange = (0, react_1.useCallback)(function (updatedValue) {
        var cleanedValue = updatedValue.replace(/\D/g, '');
        setValue(cleanedValue);
        if (!cleanedValue) {
            return;
        }
        var count = Number(cleanedValue);
        if (count > maxCount) {
            setShowError(true);
        }
        else {
            setShowError(false);
            onChange(new rrule_1.RRule(__assign(__assign({}, rule.options), { until: undefined, count: count })));
        }
    }, [rule, value, onChange]);
    return (react_1.default.createElement(components_1.MBView, { padding: 2 },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true },
            react_1.default.createElement(components_1.MBIconV2, { name: "repeat", size: (0, components_1.grid)(4), color: showError ? colors.error.foreground : colors.accent }),
            react_1.default.createElement(components_1.MBTextInput, { noBorder: true, selectTextOnFocus: true, style: [
                    exports.styles.input,
                    { backgroundColor: showError ? colors.error.background : colors.backgroundInactive },
                ], keyboardType: "number-pad", autoFocus: true, value: value, onChangeText: handleChange }),
            react_1.default.createElement(typography_1.MBTextBody1, { color: colors.foregroundActive }, (0, i18n_1.i18n)('card.blocks.recurrenceInput.ends.afterXTimes.times'))),
        showError && (react_1.default.createElement(typography_1.MBTextBody2, { color: colors.error.foreground }, (0, i18n_1.i18n)('card.blocks.recurrenceInput.ends.afterXTimes.error', { maxCount: maxCount })))));
};
exports.MBRecurrencePickerSelectCount = MBRecurrencePickerSelectCount;
exports.styles = react_native_1.StyleSheet.create({
    input: {
        paddingVertical: (0, components_1.grid)(1),
        paddingHorizontal: (0, components_1.grid)(2),
        borderRadius: (0, components_1.grid)(1),
        width: (0, components_1.grid)(20),
        marginHorizontal: (0, components_1.grid)(1),
    },
});
