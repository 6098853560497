"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormTitle = useFormTitle;
exports.useFormSubmitButtonTitle = useFormSubmitButtonTitle;
exports.useShouldShowUpdateSubmitButton = useShouldShowUpdateSubmitButton;
var hooks_1 = require("@shared/scenes/cards/hooks");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var react_1 = require("react");
var hooks_3 = require("../../../../util/permissions/hooks");
var PermissionsContext_1 = require("../../../../util/permissions/PermissionsContext");
function useFormTitle(channelSlug, templateSlug, cardDefinition, cardId) {
    var card = (0, hooks_1.useCard)(cardId).card;
    var template = (0, hooks_1.useCardTemplate)({
        channelSlug: channelSlug,
        context: 'edit',
        templateSlug: templateSlug,
        cardDefinition: cardDefinition,
        card: card,
    }).template;
    return template === null || template === void 0 ? void 0 : template.title;
}
function useFormSubmitButtonTitle(channelSlug, cardDefinitionId) {
    var _a;
    var channel = (0, hooks_2.useGetChannelInfo)({ channelSlug: channelSlug });
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, cardDefinitionId).cardDefinition;
    var template = (0, hooks_1.useCardTemplate)({
        channelSlug: channelSlug,
        context: 'edit',
        cardDefinition: cardDefinition,
    }).template;
    if (!channel || !template) {
        return null;
    }
    return (_a = template.submitButtonTitle) !== null && _a !== void 0 ? _a : (0, i18n_1.i18n)('card.update.submit');
}
/**
 * Decides if the system should show the "Save" button on the update
 * card screen
 */
function useShouldShowUpdateSubmitButton(dirty, channelId) {
    var globalAbility = (0, react_1.useContext)(PermissionsContext_1.PermissionsContext);
    var ability = (0, hooks_3.getChannelAbility)(globalAbility, channelId);
    return Boolean(dirty) && ability.can('update', 'Card');
}
