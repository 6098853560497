"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStoreLink = exports.getStoreName = void 0;
var react_native_1 = require("react-native");
var i18n_1 = require("@shared/util/i18n");
var config_1 = require("@shared/config");
var getStoreName = function () {
    return react_native_1.Platform.OS === 'android' ? (0, i18n_1.i18n)('upgrade.playStore') : (0, i18n_1.i18n)('upgrade.appStore');
};
exports.getStoreName = getStoreName;
var getStoreLink = function () {
    return react_native_1.Platform.OS === 'android'
        ? "https://play.google.com/store/apps/details?id=".concat(config_1.Config.GOOGLE_PACKAGE_NAME)
        : "https://itunes.apple.com/us/app/".concat(config_1.Config.APPLE_APP_ID);
};
exports.getStoreLink = getStoreLink;
