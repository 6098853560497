"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBVideoPreviewModal = void 0;
var linking_1 = require("@shared/util/linking");
var MBVideoPreviewModal = function (_a) {
    var onClose = _a.onClose, videoUri = _a.videoUri, visible = _a.visible;
    if (visible) {
        linking_1.MBLinking.open(videoUri);
        onClose();
    }
    return null;
};
exports.MBVideoPreviewModal = MBVideoPreviewModal;
