import * as React from 'react';
function SvgHatChef(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 27h12M20 27v-4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M15.1 13.1c-.4-.1-.7-.1-1.1-.1-2.8 0-5 2.2-5 5s2.2 5 5 5v8h12v-8c2.8 0 5-2.2 5-5s-2.2-5-5-5c-.4 0-.7 0-1.1.1"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M15.4 16c-.3-.6-.4-1.3-.4-2 0-2.8 2.2-5 5-5s5 2.2 5 5c0 .7-.1 1.4-.4 2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgHatChef;

