import * as React from 'react';
function SvgMicrophoneOff(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={16} height={16} rx={8} fill="#fff" />
      <path
        d="M12.438 6.75a.586.586 0 00-.688.5 3.831 3.831 0 01-3.195 3.2L7.375 12.1V13h1.25v-1.313a4.973 4.973 0 004.313-4.25.62.62 0 00-.5-.687zM7.075 10.37a3.692 3.692 0 01-2.762-3.057.646.646 0 00-.75-.563.62.62 0 00-.5.688 4.984 4.984 0 003.269 3.973l.743-1.04zM7.884 9.238L10.5 5.576V5.5a2.5 2.5 0 00-5 0v1.25a2.5 2.5 0 002.384 2.488z"
        fill="#FF1E5F"
      />
      <path
        d="M4.875 13a.625.625 0 01-.508-.988l6.25-8.75a.625.625 0 011.017.726l-6.25 8.75a.624.624 0 01-.509.262z"
        fill="#FF1E5F"
      />
    </svg>
  );
}
export default SvgMicrophoneOff;

