"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSelectChannelImage = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var i18n_1 = require("@shared/util/i18n");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var styles_2 = require("./styles");
var react_redux_1 = require("react-redux");
var animation_1 = require("@shared/components/animation");
var react_native_1 = require("react-native");
var imagePicker_1 = require("./imagePicker");
var ANIMATION_DURATION = 150;
var AnimateImageSelector = function (_a) {
    var children = _a.children, isInvisible = _a.isInvisible, isCentered = _a.isCentered, centerDistance = _a.centerDistance;
    return isInvisible ? (react_1.default.createElement(animation_1.MBAnimation, { duration: ANIMATION_DURATION, opacity: { from: 1, to: 0 } }, children)) : (react_1.default.createElement(animation_1.MBAnimation, { duration: ANIMATION_DURATION, translateX: { from: 0, to: centerDistance }, disabled: !isCentered }, children));
};
var ChannelImagePicker = function (_a) {
    var emoji = _a.emoji, imageUrl = _a.imageUrl, imageUploadProgress = _a.imageUploadProgress, onSelectEmoji = _a.onSelectEmoji, onSelectImage = _a.onSelectImage, onRemoveImage = _a.onRemoveImage, onRemoveEmoji = _a.onRemoveEmoji;
    var _b = (0, react_1.useState)(false), isImageLoaded = _b[0], setIsImageLoaded = _b[1];
    var _c = (0, react_1.useState)(0), containerWidth = _c[0], setContainerWidth = _c[1];
    var isImageSelectedAndLoaded = !!imageUrl && (react_native_1.Platform.OS === 'web' ? isImageLoaded : true);
    var isEmojiSelected = !!emoji;
    var isSelected = isImageSelectedAndLoaded || isEmojiSelected;
    var pxToMiddleOfContainer = containerWidth / 2 - styles_2.SELECTED_CHANNEL_IMAGE_SIZE / 2;
    var openEmojiSelector = (0, react_1.useCallback)(function (pressedElement) {
        if (isSelected) {
            return;
        }
        onSelectEmoji(pressedElement);
    }, [isSelected, onSelectEmoji]);
    var openImageSelector = (0, react_1.useCallback)(function () {
        if (isSelected) {
            return;
        }
        setIsImageLoaded(false);
        onSelectImage();
    }, [isSelected, onSelectImage]);
    var handleSize = (0, react_1.useCallback)(function (_a) {
        var width = _a.width;
        return setContainerWidth(width);
    }, []);
    var handleImageLoaded = (0, react_1.useCallback)(function () { return setIsImageLoaded(true); }, []);
    return (react_1.default.createElement(components_1.MBView, { row: true, flex: true, fullWidth: true, centerVertically: true, style: styles_2.Styles.channelImageSelectorsContainer, onSize: handleSize },
        react_1.default.createElement(AnimateImageSelector, { isCentered: isImageSelectedAndLoaded, isInvisible: isEmojiSelected, centerDistance: pxToMiddleOfContainer },
            react_1.default.createElement(imagePicker_1.MBImagePicker, { imageUrl: imageUrl, text: (0, i18n_1.i18n)('channel.create.details.addImage'), placeholderIconName: 'image-portrait', imageUploadProgress: imageUploadProgress, onPress: openImageSelector, onRemove: onRemoveImage, onImageLoaded: handleImageLoaded })),
        !isSelected && (react_1.default.createElement(components_1.MBTextOverline, { color: styles_1.Colors.v2.gray50 }, (0, i18n_1.i18n)('channel.create.details.eitherImageType'))),
        react_1.default.createElement(AnimateImageSelector, { isCentered: isEmojiSelected, isInvisible: isImageSelectedAndLoaded, centerDistance: -pxToMiddleOfContainer },
            react_1.default.createElement(imagePicker_1.MBImagePicker, { emoji: emoji, text: (0, i18n_1.i18n)('channel.create.details.addSmiley'), placeholderIconName: 'face-smile', onPress: openEmojiSelector, onRemove: onRemoveEmoji }))));
};
var MBSelectChannelImage = function (_a) {
    var imageUrl = _a.imageUrl, emoji = _a.emoji, uploadProgress = _a.uploadProgress, nativeEmojiPickerVisible = _a.nativeEmojiPickerVisible, onSelectImage = _a.onSelectImage, onCancelUpload = _a.onCancelUpload, onRemoveImage = _a.onRemoveImage, onRemoveEmoji = _a.onRemoveEmoji, onImageDropped = _a.onImageDropped, onSelectEmoji = _a.onSelectEmoji, onPressEmojiSelector = _a.onPressEmojiSelector, onHideNativeEmojiPicker = _a.onHideNativeEmojiPicker;
    var handleFilesDropped = (0, react_1.useCallback)(
    // TODO(fant): here we blindly assume that the dropped file is an image...
    function (_a) {
        var image = _a[0];
        return onImageDropped(image);
    }, [onImageDropped]);
    var handleSelectEmoji = (0, react_1.useCallback)(function (selectedEmoji) {
        onSelectEmoji(selectedEmoji);
        onHideNativeEmojiPicker();
    }, [onHideNativeEmojiPicker, onSelectEmoji]);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var isImageUploading = uploadProgress !== undefined;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { style: [{ borderRadius: isDesktop ? (0, components_1.grid)(1) : 0 }, styles_2.Styles.channelImageSelectorWrapper] },
            react_1.default.createElement(components_1.MBFileDropListener, { fill: true, row: true, center: true, onFilesDropped: handleFilesDropped, style: styles_2.Styles.imageSelectorPadding },
                react_1.default.createElement(ChannelImagePicker, { emoji: emoji, imageUrl: imageUrl, imageUploadProgress: uploadProgress, onSelectEmoji: onPressEmojiSelector, onSelectImage: onSelectImage, onRemoveImage: isImageUploading ? onCancelUpload : onRemoveImage, onRemoveEmoji: onRemoveEmoji }))),
        react_1.default.createElement(components_1.MBModalFromBottom, { visible: nativeEmojiPickerVisible, padding: 0, onClose: onHideNativeEmojiPicker },
            react_1.default.createElement(components_1.MBModalFromBottomHeader, { title: (0, i18n_1.i18n)('channel.options.selectEmoji'), rightButtonText: (0, i18n_1.i18n)('common.remove'), rightButtonColor: styles_1.Colors.v2.red, rightButtonOnPress: !!emoji ? onHideNativeEmojiPicker : undefined }),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1, style: { height: 400 } },
                react_1.default.createElement(components_1.MBEmojiPicker, { onSelect: handleSelectEmoji })))));
};
exports.MBSelectChannelImage = MBSelectChannelImage;
