"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.greyscaleImageUri = exports.resizeImageUri = exports.downloadFullSizeImageUri = exports.jsonImageUri = exports.fullSizeImageUri = exports.containedImageUrl = exports.buildImgixUrl = exports.mergeImgixParams = void 0;
/**
 * Oh the wonderful world of images...
 *
 * We have decided as a team to create the image post-processing abstraction layer in the
 * frontend and have it be purposefully built upon imgix. If we decide to move away from
 * imgix OR move our abstraction to the BE; we will refactor this module and the components
 * that depend on it.
 *
 * Assumptions:
 * 1) Images can be retrieved via imgix
 * 2) Images reside on S3 as an origin to imgix
 * 3) Images can be resized on-the-fly via imgix
 * 4) Images can have filters applied (i.e. greyscale) on-the-fly via imgix
 * 5) Clients should only request images having a size no greater than can be display on that user agent
 */
var react_native_1 = require("react-native");
var s3Staginghost = /coast-staging-uploads.s3.us-(west|east)-[12].amazonaws.com/;
var s3Prodhost = /coast-production-files.s3.us-(west|east)-[12].amazonaws.com/;
var imgixHost = /imgix.net/;
var asImgix = function (uri) {
    return uri
        ? uri
            .replace(s3Staginghost, 'coast-staging.imgix.net')
            .replace(s3Prodhost, 'coast-production.imgix.net')
        : uri;
};
// merges any existing query params with passed params
// only accepts imgix url.
var mergeImgixParams = function (uri, params) {
    if (uri && imgixHost.test(uri)) {
        var _a = uri.split('?'), base = _a[0], args = _a[1];
        var a_1 = args ? args.split('&') : [];
        Object.keys(params).forEach(function (k) { return a_1.push(params[k] ? "".concat(k, "=").concat(params[k]) : k); });
        if (a_1.length) {
            return "".concat(base, "?").concat(a_1.join('&'));
        }
        return base;
    }
    return uri;
};
exports.mergeImgixParams = mergeImgixParams;
// builds imgix url with any params and accepts s3 or imgix url
// light wrapper for mergeImgixParams above
var buildImgixUrl = function (url, params) {
    return (0, exports.mergeImgixParams)(asImgix(url), params);
};
exports.buildImgixUrl = buildImgixUrl;
// helper to get an image that proportionately fits inside `dims`,
// adjusted for dpr and compressed. can take s3 or imgix url.
var containedImageUrl = function (url, dims) {
    return (0, exports.buildImgixUrl)(url, {
        w: dims[0],
        h: dims[1],
        dpr: react_native_1.PixelRatio.get(),
        fit: 'max',
        auto: 'compress',
    });
};
exports.containedImageUrl = containedImageUrl;
// given s3 url, returns imgix for full size image
var fullSizeImageUri = function (uri) {
    return (0, exports.buildImgixUrl)(uri, { dpr: react_native_1.PixelRatio.get(), fit: 'max', auto: 'compress' });
};
exports.fullSizeImageUri = fullSizeImageUri;
var jsonImageUri = function (uri) {
    return imgixHost.test(asImgix(uri)) ? (0, exports.buildImgixUrl)(uri, { fm: 'json' }) : null;
};
exports.jsonImageUri = jsonImageUri;
// given s3 url, returns imgix download for full size image
var downloadFullSizeImageUri = function (uri) {
    return "".concat((0, exports.mergeImgixParams)((0, exports.fullSizeImageUri)(uri), { dl: null }));
};
exports.downloadFullSizeImageUri = downloadFullSizeImageUri;
// helper to get an image sized to width/height.
// accepts s3 or imgix url.
// adjusted for dpr.
var resizeImageUri = function (uri, width, height, minWidth, minHeight) {
    var dpr = react_native_1.PixelRatio.get();
    var params = {
        dpr: dpr,
    };
    if (width) {
        params.w = width;
    }
    if (height) {
        params.h = height;
    }
    if (minWidth) {
        params['min-w'] = minWidth;
    }
    if (minHeight) {
        params['min-h'] = minHeight;
    }
    return (0, exports.mergeImgixParams)(asImgix(uri), params);
};
exports.resizeImageUri = resizeImageUri;
var greyscaleImageUri = function (uri) {
    return (0, exports.mergeImgixParams)(asImgix(uri), { sat: '-100' });
};
exports.greyscaleImageUri = greyscaleImageUri;
