"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.yearlyPrice = exports.monthlyPrice = void 0;
var monthlyPrice = function (price) {
    return price.interval === 'month';
};
exports.monthlyPrice = monthlyPrice;
var yearlyPrice = function (price) {
    return price.interval === 'year';
};
exports.yearlyPrice = yearlyPrice;
