"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAttachmentList = void 0;
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var row_1 = require("./row");
var MBAttachmentList = /** @class */ (function (_super) {
    __extends(MBAttachmentList, _super);
    function MBAttachmentList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MBAttachmentList.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.attachments.length !== prevProps.attachments.length) {
            if (!!react_native_1.LayoutAnimation) {
                react_native_1.LayoutAnimation.configureNext(react_native_1.LayoutAnimation.Presets.easeInEaseOut);
            }
        }
    };
    MBAttachmentList.prototype.render = function () {
        var _this = this;
        if (!this.props.attachments.length) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(components_1.MBScrollView, { horizontal: true, nativeShouldPersistTaps: true }, this.props.attachments
                .map(function (attachment, index) { return (React.createElement(components_1.MBView, { key: attachment.fileKey, padding: "tiny", paddingRight: index === 0 ? 'none' : undefined },
                React.createElement(animation_1.MBAnimationBounceIn, null,
                    React.createElement(row_1.MBAttachmentRow, { attachment: attachment, onRemove: _this.props.onRemove })))); })
                // Reverse the attachments to show the latest one first.
                // The reason we do this after ".map" is because reverse
                // mutates the original array. We don't want that since
                // this would mutate the redux store where the attachments
                // are stored.
                .reverse()),
            React.createElement(components_1.MBSeparator, { color: styles_1.Colors.v2.gray10 })));
    };
    return MBAttachmentList;
}(React.PureComponent));
exports.MBAttachmentList = MBAttachmentList;
