import * as React from 'react';
function SvgPersonOfficer(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 27v4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M16.8 21.907l-5.825 3.51A2 2 0 0010 27.132V31h20v-3.868a2 2 0 00-.971-1.715l-5.829-3.51"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M26 13.544V15c0 5-2.686 8-6 8s-6-3-6-8v-1.456"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 15c3.866 0 7-1.343 7-3s-3.134-3-7-3-7 1.343-7 3 3.134 3 7 3z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M20 13a1 1 0 100-2 1 1 0 000 2z" fill={props.color} />
    </svg>
  );
}
export default SvgPersonOfficer;

