import * as React from 'react';
function SvgLightBulb(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 17a8 8 0 00-16 0 7.997 7.997 0 005 7.413V27h6v-2.587A7.997 7.997 0 0028 17zM17 31h6"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M16 17l2 2 2-2 2 2 2-2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgLightBulb;

