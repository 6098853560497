import * as React from 'react';
function SvgCheckBox(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 9H12a3 3 0 00-3 3v16a3 3 0 003 3h16a3 3 0 003-3V12a3 3 0 00-3-3zm-1.293 6.707l-9 9a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L17 22.586l8.293-8.293a1 1 0 111.414 1.414z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgCheckBox;

