import * as React from 'react';
function SvgGrain(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 31v-4M13 19h1a6 6 0 016 6v2h-1a6 6 0 01-6-6v-2zM21 27h-1v-2a6 6 0 016-6h1v2a6 6 0 01-6 6v0zM18 14a5.664 5.664 0 012-4 5.663 5.663 0 012 4 5.664 5.664 0 01-2 4 5.663 5.663 0 01-2-4v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgGrain;

