exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css"), "");
// Module
exports.push([module.id, "._1OvWx6jzXkp-hlecraP83d {\n  background-color: #FFF;\n  position: relative;\n}\n\n.kpLfckespNqrcvbZMFRMK {\n  background-color: #E9E9E9;\n}\n\n.CF702byNMbGgkG7gGUTc- {\n  height: 67px;\n  -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.21);\n          box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.21);\n  z-index: 1; /* make sure it's over the rest */\n}\n._2iUKTbj31QksPqTWg4keyd {\n  height: 48px;\n  border-bottom: 1px solid #E9E9E9;\n}\n\n._3ONbtrFklcCfSHUIJ5B2Ib {\n  height: 67px;\n  border-bottom: 1px solid #E9E9E9;\n}\n\n\n/* Padding classes */\n._2JrLvvC-TovS4Xt6rTVNLM {\n  padding-left: 16px;\n  padding-right: 16px;\n}\n\n._2z9_9JuGbJD2leK--_yrlL {\n  padding-left: 8px;\n  padding-right: 8px;\n}\n\n._2himwXQkfxrm3AO539BLht {\n  background-color: rgba(0, 0, 0, 0.5);\n  border-color: transparent;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_1OvWx6jzXkp-hlecraP83d " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexRowCenter"] + "",
	"gray": "kpLfckespNqrcvbZMFRMK",
	"standard": "CF702byNMbGgkG7gGUTc- _1OvWx6jzXkp-hlecraP83d " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexRowCenter"] + "",
	"slim": "_2iUKTbj31QksPqTWg4keyd _1OvWx6jzXkp-hlecraP83d " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexRowCenter"] + "",
	"large": "_3ONbtrFklcCfSHUIJ5B2Ib _1OvWx6jzXkp-hlecraP83d " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-2!../../../../node_modules/postcss-loader/src/index.js??postcss!../../composes.css").locals["flexRowCenter"] + "",
	"fullPadding": "_2JrLvvC-TovS4Xt6rTVNLM",
	"narrowPadding": "_2z9_9JuGbJD2leK--_yrlL",
	"overlaid": "_2himwXQkfxrm3AO539BLht"
};