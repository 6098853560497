import * as React from 'react';
function SvgCircleAim(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 24a4 4 0 100-8 4 4 0 000 8zM15 29.8a11 11 0 010-19.6M25 10.2a11 11 0 010 19.6"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgCircleAim;

