"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAgendaHeader = exports.AGENDA_CALENDAR_HEADER_HEIGHT = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var react_redux_1 = require("react-redux");
var i18n_1 = require("@shared/util/i18n");
var react_native_1 = require("react-native");
exports.AGENDA_CALENDAR_HEADER_HEIGHT = 44;
var MBAgendaHeader = function (_a) {
    var date = _a.date, onPress = _a.onPress, onTodayPress = _a.onTodayPress, onNextPress = _a.onNextPress, onPrevPress = _a.onPrevPress, showArrows = _a.showArrows, panHandlers = _a.panHandlers;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    return (react_1.default.createElement(react_native_1.View, __assign({}, panHandlers),
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
            react_1.default.createElement(components_1.MBView, { centerVertically: true, style: {
                    height: exports.AGENDA_CALENDAR_HEADER_HEIGHT,
                    backgroundColor: isDesktop && !showArrows ? 'transparent' : colors.background,
                }, row: true, fullWidth: true, paddingRight: 6 },
                react_1.default.createElement(components_1.MBView, { paddingLeft: 2 },
                    react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('card.calendar.controls.today'), bold: false, theme: "outline", size: "small", onPress: onTodayPress, borderColor: styles_1.Colors.v2.gray10, style: { borderWidth: 1, backgroundColor: colors.input.backgroundActive } })),
                react_1.default.createElement(components_1.MBView, null, isDesktop && showArrows && (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingLeft: 2 },
                    react_1.default.createElement(components_1.MBIconButton, { source: require('@shared/assets/icons/v2/arrow-point-left.png'), color: colors.foregroundActive, backgroundColor: "transparent", onPress: onPrevPress, size: "small" }),
                    react_1.default.createElement(components_1.MBIconButton, { source: require('@shared/assets/icons/v2/arrow-point-right.png'), color: colors.foregroundActive, backgroundColor: "transparent", onPress: onNextPress, size: "small" })))),
                react_1.default.createElement(components_1.MBView, { paddingLeft: 2 },
                    react_1.default.createElement(components_1.MBTextBody1Bold, { align: "center", allowFontScaling: false }, date))))));
};
exports.MBAgendaHeader = MBAgendaHeader;
