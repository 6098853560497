"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.operators = void 0;
var i18n_1 = require("@shared/util/i18n");
exports.operators = [
    {
        id: 'EQ',
        label: (0, i18n_1.i18n)('card.actions.filters.fields.number.operator.equalTo'),
        icon: 'equal-sign',
    },
    {
        id: 'LT',
        label: (0, i18n_1.i18n)('card.actions.filters.fields.number.operator.lessThan'),
        icon: 'less-than-sign',
    },
    {
        id: 'LTE',
        label: (0, i18n_1.i18n)('card.actions.filters.fields.number.operator.lessThanEqualTo'),
        icon: 'less-than-equal-sign',
    },
    {
        id: 'GT',
        label: (0, i18n_1.i18n)('card.actions.filters.fields.number.operator.greaterThan'),
        icon: 'greater-than-sign',
    },
    {
        id: 'GTE',
        label: (0, i18n_1.i18n)('card.actions.filters.fields.number.operator.greaterThanEqualTo'),
        icon: 'greater-than-equal-sign',
    },
    {
        id: 'empty',
        label: (0, i18n_1.i18n)('card.actions.filters.fields.common.operator.empty'),
    },
];
