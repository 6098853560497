/* @flow */
// $FlowFixMe
import { Config } from '@shared/config';

export function tryParse(text: string) {
  try {
    return JSON.parse(text);
  } catch (err) {
    return {
      error: text,
    };
  }
}

export function checkStatus(response: Object) {
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }
  return response.text().then(dataText => {
    const data = tryParse(dataText);
    const error = new Error(data.error || response.statusText);
    // $FlowFixMe
    error.status = response.status;
    // $FlowFixMe
    error.response = data;
    return Promise.reject(error);
  });
}

export type FetchProps = {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  headers?: ObjectMap<string>,
  data?: Object,
};

export type Platform = 'ANDROID' | 'IOS' | 'WEB';

export default function makeAPIFetch(platform: Platform) {
  return function apiFetch(path: string, { method, headers, data }: FetchProps) {
    const url = `${Config.API_URL}/${path}`;
    return fetch(url, {
      method,
      credentials: 'same-origin',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        'x-ts-platform': platform,
      },
      body: data ? JSON.stringify(data) : undefined,
    }).then(checkStatus);
  };
}
