"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagEditField = void 0;
var react_1 = __importStar(require("react"));
var TagField_1 = require("@shared/data/entity/fields/TagField");
var styles_1 = require("@shared/styles");
var lodash_1 = __importStar(require("lodash"));
var modal_1 = require("@shared/components/multiSelect/modal");
var EditFieldContainer_1 = require("./EditFieldContainer");
var components_1 = require("@shared/components");
var Pill_1 = require("@shared/components/pills/Pill");
var i18n_1 = require("@shared/util/i18n");
var TagEditField = function (_a) {
    var _b;
    var field = _a.field, component = _a.component, updateField = _a.updateField;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var options = (0, react_1.useMemo)(function () { var _a, _b; return (_b = (_a = component.options) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
        var archived = _a.archived;
        return !archived;
    })) !== null && _b !== void 0 ? _b : []; }, [component]);
    var labelExtractor = (0, react_1.useCallback)(function (value) { var _a, _b; return (_b = (_a = options.find(function (o) { return o.value === value; })) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : ''; }, [options]);
    var selectedTags = (0, react_1.useMemo)(function () { var _a, _b; return (_b = (_a = field === null || field === void 0 ? void 0 : field.jsonBlob) === null || _a === void 0 ? void 0 : _a.map(function (id) { return options.find(function (item) { return item.value === id; }); })) !== null && _b !== void 0 ? _b : []; }, [field === null || field === void 0 ? void 0 : field.jsonBlob, options]);
    var optionSlugs = (0, react_1.useMemo)(function () { return options.map(function (opt) { return opt.value; }); }, [options]);
    var archivedValues = (0, react_1.useMemo)(function () { return optionSlugs.filter(function (value) { var _a; return (_a = options.find(function (o) { return o.value === value; })) === null || _a === void 0 ? void 0 : _a.archived; }); }, [optionSlugs, options]);
    var onChange = (0, react_1.useCallback)(function (selection) {
        updateField(component.id, new TagField_1.TagField(selection, component.id, options));
    }, [component.id, options, updateField]);
    var renderItem = (0, react_1.useCallback)(function (value) {
        var option = options.find(function (o) { return o.value === value; });
        if (lodash_1.default.isNil(option)) {
            return react_1.default.createElement(react_1.default.Fragment, null);
        }
        return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, flex: true },
            react_1.default.createElement(Pill_1.Pill, { key: option === null || option === void 0 ? void 0 : option.value, backgroundColor: option === null || option === void 0 ? void 0 : option.backgroundColor, borderRadius: (0, components_1.grid)(2), sections: [option === null || option === void 0 ? void 0 : option.label], maxWidth: 'none', noBorder: true })));
    }, [options]);
    var match = (0, react_1.useCallback)(function (value, query, exact) {
        var label = labelExtractor(value);
        if (lodash_1.default.isNil(label)) {
            return false;
        }
        if (exact) {
            return label.toLowerCase() === (query === null || query === void 0 ? void 0 : query.toLowerCase());
        }
        return (0, lodash_1.includes)(label.toLowerCase(), query === null || query === void 0 ? void 0 : query.toLowerCase());
    }, [labelExtractor]);
    var clear = (0, react_1.useCallback)(function () {
        onChange([]);
    }, [onChange]);
    return (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: (_b = component.label) !== null && _b !== void 0 ? _b : '', onChange: onChange, selectAll: true, selectAllLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.selectAllLabel'), renderItem: renderItem, values: optionSlugs, selectedValues: selectedTags === null || selectedTags === void 0 ? void 0 : selectedTags.map(function (t) { return t.value; }), labelExtractor: labelExtractor, match: match, archivedValues: archivedValues, itemLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabel'), itemLabelPlural: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabelPlural'), errorLabel: (0, i18n_1.i18n)('errors.tag.enterName'), itemsEditable: false },
        react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: selectedTags.length > 0, onClear: selectedTags.length > 0 ? clear : undefined },
            react_1.default.createElement(components_1.MBView, { row: true, wrap: true, style: { flexWrap: 'wrap' }, paddingVertical: 0.5 },
                selectedTags.length > 0 &&
                    selectedTags.map(function (tag) { return (react_1.default.createElement(Pill_1.Pill, { key: tag.value, backgroundColor: tag.backgroundColor, borderRadius: (0, components_1.grid)(2), sections: [tag.label], maxWidth: 'none', noBorder: true })); }),
                selectedTags.length === 0 && (react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundInactive }, component.placeholder))))));
};
exports.TagEditField = TagEditField;
