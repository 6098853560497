import * as React from 'react';
function SvgSwapSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.938 13h18.321"
        stroke={props.color}
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M23 13l-9.753 10M23 13L13.248 3M3 13l5.246 5.379M3 13l5.246-5.379"
        stroke={props.color}
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgSwapSmall;

