"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
exports.default = default_1;
var Actions = __importStar(require("./actions"));
exports.initialState = {
    promptFromChannelSlug: {},
    shouldNotifyFromChannelSlug: {},
    lastAnsweredAtFromChannelSlug: {},
};
function default_1(state, action) {
    var _a, _b, _c, _d;
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case Actions.UPDATE_SHOULD_NOTIFY_PENDING_USERS: {
            var _e = action.value, channelSlug = _e.channelSlug, notify = _e.notify;
            return __assign(__assign({}, state), { shouldNotifyFromChannelSlug: __assign(__assign({}, state.shouldNotifyFromChannelSlug), (_a = {}, _a[channelSlug] = notify, _a)), lastAnsweredAtFromChannelSlug: __assign(__assign({}, state.lastAnsweredAtFromChannelSlug), (_b = {}, _b[channelSlug] = new Date().toISOString(), _b)), promptFromChannelSlug: __assign(__assign({}, state.promptFromChannelSlug), (_c = {}, _c[channelSlug] = undefined, _c)) });
        }
        case Actions.SET_PROMPT_TO_NOTIFY_PENDING: {
            var _f = action.value, channelSlug = _f.channelSlug, messageId = _f.messageId, users = _f.users;
            return __assign(__assign({}, state), { promptFromChannelSlug: __assign(__assign({}, state.promptFromChannelSlug), (_d = {}, _d[channelSlug] = { messageId: messageId, users: users }, _d)) });
        }
        default:
            return state;
    }
}
