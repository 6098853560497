"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOpenCardOptionsMenu = useOpenCardOptionsMenu;
var cardSelection_1 = require("@shared/api/providers/cardSelection");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var render_1 = require("@shared/components/blocks/contexts/render");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var hooks_1 = require("@shared/scenes/cards/form/hooks");
var actionSheet_1 = require("@shared/util/actionSheet");
var analytics_1 = require("@shared/util/analytics");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var PrintContext_1 = require("@shared/util/print/PrintContext");
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var useRemoveCardWithConfirmation_1 = require("./useRemoveCardWithConfirmation");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
function useOpenCardOptionsMenu(card, options) {
    var _a, _b, _c, _d, _e;
    var _f = options.showEdit, showEdit = _f === void 0 ? true : _f, onOpen = options.onOpen, onClose = options.onClose, onRemoved = options.onRemoved, onShare = options.onShare, onDownload = options.onDownload, templateSlug = options.templateSlug;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var duplicateCard = (0, hooks_1.useDuplicateCard)();
    var removeCardWithConfirmation = (0, useRemoveCardWithConfirmation_1.useRemoveCardWithConfirmation)(card, onRemoved);
    var _g = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext), canCreateCard = _g.canCreateCard, canUpdateCard = _g.canUpdateCard, canDeleteCard = _g.canDeleteCard;
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var printContext = (0, react_1.useContext)(PrintContext_1.PrintContext);
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var selectionState = (0, cardSelection_1.useCardSelectionState)();
    var _h = (0, cardSelection_1.useCardSelectionActions)(), setSelectionActive = _h.setActive, addCard = _h.addCard;
    var isCollectionView = renderContext && !['form', 'detail'].includes(renderContext);
    var cardSelectionEnabled = selectionState && isCollectionView && isDesktop;
    var isLowCodeCard = (0, lodash_1.isNil)(card.workflowTemplateId);
    var workflowTemplate = (0, hooks_2.useWorkflowTemplate)(card.workflowTemplateId).workflowTemplate;
    var isAuditTrailEnabled = (workflowTemplate || {}).isAuditTrailEnabled;
    var scheduledBatchUpdateEnabled = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.SCHEDULED_BATCH_UPDATE);
    var recurringEntitiesScheduleId = (_a = card.fields) === null || _a === void 0 ? void 0 : _a.entityBatch;
    var showBulkUpdateItem = scheduledBatchUpdateEnabled &&
        !(0, lodash_1.isNil)(recurringEntitiesScheduleId) &&
        !(0, lodash_1.isNil)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) &&
        !(0, lodash_1.isNil)(templateSlug);
    var navigateToCardUpdate = (0, useNavigator_1.useNavigateTo)('CardUpdate', {
        cardId: card.id,
        channelSlug: (_b = channel === null || channel === void 0 ? void 0 : channel.slug) !== null && _b !== void 0 ? _b : '',
    });
    var navigateToBulkUpdate = (0, useNavigator_1.useNavigateTo)('CardBulkUpdate', {
        cardId: card.id,
        channelSlug: (_c = channel === null || channel === void 0 ? void 0 : channel.slug) !== null && _c !== void 0 ? _c : '',
        workflowTemplateId: (_d = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) !== null && _d !== void 0 ? _d : '',
        viewTemplateId: templateSlug !== null && templateSlug !== void 0 ? templateSlug : '',
        recurringEntitiesScheduleId: recurringEntitiesScheduleId !== null && recurringEntitiesScheduleId !== void 0 ? recurringEntitiesScheduleId : '',
        allOrUpcoming: 'all',
    });
    var navigateToCardHistory = (0, useNavigator_1.useNavigateTo)('CardHistory', {
        cardId: card.id,
        channelSlug: (_e = channel === null || channel === void 0 ? void 0 : channel.slug) !== null && _e !== void 0 ? _e : '',
    });
    return (0, react_1.useCallback)(function (anchor) {
        onOpen === null || onOpen === void 0 ? void 0 : onOpen();
        return actionSheet_1.MBActionSheet.show({
            parent: anchor,
            webVerticalAlignment: 'below',
            webHorizontalAlignment: 'right',
            onClose: onClose,
            options: [
                {
                    title: (0, i18n_1.i18n)('common.edit'),
                    hidden: !showEdit || !canUpdateCard.enabled,
                    onPress: navigateToCardUpdate,
                },
                {
                    // TODO: [sc-42885] i18n - waiting on design to see where this menu
                    // item will live
                    title: 'Bulk Update',
                    hidden: !showBulkUpdateItem,
                    onPress: navigateToBulkUpdate,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.duplicate'),
                    hidden: !canCreateCard.enabled,
                    onPress: function () { return duplicateCard(card.id); },
                },
                {
                    title: (0, i18n_1.i18n)('common.share'),
                    onPress: onShare,
                },
                {
                    title: (0, i18n_1.i18n)('common.download'),
                    hidden: (0, lodash_1.isNil)(onDownload) || isLowCodeCard,
                    onPress: onDownload,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.cardHistory'),
                    hidden: !isAuditTrailEnabled,
                    onPress: navigateToCardHistory,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.print'),
                    hidden: !printContext,
                    onPress: function () {
                        analytics_1.MBAnalytics.track('PrintCard', { cardId: card.id, channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug });
                        printContext.onPrint();
                    },
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.selectMultiple'),
                    hidden: !cardSelectionEnabled,
                    onPress: function () {
                        addCard(card);
                        setSelectionActive(true);
                    },
                },
                {
                    title: (0, i18n_1.i18n)('common.delete'),
                    destructive: true,
                    hidden: !canDeleteCard.enabled,
                    onPress: function () { return removeCardWithConfirmation(anchor); },
                },
            ],
        });
    }, [
        onOpen,
        onClose,
        showEdit,
        canUpdateCard.enabled,
        navigateToCardUpdate,
        navigateToBulkUpdate,
        canCreateCard.enabled,
        onShare,
        onDownload,
        isLowCodeCard,
        isAuditTrailEnabled,
        navigateToCardHistory,
        printContext,
        cardSelectionEnabled,
        canDeleteCard.enabled,
        duplicateCard,
        card,
        channel === null || channel === void 0 ? void 0 : channel.slug,
        addCard,
        setSelectionActive,
        removeCardWithConfirmation,
        showBulkUpdateItem,
    ]);
}
