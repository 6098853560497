import * as React from 'react';
function SvgTimer(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 28c6.49 0 11.75-.784 11.75-1.75s-5.26-1.75-11.75-1.75S2 25.284 2 26.25 7.26 28 13.75 28z"
        fill="#E9E9E9"
      />
      <path
        d="M20.545 8.396a.818.818 0 01-.578-.238.808.808 0 010-1.149l2.182-2.166a.822.822 0 011.156 0c.32.317.32.831 0 1.148l-2.181 2.167a.818.818 0 01-.579.238z"
        fill="#335262"
      />
      <path
        d="M14 24.917c5.422 0 9.818-4.365 9.818-9.75S19.422 5.417 14 5.417c-5.423 0-9.818 4.365-9.818 9.75s4.395 9.75 9.818 9.75z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.09 15.166C3.09 21.14 7.986 26 14 26c6.015 0 10.909-4.86 10.909-10.834 0-5.973-4.894-10.833-10.91-10.833-6.014 0-10.908 4.86-10.908 10.833zm2.183 0C5.273 10.388 9.188 6.5 14 6.5s8.727 3.888 8.727 8.666c0 4.78-3.915 8.667-8.727 8.667s-8.727-3.888-8.727-8.667z"
        fill="#335262"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.273 2.708c0 1.494 1.223 2.709 2.727 2.709 1.504 0 2.727-1.215 2.727-2.709C16.727 1.215 15.504 0 14 0c-1.504 0-2.727 1.215-2.727 2.708zm1.09 0c0-.896.735-1.625 1.637-1.625.903 0 1.637.73 1.637 1.625 0 .896-.734 1.625-1.637 1.625a1.632 1.632 0 01-1.636-1.625z"
        fill="#335262"
      />
      <path
        d="M13.229 14.4c.426-.423 5.4-3.83 5.4-3.83s-3.432 4.94-3.858 5.362a1.096 1.096 0 01-1.542 0 1.078 1.078 0 010-1.532z"
        fill="#EF4853"
      />
      <path
        d="M14 9.75a.543.543 0 01-.545-.542V8.125c0-.3.244-.542.545-.542.302 0 .546.243.546.542v1.083c0 .3-.244.542-.546.542zM19.455 15.167c0-.3.244-.542.545-.542h1.091c.301 0 .545.242.545.542 0 .3-.244.541-.545.541H20a.543.543 0 01-.545-.541zM14 20.583c.302 0 .546.243.546.542v1.083c0 .3-.244.542-.546.542a.543.543 0 01-.545-.542v-1.083c0-.3.244-.542.545-.542zM8.546 15.167c0 .3-.245.541-.546.541H6.91a.543.543 0 01-.546-.541c0-.3.244-.542.545-.542H8c.301 0 .546.242.546.542zM23.912 8.07l-3.857-3.83a.539.539 0 010-.765l1.157-1.15a.548.548 0 01.771 0l3.857 3.83a.539.539 0 010 .767L24.683 8.07a.548.548 0 01-.771 0z"
        fill="#919191"
      />
      <path
        d="M7.455 8.396a.818.818 0 01-.579-.238L4.694 5.991a.808.808 0 010-1.148.822.822 0 011.157 0l2.182 2.166c.32.317.32.832 0 1.15a.818.818 0 01-.578.237z"
        fill="#335262"
      />
      <path
        d="M4.088 8.07l3.857-3.83a.539.539 0 000-.765l-1.157-1.15a.548.548 0 00-.771 0L2.16 6.156a.539.539 0 000 .767L3.317 8.07a.548.548 0 00.771 0z"
        fill="#919191"
      />
    </svg>
  );
}
export default SvgTimer;

