import * as React from 'react';
function SvgAvatarSearch(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.071 26.071L31 31M19.992 18.737L22.2 20.4c.504.378.8.97.8 1.6v1h-8v-1c0-.63.296-1.222.8-1.6l2.208-1.663"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M19 19a2 2 0 01-2-2v-1a2 2 0 114 0v1a2 2 0 01-2 2z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M19 29c5.523 0 10-4.477 10-10S24.523 9 19 9 9 13.477 9 19s4.477 10 10 10z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgAvatarSearch;

