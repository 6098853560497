import * as React from 'react';
function SvgShip(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 90 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.395 13.372V5H43.14v8.372"
        stroke="#DAF6F0"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M18.869 34.484v-13.53h40.592l6.765 13.53"
        stroke="#DAF6F0"
        strokeWidth={10}
        strokeMiterlimit={10}
      />
      <path
        d="M18.869 56.78l-6.765-20.296h67.653L69.609 56.78M5 63.233c0 7.705 6.513 13.953 14.546 13.953 5.52 0 10.263-2.984 12.727-7.329 2.463 4.345 7.207 7.329 12.727 7.329 5.52 0 10.264-2.984 12.727-7.329 2.464 4.345 7.208 7.329 12.728 7.329C78.487 77.186 85 70.938 85 63.233"
        stroke="#DAF6F0"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgShip;

