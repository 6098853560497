"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSplitButton = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var _1 = require(".");
var styles_1 = require("./styles");
var MBSplitButton = function (_a) {
    var onRightIconPress = _a.onRightIconPress, rightIconStyle = _a.rightIconStyle, rightIcon = _a.rightIcon, rightIconSize = _a.rightIconSize, _b = _a.theme, theme = _b === void 0 ? 'normal' : _b, _c = _a.size, size = _c === void 0 ? 'normal' : _c, titleColorOverride = _a.titleColor, borderColorOverride = _a.borderColor, props = __rest(_a, ["onRightIconPress", "rightIconStyle", "rightIcon", "rightIconSize", "theme", "size", "titleColor", "borderColor"]);
    var backgroundColor = (0, _1.useBackgroundColor)(theme, props.disabled);
    var foregroundColor = (0, _1.useForegroundColor)(theme, props.disabled);
    var titleColor = titleColorOverride || foregroundColor;
    var _borderColor = (0, _1.useBorderColor)(theme, props.disabled);
    var borderColor = borderColorOverride || _borderColor;
    var buttonProps = __assign(__assign({}, props), { theme: theme, size: size, titleColor: titleColorOverride, borderColor: borderColorOverride, style: styles_1.Styles.buttonWithMoreOptions });
    var rightIconContent = (0, react_1.useMemo)(function () {
        if (isIconName(rightIcon)) {
            return react_1.default.createElement(components_1.MBIconV2, { name: rightIcon, size: rightIconSize, color: titleColor });
        }
        return rightIcon;
    }, [rightIcon, rightIconSize, titleColor]);
    return (react_1.default.createElement(components_1.MBView, { row: true, nativeID: "splitCreateButton" },
        react_1.default.createElement(_1.MBButton, __assign({}, buttonProps)),
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onRightIconPress },
            react_1.default.createElement(components_1.MBView, { center: true, paddingHorizontal: "normal", style: [
                    styles_1.Styles.button,
                    size === 'normal' && styles_1.Styles.buttonSizeNormal,
                    size === 'small' && styles_1.Styles.buttonSizeSmall,
                    { backgroundColor: backgroundColor, borderColor: borderColor },
                    styles_1.Styles.splitButton,
                ] }, rightIconContent))));
};
exports.MBSplitButton = MBSplitButton;
function isIconName(rightIcon) {
    return typeof rightIcon === 'string';
}
exports.MBSplitButton.displayName = 'MBSplitButton';
