/* @flow */
import React from 'react';
import { gql, graphql } from '@common/graphql';
import { connect } from 'react-redux';
import update from 'immutability-helper';

import { apiFetch } from '@src/core/HTTPAPI';
import authenticate from '@src/core/authenticate';
import { formatRawUser } from '@common/core/createUserUtils';

import PureComponent from '@src/components/PureComponent';
import Loading from '@src/components/Loading';
import UserCreate from '@src/components/UserCreate';
import AuthLayout from '@src/components/AuthLayout';
import AuthHeader from '@src/components/AuthLayout/AuthHeader';

import updateCurrentBusinessId from '@src/redux/actions/updateCurrentBusinessId';

import type { StoreState } from '@src/types/StoreTypes';
import type { NewUser, UserCreateInputType as InputType } from '@src/types/UserTypes';

const emptyUser = {
  name: '',
  phone: '',
  email: '',
  img: '',
};

type State = {
  user: NewUser,
  isSubmitting: boolean,
};

type Props = {
  viewportHeight: number,
  isMobile: boolean,
  data: {
    myBusiness?: {
      id: number,
      img: string,
      name: string,
    },
  },
  updateCurrentBusinessId: typeof updateCurrentBusinessId,
  createUser: (x: Object) => Promise<Object>, // @TODO
};

class UserCreateContainer extends PureComponent {
  state: State;
  props: Props;

  constructor() {
    super();
    this.state = {
      user: emptyUser,
      isSubmitting: false,
    };
  }

  handleChange = (value: string, type: InputType) => {
    const newUser = { [type]: { $set: value } };

    this.setState({
      user: update(this.state.user, newUser),
    });
  };

  handleSubmit = () => {
    const myBusinessData = this.props.data.myBusiness;
    if (!myBusinessData) {
      return Promise.resolve();
    }

    const formattedDraft = formatRawUser(this.state.user);

    const newUser = {
      ...formattedDraft,
      businessId: myBusinessData.id,
    };

    return this.props.createUser(newUser)
      .then(
        (res) => {
          const payload = { userId: res.data.createUser.user.id, passcode: '0000' };
          return apiFetch('api/auth', { method: 'POST', data: payload })
            .then((data) => {
              this.props.updateCurrentBusinessId(data.businessId);
              authenticate(data);
            }).catch((err) => {
              if (err.status === 401) {
                // Don't log wrong passcode errors
                return Promise.resolve();
              }
              // this.setState({ error: 'Unexpected Error Happened. Please try again' });
              return Promise.reject(err);
            });
        },
        (err) => {
          this.setState({ isSubmitting: false });
          return Promise.reject(err);
        },
      );
  };

  render() {
    const myBusinessData = this.props.data.myBusiness;
    if (!myBusinessData) {
      return <Loading isCentered={true} />;
    }

    return (
      <div style={{ height: this.props.viewportHeight }}>
        <AuthLayout
          header={<AuthHeader text={`Join your team at ${myBusinessData.name}`} />}
          isMobile={this.props.isMobile}
        >
          <UserCreate
            isMobile={this.props.isMobile}
            draft={this.state.user}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            isSubmitting={this.state.isSubmitting}
          />
        </AuthLayout>
      </div>
    );
  }
}

const createUserMutation = gql`
mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    user {
      id
    }
  }
}
`;

const createUserContainerQuery = gql`
query CreateUserContainerQuery {
  myBusiness {
    id
    img
    name
  }
}`;

const ConnectedUserCreateContainer = connect(
  (state: StoreState) => ({
    isMobile: state.appState.viewportSizeMobile,
    viewportHeight: state.appState.viewportHeight,
  }),
  { updateCurrentBusinessId },
)(UserCreateContainer);

const withCreateUserMutation = graphql(
  createUserMutation,
  {
    props: ({ mutate }) => ({
      createUser: user => mutate({ variables: { input: { user } } }),
    }),
  },
);

const withData = graphql(
  createUserContainerQuery,
  {
    options: (props: Props) => ({ // eslint-disable-line no-unused-vars
      fetchPolicy: 'cache-and-network',
    }),
  },
);

export default withData(withCreateUserMutation(ConnectedUserCreateContainer));
