"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCreateBusiness = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var client_1 = require("@apollo/client");
var react_redux_1 = require("react-redux");
var i18n_1 = require("@shared/util/i18n");
var api_1 = require("@shared/api");
var analytics_1 = require("@shared/util/analytics");
var hooks_1 = require("@shared/util/analytics/hooks");
var storage_1 = require("@shared/util/storage");
var styles_1 = require("@shared/styles");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var header_1 = require("@shared/scenes/auth/contentWrapper/header");
var form_1 = require("@shared/scenes/business/form");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var useNavigator_2 = require("@shared/util/navigation/useNavigator");
var MBCreateBusiness = function () {
    var createMutation = (0, client_1.useMutation)(api_1.Mutations.business.create)[0];
    var dispatch = (0, react_redux_1.useDispatch)();
    var goBack = (0, useNavigator_1.useNavigator)().goBack;
    var navigateToSelectWorkflows = (0, useNavigator_2.useNavigateTo)('OnboardingSelectWorkflows');
    var create = (0, react_1.useCallback)(function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var onboardingSlug, res, businessId;
        var name = _b.name, imageUrl = _b.imageUrl;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    analytics_1.MBAnalytics.track('Onboarding::Business::Create', {
                        name: name,
                        imageUrl: imageUrl,
                    });
                    onboardingSlug = react_native_1.Platform.select({
                        web: 'SELECT_ONE_WF_LAST_STEP_SLIM_INTERCOM_TOURS_AND_CAROUSELS_WEB',
                        default: 'SELECT_ONE_WF_LAST_STEP_SLIM_INTERCOM_TOURS_AND_CAROUSELS_NATIVE',
                    });
                    return [4 /*yield*/, createMutation({
                            variables: { name: name, imageUrl: imageUrl, onboardingSlug: onboardingSlug },
                            // Update the businesses connected to the user
                            // to prevent being sent back to the start of
                            // onboarding when completed.
                            refetchQueries: ['MeQuery'],
                        })];
                case 1:
                    res = _c.sent();
                    businessId = res.data.createBusiness.business.id;
                    dispatch({ businessId: businessId, type: 'UPDATE_CURRENT_BUSINESS_ID' });
                    if (react_native_1.Platform.OS === 'web') {
                        storage_1.MBStorage.set('currentBusinessId', String(businessId));
                    }
                    navigateToSelectWorkflows();
                    return [2 /*return*/];
            }
        });
    }); }, [createMutation, navigateToSelectWorkflows]);
    return (react_1.default.createElement(contentWrapper_1.MBAuthContentWrapper, { logo: "white", padding: false, header: react_1.default.createElement(header_1.MBAuthContentWrapperHeader, { style: { backgroundColor: styles_1.Colors.v2.tertiaryCoolBlue20 }, title: (0, i18n_1.i18n)('onboarding.createBusiness.title'), onPressLeft: (0, hooks_1.useTrackEventWithCallback)('Onboarding::CreateBusiness::PressBack', goBack) }) },
        react_1.default.createElement(form_1.MBBusinessForm, { showUnsavedChangesWarning: false, showGooglePlacesAutocomplete: react_native_1.Platform.OS !== 'web', cta: (0, i18n_1.i18n)('common.create'), onSubmit: create, disableImageUpload: true, onStartSelectImage: (0, hooks_1.useTrackEvent)('Onboarding::Business::ImagePicker::Start'), onSelectedImage: (0, hooks_1.useTrackEvent)('Onboarding::Business::ImagePicker::Selected'), onUploadedImage: (0, hooks_1.useTrackEvent)('Onboarding::Business::ImagePicker::Uploaded') })));
};
exports.MBCreateBusiness = MBCreateBusiness;
