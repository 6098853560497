"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.FLAGS = exports.DELETE_WORKSPACE_SECTION = exports.SCHEDULED_BATCH_UPDATE = exports.SCHEDULED_BATCH_CREATE = exports.SCHEDULED_AUTOMATION_COMPONENT = exports.DATA_FRESHNESS = exports.NO_CODE_CREATE_FORM = exports.NO_CODE_UPDATE_FORM = exports.AUTOMATIONS_IN_CARD_FORM = exports.EXPORT_TO_CSV_VIEW_OPTION = exports.MULTIPLE_FILTER_GROUPS = exports.BOARD_VIEW = exports.TIME_TRACKER_COMPONENT_GEOLOCATION = exports.RELATED_CARD_REFERENCE_IMPROVEMENTS = exports.TIMER_COMPONENT = exports.ABSOLUTE_TIMEOUT = exports.INACTIVITY_TIMEOUT = exports.BUILDER = exports.PAYWALL_EXPERIMENT = void 0;
exports.useLaunchDarklyContext = useLaunchDarklyContext;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
// all flags here
exports.PAYWALL_EXPERIMENT = 'paywall-experiment';
exports.BUILDER = 'builder';
exports.INACTIVITY_TIMEOUT = 'inactivity-timeout';
exports.ABSOLUTE_TIMEOUT = 'absolute-timeout';
exports.TIMER_COMPONENT = 'timer-component';
exports.RELATED_CARD_REFERENCE_IMPROVEMENTS = 'related-card-reference-improvements';
exports.TIME_TRACKER_COMPONENT_GEOLOCATION = 'time-tracker-component-geolocation';
exports.BOARD_VIEW = 'board-view';
exports.MULTIPLE_FILTER_GROUPS = 'multi-filter-groups';
exports.EXPORT_TO_CSV_VIEW_OPTION = 'export-to-csv-view-option';
exports.AUTOMATIONS_IN_CARD_FORM = 'automations-in-card-form';
exports.NO_CODE_UPDATE_FORM = 'no-code-entity-update-form';
exports.NO_CODE_CREATE_FORM = 'no-code-entity-create-form';
exports.DATA_FRESHNESS = 'data-freshness';
exports.SCHEDULED_AUTOMATION_COMPONENT = 'scheduled-automation-component';
exports.SCHEDULED_BATCH_CREATE = 'scheduled-batch-create';
exports.SCHEDULED_BATCH_UPDATE = 'scheduled-batch-update';
exports.DELETE_WORKSPACE_SECTION = 'delete-workspace-section';
// set default state here. format required by native factory wrapper
exports.FLAGS = (_a = {},
    _a[exports.PAYWALL_EXPERIMENT] = {
        type: String, // redacted or banner
        defaultVal: null,
    },
    _a[exports.BUILDER] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.INACTIVITY_TIMEOUT] = {
        type: Number,
        defaultVal: 0,
    },
    _a[exports.ABSOLUTE_TIMEOUT] = {
        type: Number,
        defaultVal: 0,
    },
    _a[exports.TIMER_COMPONENT] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.RELATED_CARD_REFERENCE_IMPROVEMENTS] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.TIME_TRACKER_COMPONENT_GEOLOCATION] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.BOARD_VIEW] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.MULTIPLE_FILTER_GROUPS] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.EXPORT_TO_CSV_VIEW_OPTION] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.AUTOMATIONS_IN_CARD_FORM] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.NO_CODE_UPDATE_FORM] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.NO_CODE_CREATE_FORM] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.DATA_FRESHNESS] = {
        type: Object,
        defaultVal: { enabled: false },
    },
    _a[exports.SCHEDULED_AUTOMATION_COMPONENT] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.SCHEDULED_BATCH_CREATE] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.SCHEDULED_BATCH_UPDATE] = {
        type: Boolean,
        defaultVal: false,
    },
    _a[exports.DELETE_WORKSPACE_SECTION] = {
        type: Boolean,
        defaultVal: false,
    },
    _a);
function useLaunchDarklyContext() {
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var profile = (0, client_1.useQuery)(api_1.Queries.user.profile, {
        variables: { businessId: businessId !== null && businessId !== void 0 ? businessId : -1 },
        skip: (0, lodash_1.isNil)(businessId),
    }).data;
    var personalDetails = (0, client_1.useQuery)(api_1.Queries.user.myPersonalDetails).data;
    var context = (0, react_1.useMemo)(function () {
        if (businessId && (profile === null || profile === void 0 ? void 0 : profile.me) && (personalDetails === null || personalDetails === void 0 ? void 0 : personalDetails.me)) {
            var business = profile.me.businesses.find(function (b) { return (b === null || b === void 0 ? void 0 : b.id) === businessId; });
            var user = __assign(__assign({}, profile.me), personalDetails.me);
            // Note: our React Native version (0.68) + supported LD SDK (6.3.3)
            // requires us to use a user not contexts
            var ldUser = {
                key: String(user.id),
                firstName: user.firstName,
                lastName: user.lastName,
                custom: {
                    businessId: business === null || business === void 0 ? void 0 : business.id,
                    businessName: business === null || business === void 0 ? void 0 : business.name,
                },
            };
            return ldUser;
        }
        else {
            return null;
        }
    }, [businessId, profile, personalDetails]);
    return context;
}
