"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    background: {
        backgroundColor: styles_1.Colors.v2.green10,
        marginBottom: 10,
        // Note(fant): this is a hack to make it look like the
        // banner's green color goes all the way to the top.
        height: react_native_1.Dimensions.get('screen').height,
        justifyContent: 'flex-end',
    },
    image: {
        width: 40,
        height: 40,
    },
});
