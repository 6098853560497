import * as React from 'react';
function SvgFaceSmileFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 8a12 12 0 100 24 12 12 0 000-24zm-6 10.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 8.5a4 4 0 01-4-4h2a2 2 0 004 0h2a4 4 0 01-4 4zm4.5-7a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgFaceSmileFilled;

