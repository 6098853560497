
const initialState = {
  user: {
    name: '',
    phone: '',
    email: '',
    img: '',
  },
  business: {
    name: '',
    img: '',
  },
  signUpToken: '',
};

export default function createAccountReducer(
  state = initialState, action,
  // state: CreateAccountReducer = initialState, action: Action,
) {
  switch (action.type) {

    case 'UPDATE_CREATE_ACCOUNT_USER': {
      const { user } = action;
      return {
        ...state,
        user,
      };
    }

    case 'UPDATE_CREATE_ACCOUNT_BUSINESS': {
      const { business } = action;
      return {
        ...state,
        business,
      };
    }

    case 'RESET_CREATE_ACCOUNT': {
      return {
        user: action.user || initialState.user,
        business: action.business || initialState.business,
        signUpToken: action.signUpToken || initialState.signUpToken,
      };
    }

    default:
      return state;
  }
}
