"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var view_1 = require("../view");
var arrowHeight = 10;
var arrowWidth = 2 * arrowHeight;
exports.Styles = react_native_1.StyleSheet.create({
    container: __assign({ shadowColor: 'black', shadowRadius: 50, shadowOpacity: 0.15, borderRadius: (0, view_1.grid)(1), maxHeight: 400 }, react_native_1.Platform.select({ web: { overflow: 'auto' } })),
    themeWhite: { backgroundColor: 'white' },
    themeDark: { backgroundColor: 'rgba(0,0,0,0.7)' },
    themePurple: { backgroundColor: styles_1.Colors.v2.purple },
    arrow: {
        width: 0,
        height: 0,
        borderLeftWidth: arrowWidth / 2,
        borderRightWidth: arrowWidth / 2,
        borderStyle: 'solid',
        borderColor: 'white',
        backgroundColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        marginHorizontal: arrowWidth / 4,
        // make the tooltip appear over the container
        // so that it is unaffected by its shadow.
        zIndex: 1,
    },
    arrowUp: { borderBottomWidth: arrowHeight },
    arrowDown: { borderTopWidth: arrowHeight },
    closeIcon: {
        padding: 0,
        width: 12,
        height: 12,
    },
});
