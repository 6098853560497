"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    notificationBadge: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    icon: {
        width: 36,
        height: 36,
        padding: 0,
    },
    underline: {
        width: 20,
        height: 2,
        alignSelf: 'center',
        backgroundColor: styles_1.Colors.v2.green,
    },
    headerBusiness: {
        width: 24,
        height: 24,
        padding: 0,
    },
    headerBusinessContainer: {
        width: 28,
        height: 28,
        borderRadius: 14,
        borderColor: 'white',
        borderWidth: 2,
        overflow: 'hidden',
        backgroundColor: styles_1.Colors.v2.gray10,
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
    headerUser: {
        width: 44,
        height: 44,
        borderRadius: 22,
        overflow: 'hidden',
        backgroundColor: styles_1.Colors.v2.gray10,
        borderWidth: 2,
        borderColor: styles_1.Colors.v2.white,
    },
});
