"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBDirectoryProfile = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var index_1 = require("@shared/util/intercom/index");
var MBDirectoryProfile = function (_a) {
    var _b, _c;
    var user = _a.user;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var showIntercomMessenger = (0, index_1.useShowIntercomMessenger)();
    var handleContactSupport = (0, react_1.useCallback)(function () {
        return showIntercomMessenger({
            message: (0, i18n_1.i18n)('directory.profile.changeDetailsSupportMessage', { fullName: user.fullName }),
        });
    }, [showIntercomMessenger, user.fullName]);
    return (react_1.default.createElement(components_1.MBView, { centerHorizontally: true, padding: 2 },
        react_1.default.createElement(components_1.MBView, { center: true, style: {
                width: 106,
                height: 106,
                borderRadius: 106 / 2,
                overflow: 'hidden',
                backgroundColor: colors.foregroundInactive,
            } }, user.isPending ? (react_1.default.createElement(components_1.MBIconV2, { name: "person", size: 72, color: colors.background })) : (react_1.default.createElement(components_1.MBUserImage, { size: "large", user: user }))),
        react_1.default.createElement(components_1.MBView, { paddingTop: 2 }),
        react_1.default.createElement(components_1.MBTextH3, { color: user.isPending ? colors.foregroundInactive : colors.foregroundActive },
            user.fullName,
            " ",
            user.isPending ? (0, i18n_1.i18n)('directory.profile.pending') : undefined),
        react_1.default.createElement(components_1.MBView, { paddingTop: 4, style: { width: '100%', maxWidth: 440 } },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('directory.profile.phoneLabel')),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1 }),
            react_1.default.createElement(components_1.MBView, { padding: 2, style: [styles_1.SharedStyles.borderRadius, { backgroundColor: colors.input.backgroundInactive }] },
                react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, (_b = user.phoneNumber) !== null && _b !== void 0 ? _b : '-'))),
        react_1.default.createElement(components_1.MBView, { paddingTop: 4, style: { width: '100%', maxWidth: 440 } },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('directory.profile.emailLabel')),
            react_1.default.createElement(components_1.MBView, { paddingTop: 1 }),
            react_1.default.createElement(components_1.MBView, { padding: 2, style: [styles_1.SharedStyles.borderRadius, { backgroundColor: colors.input.backgroundInactive }] },
                react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, (_c = user.email) !== null && _c !== void 0 ? _c : '-'))),
        react_1.default.createElement(components_1.MBView, { paddingTop: 4 },
            react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive, align: "center" },
                (0, i18n_1.i18n)('directory.profile.footer.wantToMakeAnEdit'),
                ' ',
                react_1.default.createElement(components_1.MBTextBody1Bold, { onPress: handleContactSupport }, (0, i18n_1.i18n)('directory.profile.footer.contactSupport'))))));
};
exports.MBDirectoryProfile = MBDirectoryProfile;
