"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = default_1;
var Actions = __importStar(require("./actions"));
var initialState = {
    selectedUsers: [],
    workflowListItem: undefined,
    workspaceSectionId: undefined,
    accessLevel: undefined,
};
function default_1(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case Actions.ADD_SELECTED_USER: {
            return __assign(__assign({}, state), { selectedUsers: __spreadArray(__spreadArray([], state.selectedUsers, true), [action.value], false) });
        }
        case Actions.REMOVE_SELECTED_USER: {
            var user_1 = action.value;
            return __assign(__assign({}, state), { selectedUsers: state.selectedUsers.filter(function (u) { return u.id !== user_1.id; }) });
        }
        case Actions.SET_WORKFLOW_LIST_ITEM: {
            var item = action.value;
            return __assign(__assign({}, state), { workflowListItem: item });
        }
        case Actions.SET_WORKSPACE_LIST_SECTION:
            return __assign(__assign({}, state), { workspaceSectionId: action.value });
        case Actions.RESET_SELECTED_USERS:
            return __assign(__assign({}, state), { selectedUsers: action.value });
        case Actions.SET_ACCESS_LEVEL:
            return __assign(__assign({}, state), { accessLevel: action.value });
        default:
            return state;
    }
}
