"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VERSION_STRING = exports.GOOGLE_MAPS_WEB_API_KEY = exports.EnvVars = void 0;
// TODO(fant): configure this in web/config/env.js
exports.EnvVars = process.env;
// Injected into environment in webpack config
exports.GOOGLE_MAPS_WEB_API_KEY = process.env.GOOGLE_MAPS_WEB_API_KEY;
// TODO(fant): how do we get the version from web?
var version = process.env.VERSION;
var commit = process.env.GIT_COMMIT || 'unknown commit';
exports.VERSION_STRING = "v".concat(version, " (").concat(commit, ")");
