import * as React from 'react';
function SvgUmbrella(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 28a3 3 0 11-6 0v-8M20 11V9"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 11c-2.961 1.474-5 4.468-5 8v1M20 11c2.961 1.474 5 4.468 5 8v1M20 20v-9"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M30 20c-.502-5.053-4.815-9-10-9s-9.498 3.947-10 9h20z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgUmbrella;

