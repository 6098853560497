"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unitListItems = void 0;
exports.parseDurationString = parseDurationString;
var lodash_1 = require("lodash");
var iso8601_duration_1 = require("iso8601-duration");
function parseDurationString(duration) {
    if ((0, lodash_1.isNil)(duration)) {
        return null;
    }
    var isNegative = duration.startsWith('-');
    var str = !isNegative ? duration : duration.slice(1);
    var matches = (0, iso8601_duration_1.parse)(str);
    // PnD
    if (str.includes('D')) {
        return { number: String(matches.days), unit: isNegative ? '-PnD' : 'PnD' };
    }
    // PnH
    if (str.includes('H')) {
        return { number: String(matches.hours), unit: isNegative ? '-PTnH' : 'PTnH' };
    }
    // PTnM
    if (str.includes('M') && str.includes('PT')) {
        if (duration === 'PT0M') {
            return { number: '0', unit: 'PT0M' };
        }
        return { number: String(matches.minutes), unit: isNegative ? '-PTnM' : 'PTnM' };
    }
    return null;
}
exports.unitListItems = [
    {
        id: 'PT0M',
        label: 'builder.scheduledAutomation.units.timeOfEvent',
        labelWithCount: 'builder.scheduledAutomation.units.timeOfEvent',
    },
    {
        id: '-PTnM',
        label: 'builder.scheduledAutomation.units.minutesBefore',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.minutesBefore',
    },
    {
        id: '-PTnH',
        label: 'builder.scheduledAutomation.units.hoursBefore',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.hoursBefore',
    },
    {
        id: '-PnD',
        label: 'builder.scheduledAutomation.units.daysBefore',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.daysBefore',
    },
    {
        id: 'PTnM',
        label: 'builder.scheduledAutomation.units.minutesAfter',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.minutesAfter',
    },
    {
        id: 'PTnH',
        label: 'builder.scheduledAutomation.units.hoursAfter',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.hoursAfter',
    },
    {
        id: 'PnD',
        label: 'builder.scheduledAutomation.units.daysAfter',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.daysAfter',
    },
];
