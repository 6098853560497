import * as React from 'react';
function SvgPersonChef(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 24h4a7 7 0 017 7v0H11a7 7 0 017-7v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M20 28a1 1 0 100-2 1 1 0 000 2z" fill={props.color} />
      <path
        d="M24 17v3a4 4 0 11-8 0v-3M24 9h-8a2 2 0 000 4v4h8v-4a2 2 0 000-4v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPersonChef;

