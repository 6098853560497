import * as React from 'react';
function SvgCamera(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.91 26c2.738 0 4.958-2.239 4.958-5s-2.22-5-4.959-5c-2.738 0-4.959 2.239-4.959 5s2.22 5 4.96 5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.835 30H10.983c-.526 0-1.03-.21-1.402-.586A2.009 2.009 0 019 28V15c0-.53.209-1.04.58-1.414A1.975 1.975 0 0110.984 13h3.967l1.984-3h5.95l1.984 3h3.967c.526 0 1.03.21 1.402.586.372.375.581.884.581 1.414v13c0 .53-.209 1.04-.58 1.414a1.975 1.975 0 01-1.403.586z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.302 15.91a.41.41 0 11-.819 0 .41.41 0 01.819 0z" stroke={props.color} />
    </svg>
  );
}
export default SvgCamera;

