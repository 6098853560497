import * as React from 'react';
function SvgUrgent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(.4 1.5)" stroke={props.color} fill="none" fillRule="evenodd">
        <path d="M5.107.781L3.655 1.875l.7.099a.41.41 0 11.654-.493l.4-.3-.302-.4zM10.528.781l1.452 1.094-.7.099a.41.41 0 10-.653-.493l-.4-.3.301-.4z" />
        <circle strokeLinecap="square" cx={7.955} cy={5.682} r={4.091} />
        <path
          strokeLinecap="round"
          d="M7.955 3.41v2.272l1.363.909M6.59.227h2.728M7.955.227v1.364M1.23 4.09h1.952M.17 5.91h2.658M.877 7.727h2.305"
        />
      </g>
    </svg>
  );
}
export default SvgUrgent;

