"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var view_1 = require("@shared/components/view");
exports.Styles = react_native_1.StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100%',
        width: 'fit-content',
        marginBottom: (0, view_1.grid)(2),
    },
    header: {
        marginLeft: 0,
        marginRight: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
    },
    isCollapsed: {
        marginBottom: (0, view_1.grid)(2),
    },
    isEmpty: {
        // @ts-ignore
        position: 'sticky',
        left: 0,
    },
});
