import * as React from 'react';
function SvgAvatarEmpty(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 32c.167-4.444 4.127-8 9-8 4.346 0 7.984 2.844 8.82 6.625.103.439.154.9.18 1.363M20 21.006c3.314 0 6-2.687 6-6.003A6.002 6.002 0 0020 9c-3.314 0-6 2.688-6 6.003a6.002 6.002 0 006 6.003z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgAvatarEmpty;

