"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.UnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
exports.UnvalidatedStyles = {
    defaultProgressBarColor: 'rgba(0, 0, 0, 0.5)',
    defaultProgressBarUnfilledColor: 'rgba(0, 0, 0, 0.2)',
    circularProgressBarSize: {
        normal: 40,
        small: 24,
    },
};
exports.Styles = react_native_1.StyleSheet.create({
    circularProgressBarNormalSize: {
        width: exports.UnvalidatedStyles.circularProgressBarSize.normal,
        height: exports.UnvalidatedStyles.circularProgressBarSize.normal,
    },
    circularProgressBarSmallSize: {
        width: exports.UnvalidatedStyles.circularProgressBarSize.small,
        height: exports.UnvalidatedStyles.circularProgressBarSize.small,
    },
});
