import * as React from 'react';
function SvgPersonConcierge(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 28a1 1 0 100-2 1 1 0 000 2zM24 28a1 1 0 100-2 1 1 0 000 2z" fill={props.color} />
      <path
        d="M20 27v4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M16.8 21.907l-5.825 3.51A2 2 0 0010 27.132V31h20v-3.868a2 2 0 00-.971-1.715l-5.829-3.51"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M14 15v-5a14.515 14.515 0 016-1 14.515 14.515 0 016 1v5c0 5-2.686 8-6 8s-6-3-6-8z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M14 15a14.514 14.514 0 016-1 14.514 14.514 0 016 1"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgPersonConcierge;

