import * as React from 'react';
function SvgBullhornQuiet(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.336 22l2.519 6.252a2 2 0 01-1.108 2.6v0a2 2 0 01-2.6-1.108L13 22"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M19 14h-6a4 4 0 100 8h6l8 4V10l-8 4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M29 15v6a3 3 0 000-6z" fill={props.color} />
    </svg>
  );
}
export default SvgBullhornQuiet;

