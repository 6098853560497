import ReactPixel from 'react-facebook-pixel';
import { Config } from '@shared/config';


export function init() {
    if (!Config.FACEBOOK_PIXEL_ID) {
        console.warn('[FB Pixel] Config.FACEBOOK_PIXEL_ID not set');
        return;
    }
    
    ReactPixel.init(
        Config.FACEBOOK_PIXEL_ID,
        null /* advanced matching */,
        { autoConfig: true, debug: __DEV__ },
    );

    ReactPixel.pageView();
}
