import * as React from 'react';
function SvgFilled(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 9H9v3H7V9H4V7h3V4h2v3h3v2z"
        fill={props.color}
        fillRule="nonzero"
      />
    </svg>
  );
}
export default SvgFilled;

