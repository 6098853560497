"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLowCodeViewTemplate = isLowCodeViewTemplate;
var lodash_1 = require("lodash");
function isLowCodeViewTemplate(viewTemplate) {
    if ((0, lodash_1.isNil)(viewTemplate)) {
        return true;
    }
    switch (viewTemplate.subtype) {
        case 'TABLE':
        case 'LIST':
            return false;
        case 'CALENDAR':
            return (0, lodash_1.isNil)(viewTemplate.calendarDateComponentId);
        default:
            return true;
    }
}
