import * as React from 'react';
function SvgAllCardsEmptyDesktop(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 332 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#all-cards-empty-desktop_svg__filter0_d)">
        <mask
          id="all-cards-empty-desktop_svg__a"
          maskUnits="userSpaceOnUse"
          x={7}
          y={7}
          width={310}
          height={191}
        >
          <rect x={7} y={7} width={309.652} height={191} rx={8} fill="#fff" />
        </mask>
        <g mask="url(#all-cards-empty-desktop_svg__a)">
          <path fill="#F7F7F7" d="M7 7.724h309.652v190.277H7z" />
          <path fill="#fff" d="M7.001 7h309.652v18.811H7.001z" />
          <path fill="#073A44" d="M7.001 7H83.69v191H7.001z" />
          <circle cx={97.074} cy={16.767} r={5.426} fill="#E6E6E6" />
          <circle cx={21.426} cy={49.426} r={5.426} fill="#31727E" />
          <rect x={33.363} y={48.341} width={31.11} height={2.17} rx={1.085} fill="#31727E" />
          <circle cx={21.426} cy={72.278} r={5.426} fill="#31727E" />
          <rect x={33.363} y={71.193} width={31.11} height={2.17} rx={1.085} fill="#31727E" />
          <circle cx={21.426} cy={95.131} r={5.426} fill="#31727E" />
          <rect x={33.363} y={94.045} width={31.11} height={2.17} rx={1.085} fill="#31727E" />
          <circle cx={21.426} cy={117.983} r={5.426} fill="#31727E" />
          <rect x={33.363} y={116.898} width={31.11} height={2.17} rx={1.085} fill="#31727E" />
          <circle cx={21.426} cy={140.835} r={5.426} fill="#31727E" />
          <rect x={33.363} y={139.75} width={31.11} height={2.17} rx={1.085} fill="#31727E" />
          <circle cx={21.426} cy={163.687} r={5.426} fill="#31727E" />
          <rect x={33.363} y={162.602} width={31.11} height={2.17} rx={1.085} fill="#31727E" />
          <rect x={109.011} y={15.682} width={31.11} height={2.17} rx={1.085} fill="#E6E6E6" />
          <rect x={12.064} y={28.704} width={65.837} height={7.235} rx={3.617} fill="#31727E" />
          <rect x={88.03} y={32.322} width={65.837} height={7.235} rx={3.617} fill="#E6E6E6" />
          <g filter="url(#all-cards-empty-desktop_svg__filter1_d)">
            <rect x={90} y={46} width={221} height={25} rx={4} fill="#fff" />
          </g>
          <rect x={132} y={56} width={63} height={4} rx={2} fill="#E6E6E6" />
          <rect x={216} y={56} width={73} height={4} rx={2} fill="#E6E6E6" />
          <path d="M114.2 58a7.2 7.2 0 11-14.4 0 7.2 7.2 0 0114.4 0z" fill="#DAF6F0" />
          <g filter="url(#all-cards-empty-desktop_svg__filter2_d)">
            <rect x={90} y={76} width={221} height={25} rx={4} fill="#fff" />
          </g>
          <rect x={132} y={86} width={63} height={4} rx={2} fill="#E6E6E6" />
          <rect x={216} y={86} width={73} height={4} rx={2} fill="#E6E6E6" />
          <path d="M114.2 88a7.2 7.2 0 11-14.4 0 7.2 7.2 0 0114.4 0z" fill="#DAF6F0" />
          <g filter="url(#all-cards-empty-desktop_svg__filter3_d)">
            <rect x={90} y={106} width={221} height={25} rx={4} fill="#fff" />
          </g>
          <rect x={132} y={116} width={63} height={4} rx={2} fill="#E6E6E6" />
          <rect x={216} y={116} width={73} height={4} rx={2} fill="#E6E6E6" />
          <path d="M114.2 118a7.2 7.2 0 11-14.401-.001A7.2 7.2 0 01114.2 118z" fill="#DAF6F0" />
          <g filter="url(#all-cards-empty-desktop_svg__filter4_d)">
            <rect x={90} y={136} width={221} height={25} rx={4} fill="#fff" />
          </g>
          <rect x={132} y={146} width={63} height={4} rx={2} fill="#E6E6E6" />
          <rect x={216} y={146} width={73} height={4} rx={2} fill="#E6E6E6" />
          <path d="M114.2 148a7.2 7.2 0 11-14.401-.001A7.2 7.2 0 01114.2 148z" fill="#DAF6F0" />
        </g>
      </g>
      <defs>
        <filter
          id="all-cards-empty-desktop_svg__filter0_d"
          x={0}
          y={0}
          width={331.652}
          height={213}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={4} dy={4} />
          <feGaussianBlur stdDeviation={5.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-desktop_svg__filter1_d"
          x={84}
          y={40}
          width={237}
          height={41}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-desktop_svg__filter2_d"
          x={84}
          y={70}
          width={237}
          height={41}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-desktop_svg__filter3_d"
          x={84}
          y={100}
          width={237}
          height={41}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="all-cards-empty-desktop_svg__filter4_d"
          x={84}
          y={130}
          width={237}
          height={41}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx={2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgAllCardsEmptyDesktop;

