import * as React from 'react';
function SvgPen(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23 13l4 4" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M16 28l-5 1 1-5L27 9l4 4-15 15z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPen;

