"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagFieldOption = void 0;
var TagFieldOption = /** @class */ (function () {
    function TagFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    TagFieldOption.prototype.matchesComponent = function (component, reservedIds) {
        var isReserved = !!reservedIds.find(function (id) { return id === component.id; });
        return (!isReserved &&
            component.type === 'TAG' &&
            !!component.multiselect === !!this.component.multiselect);
    };
    TagFieldOption.prototype.canBeAdded = function (_components) {
        return true;
    };
    return TagFieldOption;
}());
exports.TagFieldOption = TagFieldOption;
