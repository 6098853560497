import * as React from 'react';
function SvgEnvelope(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 96 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path stroke="#DAF6F0" strokeWidth={10} d="M5 5h86v70H5z" />
      <path
        d="M73.846 22.154L48 48 22.154 22.154M25.846 51.692l-7.384 7.385M70.154 51.692l7.385 7.385"
        stroke="#DAF6F0"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgEnvelope;

