import * as React from 'react';
function SvgMeter(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 11v3M27.778 14.222l-2.121 2.12M31 22h-3M15 17l3.586 3.586M9 22h3M20 24a2 2 0 100-4 2 2 0 000 4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M28.485 29A10.955 10.955 0 0031 22c0-6.075-4.925-11-11-11S9 15.925 9 22c0 2.659.944 5.098 2.515 7h16.97z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMeter;

