"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionViewType = void 0;
exports.trackCollectionViewLoad = trackCollectionViewLoad;
var analytics_1 = require("@shared/util/analytics");
var CollectionViewType;
(function (CollectionViewType) {
    CollectionViewType["TABLE"] = "table";
    CollectionViewType["LIST"] = "list";
    CollectionViewType["KANBAN"] = "kanban";
    CollectionViewType["AGENDA"] = "agenda";
    CollectionViewType["CALENDAR"] = "calendar";
    CollectionViewType["NO_CODE_LIST"] = "no-code-list";
})(CollectionViewType || (exports.CollectionViewType = CollectionViewType = {}));
function trackCollectionViewLoad(viewType) {
    analytics_1.MBAnalytics.track('CollectionViewLoad', {
        viewType: viewType,
    });
}
