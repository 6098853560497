"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponseHeaders = exports.ResponseHeadersContext = void 0;
var client_1 = require("@apollo/client");
var react_1 = require("react");
exports.ResponseHeadersContext = (0, react_1.createContext)({});
var useResponseHeaders = function () {
    var _a = (0, react_1.useState)({}), headers = _a[0], setResponseHeaders = _a[1];
    var link = (0, react_1.useMemo)(function () { return createResponseHeaderLink(setResponseHeaders); }, []);
    return { link: link, headers: headers };
};
exports.useResponseHeaders = useResponseHeaders;
var RESPONSE_HEADER_WHITELIST = ['min-native-version', 'min-codepush-version'];
var createResponseHeaderLink = function (setHeaders) {
    var headerCache = {};
    return new client_1.ApolloLink(function (operation, forward) {
        var op = forward(operation);
        if (typeof op.map !== 'undefined') {
            return op.map(function (response) {
                var _a, _b, _c;
                // TODO: this object is empty on web; figure out why
                var headers = (_c = (_b = (_a = operation.getContext()) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.headers) === null || _c === void 0 ? void 0 : _c.map;
                if (headers) {
                    var dirty_1 = false;
                    RESPONSE_HEADER_WHITELIST.forEach(function (header) {
                        if (headerCache[header] !== headers[header]) {
                            headerCache[header] = headers[header];
                            dirty_1 = true;
                        }
                    });
                    if (dirty_1) {
                        setHeaders(__assign({}, headerCache));
                    }
                }
                return response;
            });
        }
        return op;
    });
};
