/* @flow */

import moment from 'moment';
import { gql } from '@common/graphql';

import type { Channel as ChannelType } from '@src/types/ChannelTypes';

export function getSnoozeEndDateFromDelay(delay?: number): Date {
  switch (delay) {
    case 15:
    case 60:
    case 120:
    case 240:
    case 480:
    case 1440:
      return moment().add(delay, 'minutes').toDate();

    case Infinity:
      // https://en.wikipedia.org/wiki/Year_2038_problem
      return moment().add(10, 'years').toDate();

    default: {
      // Next morning 9am.
      const endDate = moment();
      if (endDate.hours() > 8) {
        endDate.add(1, 'day');
      }

      endDate.hours(9);
      endDate.minutes(0);
      endDate.seconds(0);

      return endDate.toDate();
    }
  }
}

export function isDateSnoozedForever(date: Date): boolean {
  // TODO(fant): this is super hacky...
  // Note: in shared components we set
  // "forever" to a future date (2038-01-01)
  return date.getFullYear() > new Date().getFullYear() + 9;
}

export function getFormattedEndDate(date: Date): string {
  return moment(date).format('h:mm a');
}

export function expireSnoozedChannels(client: Object, channels: Array<ChannelType>) {
  channels.forEach(channel => {
    if (channel.snoozeUntil && channel.snoozeUntil < new Date().toISOString()) {
      client.writeFragment({
        id: `Channel${channel.id}`,
        fragmentName: 'unsnoozeChannel',
        fragment: gql`
          fragment unsnoozeChannel on Channel {
            id
            snoozeUntil
          }
        `,
        data: {
          id: channel.id,
          snoozeUntil: null,
          __typename: 'Channel',
        },
      });
    }
  });
}

export function expireSnoozedUser(client: Object, user: { id: number, snoozeUntil: ?string }) {
  if (user.snoozeUntil && user.snoozeUntil < new Date().toISOString()) {
    client.writeFragment({
      id: `User${user.id}`,
      fragmentName: 'unsnoozeUser',
      fragment: gql`
        fragment unsnoozeUser on User {
          id
          snoozeUntil
        }
      `,
      data: {
        id: user.id,
        snoozeUntil: null,
        __typename: 'User',
      },
    });
  }
}

export const snoozeTimesAndLabels = [
  { duration: 15, label: '15 Minutes' },
  { duration: 60, label: '1 Hour' },
  { duration: 120, label: '2 Hours' },
  { duration: 480, label: '8 Hours' },
  { duration: 1440, label: '24 Hours' },
  { label: 'Until Next Morning (9am)' },
  { duration: Infinity, label: 'Until I Turn it Off' },
];
