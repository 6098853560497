import * as React from 'react';
function SvgHandshake(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 16l-2.172 2.172a4 4 0 01-5.656 0L14 18l4.929-4.929a7.072 7.072 0 0110 0v0A7.074 7.074 0 0130 21.7"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M23.266 19.266l3.613 3.613a3 3 0 010 4.242l-1.636 1.636a6 6 0 01-8.486 0l-5.686-5.686a7.072 7.072 0 010-10v0A7.048 7.048 0 0116 11"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M26.391 27.609l-3.891-3.89M23.513 29.965l-3.63-3.63"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgHandshake;

