"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RETURN_CHANNEL_SLUG_QUERY = void 0;
exports.CardBulkUpdateRoute = CardBulkUpdateRoute;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var constants_1 = require("@shared/styles/constants");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var lodash_1 = require("lodash");
var hooks_1 = require("../../hooks");
var hooks_2 = require("./hooks");
var useEditRecurringEntity_1 = require("@shared/scenes/entity/edit/hooks/useEditRecurringEntity");
var CardEntityBulkEditForm_1 = require("@shared/scenes/entity/edit/components/CardEntityBulkEditForm");
var hooks_3 = require("@shared/util/hooks");
exports.RETURN_CHANNEL_SLUG_QUERY = 'returnChannelSlug';
function CardBulkUpdateRoute(_a) {
    var _b;
    var params = _a.params, location = _a.location;
    var channelSlug = params.channelSlug;
    var channel = (0, hooks_3.useGetChannelInfo)({ channelSlug: channelSlug });
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, params.workflowTemplateId).cardDefinition;
    var title = (0, hooks_2.useFormTitle)(params.channelSlug, params.viewTemplateId, cardDefinition, params.cardId);
    var submitButtonTitle = (0, hooks_2.useFormSubmitButtonTitle)(params.channelSlug, cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id);
    var _c = (0, useNavigator_1.useNavigator)(), goBack = _c.goBack, replace = _c.replace;
    var returnTo = (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isNil)(location.query[exports.RETURN_CHANNEL_SLUG_QUERY])) {
            return undefined;
        }
        return {
            channelSlug: location.query[exports.RETURN_CHANNEL_SLUG_QUERY],
            cardId: location.query.returnCardId,
        };
    }, [location.query]);
    var onBack = (0, react_1.useCallback)(function () {
        if ((0, lodash_1.isNil)(returnTo)) {
            goBack();
        }
        else {
            replace((0, lodash_1.isNil)(returnTo === null || returnTo === void 0 ? void 0 : returnTo.cardId) ? 'CardCreate' : 'CardUpdate', __assign(__assign({}, returnTo), { restoreSavedData: true }));
        }
    }, [goBack, replace, returnTo]);
    var _d = (0, useEditRecurringEntity_1.useEditRecurringEntity)({
        workflowTemplateId: params.workflowTemplateId,
        viewTemplateId: params.viewTemplateId,
        entityId: params.cardId,
        recurringEntitiesScheduleId: params.recurringEntitiesScheduleId,
        allOrUpcoming: params.allOrUpcoming,
    }), components = _d.components, fields = _d.fields, updateField = _d.updateField, errors = _d.errors, isDirty = _d.isDirty, save = _d.save;
    var showSaveButton = (0, hooks_2.useShouldShowUpdateSubmitButton)(isDirty, (_b = channel === null || channel === void 0 ? void 0 : channel.id) !== null && _b !== void 0 ? _b : -1);
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: title, paddingOnWeb: "none", onBack: onBack, desktopModalRightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 }, showSaveButton && react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: save })), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: title, rightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 }, showSaveButton && react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: save })), onPressLeft: goBack }) },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(CardEntityBulkEditForm_1.CardEntityBulkEditForm, { fields: fields, updateField: updateField, components: components, errors: errors, workflowTemplateId: params.workflowTemplateId, allOrUpcoming: params.allOrUpcoming }))));
}
