import * as React from 'react';
function SvgRight(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.4 8l-8-8-1.467 1.533L11.4 8l-6.467 6.467L6.4 16z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  );
}
export default SvgRight;

