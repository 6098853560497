"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduledAutomationField = void 0;
var lodash_1 = require("lodash");
var ScheduledAutomationField = /** @class */ (function () {
    function ScheduledAutomationField(jsonBlob) {
        var _a;
        this.fieldType = 'ScheduledAutomationField';
        this.durations = (_a = jsonBlob === null || jsonBlob === void 0 ? void 0 : jsonBlob.automationTriggerDurations) !== null && _a !== void 0 ? _a : [];
    }
    ScheduledAutomationField.prototype.validate = function () {
        return null;
    };
    ScheduledAutomationField.prototype.isEqual = function (field) {
        return field instanceof ScheduledAutomationField && (0, lodash_1.isEqual)(this.durations, field.durations);
    };
    ScheduledAutomationField.prototype.toFieldJson = function () {
        return { automationTriggerDurations: this.durations };
    };
    Object.defineProperty(ScheduledAutomationField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.durations);
        },
        enumerable: false,
        configurable: true
    });
    return ScheduledAutomationField;
}());
exports.ScheduledAutomationField = ScheduledAutomationField;
