"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InCardsSheet = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var multiSelect_1 = require("@shared/components/multiSelect");
var cardSelectItem_1 = require("@shared/components/blocks/lib/inputCards/cardSelectItem");
var noCards_1 = require("@shared/components/blocks/lib/inputCards/noCards");
var provider_1 = require("@shared/components/bottomSheet/provider");
var react_redux_1 = require("react-redux");
var i18n_1 = require("@shared/util/i18n");
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var use_debounce_1 = require("use-debounce");
var quickCreateCard_1 = require("@shared/components/blocks/lib/inputCards/quickCreateCard");
var InCardsSheet = function (_a) {
    var onPress = _a.onPress, cardId = _a.cardId, channelId = _a.channelId, channelName = _a.channelName, onAdd = _a.onAdd;
    var bottomSheetAPI = (0, react_1.useContext)(provider_1.BottomSheetContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var _b = (0, react_1.useState)(''), keyword = _b[0], _setKeyword = _b[1];
    var setKeyword = (0, use_debounce_1.useDebouncedCallback)(_setKeyword, 100)[0];
    var _c = (0, react_1.useState)([]), searchResults = _c[0], setSearchResults = _c[1];
    var _d = (0, client_1.useQuery)(api_1.Queries.card.listRelatedCards, {
        variables: {
            input: {
                id: cardId,
                channelId: channelId,
                type: 'IN',
                statuses: ['ACTIVE'],
            },
        },
        fetchPolicy: 'cache-and-network',
    }), data = _d.data, loading = _d.loading;
    (0, react_1.useEffect)(function () {
        if (data === null || data === void 0 ? void 0 : data.listRelatedCards) {
            var cards = data.listRelatedCards
                .map(function (val) { return ({ cardId: val.id, name: val.name }); })
                .filter(function (c) { return c.name.toLowerCase().includes(keyword.toLowerCase()); });
            setSearchResults(cards);
        }
    }, [keyword, data]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (isDesktop) {
            (_a = bottomSheetAPI.refreshHeight) === null || _a === void 0 ? void 0 : _a.call(bottomSheetAPI);
        }
    }, [isDesktop, searchResults]);
    var renderItem = (0, react_1.useCallback)(function (card) { return react_1.default.createElement(cardSelectItem_1.CardSelectItem, { card: card }); }, []);
    var renderEmptyState = (0, react_1.useCallback)(function (query) {
        return (react_1.default.createElement(components_1.MBView, { paddingVertical: 3, paddingHorizontal: 2, center: true },
            query.length > 0 && (react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: (0, i18n_1.i18n)('card.blocks.inCards.search.emptyState.title', { keyword: query }), description: (0, i18n_1.i18n)('card.blocks.inCards.search.emptyState.message') })),
            query.length === 0 && !loading && react_1.default.createElement(noCards_1.NoCards, null),
            query.length === 0 && loading && react_1.default.createElement(components_1.MBLoadingIndicator, null)));
    }, [loading]);
    var match = (0, react_1.useCallback)(function (card, query, exact) {
        if (exact) {
            return card.name === query;
        }
        return true;
    }, []);
    var renderCreateNew = (0, react_1.useCallback)(function (query) {
        return react_1.default.createElement(quickCreateCard_1.QuickCreateCard, { channelName: channelName, query: query });
    }, [channelName]);
    var onCreate = (0, react_1.useCallback)(function (query) { return Promise.resolve(onAdd(query)); }, [onAdd]);
    var hasMore = false; // TODO: when query returns pagination data, make this dynamic
    return (react_1.default.createElement(multiSelect_1.MBMultiSelect, { onChange: onPress, renderCreateNew: renderCreateNew, onCreate: onCreate, keyExtractor: function (card) { return card.cardId; }, max: 1, match: match, renderItem: renderItem, renderEmptyState: renderEmptyState, values: searchResults, selectedValues: [], onSearch: setKeyword, helpText: hasMore ? (0, i18n_1.i18n)('card.blocks.cardsInput.cardListLimitHelp') : undefined }));
};
exports.InCardsSheet = InCardsSheet;
