import * as React from 'react';
function SvgCheckTask(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.5 9.966c-4.033-1.806-8.92-1.082-12.255 2.192-4.18 4.108-4.345 11.004-.358 15.304 4.271 4.613 11.483 4.713 15.893.312 3.858-3.851 4.244-9.848 1.185-14.15l-9.995 9.978-4.015-4.008"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgCheckTask;

