"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileFieldOption = void 0;
var FileFieldOption = /** @class */ (function () {
    function FileFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    FileFieldOption.prototype.matchesComponent = function (component, reservedIds) {
        var isReserved = !!reservedIds.find(function (id) { return id === component.id; });
        if (component.type !== 'FILE' || isReserved) {
            return false;
        }
        var hasContentTypes = !!component.acceptedContentTypes &&
            !!this.component.acceptedContentTypes;
        if (hasContentTypes) {
            return (this.component.acceptedContentTypes[0] === 'image/*' &&
                component.acceptedContentTypes[0] ===
                    'image/*');
        }
        else {
            return (!!component.acceptedContentTypes ===
                !!this.component.acceptedContentTypes);
        }
    };
    FileFieldOption.prototype.canBeAdded = function (_components) {
        return true;
    };
    return FileFieldOption;
}());
exports.FileFieldOption = FileFieldOption;
