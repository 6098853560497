"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBStackedReactionsWithList = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var stacked_1 = require("../stacked");
var list_1 = require("../list");
/*
    Component wrapping stacked reactions with
    showing a reaction list in a modal on press.
*/
var MBStackedReactionsWithList = /** @class */ (function (_super) {
    __extends(MBStackedReactionsWithList, _super);
    function MBStackedReactionsWithList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { isListShown: false };
        _this.showList = function () { return _this.setState({ isListShown: true }); };
        _this.hideList = function () { return _this.setState({ isListShown: false }); };
        return _this;
    }
    MBStackedReactionsWithList.prototype.render = function () {
        return (React.createElement(components_1.MBView, null,
            React.createElement(components_1.MBTouchableOpacity, { onPress: this.showList, style: { alignSelf: 'flex-start' } },
                React.createElement(stacked_1.MBStackedReactions, __assign({}, this.props))),
            React.createElement(components_1.MBModalFromBottom, { visible: this.state.isListShown, title: "Reactions", onClose: this.hideList },
                React.createElement(list_1.MBReactionList, { reactions: this.props.reactions }))));
    };
    return MBStackedReactionsWithList;
}(React.PureComponent));
exports.MBStackedReactionsWithList = MBStackedReactionsWithList;
