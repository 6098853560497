"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBottomSheet = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_dom_1 = require("react-dom");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/components/bottomSheet/hooks");
var provider_1 = require("@shared/components/bottomSheet/provider");
var lodash_1 = require("lodash");
var MBBottomSheet = function (_a) {
    var children = _a.children, onClose = _a.onClose, title = _a.title, renderTitleBar = _a.renderTitleBar, containerRef = _a.containerRef, layout = _a.layout;
    var bottomSheet = (0, react_1.useContext)(provider_1.BottomSheetContext);
    var _b = (0, react_1.useState)({ x: -9999, y: -9999 }), currentPosition = _b[0], setCurrentPosition = _b[1];
    var updatePosition = (0, react_1.useCallback)(function (e) {
        var _a, _b, _c;
        var sheetHeight = e.nativeEvent.layout.height;
        var sheetWidth = e.nativeEvent.layout.width;
        var triggerNode = (0, react_dom_1.findDOMNode)(containerRef.current);
        var triggerRect = triggerNode === null || triggerNode === void 0 ? void 0 : triggerNode.getBoundingClientRect();
        var bodyRect = document.body.getBoundingClientRect();
        if ((0, lodash_1.isNil)(triggerRect) || (0, lodash_1.isNil)(bodyRect)) {
            return;
        }
        var sheetY = triggerRect.bottom + (((_a = layout === null || layout === void 0 ? void 0 : layout.offset) === null || _a === void 0 ? void 0 : _a.y) || 0);
        if (sheetHeight + sheetY > bodyRect.height) {
            sheetY = triggerRect.top - sheetHeight - (((_b = layout === null || layout === void 0 ? void 0 : layout.offset) === null || _b === void 0 ? void 0 : _b.y) || 0);
        }
        sheetY = Math.max(sheetY, 0);
        var sheetX = triggerRect.x + (((_c = layout === null || layout === void 0 ? void 0 : layout.offset) === null || _c === void 0 ? void 0 : _c.x) || 0);
        if (sheetWidth + sheetX > bodyRect.width) {
            sheetX = bodyRect.width - sheetWidth;
        }
        sheetX = Math.max(sheetX, 0);
        setCurrentPosition({ x: sheetX, y: sheetY });
    }, [containerRef, setCurrentPosition, layout]);
    var titleBar = (0, hooks_1.useTitleBar)(title, renderTitleBar);
    var content = (0, react_1.useMemo)(function () { return (react_1.default.createElement(react_native_1.View, { onLayout: updatePosition, style: [
            styles.container,
            {
                minWidth: (layout === null || layout === void 0 ? void 0 : layout.minWidth) || 'auto',
                height: (layout === null || layout === void 0 ? void 0 : layout.minHeight) || 'auto',
                maxHeight: '100%',
                maxWidth: (layout === null || layout === void 0 ? void 0 : layout.maxWidth) || 'none',
            },
            // @ts-ignore
            {
                overflow: 'auto',
            },
        ] },
        titleBar,
        children)); }, [children, titleBar, updatePosition, layout]);
    (0, react_1.useEffect)(function () {
        bottomSheet === null || bottomSheet === void 0 ? void 0 : bottomSheet.setContentAt(content, currentPosition, onClose);
        return function () {
            bottomSheet === null || bottomSheet === void 0 ? void 0 : bottomSheet.reset();
        };
    }, [content, currentPosition, bottomSheet, onClose]);
    return null;
};
exports.MBBottomSheet = MBBottomSheet;
var styles = react_native_1.StyleSheet.create({
    container: __assign(__assign(__assign({}, styles_1.SharedUnvalidatedStyles.borderRadius), styles_1.SharedUnvalidatedStyles.shadow), { backgroundColor: styles_1.Colors.v2.white, overflow: 'hidden' }),
});
