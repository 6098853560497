/* @flow */
import {Config} from '@shared/config';
import getWorker from '@src/core/ServiceWorkerRegistration';
import _ from 'lodash';

export function hasPermission() {
  const worker = getWorker();
  if (!worker) {
    if (__DEV__) {
      console.info('Feature unavailable'); // eslint-disable-line no-console
    }
    return Promise.resolve(null);
  }
  return worker.then(w => {
    if (!w.pushManager || !w.pushManager.permissionState) {
      return true; // Assume permission
    }
    return w.pushManager.permissionState({userVisibleOnly: true});
  });
}

export async function tryRegisterPushToken() {
  const worker = await getWorker();
  if (_.isNil(worker)) {
    if (__DEV__) {
      console.info('Feature unavailable'); // eslint-disable-line no-console
    }
    return null;
  }
  const subscription = await worker.pushManager.getSubscription();
  if (!_.isNil(subscription)) {
    const base64Key = btoa(
      new Uint8Array(subscription.options.applicationServerKey).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    );
    // For some reason, conversion above from ArrayBuffer to base 64 string ends with an '=', remove it
    const subscriptionServerKey = base64Key.slice(0, -1);
    if (subscriptionServerKey !== Config.VAPID_PUBLIC_KEY) {
      await subscription.unsubscribe();
    }
  }
  return worker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: Config.VAPID_PUBLIC_KEY
  });
}
