import * as React from 'react';
function SvgCardSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="card-small_svg__a" fill="#fff">
        <rect y={3} width={24} height={18} rx={2} />
      </mask>
      <rect
        y={3}
        width={24}
        height={18}
        rx={2}
        stroke={props.color}
        strokeWidth={6}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
        mask="url(#card-small_svg__a)"
      />
      <path stroke={props.color} strokeWidth={3} d="M4 9.5h16M5 14.5h9" />
    </svg>
  );
}
export default SvgCardSmall;

