import * as React from 'react';
function SvgArrowPointRightCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 0C10.766 0 0 10.766 0 24s10.766 24 24 24 24-10.766 24-24S37.234 0 24 0zm-4 34.828L17.172 32l8-8-8-8L20 13.172 30.828 24 20 34.828z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgArrowPointRightCircle;

