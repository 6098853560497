import * as React from 'react';
function SvgArrowLinkOutSmall3(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.2 12.8L20 4" stroke={props.color} strokeWidth={3} strokeMiterlimit={10} />
      <path
        d="M13.6 4H20v6.4M4 4v16h16"
        stroke={props.color}
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgArrowLinkOutSmall3;

