/* @flow */
import type { ActionCreator } from '@src/types/ReduxTypes';
import type { Type } from '@src/types/ToastrTypes';

// Note(fant): dispatching to the store without being connected to Redux is an anti-pattern
import { store } from '@shared/store';

export default function addMessageToastr(message: string, type: Type = 'success'): ActionCreator {
  return store.dispatch({ type: 'UPDATE_TOASTR', message, toastrType: type });
}
