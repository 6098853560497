"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnboardingWorkflowIdToInstallStore = exports.DefaultWorkflowIdToInstallStore = void 0;
// @ts-strict-ignore
var react_native_1 = require("react-native");
var STORAGE_ID_KEY = 'workflow-id-to-install';
var ONBAORDING_STORAGE_ID_KEY = 'onboarding-workflow-id-to-install';
var WorkflowIdToInstallStore = /** @class */ (function () {
    function WorkflowIdToInstallStore(storageKey) {
        this.storageKey = storageKey;
    }
    WorkflowIdToInstallStore.prototype.workflowIdToInstall = function () {
        if (react_native_1.Platform.OS !== 'web') {
            return null;
        }
        return window.sessionStorage.getItem(this.storageKey);
    };
    WorkflowIdToInstallStore.prototype.addWorkflowIdToStore = function (workflowId) {
        if (react_native_1.Platform.OS === 'web') {
            window.sessionStorage.setItem(this.storageKey, workflowId);
        }
    };
    WorkflowIdToInstallStore.prototype.clearStore = function () {
        if (react_native_1.Platform.OS === 'web') {
            window.sessionStorage.removeItem(this.storageKey);
        }
    };
    return WorkflowIdToInstallStore;
}());
exports.DefaultWorkflowIdToInstallStore = new WorkflowIdToInstallStore(STORAGE_ID_KEY);
exports.OnboardingWorkflowIdToInstallStore = new WorkflowIdToInstallStore(ONBAORDING_STORAGE_ID_KEY);
