import * as React from 'react';
function SvgTicket(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 20a3 3 0 013-3v-5H9v5a3 3 0 110 6v5h22v-5a3 3 0 01-3-3zM16 18h8M16 22h8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgTicket;

