"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBInlineCard = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var ICON_SIZE = (0, components_1.grid)(3);
var ICON_RIGHT_PADDING = (0, components_1.grid)(1);
var MBInlineCard = function (_a) {
    var card = _a.card, onPress = _a.onPress, onRemove = _a.onRemove;
    var colors = react_1.default.useContext(styles_1.ColorThemeContext);
    var onClickCard = (0, react_1.useMemo)(function () {
        if (onPress) {
            return function () {
                onPress === null || onPress === void 0 ? void 0 : onPress(card);
            };
        }
    }, [card, onPress]);
    var cardPill = (0, react_1.useMemo)(function () {
        return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onClickCard, style: styles.container },
            react_1.default.createElement(components_1.MBView, { style: styles.card },
                react_1.default.createElement(components_1.MBView, { style: { paddingRight: ICON_RIGHT_PADDING } },
                    react_1.default.createElement(components_1.MBIconV2, { name: "arrow-link-out", color: colors.foregroundActive, size: ICON_SIZE })),
                react_1.default.createElement(components_1.MBView, { style: styles.cardName },
                    react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, card.name)))));
    }, [card.name, colors.foregroundActive, onClickCard]);
    if (!onRemove) {
        return cardPill;
    }
    return (react_1.default.createElement(components_1.MBView, { row: true, style: { justifyContent: 'space-between' } },
        cardPill,
        react_1.default.createElement(components_1.MBView, { fill: true }),
        react_1.default.createElement(components_1.MBClearButton, { onPress: onRemove })));
};
exports.MBInlineCard = MBInlineCard;
var styles = react_native_1.StyleSheet.create({
    container: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        flexWrap: 'wrap',
        maxWidth: '75%',
    },
    card: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        border: "1px solid ".concat(styles_1.Colors.v2.gray10),
        borderRadius: (0, components_1.grid)(1),
        marginBottom: (0, components_1.grid)(0.5),
        marginRight: (0, components_1.grid)(0.5),
        boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.1)',
        alignItems: 'flex-start',
        backgroundColor: 'white',
        paddingLeft: (0, components_1.grid)(1),
        paddingRight: (0, components_1.grid)(2),
        paddingTop: (0, components_1.grid)(1),
        paddingBottom: (0, components_1.grid)(1),
    },
    cardName: __assign(__assign({ display: 'flex', flexWrap: 'wrap', flex: '1 1 auto', paddingTop: 2 }, components_1.MBTypographyStylesRaw.default), { lineHeight: '24px' }),
});
