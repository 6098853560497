"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardActionsSheet = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var bottomSheet_1 = require("@shared/components/bottomSheet");
var provider_1 = require("@shared/util/navigation/stack/provider");
var filters_1 = require("./filters");
var filterByTag_1 = require("./filterByTag");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var i18n_1 = require("@shared/util/i18n");
var react_native_1 = require("react-native");
var provider_2 = require("@shared/scenes/cards/actions/provider");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var react_redux_1 = require("react-redux");
var filterByAssignee_1 = require("./filterByAssignee");
var filterByDate_1 = require("./filterByDate");
var filterByCard_1 = require("@shared/scenes/cards/actions/sheets/filterByCard");
var WIDTH = 360;
var HEIGHT = 420;
var getFilterRoute = function (type) {
    switch (type) {
        case 'assignee':
            return 'filterByAssignee';
        case 'date':
            return 'filterByDate';
        case 'card':
            return 'filterByCard';
        case 'tag':
            return 'filterByTag';
        default:
            return undefined;
    }
};
var MBCardActionsSheet = function (_a) {
    var onClose = _a.onClose, containerRef = _a.containerRef;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var nav = (0, provider_1.useNavStack)([{ name: 'filters' }]);
    var title = (0, react_1.useMemo)(function () {
        switch (nav.currentRoute.name) {
            case 'filters':
                return (0, i18n_1.i18n)('card.actions.filters.label');
            case 'filterByTag':
                return nav.currentRoute.params.title;
            case 'filterByAssignee':
                return nav.currentRoute.params.title;
            case 'filterByDate':
                return nav.currentRoute.params.title;
            case 'filterByCard':
                return nav.currentRoute.params.title;
            default:
                return undefined;
        }
    }, [nav.currentRoute]);
    var children = (0, react_1.useMemo)(function () {
        switch (nav.currentRoute.name) {
            case 'filters':
                return (react_1.default.createElement(filters_1.MBCardActionsFiltersSheet, { onPress: function (fieldName, fieldTitle, type) {
                        return nav.push({
                            name: getFilterRoute(type),
                            params: { fieldName: fieldName, title: fieldTitle },
                        });
                    } }));
            case 'filterByTag':
                return react_1.default.createElement(filterByTag_1.MBCardActionsFilterByTagSheet, { name: nav.currentRoute.params.fieldName });
            case 'filterByAssignee':
                return react_1.default.createElement(filterByAssignee_1.MBCardActionsFilterByAssigneeSheet, { name: nav.currentRoute.params.fieldName });
            case 'filterByDate':
                return react_1.default.createElement(filterByDate_1.MBCardActionsFilterByDateSheet, { name: nav.currentRoute.params.fieldName });
            case 'filterByCard':
                return react_1.default.createElement(filterByCard_1.MBCardActionsFilterByCardSheet, { name: nav.currentRoute.params.fieldName });
            default:
                return null;
        }
    }, [nav]);
    var renderTitleBar = (0, react_1.useCallback)(function () {
        if (nav.currentRoute.name === 'filters') {
            return react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: title });
        }
        return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: title, leftIcon: "arrow-point-left", onLeftIconPress: nav.pop }));
    }, [nav.currentRoute, nav.pop, title]);
    var cardFilterStateContextProxy = (0, react_1.useContext)(provider_2.CardFilterStateContext);
    var cardChannelContextProxy = (0, react_1.useContext)(channel_1.CardChannelContext);
    return (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { initialSize: "full", onClose: onClose, renderTitleBar: renderTitleBar, containerRef: containerRef, layout: isDesktop ? { offset: { x: 0, y: 10 }, minWidth: WIDTH, minHeight: HEIGHT } : undefined },
        react_1.default.createElement(channel_1.CardChannelContext.Provider, { value: cardChannelContextProxy },
            react_1.default.createElement(provider_2.CardFilterStateContext.Provider, { value: cardFilterStateContextProxy }, isDesktop ? react_1.default.createElement(react_native_1.View, { style: { width: WIDTH, height: HEIGHT } }, children) : children))));
};
exports.MBCardActionsSheet = MBCardActionsSheet;
exports.MBCardActionsSheet.displayName = 'MBCardActionsSheet';
