"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextFieldOption = void 0;
var TextFieldOption = /** @class */ (function () {
    function TextFieldOption(title, icon, component) {
        this.title = title;
        this.icon = icon;
        this.component = component;
    }
    TextFieldOption.prototype.matchesComponent = function (component, reservedIds) {
        var isReserved = !!reservedIds.find(function (id) { return id === component.id; });
        return (!isReserved &&
            component.type === 'TEXT' &&
            component.multiline === this.component.multiline);
    };
    TextFieldOption.prototype.canBeAdded = function (_components) {
        return true;
    };
    return TextFieldOption;
}());
exports.TextFieldOption = TextFieldOption;
