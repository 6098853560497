import * as React from 'react';
function SvgPlaceholderEditProfile(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 171 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="placeholder-edit-profile_svg__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={171}
        height={171}
      >
        <circle cx={85.5} cy={85.5} r={85.5} fill="#F7F7F7" />
      </mask>
      <g mask="url(#placeholder-edit-profile_svg__a)" strokeWidth={13} strokeMiterlimit={10}>
        <path
          d="M12.58 86.358c13.98 0 25.314-11.34 25.314-25.329S26.56 35.7 12.58 35.7c-13.98 0-25.315 11.34-25.315 25.329S-1.402 86.358 12.58 86.358z"
          stroke="#DAF6F0"
        />
        <path
          d="M-23.225 131.374c19.71-19.721 51.716-19.721 71.516 0"
          stroke="#DAF6F0"
          strokeLinecap="square"
        />
        <path
          d="M156.579 86.358c13.981 0 25.315-11.34 25.315-25.329S170.56 35.7 156.579 35.7c-13.981 0-25.315 11.34-25.315 25.329s11.334 25.329 25.315 25.329z"
          stroke="#DAF6F0"
        />
        <path
          d="M120.775 131.374c19.71-19.721 51.715-19.721 71.515 0"
          stroke="#DAF6F0"
          strokeLinecap="square"
        />
        <path
          d="M84.58 86.358c13.98 0 25.314-11.34 25.314-25.329S98.56 35.7 84.579 35.7c-13.98 0-25.315 11.34-25.315 25.329S70.598 86.358 84.58 86.358z"
          stroke="#00CAAC"
        />
        <path
          d="M48.775 131.374c19.71-19.721 51.715-19.721 71.515 0"
          stroke="#00CAAC"
          strokeLinecap="square"
        />
      </g>
      <circle cx={85.5} cy={85.5} r={81.5} stroke="#DAF6F0" strokeWidth={8} />
    </svg>
  );
}
export default SvgPlaceholderEditProfile;

