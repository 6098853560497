import * as React from 'react';
function SvgSignatureBallpointPen(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 31h22M11.828 25.172a2.828 2.828 0 010-4L23.167 9.833a2.828 2.828 0 114 4L15.828 25.172a2.83 2.83 0 01-4 0zM11.828 25.172L10 27M29 16l1 1-5 5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgSignatureBallpointPen;

