"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFragmentTemplateSummary = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("../../hooks");
var MBFragmentTemplateSummary = function (_a) {
    var template = _a.template, onPress = _a.onPress;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var itemsLabel = (0, react_1.useMemo)(function () {
        var _a, _b;
        return (0, i18n_1.i18n)('channel.options.fragmentTemplates.itemsLabel', {
            count: ((_b = (_a = template.template) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length) || 0,
        });
    }, [template]);
    var onPressContainer = (0, react_1.useCallback)(function () { return onPress(template); }, [template, onPress]);
    var _b = (0, hooks_1.useTemplateOptions)(template), displayOptions = _b.displayOptions, loading = _b.loading;
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPressContainer },
        react_1.default.createElement(components_1.MBView, { row: true, padding: 2, style: [styles.container, { backgroundColor: colors.backgroundInactive }] },
            react_1.default.createElement(components_1.MBIconV2, { name: "list", color: colors.foregroundActive }),
            react_1.default.createElement(components_1.MBView, { style: styles.rightColumn, flex: true },
                react_1.default.createElement(components_1.MBTextBody1Bold, null, template.name),
                react_1.default.createElement(components_1.MBTextBody1, null, itemsLabel)),
            loading ? (react_1.default.createElement(components_1.MBLoadingIndicator, { paddingBottom: 1, paddingRight: 1 })) : (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: displayOptions },
                react_1.default.createElement(components_1.MBIconV2, { name: "dots", color: colors.foregroundActive, size: (0, components_1.grid)(4) }))))));
};
exports.MBFragmentTemplateSummary = MBFragmentTemplateSummary;
var styles = react_native_1.StyleSheet.create({
    container: {
        borderRadius: (0, components_1.grid)(1),
        marginBottom: (0, components_1.grid)(2),
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: (0, components_1.grid)(1),
    },
});
exports.MBFragmentTemplateSummary.displayName = 'MBFragmentTemplateSummary';
