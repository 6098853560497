/* @flow */
import React from 'react';

import UserImage from '@src/components/UserImage';

import styles from './LoginUserRow.css';

type Props = {
  firstName: string,
  lastName: string,
  id: number,
  img: string,
  isNew: boolean,
  isLast: boolean,
  onClick: (ev: BrowserEvent, userId: number) => void,
};

export default function LoginUserRow({
  id, img, firstName, lastName, isNew, isLast, onClick,
}: Props) {
  return (
    <div
      className={isLast ? styles.rootLast : styles.root}
      onClick={ev => onClick(ev, id)}
    >
      <UserImage url={img} size="3rem" />
      <div className={styles.name}>
        {firstName} {lastName}
      </div>
      {isNew && <div className={styles.new}>New User!</div>}
    </div>
  );
}
