import * as React from 'react';
function SvgFaceSkull(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 31v-3M22 31v-3M15.5 22a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM24.5 22a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M30 19a10 10 0 10-16 7.986V30a1 1 0 001 1h10a1 1 0 001-1v-3.014A9.973 9.973 0 0030 19v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgFaceSkull;

