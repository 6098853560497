import * as React from 'react';
function SvgTimeAlarm(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 15v5h5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M11 31l3.132-4.176" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M20 29a9 9 0 100-18 9 9 0 000 18z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M9 14l5-5M25.868 26.824L29 31M31 14l-5-5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgTimeAlarm;

