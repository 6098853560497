"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBPlatformTimePicker = void 0;
var _1 = require(".");
/**
 * Time picker component that automatically renders in the preferred way for the platform.
 *
 * On mobile, it uses the native date picker in `time` mode. On web, it uses `MBTimePicker`.
 *
 * The parent is responsible for hiding the picker whenever `onHide` is called. The presence of this component in
 * the parent's render tree will cause the picker to be shown, and on Android this will be a full-screen modal which is
 * unlike the inline controls on web or iOS. On web and iOS we want this component to stick around; on Android we do
 * not. We cannot hide it from within the component because the parent needs to be able to control when the picker has
 * "focus", and is shown, again. We try to make this as easy as possible by providing the `onHide` prop. The caller
 * does not need to check the platform.
 */
var MBPlatformTimePicker = function (_a) {
    var style = _a.style, value = _a.value, onChange = _a.onChange;
    return (0, _1.MBTimePicker)({
        style: style,
        value: (value !== null && value !== void 0 ? value : new Date()).toISOString(),
        onChange: function (dateString) { return onChange(new Date(dateString)); },
    });
};
exports.MBPlatformTimePicker = MBPlatformTimePicker;
