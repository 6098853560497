import * as React from 'react';
function SvgPinWorld(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 31c-1.958-1.583-2.083-6.792 1-8 1.636-.641 2.166 2.937 5.375 2.042.621-.173 2.083.708 1.551 2.052M14.294 12.787c.631.466 1.124 1.071 1.589 1.849.405.679 1.306 2.57.481 3.638-.934 1.206-3.637 1.613-3.637 2.726 0 .837 1.185 1.793 1.818 2.727.952 1.405.519 2.767 0 3.636-.269.451-.727.811-1.203 1.097"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M29.8 19c.131.646.2 1.315.2 2 0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10c.494 0 .98.036 1.455.105"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M29 13c0 2.469-4 6-4 6s-4-3.531-4-6c0-2.531 2.067-4 4-4 1.933 0 4 1.469 4 4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPinWorld;

