import * as React from 'react';
function SvgLink(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.392 21.464l-5.856-5.856 4.392-4.393a4.136 4.136 0 015.857 0 4.136 4.136 0 010 5.857l-4.393 4.392zM11.215 28.785a4.136 4.136 0 010-5.857l4.393-4.392 5.856 5.856-4.392 4.393a4.136 4.136 0 01-5.857 0zM23.66 16.34l-7.32 7.32"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgLink;

