"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.Styles = react_native_1.StyleSheet.create({
    container: __assign(__assign({}, styles_1.SharedUnvalidatedStyles.borderRadius), { overflow: 'hidden' }),
    modalContainer: {
        width: 896,
        maxWidth: '90vw',
        height: 768,
        maxHeight: '95vh',
    },
    menuContainer: {
        backgroundColor: styles_1.Colors.v2.gray05,
        width: 270,
        alignSelf: 'stretch',
    },
    desktopContentContainer: {
        paddingTop: 50,
        paddingHorizontal: 20,
    },
    whiteBackground: {
        backgroundColor: 'white',
    },
});
