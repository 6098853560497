import * as React from 'react';
function SvgAppCircles(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 18a4 4 0 100-8 4 4 0 000 8zM14 18a4 4 0 100-8 4 4 0 000 8zM26 30a4 4 0 100-8 4 4 0 000 8zM14 30a4 4 0 100-8 4 4 0 000 8z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgAppCircles;

