import * as React from 'react';
function SvgAvatarMulti(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 31H9v-4.313c0-1.213.725-2.307 1.846-2.772C11.981 23.445 13.67 23 16 23s4.019.445 5.154.916A2.994 2.994 0 0123 26.687V31zM16 20a4 4 0 100-8 4 4 0 000 8zM31 19.836c0-.604-.265-1.179-.738-1.554C29.539 17.708 28.285 17 26.5 17s-3.039.708-3.762 1.282c-.473.375-.738.95-.738 1.554V21h9v-1.164zM26.5 14a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgAvatarMulti;

