/* @flow */
import Segment from '@src/core/Segment';
import PureComponent from '@src/components/PureComponent';
import type { Step } from '@common/AuthTypes';

type Props = {
  location: {
    state?: Step
  },
  routes: Array<Object>,
  params: Object,
  children: Array<any>,
};

export default class AnalyticsContainer extends PureComponent {
  props: Props;

  componentDidMount() {
    this.instrumentRoute();
  }

  componentDidUpdate() {
    this.instrumentRoute();
  }

  render() {
    return this.props.children;
  }

  instrumentRoute() {
    let routePattern = this.props.routes.reduce((acc, route) => {
      if (route.path && route.path.length > 1) {
        return `${acc}${route.path}/`;
      }
      return acc;
    }, '');

    if (this.props.location.state) {
      routePattern += `${this.props.location.state.step}`;
    }

    if (routePattern[0] !== '/') {
      routePattern = `/${routePattern}`;
    }

    if (routePattern.length > 1) {
      Segment.page(routePattern, this.props.params);
    }
  }
}
