import * as React from 'react';
function SvgEyeHide(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 19.8s4.8-8.4 12-8.4 12 8.4 12 8.4-4.8 8.4-12 8.4-12-8.4-12-8.4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 23.4a3.6 3.6 0 100-7.2 3.6 3.6 0 000 7.2zM9.2 30.6L30.8 9"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgEyeHide;

