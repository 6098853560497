"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reset = exports.setDetails = exports.updateWorkflowTemplateName = exports.updateIsImageUploading = exports.updateImageUrl = exports.updateDescription = exports.updateEmoji = exports.updateName = exports.RESET = exports.SET_DETAILS = exports.UPDATE_WORKFLOW_TEMPLATE_NAME = exports.UPDATE_EMOJI = exports.UPDATE_IS_IMAGE_UPLOADING = exports.UPDATE_IMAGE_URL = exports.UPDATE_DESCRIPTION = exports.UPDATE_NAME = void 0;
exports.UPDATE_NAME = 'CHANNEL:CREATE:UPDATE_NAME';
exports.UPDATE_DESCRIPTION = 'CHANNEL:CREATE:UPDATE_DESCRIPTION';
exports.UPDATE_IMAGE_URL = 'CHANNEL:CREATE:UPDATE_IMAGE_URL';
exports.UPDATE_IS_IMAGE_UPLOADING = 'CHANNEL:CREATE:UPDATE_IS_IMAGE_UPLOADING';
exports.UPDATE_EMOJI = 'CHANNEL:CREATE:EMOJI';
exports.UPDATE_WORKFLOW_TEMPLATE_NAME = 'CHANNEL:CREATE:UPDATE_WORKFLOW_TEMPLATE';
exports.SET_DETAILS = 'CHANNEL:CREATE:STATE';
exports.RESET = 'CHANNEL:CREATE:RESET';
var updateName = function (name) { return ({ type: exports.UPDATE_NAME, value: name }); };
exports.updateName = updateName;
var updateEmoji = function (emoji) { return ({ type: exports.UPDATE_EMOJI, value: emoji }); };
exports.updateEmoji = updateEmoji;
var updateDescription = function (description) { return ({
    type: exports.UPDATE_DESCRIPTION,
    value: description,
}); };
exports.updateDescription = updateDescription;
var updateImageUrl = function (imageUrl) { return ({
    type: exports.UPDATE_IMAGE_URL,
    value: imageUrl,
}); };
exports.updateImageUrl = updateImageUrl;
var updateIsImageUploading = function (isUploading) { return ({
    type: exports.UPDATE_IS_IMAGE_UPLOADING,
    value: isUploading,
}); };
exports.updateIsImageUploading = updateIsImageUploading;
var updateWorkflowTemplateName = function (name) { return ({
    type: exports.UPDATE_WORKFLOW_TEMPLATE_NAME,
    value: name,
}); };
exports.updateWorkflowTemplateName = updateWorkflowTemplateName;
var setDetails = function (details) { return ({
    type: exports.SET_DETAILS,
    value: details,
}); };
exports.setDetails = setDetails;
exports.reset = { type: exports.RESET };
