import * as React from 'react';
function SvgFlowerLotus(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.39 30.56a6.24 6.24 0 01-5.61-3.25c-2.003-3.446.482-12.662.482-12.662s1.155.303 2.724.827M28.014 15.475a47.843 47.843 0 012.724-.827s2.485 9.216.481 12.662a6.24 6.24 0 01-5.61 3.25"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M12.788 21.94C12.788 17.453 20 10 20 10s7.212 7.453 7.212 11.94A7.138 7.138 0 0120 29.233a7.139 7.139 0 01-7.212-7.293v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgFlowerLotus;

