"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBGroupByPicker = void 0;
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var context_1 = require("../../../context");
var hook_1 = require("@shared/util/actionSheet/hook");
var useFieldMetadata_1 = require("@shared/scenes/cards/useFieldMetadata");
var i18n_1 = require("@shared/util/i18n");
var MBGroupByPicker = function (_a) {
    var _b;
    var style = _a.style;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _c = (0, react_1.useContext)(context_1.CardCalendarContext), groupBy = _c.groupBy, onChangeGroupBy = _c.onChangeGroupBy;
    var unfilteredFields = (0, useFieldMetadata_1.useFieldMetadata)();
    var fields = (0, react_1.useMemo)(function () { return unfilteredFields.filter(function (f) { return f.type !== 'date'; }); }, [unfilteredFields]);
    var groupingByField = (0, react_1.useMemo)(function () {
        if (!groupBy) {
            return undefined;
        }
        switch (groupBy.type) {
            case 'assignees':
                return fields.find(function (f) { return f.type === 'assignee'; });
            case 'tag':
                return fields.find(function (f) { return f.type === 'tag' && f.name === groupBy.tagName; });
            case 'card':
                return fields.find(function (f) { return f.type === 'card' && f.name === groupBy.cardName; });
        }
    }, [groupBy, fields]);
    var showActionSheet = (0, hook_1.useActionSheet)({
        webHorizontalAlignment: 'left',
        options: __spreadArray([
            {
                title: (0, i18n_1.i18n)('card.calendar.grouping.noGrouping'),
                onPress: function () { return onChangeGroupBy(undefined); },
            }
        ], fields.map(function (field) { return ({
            title: field.title,
            onPress: function () {
                switch (field.type) {
                    case 'assignee':
                        onChangeGroupBy({ type: 'assignees' });
                        break;
                    case 'card':
                        onChangeGroupBy({
                            type: 'card',
                            cardName: field.name,
                            noCardSection: { enabled: true },
                        });
                        break;
                    case 'tag':
                        onChangeGroupBy({
                            type: 'tag',
                            tagName: field.name,
                            noTagSection: { enabled: !field.required },
                        });
                        break;
                }
            },
        }); }), true),
    });
    var mergedStyles = (0, react_1.useMemo)(function () { return (__assign(__assign({}, style), { borderTopColor: styles_1.Colors.v2.gray10, borderTopWidth: 1, borderTopStyle: 'solid', borderRightColor: styles_1.Colors.v2.gray10, borderRightWidth: 1, borderRightStyle: 'solid' })); }, [style]);
    return (react_1.default.createElement("div", { style: mergedStyles },
        react_1.default.createElement(components_1.MBTouchableOpacity, { style: {
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingHorizontal: (0, components_1.grid)(1),
            }, onPress: showActionSheet },
            !!(groupingByField === null || groupingByField === void 0 ? void 0 : groupingByField.icon) && (react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: groupingByField === null || groupingByField === void 0 ? void 0 : groupingByField.icon, size: (0, components_1.grid)(3) })),
            react_1.default.createElement(typography_1.MBTextBody2Bold, { numberOfLines: 1, style: { flex: 1 } }, (_b = groupingByField === null || groupingByField === void 0 ? void 0 : groupingByField.title) !== null && _b !== void 0 ? _b : (0, i18n_1.i18n)('card.calendar.grouping.selectGrouping')),
            react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: "arrow-point-down", size: (0, components_1.grid)(3) }))));
};
exports.MBGroupByPicker = MBGroupByPicker;
