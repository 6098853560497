/* @flow */
import React from 'react';

import styles from './NavBarTitle.css';

type Props = {
  children?: string,
};

export default function NavBarTitle({ children }: Props) {
  return (
    <strong className={styles.root}>{children}</strong>
  );
}
