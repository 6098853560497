import * as React from 'react';
function SvgMagnifyingGlass(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.907 29.276a10.988 10.988 0 01-13.684-1.498c-4.402-4.41-4.292-11.622.312-15.894 4.274-3.96 11.116-3.822 15.243.294 3.862 3.858 4.256 9.876 1.193 14.185l5.136 5.137"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgMagnifyingGlass;

