import * as React from 'react';
function SvgBullhorn(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.977 25.274l2.22 4.999c.426.977-.003 2.123-.961 2.558a1.885 1.885 0 01-2.508-.979l-4.276-9.77"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M26.727 28.287l-9.454-6.027h-4.727C10.587 22.26 9 20.641 9 18.644c0-1.998 1.587-3.617 3.546-3.617h4.727L26.727 9v19.287z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M32.636 18.644H35M31.211 13.22l2.048-1.206M31.211 24.068l2.048 1.206"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M13.727 15.027v7.233"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgBullhorn;

