import * as React from 'react';
function SvgCheckSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0C5.383 0 0 5.383 0 12c0 6.616 5.383 12 12 12 6.616 0 12-5.384 12-12 0-6.617-5.384-12-12-12zm0 21c-4.963 0-9-4.037-9-9s4.037-9 9-9 9 4.037 9 9-4.037 9-9 9z"
        fill={props.color}
      />
      <path
        d="M10.5 17.12L5.379 12l2.12-2.121 3 3 6-6 2.122 2.12-8.121 8.122z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgCheckSmall;

