import * as React from 'react';
function SvgCreditCard(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 15h22M9 19h22M29 29H11a2 2 0 01-2-2V13a2 2 0 012-2h18a2 2 0 012 2v14a2 2 0 01-2 2zM13 24h5M26 24h1"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgCreditCard;

