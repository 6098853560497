"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateWorkflowAutomationEvent = useCreateWorkflowAutomationEvent;
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var react_1 = require("react");
function useCreateWorkflowAutomationEvent() {
    var apollo = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(function (input) {
        return apollo.mutate({
            mutation: api_1.Mutations.automation.createWorkflowAutomationEvent,
            variables: {
                input: input,
            },
        });
    }, [apollo]);
}
