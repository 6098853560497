import * as React from 'react';
function SvgMoneyRefund(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 20a10 10 0 1010-10 9.836 9.836 0 00-8.9 5.5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M10.264 10.57l.836 4.93 4.93-.836"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M23.6 21.868a2.273 2.273 0 01-.825 1.833 4.1 4.1 0 01-2.31.829V26h-.982v-1.442a7.96 7.96 0 01-3.07-.617v-1.893c.481.23.984.412 1.5.545a8.055 8.055 0 001.568.28v-2.224L19 20.463a5.353 5.353 0 01-2.012-1.216 2.334 2.334 0 01-.588-1.625 2.122 2.122 0 01.814-1.71c.66-.49 1.45-.772 2.27-.812V14h.982v1.069a8.134 8.134 0 012.97.66l-.674 1.678a7.754 7.754 0 00-2.3-.559v2.116c.693.243 1.36.556 1.991.932.351.22.648.516.867.868.191.336.288.718.28 1.104zm-5.042-4.246a.679.679 0 00.219.52c.212.177.45.319.707.42v-1.686c-.617.091-.925.34-.926.746zm2.884 4.354a.647.647 0 00-.244-.509 3.174 3.174 0 00-.732-.431v1.786c.65-.11.975-.392.976-.846z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgMoneyRefund;

