"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedCardOptions = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var hooks_1 = require("@shared/components/blocks/lib/cards/hooks");
var MultiSelect_1 = require("./multiSelect/MultiSelect");
var Pill_1 = require("@shared/components/pills/Pill");
var Option_1 = require("./multiSelect/Option");
var Selection_1 = require("./multiSelect/Selection");
var useGetRelatedCardDataFromSelection_1 = require("./hooks/useGetRelatedCardDataFromSelection");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var lodash_1 = require("lodash");
var i18n_1 = require("@shared/util/i18n");
var Option = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var data = props.data;
    var isEmptyFilter = data.value === 'empty';
    return (react_1.default.createElement(Option_1.Option, __assign({}, props, { icon: data.filter.icon }),
        isEmptyFilter && react_1.default.createElement(typography_1.MBTextBody1, { style: { marginLeft: (0, view_1.grid)(1) } }, data.label),
        !isEmptyFilter && react_1.default.createElement(Pill_1.Pill, { sections: [data.label], maxWidth: 200 })));
};
var MultiValue = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var data = props.data;
    return (react_1.default.createElement(Selection_1.Selection, __assign({}, props),
        react_1.default.createElement(Pill_1.Pill, { sections: [data.label], maxWidth: 225, onRemove: function () {
                return props.setValue(__spreadArray([], props.getValue().filter(function (v) { return v.value !== data.value; }), true), 'deselect-option');
            } })));
};
var RelatedCardOptions = function (_a) {
    var _b;
    var filter = _a.filter, setOption = _a.setOption, selectedOptions = _a.selectedOptions, includeEmptyOption = _a.includeEmptyOption;
    var selections = ((_b = selectedOptions.get(filter.uuid)) === null || _b === void 0 ? void 0 : _b.selection) || [];
    var _c = (0, react_1.useState)([]), options = _c[0], setOptions = _c[1];
    var _d = (0, hooks_1.useSearchCards)(null, filter.component), onSearch = _d.onSearch, searchResults = _d.searchResults;
    var defaultSelectionsWithData = (0, useGetRelatedCardDataFromSelection_1.useGetRelatedCardDataFromSelection)(selections);
    var onChange = function (selected) {
        setOption(filter, 'updateSelections', selected);
    };
    (0, react_1.useEffect)(function () {
        setOptions(__spreadArray(__spreadArray([], (includeEmptyOption
            ? [
                {
                    value: 'empty',
                    label: (0, i18n_1.i18n)('card.actions.filters.emptyLabel', { label: filter.label }),
                    filter: filter,
                },
            ]
            : []), true), ((searchResults === null || searchResults === void 0 ? void 0 : searchResults.map(function (sr) { return ({ value: sr.cardId, label: sr.name, filter: filter }); })) || []), true));
    }, [searchResults, filter, includeEmptyOption]);
    // prevent render until data is available since react-select doesn't update defaults
    if (!(0, lodash_1.isEmpty)(selections) && (0, lodash_1.isEmpty)(defaultSelectionsWithData)) {
        return null;
    }
    return (react_1.default.createElement(MultiSelect_1.OpenView, { componentId: filter.id, options: options, onSearch: onSearch, onChange: onChange, selectedOptions: defaultSelectionsWithData, components: { Option: Option, MultiValue: MultiValue } }));
};
exports.RelatedCardOptions = RelatedCardOptions;
