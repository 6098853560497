import * as React from 'react';
function SvgButtonPress(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 15H13a2 2 0 01-2-2v-2a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2h-1"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M29 31v-9c0-1.1-.886-2-1.97-2h-5.909v-6c0-1.1-.886-2-1.97-2-1.083 0-1.97.9-1.97 2v11l-2.659-2.1c-.788-.6-1.773-.8-2.659-.4-.788.3-1.083 1.2-.69 1.8l4.039 6.7"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgButtonPress;

