import * as React from 'react';
function SvgHatBaseball(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 22s-4 1.4-4 3c0 1.8 6.2 4 10 4 4 0 10-5 10-5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 10.2c4 .9 7 4.5 7 8.8v5.5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M31 23s-2.7 2-9 2-9-2-9-2v-4c0-5 4-9 9-9s9 4 9 9v4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgHatBaseball;

