import * as React from 'react';
function SvgStarOutline(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 7.112l3.863 7.825 8.637 1.255-6.25 6.093 1.475 8.602L20 26.828l-7.725 4.06 1.475-8.602-6.25-6.093 8.637-1.255L20 7.112z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgStarOutline;

