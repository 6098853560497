"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUserNotificationSettings = void 0;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var alert_1 = require("@shared/util/alert");
var i18n_1 = require("@shared/util/i18n");
var styles_1 = require("@shared/styles");
var description_1 = require("./description");
var snooze_1 = require("../containers/snooze");
var MBUserNotificationSettings = function (_a) {
    var setting = _a.setting, onSelect = _a.onSelect, onResetSettings = _a.onResetSettings;
    return (React.createElement(components_1.MBScrollView, null,
        React.createElement(components_1.MBView, { padding: "normal" },
            React.createElement(components_1.MBText, { color: styles_1.Colors.v2.purple, bold: true, size: "large" }, (0, i18n_1.i18n)('userNotifications.snooze.title')),
            React.createElement(components_1.MBText, { color: styles_1.Colors.v2.purple, size: "small" }, (0, i18n_1.i18n)('userNotifications.snooze.description'))),
        React.createElement(snooze_1.MBSnoozeRow, null),
        React.createElement(components_1.MBView, { paddingTop: "small" }),
        React.createElement(components_1.MBSeparator, null),
        React.createElement(components_1.MBView, { padding: "normal", paddingTop: "large" },
            React.createElement(components_1.MBText, { color: styles_1.Colors.v2.purple, bold: true, size: "large" }, (0, i18n_1.i18n)('userNotifications.setting.title')),
            React.createElement(components_1.MBText, { color: styles_1.Colors.v2.purple, size: "small" }, (0, i18n_1.i18n)('userNotifications.setting.description'))),
        React.createElement(components_1.MBSelectableRow, { title: (0, i18n_1.i18n)('userNotifications.setting.options.all.title'), selected: setting === 'ALL', paddingVertical: "none", onChange: function () { return onSelect('ALL'); } }),
        React.createElement(components_1.MBSelectableRow, { title: (0, i18n_1.i18n)('userNotifications.setting.options.mentions.title'), selected: setting === 'MENTIONS', paddingVertical: "none", onChange: function () { return onSelect('MENTIONS'); } }),
        React.createElement(components_1.MBSelectableRow, { title: (0, i18n_1.i18n)('userNotifications.setting.options.none.title'), selected: setting === 'NONE', paddingVertical: "none", onChange: function () { return onSelect('NONE'); } }),
        React.createElement(description_1.MBNotificationSettingDescription, { setting: setting }),
        React.createElement(components_1.MBTouchableOpacity, { onPress: function () {
                return alert_1.MBAlert.confirm({
                    title: (0, i18n_1.i18n)('userNotifications.setting.reset.confirmation.title'),
                    message: (0, i18n_1.i18n)('userNotifications.setting.reset.confirmation.description'),
                    confirmText: (0, i18n_1.i18n)('userNotifications.setting.reset.confirmation.confirm'),
                    confirmStyle: 'destructive',
                    onConfirm: onResetSettings,
                });
            } },
            React.createElement(components_1.MBView, { center: true, paddingHorizontal: "normal", paddingBottom: "none", paddingTop: "large" },
                React.createElement(components_1.MBText, { color: styles_1.Colors.v2.red }, (0, i18n_1.i18n)('userNotifications.setting.reset.title')))),
        React.createElement(components_1.MBView, { paddingHorizontal: "normal", paddingTop: "small", paddingBottom: "normal" },
            React.createElement(components_1.MBText, { center: true, size: "small", color: styles_1.Colors.v2.gray50 }, (0, i18n_1.i18n)('userNotifications.setting.reset.description')))));
};
exports.MBUserNotificationSettings = MBUserNotificationSettings;
