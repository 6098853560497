import * as React from 'react';
function SvgPersonMan(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 31l2-5h2l2 5M14.003 14.796c.63.186 1.292.31 1.982.31 1.96 0 3.729-.808 5-2.106a7.008 7.008 0 005.014 2.106"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M14 15a6 6 0 1112 0c0 5-2.686 8-6 8s-6-3-6-8z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M16.796 21.907l-5.825 3.51A2.002 2.002 0 0010 27.132V31h20v-3.868a2 2 0 00-.971-1.715l-5.825-3.51"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgPersonMan;

