"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormTitle = useFormTitle;
exports.useFormSubmitButtonTitle = useFormSubmitButtonTitle;
exports.useTemplateWithEditContextFallback = useTemplateWithEditContextFallback;
var hooks_1 = require("@shared/scenes/cards/hooks");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
function useFormTitle(channelSlug, templateSlug, cardDefinition) {
    var template = useTemplateWithEditContextFallback(channelSlug, templateSlug, cardDefinition).template;
    return template === null || template === void 0 ? void 0 : template.title;
}
function useFormSubmitButtonTitle(channelSlug, templateSlug, cardDefinitionId) {
    var _a;
    var channel = (0, hooks_2.useGetChannelInfo)({ channelSlug: channelSlug });
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, cardDefinitionId).cardDefinition;
    var template = useTemplateWithEditContextFallback(channelSlug, templateSlug, cardDefinition).template;
    if (!channel || !template) {
        return null;
    }
    return (_a = template.submitButtonTitle) !== null && _a !== void 0 ? _a : (0, i18n_1.i18n)('card.create.submit');
}
function useTemplateWithEditContextFallback(channelSlug, templateSlug, cardDefinition) {
    // We prefer the create template where available because forms in Low Code workspaces depend on the distinction
    // when creating vs editing. No Code workspaces do not have this distinction and do not have create templates; the
    // edit template is used for both creating and editing.
    return (0, hooks_1.useCardTemplate)({
        channelSlug: channelSlug,
        cardDefinition: cardDefinition,
        templateSlug: templateSlug,
        contexts: ['create', 'edit'],
    }, true);
}
