import * as React from 'react';
function SvgUnderlineLight(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.417 9.917V20A6.417 6.417 0 0120 26.417h0A6.417 6.417 0 0113.583 20V9.917M9.917 30.083h20.166M11.75 9.917h3.667M24.583 9.917h3.667"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgUnderlineLight;

