"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInput = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var TextInput = function (_a) {
    var _b;
    var _c = _a.editable, editable = _c === void 0 ? true : _c, autosize = _a.autosize, props = __rest(_a, ["editable", "autosize"]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var hasError = ((_b = props.error) === null || _b === void 0 ? void 0 : _b.length) > 0;
    var initialBorderColor = (0, react_1.useMemo)(function () {
        if (hasError) {
            return colors.error.border;
        }
        if (editable) {
            return colors.input.borderInactive;
        }
        return colors.input.borderInactive;
    }, [hasError, editable, colors.input.borderInactive, colors.error.border]);
    var _d = (0, react_1.useState)(initialBorderColor), borderColor = _d[0], setBorderColor = _d[1];
    var textColor = (0, react_1.useMemo)(function () {
        if (hasError) {
            return colors.error.foreground;
        }
        if (editable) {
            return colors.input.foregroundActive;
        }
        return colors.input.foregroundInactive;
    }, [
        colors.error.foreground,
        colors.input.foregroundActive,
        colors.input.foregroundInactive,
        editable,
        hasError,
    ]);
    var inputStyle = (0, react_1.useMemo)(function () { return (__assign(__assign({}, props === null || props === void 0 ? void 0 : props.style), { backgroundColor: hasError ? colors.error.background : colors.backgroundInactive, color: textColor, borderRadius: (0, components_1.grid)(1), padding: (0, components_1.grid)(1), borderWidth: 1, borderStyle: 'solid', borderColor: borderColor })); }, [colors, borderColor, textColor, hasError, props]);
    var onFocus = (0, react_1.useCallback)(function () {
        var _a;
        if (hasError) {
            setBorderColor(colors.error.border);
        }
        else if (editable) {
            setBorderColor(colors.input.borderActive);
        }
        (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props);
    }, [colors, hasError, props, editable]);
    var onBlur = (0, react_1.useCallback)(function (event) {
        var _a;
        if (hasError) {
            setBorderColor(colors.error.border);
        }
        else if (editable) {
            setBorderColor(colors.input.borderInactive);
        }
        (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, event);
    }, [colors, hasError, props, editable]);
    (0, react_1.useEffect)(function () {
        if (props.autoFocus) {
            setBorderColor(colors.input.borderActive);
        }
    }, [colors.input.borderActive, props.autoFocus]);
    return (react_1.default.createElement(components_1.MBView, null,
        autosize ? (react_1.default.createElement(components_1.MBAutosizeTextInput, __assign({}, props, { onBlur: onBlur, onFocus: onFocus, style: inputStyle }))) : (react_1.default.createElement(components_1.MBTextInput, __assign({}, props, { onBlur: onBlur, onFocus: onFocus, style: inputStyle }))),
        hasError && (react_1.default.createElement(components_1.MBTextBody2, { color: colors.error.foreground, style: { marginTop: 1 } }, props.error))));
};
exports.TextInput = TextInput;
exports.TextInput.displayName = 'TextInput';
