"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardActionsFilterByTagSheet = exports.isTagFilter = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var multiSelect_1 = require("@shared/components/multiSelect");
var hooks_1 = require("@shared/components/blocks/lib/tags/hooks");
var tags_1 = require("@shared/components/blocks/lib/tags");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var field_1 = require("@shared/components/blocks/lib/field");
var styles_1 = require("@shared/styles");
var provider_1 = require("@shared/scenes/cards/actions/provider");
var lodash_1 = require("lodash");
var isTagFilter = function (fieldName) {
    return function (filter) { var _a; return filter.type === 'tag' && !!((_a = filter.mustHaveAny) === null || _a === void 0 ? void 0 : _a.some(function (tag) { return tag.fieldName === fieldName; })); };
};
exports.isTagFilter = isTagFilter;
var MBCardActionsFilterByTagSheet = function (_a) {
    var name = _a.name;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, hooks_1.useTagOptions)(name), options = _b.options, loading = _b.loading;
    var optionValues = (0, react_1.useMemo)(function () { return options.filter(function (opt) { return !opt.archivedAt; }).map(function (opt) { return opt.slug; }); }, [options]);
    var _c = (0, react_1.useContext)(provider_1.CardFilterStateContext), appliedFilters = _c.appliedFilters, setAppliedFilters = _c.setAppliedFilters;
    var renderItem = (0, react_1.useCallback)(function (value) {
        var option = (0, tags_1.getTagOption)(value, options);
        return (react_1.default.createElement(components_1.MBView, { row: true, paddingLeft: 4 },
            react_1.default.createElement(tag_1.Tag, { color: option.colorName }, (0, field_1.defaultFieldPresenter)(option.label, 'text', 'body1', colors.foregroundActive))));
    }, [colors, options]);
    var isCurrentFilter = (0, react_1.useMemo)(function () { return (0, exports.isTagFilter)(name); }, [name]);
    var handleChange = (0, react_1.useCallback)(function (values) {
        var _a;
        var otherFilters = appliedFilters.filter(function (filter) { return !isCurrentFilter(filter); });
        var changedFilter = {
            type: 'tag',
            mustHaveAny: options.filter(function (tag) { return tag.fieldName === name && values.includes(tag.slug); }),
        };
        if (!!((_a = changedFilter.mustHaveAny) === null || _a === void 0 ? void 0 : _a.length)) {
            var filters = __spreadArray(__spreadArray([], otherFilters, true), [changedFilter], false);
            setAppliedFilters(filters);
        }
        else {
            setAppliedFilters(otherFilters);
        }
    }, [appliedFilters, name, options, isCurrentFilter, setAppliedFilters]);
    var selectedValues = (0, react_1.useMemo)(function () { var _a, _b; return (_b = (_a = appliedFilters.find(isCurrentFilter)) === null || _a === void 0 ? void 0 : _a.mustHaveAny.map(function (tag) { return tag.slug; })) !== null && _b !== void 0 ? _b : []; }, [appliedFilters, isCurrentFilter]);
    var labelExtractor = (0, react_1.useCallback)(function (value) { var _a; return (_a = (0, tags_1.getTagOption)(value, options)) === null || _a === void 0 ? void 0 : _a.label; }, [options]);
    var match = (0, react_1.useCallback)(function (value, query, exact) {
        var label = labelExtractor(value);
        if ((0, lodash_1.isNil)(label)) {
            return false;
        }
        if (exact) {
            return label.toLowerCase() === query.toLowerCase();
        }
        return (0, lodash_1.includes)(label.toLowerCase(), query.toLowerCase());
    }, [labelExtractor]);
    if (loading) {
        return null;
    }
    return (react_1.default.createElement(multiSelect_1.MBMultiSelect, { onChange: handleChange, renderItem: renderItem, values: optionValues, selectedValues: selectedValues, match: match }));
};
exports.MBCardActionsFilterByTagSheet = MBCardActionsFilterByTagSheet;
