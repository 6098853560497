"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFieldRecordModified = isFieldRecordModified;
exports.getModifiedFields = getModifiedFields;
var lodash_1 = require("lodash");
function isFieldRecordModified(originalFields, updatedFields) {
    // If the number of fields is different, they are dirty
    var originalFieldKeys = Object.keys(originalFields);
    var updatedFieldKeys = Object.keys(updatedFields);
    if (originalFieldKeys.length !== updatedFieldKeys.length) {
        return true;
    }
    return originalFieldKeys.some(function (componentId) {
        var originalField = originalFields[componentId];
        var updatedField = updatedFields[componentId];
        return isFieldModified(originalField, updatedField);
    });
}
function isFieldModified(originalField, updatedField) {
    // If both are null, they are not dirty
    if ((0, lodash_1.isNil)(originalField) && (0, lodash_1.isNil)(updatedField)) {
        return false;
    }
    // If either is null, they are dirty
    if ((0, lodash_1.isNil)(originalField) || (0, lodash_1.isNil)(updatedField)) {
        return true;
    }
    // If they are not equal, they are dirty
    return !originalField.isEqual(updatedField);
}
function getModifiedFields(originalFields, updatedFields) {
    return Object.keys(originalFields).reduce(function (acc, componentId) {
        var originalField = originalFields[componentId];
        var updatedField = updatedFields[componentId];
        var isModified = isFieldModified(originalField, updatedField);
        if (isModified) {
            acc[componentId] = updatedField;
        }
        return acc;
    }, {});
}
