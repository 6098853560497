import * as React from 'react';
function SvgPersonTinyHeavy(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.927 12.41a4.404 4.404 0 004.402-4.405 4.404 4.404 0 10-8.805 0 4.404 4.404 0 004.403 4.405z"
        stroke={props.color}
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M5.7 20.239c3.428-3.43 8.994-3.43 12.438 0"
        stroke={props.color}
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPersonTinyHeavy;

