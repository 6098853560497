import * as React from 'react';
function SvgSendAsUrgent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 87 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={2} width={83} height={145} rx={11} fill="#281E38" />
      <path fill="#fff" d="M7 14h73v113H7z" />
      <path
        d="M43.5 43.575c-13.206 0-23.925 10.048-23.925 22.43 0 7.267 1.495 11.962 7.477 17.943L43.5 97.406v-8.972c13.207 0 23.925-10.048 23.925-22.43 0-12.38-10.718-22.429-23.925-22.429z"
        fill="#fff"
        stroke="#FF5E84"
        strokeWidth={4}
        strokeMiterlimit={10}
      />
      <path
        d="M43.5 54.45v15.225M43.5 74.025v4.35"
        stroke="#FF5E84"
        strokeWidth={4}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgSendAsUrgent;

