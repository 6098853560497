import * as React from 'react';
function SvgLock(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 32h16V15.667H12V32zM20 6.333c-4 0-5.714 3.258-5.714 5.834v3.5h11.428v-3.5C25.714 9.59 24 6.333 20 6.333zM20 21.5V25"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 26.167c.631 0 1.143-.523 1.143-1.167S20.63 23.833 20 23.833s-1.143.523-1.143 1.167.512 1.167 1.143 1.167z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgLock;

