"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
exports.Styles = react_native_1.StyleSheet.create({
    editDetailsButtonBackground: {
        width: (0, components_1.grid)(4.5),
        height: (0, components_1.grid)(4.5),
        backgroundColor: styles_1.Colors.v2.purple,
        borderRadius: (0, components_1.grid)(4.5) / 2,
    },
    editDetailsButtonPosition: {
        position: 'absolute',
        top: (0, components_1.grid)(1),
        right: (0, components_1.grid)(1),
        zIndex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    editDetailsButtonTouchArea: {
        width: (0, components_1.grid)(6),
        height: (0, components_1.grid)(6),
    },
});
