import * as React from 'react';
function SvgArrowPointLeftCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 24c0 13.234 10.766 24 24 24s24-10.766 24-24S37.234 0 24 0 0 10.766 0 24zm17.172 0L28 13.172 30.828 16l-8 8 8 8L28 34.828 17.172 24z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgArrowPointLeftCircle;

