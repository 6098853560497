import * as React from 'react';
function SvgHatCrown(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 24h22" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M31 13l-5 4-6-6-6 6-5-4v13a2 2 0 002 2h18a2 2 0 002-2V13z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgHatCrown;

