"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBActivityFeedHeaderButton = void 0;
// @ts-strict-ignore
var activityFeed_1 = require("@shared/api/providers/activityFeed");
var components_1 = require("@shared/components");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var provider_1 = require("@shared/components/bottomSheet/provider");
var feed_1 = require("@shared/scenes/activityFeed/feed");
var i18n_1 = require("@shared/util/i18n");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var MBActivityFeedHeaderButton = function () {
    var containerRef = (0, react_1.useRef)();
    var _a = (0, react_1.useState)(false), active = _a[0], setActive = _a[1];
    var unread = (0, react_1.useContext)(activityFeed_1.ActivityFeedContext).unread;
    var toggle = (0, react_1.useCallback)(function () {
        setActive(!active);
    }, [active]);
    var bottomSheetAPI = (0, react_1.useContext)(provider_1.BottomSheetContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var close = (0, react_1.useCallback)(function () {
        var _a;
        isDesktop && ((_a = bottomSheetAPI.reset) === null || _a === void 0 ? void 0 : _a.call(bottomSheetAPI));
        setActive(false);
    }, [isDesktop, bottomSheetAPI]);
    var renderTitleBar = (0, react_1.useCallback)(function () {
        return (react_1.default.createElement(components_1.MBView, { padding: 3 },
            react_1.default.createElement(components_1.MBTextH4, null, (0, i18n_1.i18n)('activityFeed.title'))));
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { ref: containerRef, testID: "notifications-menu-button" },
            react_1.default.createElement(components_1.MBIconButton, { source: require('@shared/assets/icons/v2/bell.png'), inverted: true, onPress: toggle }),
            unread > 0 && (react_1.default.createElement(components_1.MBNotificationBadge, { count: unread, style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                } }))),
        active && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { onClose: close, renderTitleBar: renderTitleBar, containerRef: containerRef, layout: { offset: { x: 0, y: 10 }, minWidth: 375, minHeight: 500 } },
            react_1.default.createElement(feed_1.MBActivityFeed, { onBeforePress: close })))));
};
exports.MBActivityFeedHeaderButton = MBActivityFeedHeaderButton;
