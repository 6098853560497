import * as React from 'react';
function SvgPacman(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 20l9.519-5.5a11 11 0 100 10.992L20 20z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M30 22a2 2 0 100-4 2 2 0 000 4zM20.5 16a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgPacman;

