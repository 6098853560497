import * as React from 'react';
function SvgTruckDelivery(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 10.25H8.75v16.5H17v-16.5zM26 26.75h5.25V20l-4.5-1.5-1.5-5.25H17M17 26.75h3"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M23 29.75a3 3 0 100-6 3 3 0 000 6zM20.75 16.25v2.25h3"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgTruckDelivery;

