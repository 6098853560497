import * as React from 'react';
function SvgGlobal(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 9v22M9 20h22" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M20 31c3.038 0 5.5-4.925 5.5-11S23.038 9 20 9s-5.5 4.925-5.5 11 2.462 11 5.5 11z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 31c6.075 0 11-4.925 11-11S26.075 9 20 9 9 13.925 9 20s4.925 11 11 11z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgGlobal;

