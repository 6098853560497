import * as React from 'react';
function SvgPlaceholderEditBusiness(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 171 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="placeholder-edit-business_svg__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={171}
        height={171}
      >
        <circle cx={85.5} cy={85.5} r={85.5} fill="#F7F7F7" />
      </mask>
      <g
        mask="url(#placeholder-edit-business_svg__a)"
        stroke="#8CA6DA"
        strokeWidth={8}
        strokeMiterlimit={10}
        strokeLinecap="square"
      >
        <path d="M122.95 65.025V46.05h-75.9v75.9h28.463V99.812h18.974v22.138h28.463V65.025z" />
        <path d="M66.025 68.188v3.162a9.475 9.475 0 01-9.488 9.488 9.475 9.475 0 01-9.487-9.488v-3.162" />
        <path d="M85 68.188v3.162a9.475 9.475 0 01-9.487 9.488 9.475 9.475 0 01-9.488-9.488v-3.162" />
        <path d="M103.975 68.188v3.162a9.475 9.475 0 01-9.487 9.488A9.475 9.475 0 0185 71.35v-3.162" />
        <path d="M122.95 68.188v3.162a9.475 9.475 0 01-9.487 9.488 9.475 9.475 0 01-9.488-9.488v-3.162" />
      </g>
      <circle cx={85.5} cy={85.5} r={81.5} stroke="#C5D2EC" strokeWidth={8} />
    </svg>
  );
}
export default SvgPlaceholderEditBusiness;

