"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countCompletedSubformFields = exports.getDefaultViewTemplateId = void 0;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var getDefaultViewTemplateId = function (viewTemplates) {
    if (!(0, lodash_1.isNil)(viewTemplates)) {
        var viewTemplate = viewTemplates === null || viewTemplates === void 0 ? void 0 : viewTemplates.find(isDefaultViewTemplate);
        return viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.id;
    }
    return null;
};
exports.getDefaultViewTemplateId = getDefaultViewTemplateId;
var isDefaultViewTemplate = function (viewTemplate) {
    return viewTemplate.type === 'CARD' && !viewTemplate.isPublic;
};
var countCompletedSubformFields = function (components, data) {
    var componentIds = (0, lodash_1.isEmpty)(components) ? [] : components.map(function (component) { return component.id; });
    var keys = (0, lodash_1.isNil)(data) ? [] : Object.keys(data);
    var count = 0;
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        if (componentIds.includes(key) && hasAuditComponentValue(data[key])) {
            count += 1;
        }
    }
    return count;
};
exports.countCompletedSubformFields = countCompletedSubformFields;
function hasAuditComponentValue(value) {
    return !(0, lodash_1.isEmpty)(value.tags) || (!(0, lodash_1.isNil)(value.checked) && value.checked) || !(0, lodash_1.isEmpty)(value.text);
}
