"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.operators = void 0;
var i18n_1 = require("@shared/util/i18n");
exports.operators = [
    {
        label: (0, i18n_1.i18n)('card.actions.filters.fields.date.operator.empty'),
        id: 'empty',
    },
    {
        label: (0, i18n_1.i18n)('card.actions.filters.fields.date.operator.today'),
        id: 'today',
    },
    {
        label: (0, i18n_1.i18n)('card.actions.filters.fields.date.operator.exactly'),
        id: 'exactly',
    },
    {
        label: (0, i18n_1.i18n)('card.actions.filters.fields.date.operator.between'),
        id: 'between',
    },
    {
        label: (0, i18n_1.i18n)('card.actions.filters.fields.date.operator.startsOn'),
        id: 'startsOn',
    },
    {
        label: (0, i18n_1.i18n)('card.actions.filters.fields.date.operator.endsOn'),
        id: 'endsOn',
    },
    {
        label: (0, i18n_1.i18n)('card.actions.filters.fields.date.operator.daysBeforeToday'),
        id: 'daysBeforeToday',
    },
    {
        label: (0, i18n_1.i18n)('card.actions.filters.fields.date.operator.daysAfterToday'),
        id: 'daysAfterToday',
    },
];
