"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBDimensions = void 0;
var react_native_1 = require("react-native");
/*
    TODO(fant): the stories options are used when Storybook is run.
    They're hacky so no proper types exist. Consider forking
    Definitely Typed's react-native types for this. Not ideal...
    The reason we do it is because we want to select some web options
    over native options when running Storybook with Platform iOS
    forced.
*/
exports.MBDimensions = {
    get width() {
        return react_native_1.Platform.select({
            web: window.innerWidth,
            stories: window.innerWidth,
            default: react_native_1.Dimensions.get('screen').width,
        });
    },
    get height() {
        return react_native_1.Platform.select({
            web: window.innerHeight,
            stories: window.innerHeight,
            default: react_native_1.Dimensions.get('screen').height,
        });
    },
};
