import * as React from 'react';
function SvgFileSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.5 0h-18C2.6 0 2 .6 2 1.5v21c0 .9.6 1.5 1.5 1.5h12l7.5-7.5v-15c0-.9-.6-1.5-1.5-1.5zM5 3h15v12h-6v6H5V3z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgFileSmall;

