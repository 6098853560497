"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
exports.Styles = react_native_1.StyleSheet.create({
    row: { flexDirection: 'row' },
    rowReverse: { flexDirection: 'row-reverse' },
    // TODO this should be flexWrap: 'wrap'. requires usage audit to fix
    wrap: { alignSelf: 'flex-start' },
    paddingNone: { padding: 0 },
    paddingTopNone: { paddingTop: 0 },
    paddingLeftNone: { paddingLeft: 0 },
    paddingRightNone: { paddingRight: 0 },
    paddingBottomNone: { paddingBottom: 0 },
    paddingVerticalNone: { paddingVertical: 0 },
    paddingHorizontalNone: { paddingHorizontal: 0 },
    paddingTiny: { padding: 5 },
    paddingTopTiny: { paddingTop: 5 },
    paddingLeftTiny: { paddingLeft: 5 },
    paddingRightTiny: { paddingRight: 5 },
    paddingBottomTiny: { paddingBottom: 5 },
    paddingVerticalTiny: { paddingVertical: 5 },
    paddingHorizontalTiny: { paddingHorizontal: 5 },
    paddingSmall: { padding: 10 },
    paddingTopSmall: { paddingTop: 10 },
    paddingLeftSmall: { paddingLeft: 10 },
    paddingRightSmall: { paddingRight: 10 },
    paddingBottomSmall: { paddingBottom: 10 },
    paddingVerticalSmall: { paddingVertical: 10 },
    paddingHorizontalSmall: { paddingHorizontal: 10 },
    paddingNormal: { padding: 20 },
    paddingTopNormal: { paddingTop: 20 },
    paddingLeftNormal: { paddingLeft: 20 },
    paddingRightNormal: { paddingRight: 20 },
    paddingBottomNormal: { paddingBottom: 20 },
    paddingVerticalNormal: { paddingVertical: 20 },
    paddingHorizontalNormal: { paddingHorizontal: 20 },
    paddingLarge: { padding: 30 },
    paddingTopLarge: { paddingTop: 30 },
    paddingLeftLarge: { paddingLeft: 30 },
    paddingRightLarge: { paddingRight: 30 },
    paddingBottomLarge: { paddingBottom: 30 },
    paddingVerticalLarge: { paddingVertical: 30 },
    paddingHorizontalLarge: { paddingHorizontal: 30 },
});
