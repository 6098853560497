import * as React from 'react';
function SvgAtSymbol(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.694 29.965c-2.513.961-10.181 2.53-14.34-2.255-4.03-4.637-2.281-11.654.736-15.162 2.726-3.17 8.362-4.636 13.3-2.631a9.07 9.07 0 015.589 9.314c-.173 2.673-1 5.555-3.683 5.764-2.683.209-3.119-2.295-3.296-3.509.025-2.138.184-4.272.477-6.39-5.892-1.8-7.972 1.337-8.621 3.258s-.866 4.929 1.56 6.223c2.426 1.294 5.635-.377 6.584-3.091"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgAtSymbol;

