import * as React from 'react';
function SvgHand(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 13v-1a2 2 0 00-4 0v9.523a1 1 0 01-1.371.928l-2.543-1.017a1.773 1.773 0 00-1.913.393v0a1.773 1.773 0 00-.081 2.422l3.218 3.678A9.001 9.001 0 0020.084 31H22a9 9 0 009-9v-8.5a1.5 1.5 0 10-3 0v.5"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 19v-8a2 2 0 114 0v8M24 13v-1a2 2 0 012-2v0a2 2 0 012 2v7"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgHand;

