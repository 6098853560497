import * as React from 'react';
function SvgStorefront(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 14.5V9H9v22h8.25v-6.417h5.5V31H31V14.5z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M14.5 15.416v.917a2.746 2.746 0 01-2.75 2.75A2.746 2.746 0 019 16.333v-.916"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 15.416v.917a2.746 2.746 0 01-2.75 2.75 2.746 2.746 0 01-2.75-2.75v-.916"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M25.5 15.416v.917a2.746 2.746 0 01-2.75 2.75 2.746 2.746 0 01-2.75-2.75v-.916"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M31 15.416v.917a2.746 2.746 0 01-2.75 2.75 2.746 2.746 0 01-2.75-2.75v-.916"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgStorefront;

