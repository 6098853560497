"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityBatchField = void 0;
exports.isEntityBatchField = isEntityBatchField;
var lodash_1 = require("lodash");
var EntityBatchField = /** @class */ (function () {
    function EntityBatchField(id, refs) {
        this.fieldType = 'EntityBatch';
        this.id = id;
        this.refs = refs;
    }
    EntityBatchField.prototype.validate = function () {
        return null;
    };
    EntityBatchField.prototype.isEqual = function (field) {
        return field instanceof EntityBatchField && this.id === field.id;
    };
    EntityBatchField.prototype.toFieldJson = function () {
        return this.id;
    };
    Object.defineProperty(EntityBatchField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.id);
        },
        enumerable: false,
        configurable: true
    });
    return EntityBatchField;
}());
exports.EntityBatchField = EntityBatchField;
function isEntityBatchField(field) {
    return field instanceof EntityBatchField;
}
