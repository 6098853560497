"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetChannelInfo = useGetChannelInfo;
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var lodash_1 = require("lodash");
function useGetChannelInfo(_a) {
    var channelId = _a.channelId, channelSlug = _a.channelSlug;
    var data = (0, client_1.useQuery)(api_1.Queries.channel.getChannel, {
        variables: { id: channelId, slug: channelSlug },
        skip: (0, lodash_1.isNil)(channelId) && (0, lodash_1.isNil)(channelSlug),
    }).data;
    return data === null || data === void 0 ? void 0 : data.channel;
}
