"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonthlyYearlyToggle = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var react_native_1 = require("react-native");
var i18n_1 = require("@shared/util/i18n");
var MonthlyYearlyToggle = function (_a) {
    var interval = _a.interval, onChange = _a.onChange;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var handleChange = (0, react_1.useCallback)(function (enabled) { return onChange(enabled ? 'year' : 'month'); }, [onChange]);
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true },
        react_1.default.createElement(components_1.MBTextBody1Bold, { color: interval === 'month' ? colors.foregroundActive : colors.foregroundInactive }, (0, i18n_1.i18n)('billing.plans.monthlyYearlyToggle.monthly')),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2 },
            react_1.default.createElement(react_native_1.Switch, { value: interval === 'year', 
                // @ts-expect-error
                trackColor: styles_1.Colors.v2.green30, thumbColor: styles_1.Colors.v2.green, activeTrackColor: styles_1.Colors.v2.green30, activeThumbColor: styles_1.Colors.v2.green, onValueChange: handleChange })),
        react_1.default.createElement(components_1.MBTextBody1Bold, { color: interval === 'year' ? colors.foregroundActive : colors.foregroundInactive }, (0, i18n_1.i18n)('billing.plans.monthlyYearlyToggle.yearly')),
        react_1.default.createElement(components_1.MBView, { paddingVertical: 0.25, paddingHorizontal: 1, style: [
                styles_1.SharedStyles.borderRadius,
                { backgroundColor: colors.highlightActive, marginLeft: (0, components_1.grid)(1) },
            ] },
            react_1.default.createElement(components_1.MBTextBody2, { color: colors.foregroundActive }, (0, i18n_1.i18n)('billing.plans.monthlyYearlyToggle.saveTag')))));
};
exports.MonthlyYearlyToggle = MonthlyYearlyToggle;
