"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFieldFileBlock = void 0;
var react_1 = __importStar(require("react"));
var InputBlockMedia_1 = require("./inputFile/InputBlockMedia");
var layoutConfig_1 = require("../contexts/layoutConfig");
var label_1 = require("../utils/label");
var view_1 = require("@shared/components/view");
var render_1 = require("../contexts/render");
var MBFieldFileBlock = function (_a) {
    var value = _a.value, label = _a.label;
    var _b = (0, layoutConfig_1.useLayoutConfig)().staticBlock, displayLabel = _b.displayLabel, paddingLeft = _b.paddingLeft;
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var isCompactFileView = renderContext !== 'detail' && renderContext !== 'form';
    return (react_1.default.createElement(view_1.MBView, { paddingVertical: 0.5 },
        displayLabel && !!label && (react_1.default.createElement(view_1.MBView, { row: true, paddingLeft: paddingLeft, paddingRight: 4, paddingBottom: 0.5 },
            react_1.default.createElement(label_1.MBBlockLabel, { label: label }))),
        react_1.default.createElement(InputBlockMedia_1.InputBlockMedia, { files: value, compactFileView: isCompactFileView, maxHeight: (0, view_1.grid)(8), maxWidth: (0, view_1.grid)(8), verticalPadding: 0, horizontalPadding: 2, numImagesToShow: 3, editable: false })));
};
exports.MBFieldFileBlock = MBFieldFileBlock;
