import * as React from 'react';
function SvgPencilPaper(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 12h-7v17h17v-7"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M26 12l3 3" stroke={props.color} strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M21 23l-4 1 1-4L29 9l3 3-11 11z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPencilPaper;

