"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var view_1 = require("@shared/components/view");
exports.Styles = react_native_1.StyleSheet.create({
    whiteBackground: {
        backgroundColor: 'white',
    },
    selectAvatarImageContainer: __assign({ width: 144, height: 144 }, react_native_1.Platform.select({
        web: {
            maxWidth: '35vw',
            maxHeight: '35vw',
        },
    })),
    selectAvatarImage: __assign({ borderRadius: 164 / 2, overflow: 'hidden' }, react_native_1.Platform.select({
        // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
        web: { WebkitMaskImage: '-webkit-radial-gradient(white, black)' },
        default: {},
    })),
    selectAvatarEditIcon: {
        width: 48,
        height: 48,
        borderRadius: 24,
        padding: 4,
    },
    field: {
        backgroundColor: styles_1.Colors.v2.gray05,
        borderRadius: (0, view_1.grid)(1),
        borderWidth: 0,
        paddingVertical: 15,
        paddingHorizontal: 10,
    },
    fieldError: {
        backgroundColor: styles_1.Colors.v2.actionTropicalRed10,
    },
});
