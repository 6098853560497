"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTooltip = void 0;
var React = __importStar(require("react"));
var ms = __importStar(require("milliseconds"));
var components_1 = require("@shared/components");
var base_1 = require("@shared/components/animation/base");
var styles_1 = require("./styles");
/*
    Tooltip component that can be absolutely positioned to overlay actual content.
    Tooltips can show with the arrow pointing down or up. The arrow can be aligned
    to the left, center or right of the tooltip box.
*/
var MBTooltip = function (_a) {
    var children = _a.children, _b = _a.padding, padding = _b === void 0 ? 'small' : _b, _c = _a.theme, theme = _c === void 0 ? 'white' : _c, _d = _a.verticalArrowAlignment, verticalArrowAlignment = _d === void 0 ? 'below' : _d, _e = _a.horizontalArrowAlignment, horizontalArrowAlignment = _e === void 0 ? 'left' : _e;
    var isBelow = verticalArrowAlignment === 'below';
    // Make the tooltip animate in from below or above
    var translateY = isBelow ? 10 : -10;
    // Flip the order of the content and the tooltip arrow if the arrow should be above
    var flexDirection = isBelow ? 'column' : 'column-reverse';
    return (React.createElement(base_1.MBAnimation, { opacity: { from: 0, to: 1 }, translateY: { from: translateY, to: 0 }, duration: ms.seconds(0.2), style: { flexDirection: flexDirection, paddingHorizontal: 5 } },
        React.createElement(components_1.MBView, { padding: padding, style: [
                styles_1.Styles.container,
                theme === 'white' && styles_1.Styles.themeWhite,
                theme === 'dark' && styles_1.Styles.themeDark,
                theme === 'purple' && styles_1.Styles.themePurple,
                horizontalArrowAlignment === 'left' && { alignSelf: 'flex-start' },
                horizontalArrowAlignment === 'right' && { alignSelf: 'flex-end' },
                horizontalArrowAlignment === 'center' && { alignSelf: 'center' },
            ] }, children)));
};
exports.MBTooltip = MBTooltip;
