"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCanViewCollectionView = void 0;
var react_1 = require("react");
var provider_1 = require("@shared/scenes/billing/banners/provider");
var useCanViewCollectionView = function () {
    var billing = (0, react_1.useContext)(provider_1.BillingContext);
    return billing.isPaidPlan;
};
exports.useCanViewCollectionView = useCanViewCollectionView;
