import * as React from 'react';
function SvgInfoOutline(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.333 33.667c7.364 0 13.334-5.97 13.334-13.334C33.667 12.97 27.697 7 20.333 7 12.97 7 7 12.97 7 20.333c0 7.364 5.97 13.334 13.333 13.334zM20.333 19.121v7.273"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20.334 15.485a1.212 1.212 0 100-2.424 1.212 1.212 0 000 2.424z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgInfoOutline;

