import * as React from 'react';
function SvgTimeWatch(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 28H14a2 2 0 01-2-2V14a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2zM28 18h2v4h-2"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M20 16v4h4M14 9h12M14 31h12"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgTimeWatch;

