import * as React from 'react';
function SvgStar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.952 16.133a.605.605 0 00-.557-.368h-7.38l-3.467-7.416a.605.605 0 00-1.096 0l-3.467 7.416h-7.38a.605.605 0 00-.435 1.025l5.464 5.667-2.197 8.791a.605.605 0 00.887.672L20 27.534l7.676 4.386a.603.603 0 00.666-.043.605.605 0 00.221-.629l-2.197-8.79 5.464-5.668a.605.605 0 00.122-.657z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgStar;

