import * as React from 'react';
function SvgTrophy(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 17h-1a3 3 0 01-3-3V9h4M26 17h1a3 3 0 003-3V9h-4M20 27v-4"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 23a6 6 0 01-6-6V9h12v8a6 6 0 01-6 6zM26 31H14a4 4 0 014-4h4a4 4 0 014 4z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgTrophy;

