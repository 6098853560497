"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSelectChannelImage = void 0;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/upload/hooks");
var UploadActions = __importStar(require("@shared/scenes/upload/store/actions"));
var util_1 = require("@shared/scenes/upload/util");
var analytics_1 = require("@shared/util/analytics");
var image_1 = require("@shared/util/media/image");
var index_1 = require("@shared/util/overlay/index");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var selectChannelImage_1 = require("../components/selectChannelImage");
var styles_1 = require("../components/styles");
var MBSelectChannelImage = function (_a) {
    var emoji = _a.emoji, imageUrl = _a.imageUrl, onRemoveImage = _a.onRemoveImage, onRemoveEmoji = _a.onRemoveEmoji, onChangeEmoji = _a.onChangeEmoji, onChangeImageUrl = _a.onChangeImageUrl, onImageUploadStart = _a.onImageUploadStart, onImageUploadEnd = _a.onImageUploadEnd;
    var client = (0, client_1.useApolloClient)();
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var _b = (0, react_1.useState)(), upload = _b[0], setUpload = _b[1];
    var validateUploads = (0, hooks_1.useValidateUploads)({ showUpgradeModal: true });
    var _c = (0, react_1.useState)(false), nativeEmojiPickerVisible = _c[0], setNativeEmojiPickerVisible = _c[1];
    var cancelledImageUploadIdRef = (0, react_1.useRef)(null); // is ref to not become outdated in the async upload function
    var uploadProgress = (0, react_redux_1.useSelector)(function (state) {
        return !!upload ? state.upload.fileUploadProgressById[upload.id] : undefined;
    });
    var handleUploadImage = (0, react_1.useCallback)(function (image) { return __awaiter(void 0, void 0, void 0, function () {
        var isFileValid, publicUrl, imgixPublicUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, , 3, 4]);
                    onImageUploadStart();
                    setUpload(image);
                    isFileValid = !!validateUploads([image])[0];
                    if (!isFileValid) return [3 /*break*/, 2];
                    return [4 /*yield*/, UploadActions.uploadFile(client, image)];
                case 1:
                    publicUrl = _a.sent();
                    imgixPublicUrl = (0, image_1.resizeImageUri)(publicUrl, styles_1.SELECTED_CHANNEL_IMAGE_SIZE, styles_1.SELECTED_CHANNEL_IMAGE_SIZE, styles_1.SELECTED_CHANNEL_IMAGE_SIZE, styles_1.SELECTED_CHANNEL_IMAGE_SIZE);
                    if (cancelledImageUploadIdRef.current === image.id) {
                        cancelledImageUploadIdRef.current = null;
                        return [2 /*return*/];
                    }
                    onChangeImageUrl(imgixPublicUrl);
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    setUpload(undefined);
                    onImageUploadEnd();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [onImageUploadStart, validateUploads, onChangeImageUrl, onImageUploadEnd]);
    var handleSelectImage = (0, react_1.useCallback)(function () { return util_1.MBUploadUtil.selectImage().then(handleUploadImage); }, [handleUploadImage]);
    var handleCancelUpload = (0, react_1.useCallback)(function () {
        if (!(upload === null || upload === void 0 ? void 0 : upload.id)) {
            return;
        }
        setUpload(undefined);
        cancelledImageUploadIdRef.current = upload.id;
    }, [upload]);
    var handleImageDropped = (0, react_1.useCallback)(function (image) {
        return handleUploadImage({
            id: String(Date.now()),
            url: URL.createObjectURL(image),
            name: image.name,
            contentType: image.type,
            size: image.size,
        });
    }, [handleUploadImage]);
    var handleSelectEmojiWeb = (0, react_1.useCallback)(function (button) {
        return index_1.MBOverlay.show({
            parent: button,
            closeOnBackdropPress: true,
            position: function (_a) {
                var y = _a.top, x = _a.left, height = _a.height;
                return ({
                    top: y + height,
                    left: isDesktop ? x : 0,
                });
            },
            component: function (_a) {
                var close = _a.close;
                return (react_1.default.createElement(components_1.MBEmojiPicker, { isMobileWeb: !isDesktop, onSelect: function (selectedEmoji) {
                        close();
                        onChangeEmoji(selectedEmoji);
                        analytics_1.MBAnalytics.track('Channel::Options::Name::SelectEmoji', {
                            emoji: selectedEmoji,
                            channelSlug: 'new',
                        });
                    } }));
            },
        });
    }, [onChangeEmoji, isDesktop]);
    var handleSelectEmoji = (0, react_1.useCallback)(function (button) {
        if (react_native_1.Platform.OS === 'web') {
            handleSelectEmojiWeb(button);
        }
        else {
            setNativeEmojiPickerVisible(true);
        }
    }, [handleSelectEmojiWeb]);
    var handleHideNativeEmojiPicker = (0, react_1.useCallback)(function () { return setNativeEmojiPickerVisible(false); }, [setNativeEmojiPickerVisible]);
    return (react_1.default.createElement(selectChannelImage_1.MBSelectChannelImage, { imageUrl: !!upload ? upload.url : imageUrl, emoji: emoji, uploadProgress: uploadProgress, nativeEmojiPickerVisible: nativeEmojiPickerVisible, onSelectImage: handleSelectImage, onCancelUpload: handleCancelUpload, onRemoveImage: onRemoveImage, onRemoveEmoji: onRemoveEmoji, onImageDropped: handleImageDropped, onSelectEmoji: onChangeEmoji, onPressEmojiSelector: handleSelectEmoji, onHideNativeEmojiPicker: handleHideNativeEmojiPicker }));
};
exports.MBSelectChannelImage = MBSelectChannelImage;
