"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMessageBubble = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var actionSheet_1 = require("@shared/util/actionSheet");
var alert_1 = require("@shared/util/alert");
var linking_1 = require("@shared/util/linking");
var analytics_1 = require("@shared/util/analytics");
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var messageReactionsAndSeenBy_1 = require("@shared/scenes/chat/thread/containers/messageReactionsAndSeenBy");
var messageInvisibleReactionPicker_1 = require("@shared/scenes/chat/thread/containers/messageInvisibleReactionPicker");
var attachments_1 = require("./components/attachments");
var urgentBadge_1 = require("../urgentBadge");
var sentBySMS_1 = require("./components/sentBySMS");
var content_1 = require("./components/content");
var senderName_1 = require("./components/senderName");
var senderImage_1 = require("./components/senderImage");
var deliveryStatus_1 = require("../deliveryStatus");
var styles_1 = require("./styles");
/**
 * Light wrapper for MBMessageBubble below.
 *
 * Provides sane MBHover functionality.
 */
var RenderWrapper = function (_a) {
    var message = _a.message, channelSlug = _a.channelSlug, layout = _a.layout, theme = _a.theme, deliveryStatus = _a.deliveryStatus, isDesktop = _a.isDesktop, isHidingLinkPreview = _a.isHidingLinkPreview, messageBubble = _a.messageBubble;
    var _b = React.useState(false), isHovered = _b[0], setIsHovered = _b[1];
    var wrapToMakePressableOnMobileAndSelectableOnDesktop = function (content) {
        if (isDesktop) {
            return content;
        }
        return (React.createElement(components_1.MBTouchableOpacity, { activeOpacity: 0.7, children: content, onPress: messageBubble.onPress, onLongPress: messageBubble.onLongPress }));
    };
    return (React.createElement(components_1.MBHover, { onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); } }, wrapToMakePressableOnMobileAndSelectableOnDesktop(React.createElement(React.Fragment, null,
        React.createElement(messageInvisibleReactionPicker_1.MBInvisibleReactionPicker
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        , { 
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ref: function (ref) { return (messageBubble.reactionPicker = ref); }, message: message, channelSlug: channelSlug }),
        React.createElement(components_1.MBView, { paddingTop: 1 },
            messageBubble.isShowingSenderName && (React.createElement(senderName_1.MBSenderName, { name: message.sender.isMe ? undefined : message.sender.fullName, align: theme.align, imagePlaceholder: theme.showImage, date: message.createdAt })),
            React.createElement(components_1.MBView, { style: [
                    theme.align === 'left' && styles_1.Styles.rowAlignLeft,
                    theme.align === 'right' && styles_1.Styles.rowAlignRight,
                ] },
                theme.showImage && React.createElement(senderImage_1.MBSenderImage, { message: message, layout: layout }),
                React.createElement(components_1.MBView, { fill: true },
                    !!message.urgent && React.createElement(urgentBadge_1.MBUrgentBadge, { align: theme.align }),
                    React.createElement(content_1.MBMessageBubbleContent, { onLongPressLinkPreview: messageBubble.onLongPressLinkPreview, theme: theme, message: message, layout: layout, channelSlug: channelSlug, isHidingLinkPreview: isHidingLinkPreview, hovering: isHovered, isDesktop: isDesktop }),
                    React.createElement(attachments_1.MBMessageBubbleAttachments, { message: message, align: theme.align, channelSlug: channelSlug, isSent: messageBubble.isSent, isDesktop: isDesktop, onLongPress: messageBubble.onLongPress, blink: !!theme.blinkBackgroundColor }))),
            React.createElement(components_1.MBView, { row: true },
                theme.showImage && React.createElement(components_1.MBView, { style: styles_1.Styles.userImagePlaceholder }),
                React.createElement(components_1.MBView, { style: [styles_1.Styles.flex, theme.align === 'right' && styles_1.Styles.alignRight] },
                    messageBubble.isSent && React.createElement(sentBySMS_1.MBSentBySMS, { message: message }),
                    React.createElement(messageReactionsAndSeenBy_1.MBMessageReactionsAndSeenBy, { message: message, layout: layout, align: theme.align, isDM: theme.isDM, pickerBottomMargin: styles_1.UnvalidatedStyles.reactionPickerBottomMargin, channelSlug: channelSlug }))),
            !!deliveryStatus && !layout.seenByUsers.length && (React.createElement(deliveryStatus_1.MBMessageDeliveryStatus, { status: deliveryStatus, isEdited: !!message.editedAt, style: styles_1.Styles.deliveryStatus, testID: "chat-message-delivered" })))))));
};
/*
    Message bubble base. Aligns and colors the message bubble depending on theme.
    TODO(fant): The complexity of this is already pretty big. How can we make it
    easier to disentangle?

    This contains:
      * urgent badge
      * message content
      * attachments
      * sent by sms text
      * reactions and seen by

    Note: this component doesn't show delivery status (compared to me.tsx) because
    all messages from other people will (by definition) already be delivered.
*/
var MBMessageBubble = /** @class */ (function (_super) {
    __extends(MBMessageBubble, _super);
    function MBMessageBubble() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { isShowingDetails: false };
        _this.toggleDetails = function () {
            var willShowDetails = !_this.state.isShowingDetails;
            _this.setState({ isShowingDetails: willShowDetails });
            if (!!react_native_1.LayoutAnimation) {
                react_native_1.LayoutAnimation.configureNext(react_native_1.LayoutAnimation.Presets.easeInEaseOut);
            }
            if (willShowDetails) {
                analytics_1.MBAnalytics.track('Message::ShowDetails', { messageId: _this.props.message.id });
            }
        };
        _this.confirmDelete = function () {
            return alert_1.MBAlert.confirm({
                title: 'Delete message',
                message: 'Are you sure you want to delete this message? This action cannot be undone.',
                confirmText: 'Yes, delete',
                confirmStyle: 'destructive',
                onConfirm: _this.props.onDelete,
            });
        };
        _this.onPress = function () {
            if (!!_this.props.onPress) {
                _this.props.onPress();
            }
            else {
                _this.toggleDetails();
            }
        };
        _this.onLongPress = function () {
            return actionSheet_1.MBActionSheet.show({
                parent: _this,
                webHorizontalAlignment: _this.props.theme.align,
                webVerticalAlignment: 'above',
                options: [
                    {
                        title: 'Add a Reaction...',
                        hidden: !_this.isSent,
                        onPress: function () { return _this.reactionPicker.showPicker(); },
                    },
                    {
                        title: 'Copy Text',
                        hidden: !_this.props.message.content,
                        onPress: function () {
                            react_native_1.Clipboard.setString(_this.props.message.content);
                            alert_1.MBAlert.alert({
                                title: 'Copied to clipboard',
                                confirmText: 'OK',
                            });
                        },
                    },
                    {
                        title: 'Edit Message',
                        hidden: !_this.props.message.sender.isMe || !_this.props.message.content.length,
                        onPress: _this.props.onEdit,
                    },
                    {
                        title: 'Delete Message',
                        hidden: !_this.props.message.sender.isMe,
                        destructive: true,
                        onPress: _this.confirmDelete,
                    },
                ],
            });
        };
        _this.onLongPressLinkPreview = function (url) {
            return actionSheet_1.MBActionSheet.show({
                parent: _this,
                webHorizontalAlignment: 'center',
                webVerticalAlignment: 'above',
                options: [
                    {
                        title: 'Visit Link',
                        onPress: function () { return linking_1.MBLinking.open(url); },
                    },
                    {
                        title: 'Add a Reaction...',
                        hidden: !_this.isSent,
                        onPress: function () { return _this.reactionPicker.showPicker(); },
                    },
                    {
                        title: 'Copy Link',
                        hidden: react_native_1.Platform.OS === 'web',
                        onPress: function () {
                            react_native_1.Clipboard.setString(url);
                            alert_1.MBAlert.alert({
                                title: 'Copied link to clipboard',
                                confirmText: 'OK',
                            });
                        },
                    },
                    {
                        title: 'Edit Message',
                        hidden: !_this.props.onEdit,
                        onPress: function () { return _this.props.onEdit(); },
                    },
                    {
                        title: 'Delete Message',
                        hidden: !_this.props.onDelete,
                        destructive: true,
                        onPress: _this.confirmDelete,
                    },
                ],
            });
        };
        return _this;
    }
    Object.defineProperty(MBMessageBubble.prototype, "isSent", {
        get: function () {
            return !this.props.deliveryStatus || this.props.deliveryStatus === 'sent';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MBMessageBubble.prototype, "isShowingSenderName", {
        get: function () {
            if (this.state.isShowingDetails) {
                return true;
            }
            if (this.props.layout.isGroupedWithAbove) {
                return false;
            }
            return this.isSent;
        },
        enumerable: false,
        configurable: true
    });
    MBMessageBubble.prototype.render = function () {
        return React.createElement(RenderWrapper, __assign({}, this.props, { messageBubble: this }));
    };
    return MBMessageBubble;
}(React.PureComponent));
exports.MBMessageBubble = MBMessageBubble;
