import * as React from 'react';
function SvgHeadset(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 31h5a3 3 0 003-3v-3M15 20h-5v5a2 2 0 002 2h3v-7z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 20h-5v7h3a2 2 0 002-2v-5zM30 20v-1c0-5.523-4.477-10-10-10s-10 4.477-10 10v1"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgHeadset;

