// @flow
export function formatE164(rawPhoneNumber: string): string {
  const value = rawPhoneNumber.replace(/[^0-9]+/g, '');
  if (value.length === 10) {
    return `+1${value}`;
  } else {
    return `+${value}`;
  }
}

export function prettifyPhoneNumber(str?: string): string {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (!str) {
    return '';
  }
  const withoutPrefix = str.length === 12 && str.substring(0, 2) === '+1' ? str.substring(2) : str;
  if (phoneRegex.test(withoutPrefix)) {
    return withoutPrefix.replace(phoneRegex, '($1) $2-$3');
  }
  return str;
}

export function isValidPhoneNumber(rawPhoneNumber: string): boolean {
  const phoneReg = new RegExp(/^((\+?[0-9]{11,15})|([0-9]{10}))$/);
  const value = rawPhoneNumber.replace(/[^0-9a-z+]+/gi, '');
  return phoneReg.test(value);
}
