import * as React from 'react';
function SvgScreenshare(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 8v14" stroke={props.color} strokeMiterlimit={10} strokeWidth={2} />
      <path
        d="M16 12l4-4 4 4M25 16h5v12H10V16h5"
        stroke={props.color}
        strokeMiterlimit={10}
        strokeWidth={2}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgScreenshare;

