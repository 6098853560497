import * as React from 'react';
function SvgPlusCircleFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39 25c0 7.732-6.268 14-14 14s-14-6.268-14-14 6.268-14 14-14 14 6.268 14 14z"
        fill={props.color}
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M20 25h10M25 20v10"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgPlusCircleFilled;

