import * as React from 'react';
function SvgStarRate(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 11l2.234 7H29l-5.385 4.125L25.849 29 20 24.751 14.151 29l2.234-6.875L11 18h6.766L20 11z"
        fill={props.color}
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgStarRate;

