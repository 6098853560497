"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIELD_INITIAL_VALUE = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var formik_1 = require("formik");
var item_1 = require("./item");
var i18n_1 = require("@shared/util/i18n");
var styles_1 = require("@shared/styles");
var util_1 = require("../../util");
exports.FIELD_INITIAL_VALUE = {
    label: '',
    name: '',
    fieldType: 'questionSingleSelect',
    type: 'field',
    required: false,
    settings: {},
};
var MBFragmentTemplateFormItems = function (_a) {
    var _b, _c, _d;
    var disabled = _a.disabled, readOnly = _a.readOnly;
    var _e = (0, formik_1.useFormikContext)(), values = _e.values, errors = _e.errors, touched = _e.touched, setFieldTouched = _e.setFieldTouched;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var error = ((_b = touched.template) === null || _b === void 0 ? void 0 : _b.items) && typeof ((_c = errors.template) === null || _c === void 0 ? void 0 : _c.items) === 'string' && ((_d = errors.template) === null || _d === void 0 ? void 0 : _d.items);
    var fieldDescriptor = 'template.items';
    var getNewFieldValue = (0, react_1.useCallback)(function () {
        var _a;
        return __assign(__assign({}, exports.FIELD_INITIAL_VALUE), { name: (0, util_1.generateItemName)((_a = values.template) === null || _a === void 0 ? void 0 : _a.items) });
    }, [values]);
    var plusIconColor = !!error
        ? colors.error.foreground
        : disabled
            ? colors.backgroundInactive
            : colors.accent;
    return (react_1.default.createElement(formik_1.FieldArray, { name: fieldDescriptor }, function (_a) {
        var push = _a.push;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(react_native_1.FlatList, { data: values.template.items, keyExtractor: function (item) { return item.name; }, renderItem: function (_a) {
                    var index = _a.index;
                    return (react_1.default.createElement(item_1.MBFragmentTemplateItem, { index: index, readOnly: readOnly || disabled }));
                } }),
            !readOnly && (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () {
                    setFieldTouched(fieldDescriptor);
                    push(getNewFieldValue());
                } },
                react_1.default.createElement(components_1.MBView, { row: true, paddingHorizontal: 2, style: [
                        styles.addQuestion,
                        !!error && { backgroundColor: colors.error.background },
                    ] },
                    react_1.default.createElement(components_1.MBIconV2, { name: "plus-circle", size: (0, components_1.grid)(4), color: plusIconColor }),
                    react_1.default.createElement(components_1.MBText, { color: colors.foregroundInactive, style: styles.addQuestionLabel }, (0, i18n_1.i18n)('channel.options.fragmentTemplates.addField'))))),
            !!error && (react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 2 },
                react_1.default.createElement(components_1.MBTextBody2Bold, { color: colors.error.foreground }, error)))));
    }));
};
exports.default = MBFragmentTemplateFormItems;
var styles = react_native_1.StyleSheet.create({
    addQuestion: {
        alignItems: 'center',
        width: '100%',
        marginTop: (0, components_1.grid)(2),
    },
    addQuestionLabel: {
        padding: (0, components_1.grid)(1),
    },
});
