/* @flow */
import React from 'react';

import styles from './NavBar.css';

type Props = {|
  children?: React.Element<*>,
  isSlim?: boolean,
  isLarge?: boolean,
  isGray?: boolean,
  isOverlaid?: boolean,
  padding?: 'full' | 'narrow' | 'none',
|};

export default function NavBar({ children, isSlim, isLarge, isGray, isOverlaid, padding = 'none' }: Props) {
  const paddingClass = padding === 'full' ?
    styles.fullPadding :
    padding === 'narrow' ?
      styles.narrowPadding :
      '';

  return (
    <div
      className={
        `${isSlim ? styles.slim : isLarge ? styles.large : styles.standard}` +
        ` ${isGray ? styles.gray : ''}` +
        ` ${isOverlaid ? styles.overlaid : ''}` +
        ` ${paddingClass}`
      }
    >
      {children}
    </div>
  );
}
