"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBText = void 0;
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("./styles");
var MBText = function (_a) {
    var children = _a.children, center = _a.center, align = _a.align, bold = _a.bold, light = _a.light, italic = _a.italic, style = _a.style, numberOfLines = _a.numberOfLines, ellipsizeMode = _a.ellipsizeMode, onPress = _a.onPress, color = _a.color, lineThrough = _a.lineThrough, nativeId = _a.nativeId, _b = _a.size, size = _b === void 0 ? 'normal' : _b, testID = _a.testID;
    return (React.createElement(react_native_1.Text, { onPress: onPress, numberOfLines: numberOfLines, ellipsizeMode: ellipsizeMode, nativeID: nativeId, style: [
            styles_1.Styles.default,
            !!numberOfLines && styles_1.UnvalidatedStyles.numberOfLines(numberOfLines),
            light && styles_1.Styles.light,
            bold && styles_1.Styles.bold,
            italic && styles_1.Styles.italic,
            center && styles_1.Styles.center,
            lineThrough && styles_1.Styles.lineThrough,
            size === 'tiny' && styles_1.Styles.sizeTiny,
            size === 'small' && styles_1.Styles.sizeSmall,
            size === 'normal' && styles_1.Styles.sizeNormal,
            size === 'large' && styles_1.Styles.sizeLarge,
            size === 'xlarge' && styles_1.Styles.sizeXLarge,
            !!color && { color: color },
            !!align && { textAlign: align },
            style,
        ], testID: testID }, children));
};
exports.MBText = MBText;
exports.MBText.displayName = 'MBText';
