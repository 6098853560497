"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearAttachments = exports.removeAttachment = exports.attachFiles = exports.selectGIF = exports.selectAttachment = exports.hideTooltips = exports.showTooltipMentionManager = exports.hideGIFModal = exports.showGIFModal = exports.resetDraft = exports.updateContent = exports.disableUrgent = exports.enableUrgent = exports.CLEAR_ATTACHMENTS = exports.REMOVE_ATTACHMENT = exports.ADD_ATTACHMENTS = exports.UPDATE_URGENT = exports.UPDATE_CONTENT = exports.HIDE_TOOLTIPS = exports.TOGGLE_GIF_MODAL = exports.TOGGLE_TOOLTIP_MENTION_MANAGER = void 0;
var util_1 = require("@shared/scenes/upload/util");
var uuid = __importStar(require("@shared/util/uuid"));
exports.TOGGLE_TOOLTIP_MENTION_MANAGER = 'CHAT:INPUT:TOGGLE_TOOLTIP_MENTION_MANAGER';
exports.TOGGLE_GIF_MODAL = 'CHAT:INPUT:TOGGLE_GIF_MODAL';
exports.HIDE_TOOLTIPS = 'CHAT:INPUT:HIDE_TOOLTIPS';
exports.UPDATE_CONTENT = 'CHAT:INPUT:UPDATE_CONTENT';
exports.UPDATE_URGENT = 'CHAT:INPUT:UPDATE_URGENT';
exports.ADD_ATTACHMENTS = 'CHAT:INPUT:ADD_ATTACHMENTS';
exports.REMOVE_ATTACHMENT = 'CHAT:INPUT:REMOVE_ATTACHMENT';
exports.CLEAR_ATTACHMENTS = 'CHAT:INPUT:CLEAR_ATTACHMENTS';
var createAttachmentFromGIF = function (gif) { return ({
    url: gif.url,
    name: "".concat(gif.id, ".gif"),
    fileKey: uuid.v4(),
    width: gif.width,
    height: gif.height,
    contentType: 'image/gif',
    thumbnails: null,
}); };
var enableUrgent = function (draftId) { return function (dispatch) {
    return dispatch({ type: exports.UPDATE_URGENT, value: { draftId: draftId, urgent: true } });
}; };
exports.enableUrgent = enableUrgent;
var disableUrgent = function (draftId) { return function (dispatch) {
    return dispatch({ type: exports.UPDATE_URGENT, value: { draftId: draftId, urgent: false } });
}; };
exports.disableUrgent = disableUrgent;
var updateContent = function (draftId, content) { return function (dispatch) {
    return dispatch({ type: exports.UPDATE_CONTENT, value: { draftId: draftId, content: content } });
}; };
exports.updateContent = updateContent;
var resetDraft = function (draftId) { return function (dispatch) {
    (0, exports.updateContent)(draftId, undefined)(dispatch);
    (0, exports.disableUrgent)(draftId)(dispatch);
    (0, exports.clearAttachments)(draftId)(dispatch);
}; };
exports.resetDraft = resetDraft;
var showGIFModal = function (draftId) { return function (dispatch) {
    return dispatch({ type: exports.TOGGLE_GIF_MODAL, value: { draftId: draftId, visible: true } });
}; };
exports.showGIFModal = showGIFModal;
var hideGIFModal = function (draftId) { return function (dispatch) {
    return dispatch({ type: exports.TOGGLE_GIF_MODAL, value: { draftId: draftId, visible: false } });
}; };
exports.hideGIFModal = hideGIFModal;
var showTooltipMentionManager = function () { return function (dispatch) {
    return dispatch({ type: exports.TOGGLE_TOOLTIP_MENTION_MANAGER, value: true });
}; };
exports.showTooltipMentionManager = showTooltipMentionManager;
var hideTooltips = function (dispatch) { return dispatch({ type: exports.HIDE_TOOLTIPS }); };
exports.hideTooltips = hideTooltips;
var selectAttachment = function (type, draftId, validateFiles) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var attachments, validAttachments;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, util_1.MBUploadUtil.selectAttachments(type)];
                case 1:
                    attachments = _a.sent();
                    validAttachments = validateFiles(attachments).filter(Boolean);
                    if (validAttachments.length) {
                        dispatch({
                            type: exports.ADD_ATTACHMENTS,
                            value: { draftId: draftId, attachments: validAttachments },
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
exports.selectAttachment = selectAttachment;
var selectGIF = function (gif, draftId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var attachments;
    return __generator(this, function (_a) {
        attachments = [createAttachmentFromGIF(gif)];
        dispatch({
            type: exports.ADD_ATTACHMENTS,
            value: { draftId: draftId, attachments: attachments },
        });
        (0, exports.hideGIFModal)(draftId)(dispatch);
        return [2 /*return*/];
    });
}); }; };
exports.selectGIF = selectGIF;
var attachFiles = function (files, draftId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var attachments;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(files.map(util_1.MBUploadUtil.fileToAttachment))];
            case 1:
                attachments = _a.sent();
                dispatch({
                    type: exports.ADD_ATTACHMENTS,
                    value: { draftId: draftId, attachments: attachments },
                });
                return [2 /*return*/];
        }
    });
}); }; };
exports.attachFiles = attachFiles;
var removeAttachment = function (attachment, draftId) { return function (dispatch) {
    return dispatch({
        type: exports.REMOVE_ATTACHMENT,
        value: { draftId: draftId, attachment: attachment },
    });
}; };
exports.removeAttachment = removeAttachment;
var clearAttachments = function (draftId) { return function (dispatch) {
    return dispatch({ type: exports.CLEAR_ATTACHMENTS, value: draftId });
}; };
exports.clearAttachments = clearAttachments;
