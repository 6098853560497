import * as React from 'react';
function SvgToolsEmptyState(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 87 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={87} height={151.988} rx={11} fill="#281E38" />
      <path fill="#fff" d="M5.241 14.675h76.518v118.446H5.241z" />
      <circle cx={43.5} cy={73.5} r={30.5} fill="#DAF6F0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.503 66.116a4.176 4.176 0 00-5.916 0l-4.968 4.968 1.897 1.897 1.954-1.953 2.12 2.121-1.953 1.954L53.535 77l4.968-4.968a4.176 4.176 0 000-5.916zm-8.987 11.108l4.02 4.019 7.088-7.09a7.176 7.176 0 000-10.159 7.176 7.176 0 00-10.159 0l-7.09 7.09 4.02 4.019L45.497 77l-4.019-4.019-7.09 7.09a7.176 7.176 0 000 10.159c2.808 2.807 7.363 2.812 10.162-.001l.001-.002 7.087-7.086-4.02-4.02 1.898-1.897zm-6.14 1.898l-1.954 1.953 2.121 2.122 1.954-1.954 1.898 1.898-4.968 4.968-.003.003a4.176 4.176 0 01-5.914-.003 4.176 4.176 0 010-5.917l4.968-4.968 1.898 1.898zM35.282 57.553v5.779h5.779v3h-5.78v5.779h-3v-5.78h-5.778v-3h5.779v-5.778h3z"
        fill="#281E38"
      />
    </svg>
  );
}
export default SvgToolsEmptyState;

