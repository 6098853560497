import * as React from 'react';
function SvgTitleT(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 32v-2l2.485-.621A2 2 0 0018 27.439V11h-3.559a2 2 0 00-1.897 1.367L12 14h-2V8h20v6h-2l-.544-1.633A2 2 0 0025.558 11H22v16.438a2 2 0 001.515 1.94L26 30v2H14z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgTitleT;

