"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ignoreErrors = void 0;
exports.ignoreErrors = [
    'Network request failed',
    'Failed to fetch',
    'cannot parse response',
    // https://github.com/WICG/resize-observer/issues/38#issuecomment-493014026
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'Forbidden resource',
    'Invariant Violation: 9', // to init apollo client, must specify cache prop
    'Invariant Violation: 20', // store reset while query in flight
    'Store reset while query was in flight',
    'JSON Parse error: Unrecognized token',
    'Status code failure 403',
    'Requestor is not allowed to access channel',
    'Socket closed with event 4500',
    "Invalid business id header 'x-coast-business-id'",
    'Requestor is not allowed to access business',
];
