"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBNotificationSettingDescription = void 0;
var React = __importStar(require("react"));
var i18n_1 = require("@shared/util/i18n");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
var Description = function (_a) {
    var description = _a.description, message = _a.message, disabled = _a.disabled;
    return (React.createElement(components_1.MBView, { center: true, row: true, paddingHorizontal: "normal", paddingVertical: "small", style: styles_2.Styles.descriptionRow },
        React.createElement(components_1.MBView, { flex: true, paddingRight: "normal" },
            React.createElement(components_1.MBText, { size: "small", color: styles_1.Colors.v2.gray60 }, description)),
        React.createElement(components_1.MBView, { style: [styles_2.Styles.messageContainer, { opacity: disabled ? 0.5 : 1 }] },
            React.createElement(components_1.MBView, { row: true, centerVertically: true, style: styles_2.Styles.messageRow },
                React.createElement(components_1.MBIcon, { style: styles_2.Styles.messageAppIcon, source: require('@shared/assets/icons/v2/logo.png') }),
                React.createElement(components_1.MBText, { size: "small", color: styles_1.Colors.v2.gray50 }, "Coast")),
            React.createElement(components_1.MBSeparator, null),
            React.createElement(components_1.MBView, { centerVertically: true, style: styles_2.Styles.messageRow },
                React.createElement(components_1.MBText, { size: "small", color: styles_1.Colors.v2.gray50 }, message)))));
};
var MBNotificationSettingDescription = function (_a) {
    var setting = _a.setting;
    switch (setting) {
        case 'ALL':
            return (React.createElement(Description, { description: (0, i18n_1.i18n)('userNotifications.setting.options.all.description'), message: (0, i18n_1.i18n)('userNotifications.setting.options.all.previewMessage') }));
        case 'MENTIONS':
            return (React.createElement(Description, { description: (0, i18n_1.i18n)('userNotifications.setting.options.mentions.description'), message: (0, i18n_1.i18n)('userNotifications.setting.options.mentions.previewMessage') }));
        case 'NONE':
            return (React.createElement(Description, { description: (0, i18n_1.i18n)('userNotifications.setting.options.none.description'), message: (0, i18n_1.i18n)('userNotifications.setting.options.none.previewMessage'), disabled: true }));
        default:
            return null;
    }
};
exports.MBNotificationSettingDescription = MBNotificationSettingDescription;
