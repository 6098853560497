import * as React from 'react';
function SvgWrenchTools(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 27l2.95 2.95a3.5 3.5 0 005 0v0a3.5 3.5 0 000-5L28 23"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M17.925 18.225L14.5 14.8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M12.4 15.5l2.1-.7.7-2.1-2.8-2.8-2.8 2.8 2.8 2.8zM30.678 12.922L27.6 15.987l-3.595-3.576 3.08-3.066a4.212 4.212 0 00-2.259-.307 5.615 5.615 0 00-5.133 5.723A4.226 4.226 0 0020 16.4l-9.855 8.683a3.42 3.42 0 00-.205 4.905v0a3.288 3.288 0 004.928-.2l8.726-9.81a6.728 6.728 0 002.875.2 5.686 5.686 0 004.311-3.986 5.083 5.083 0 00-.102-3.27v0z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgWrenchTools;

