"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatBanner = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var config_1 = require("@shared/config");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var hoc_1 = require("@apollo/client/react/hoc");
var api_1 = require("@shared/api");
var banner_1 = require("../components/banner");
var MBChatBannerDataWrapper = function (_a) {
    var data = _a.data, currentUserId = _a.currentUserId, creator = _a.creator;
    return !!data.channel && (React.createElement(banner_1.MBChatBanner, { channel: data.channel, creator: creator, currentUserId: currentUserId }));
};
// Note: copy-pasted from containers/thread.tsx
var mapQueryToProps = (0, hoc_1.graphql)(api_1.Queries.channel.chatThread, {
    options: function (_a) {
        var channelSlug = _a.channelSlug;
        return ({
            // Note(fant): we only fetch directly from cache since
            // Apollo otherwise would make an API request, updating
            // the channel's messages. This would cause the channel
            // to be refetched from the start just after older messages
            // have been fetched.
            fetchPolicy: 'cache-only',
            variables: {
                slug: channelSlug,
                since: null,
                limit: config_1.Config.NUM_CHAT_MESSAGES_TO_FETCH,
            },
        });
    },
});
var mapStateToProps = function (state) { return ({
    currentUserId: state.appState.currentUserId,
}); };
exports.MBChatBanner = (0, redux_1.compose)(mapQueryToProps, (0, react_redux_1.connect)(mapStateToProps, null))(MBChatBannerDataWrapper);
