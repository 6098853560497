import * as React from 'react';
function SvgPeople(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.619 14.133a2.566 2.566 0 10-.002-5.132 2.566 2.566 0 00.002 5.132z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M10 18.185a5.13 5.13 0 017.247 0"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M13.619 26.94a2.566 2.566 0 10-.002-5.133 2.566 2.566 0 00.002 5.132z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M10 31a5.13 5.13 0 017.247 0"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M26.381 14.133a2.566 2.566 0 10-.001-5.132 2.566 2.566 0 00.001 5.132z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M22.753 18.185a5.13 5.13 0 017.247 0"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M26.381 26.94a2.566 2.566 0 10-.001-5.133 2.566 2.566 0 00.001 5.132z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M22.753 31A5.13 5.13 0 0130 31"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgPeople;

