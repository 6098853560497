"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colors = void 0;
exports.Colors = {
    gray: {
        normal: '#777777',
        light: '#F0F0F0',
        touch: '#f8f8f8',
        dark: '#444444',
        border: '#d3d3d3',
    },
    blue: {
        light: '#70ACF2',
    },
    red: {
        primary: '#EF4853',
        delete: '#EB0902',
    },
    green: {
        flashy: '#6FCF97',
        dark: '#18A499',
        light: '#D2EDEB',
    },
    overlay: {
        white10: 'rgba(255, 255, 255, 0.1)',
        white20: 'rgba(255, 255, 255, 0.2)',
        white30: 'rgba(255, 255, 255, 0.3)',
        white70: 'rgba(255, 255, 255, 0.7)',
        white90: 'rgba(255, 255, 255, 0.9)',
        black30: 'rgba(0, 0, 0, 0.3)',
        black50: 'rgba(0, 0, 0, 0.5)',
    },
    v2: {
        green: '#00CAAC',
        green05: '#F0FBF9',
        green08: '#E6FAF7',
        green10: '#DAF6F0',
        green30: '#8DE1CF',
        green30_opaque: 'rgba(141, 225, 207, 0.5)',
        greenSecondary60: '#31727E',
        greenSecondary90: '#0B3A43',
        greenOverlay130: '#31BA9D',
        tertiarySlateGreen: '#ABCBD1',
        tertiaryOceanBlue: '#20C3D3',
        tertiaryCoolBlue: '#8DA7D8',
        tertiaryCoolBlue20: '#E8EDF8',
        tertiaryCoolBlue50: '#C6D3EB',
        tertiaryCoolBlue140: '#487ADF',
        tertiaryDewGreen: '#DBFED2',
        purple: '#281E38',
        purple50: '#34465B',
        purple140: '#9685B1',
        gray05: '#f0f0f0',
        gray10: '#E5E5E5',
        gray30: '#b3b3b3',
        gray50: '#808080',
        gray50_15: 'rgba(128, 128, 128, 0.15)',
        gray60: '#636363',
        grayBackground: '#F7F7F7',
        red: '#FC2562',
        actionFreshGreen: '#5CEFB4',
        actionShellOrange: '#FED387',
        actionWarmRed: '#FF1E5F',
        actionTropicalRed: '#FF5E84',
        actionTropicalRed10: '#FFEFF3',
        actionCoralRed: '#FF7B70',
        avatar: {
            user: [
                '#20C9AC', // regular teal (Colors.v2.green)
                '#20C3D3', // regular ocean
                '#8DA7D8', // regular cool
                '#60E2CC', // light teal
                '#65DDE9', // light ocean
                '#B3CAE5', // light cool
            ],
            business: [
                '#34727D', // secondary green 60
                '#FED387', // action shell orange
                '#FD7C73', // action coral red
                '#8DA7D8', // tertiary cool blue
                '#20C3D3', // tertiary ocean blue
                '#20C9AC', // promary green (Colors.v2.green)
            ],
        },
        black: '#000000',
        white: '#FFFFFF',
        whiteAlpha50: 'rgba(255, 255, 255, 0.5)',
        backgroundGrayLight: '#F7F7F7',
        transparent: 'transparent',
        tagPink: '#FFEFF3',
        tagYellow: '#FFF6E6',
        tagDewGreen: '#ECFFE7',
        tagCoastGreen: '#E6FAF7',
        tagCoolBlue: '#E8EDF8',
        tagPurple: '#EAE7EF',
        tagGray: '#E6E6E6',
        tagArchived: '#F7F7F7',
        tagBorder: {
            tagPink: '#ffbccc',
            tagYellow: '#FED387',
            tagDewGreen: '#9fe18e',
            tagCoastGreen: '#90E0CF',
            tagCoolBlue: '#8DA7D8',
            tagPurple: '#af96d6',
            tagGray: '#b3b3b3',
        },
    },
};
