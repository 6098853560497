import * as React from 'react';
function SvgGear(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 22v-4h-3.26c-.19-.73-.48-1.42-.85-2.06l2.31-2.31-2.83-2.83-2.31 2.31c-.64-.38-1.33-.66-2.06-.85V9h-4v3.26c-.73.19-1.42.48-2.06.85l-2.31-2.31-2.83 2.83 2.31 2.31c-.38.64-.66 1.33-.85 2.06H9v4h3.26c.19.73.48 1.42.85 2.06l-2.31 2.31 2.83 2.83 2.31-2.31c.64.38 1.33.66 2.06.85V31h4v-3.26c.73-.19 1.42-.48 2.06-.85l2.31 2.31 2.83-2.83-2.31-2.31c.38-.64.66-1.33.85-2.06H31z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 23a3 3 0 100-6 3 3 0 000 6z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgGear;

