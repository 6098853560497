"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.UnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
exports.UnvalidatedStyles = {
    banner: { height: 30 },
    bannerTextColor: {
        online: styles_1.Colors.v2.purple,
        null: styles_1.Colors.v2.purple,
        offline: 'white',
    },
};
exports.Styles = react_native_1.StyleSheet.create({
    overflowWrapper: {
        overflow: 'hidden',
    },
    bannerConnected: { backgroundColor: styles_1.Colors.v2.actionFreshGreen },
    bannerConnecting: { backgroundColor: styles_1.Colors.v2.actionShellOrange },
    bannerDisconnected: { backgroundColor: styles_1.Colors.v2.gray50 },
    absolute: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
    icon: {
        width: 12,
        height: 12,
        marginLeft: 6,
    },
});
