"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMenu = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var client_1 = require("@apollo/client");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var useRefetchOnReconnect_1 = require("@shared/util/apollo/useRefetchOnReconnect");
var index_1 = require("../components/index");
var useLogout_1 = require("./useLogout");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var MBMenu = function (_a) {
    var _b;
    var selectedItem = _a.selectedItem, onSwitchBusiness = _a.onSwitchBusiness;
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var _c = (0, client_1.useQuery)(api_1.Queries.user.profile, { variables: { businessId: businessId }, skip: !businessId }), data = _c.data, refetch = _c.refetch;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    (0, useRefetchOnReconnect_1.useRefetchOnReconnect)(refetch);
    var logout = (0, useLogout_1.useLogout)();
    var openDirectory = (0, useNavigator_1.useNavigateTo)('Directory');
    var navigateToLinks = (0, useNavigator_1.useNavigateTo)('BaseToolsTab');
    var navigateToBilling = (0, useNavigator_1.useNavigateTo)('Billing');
    var business = (0, react_1.useMemo)(function () { var _a, _b; return !!((_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.businesses) ? (_b = data === null || data === void 0 ? void 0 : data.me) === null || _b === void 0 ? void 0 : _b.businesses.find(function (b) { return b.id === businessId; }) : undefined; }, [(_b = data === null || data === void 0 ? void 0 : data.me) === null || _b === void 0 ? void 0 : _b.businesses, businessId]);
    if (!(data === null || data === void 0 ? void 0 : data.me)) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, padding: "large" });
    }
    return (react_1.default.createElement(index_1.MBMenu, { selectedItem: selectedItem, user: data.me, business: business, isBusinessAdmin: data.me.isBusinessAdmin, isBusinessSwitcherEnabled: !isDesktop, onLogOut: logout, onLinks: navigateToLinks, onBilling: navigateToBilling, onDirectory: openDirectory, onSwitchBusiness: onSwitchBusiness }));
};
exports.MBMenu = MBMenu;
