"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelectionType = void 0;
var CustomSelectionType;
(function (CustomSelectionType) {
    CustomSelectionType[CustomSelectionType["DAILY"] = 0] = "DAILY";
    CustomSelectionType[CustomSelectionType["WEEKLY"] = 1] = "WEEKLY";
    CustomSelectionType[CustomSelectionType["MONTHLY"] = 2] = "MONTHLY";
    CustomSelectionType[CustomSelectionType["YEARLY"] = 3] = "YEARLY";
})(CustomSelectionType || (exports.CustomSelectionType = CustomSelectionType = {}));
