import * as React from 'react';
function SvgCalendar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
        d="M9 9.88h22V31H9z"
      />
      <path
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="square"
        d="M26.5 8v3.76M13.667 8v3.76"
      />
      <path stroke={props.color} strokeWidth={2} d="M9 16.56h22" />
    </svg>
  );
}
export default SvgCalendar;

