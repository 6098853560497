"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardHistorySectionItem = CardHistorySectionItem;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var lodash_1 = require("lodash");
var i18n_1 = require("@shared/util/i18n");
function CardHistorySectionItem(_a) {
    var entry = _a.entry;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var separator = entry.isMultiline === true ? '\n↓\n' : ' → ';
    return (react_1.default.createElement(react_native_1.View, { style: {
            paddingHorizontal: (0, components_1.grid)(4),
            paddingVertical: (0, components_1.grid)(1),
        } },
        react_1.default.createElement(react_native_1.View, { style: {
                paddingVertical: (0, components_1.grid)(1),
            } },
            react_1.default.createElement(components_1.MBTextBody1Bold, null, entry.componentName)),
        react_1.default.createElement(react_native_1.View, { style: {
                paddingVertical: (0, components_1.grid)(1),
            } },
            react_1.default.createElement(components_1.MBTextBody1, { style: { color: colors.foregroundInactive } },
                entry.oldValue,
                react_1.default.createElement(components_1.MBTextBody1, null,
                    !(0, lodash_1.isNil)(entry.oldValue) && separator,
                    !(0, lodash_1.isNil)(entry.newValue) && entry.newValue,
                    (0, lodash_1.isNil)(entry.newValue) && (0, i18n_1.i18n)('card.history.deletedItem'))))));
}
