"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBPlusButton = void 0;
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var i18n_1 = require("@shared/util/i18n");
var overlay_1 = require("@shared/util/overlay");
var analytics_1 = require("@shared/util/analytics");
var actionSheet_1 = require("@shared/util/actionSheet");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var actionRow_1 = require("./actionRow");
var MBPlusButton = /** @class */ (function (_super) {
    __extends(MBPlusButton, _super);
    function MBPlusButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { showingActions: false };
        _this.onSendAsUrgent = function () {
            _this.hideActions();
            // Note(fant): on web the urgent info modal will be
            // auto closed if this happens sync. If we show it
            // in the next tick, react-modal won't close immediately
            setTimeout(_this.props.onSendAsUrgent);
            analytics_1.MBAnalytics.track('Chat::Input::PlusButton::SendAsUrgent', { draftId: _this.props.draftId });
        };
        _this.onRemoveSendAsUrgent = function () {
            _this.hideActions();
            _this.props.onRemoveSendAsUrgent();
            analytics_1.MBAnalytics.track('Chat::Input::PlusButton::RemoveSendAsUrgent', {
                draftId: _this.props.draftId,
            });
        };
        _this.onShowGIFModal = function () {
            _this.props.onShowGIFModal();
            _this.hideActions();
            analytics_1.MBAnalytics.track('Chat::Input::PlusButton::ShowGIFModal', { draftId: _this.props.draftId });
        };
        _this.onSelectAttachment = function () {
            _this.props.onSelectAttachment('all');
            _this.hideActions();
            analytics_1.MBAnalytics.track('Chat::Input::PlusButton::SelectAttachment', { draftId: _this.props.draftId });
        };
        _this.onNativeCamera = function () {
            _this.props.onSelectAttachment('camera');
            _this.hideActions();
            analytics_1.MBAnalytics.track('Chat::Input::PlusButton::OpenCamera', { draftId: _this.props.draftId });
        };
        _this.onNativePhotoLibrary = function () {
            _this.props.onSelectAttachment('photolibrary');
            _this.hideActions();
            analytics_1.MBAnalytics.track('Chat::Input::PlusButton::OpenPhotoLibrary', { draftId: _this.props.draftId });
        };
        _this.hideActions = function () {
            overlay_1.MBOverlay.hide();
            _this.setState({ showingActions: false });
        };
        _this.showActionsWeb = function () {
            _this.setState({ showingActions: true });
            overlay_1.MBOverlay.show({
                parent: _this,
                closeOnBackdropPress: true,
                onClose: _this.hideActions,
                position: function (_a) {
                    var bottom = _a.bottom, height = _a.height, left = _a.left;
                    return ({
                        left: left,
                        bottom: window.innerHeight - bottom + height - window.scrollY * 2,
                        position: 'absolute',
                    });
                },
                component: function () { return (React.createElement(components_1.MBTooltip, { padding: "none", verticalArrowAlignment: "below" },
                    React.createElement(actionRow_1.MBPlusButtonActionRow, { title: (0, i18n_1.i18n)('chat.input.plusButton.addImageOrFile'), icon: require('@shared/assets/icons/v2/imagestack.png'), onPress: _this.onSelectAttachment }),
                    React.createElement(actionRow_1.MBPlusButtonActionRow, { title: (0, i18n_1.i18n)('chat.input.plusButton.gifSearch'), icon: require('@shared/assets/icons/v2/gif.png'), onPress: _this.onShowGIFModal }),
                    !_this.props.hideUrgent && !_this.props.urgent && (React.createElement(actionRow_1.MBPlusButtonActionRow, { title: (0, i18n_1.i18n)('chat.input.plusButton.sendAsUrgent'), icon: require('@shared/assets/icons/v2/clock.png'), onPress: _this.onSendAsUrgent })),
                    !_this.props.hideUrgent && !!_this.props.urgent && (React.createElement(actionRow_1.MBPlusButtonActionRow, { title: (0, i18n_1.i18n)('chat.input.plusButton.removeSendAsUrgent'), icon: require('@shared/assets/icons/v2/clock.png'), onPress: _this.onRemoveSendAsUrgent, color: styles_1.Colors.v2.red })))); },
            });
        };
        _this.showActionsNative = function () {
            return actionSheet_1.MBActionSheet.show({
                parent: _this,
                options: [
                    {
                        title: (0, i18n_1.i18n)('chat.input.plusButton.camera'),
                        onPress: _this.onNativeCamera,
                    },
                    {
                        title: (0, i18n_1.i18n)('chat.input.plusButton.photoLibrary'),
                        onPress: _this.onNativePhotoLibrary,
                    },
                    {
                        title: (0, i18n_1.i18n)('chat.input.plusButton.gifSearch'),
                        onPress: _this.onShowGIFModal,
                    },
                    {
                        title: (0, i18n_1.i18n)('chat.input.plusButton.sendAsUrgent'),
                        hidden: _this.props.urgent || _this.props.hideUrgent,
                        onPress: _this.onSendAsUrgent,
                    },
                    {
                        title: (0, i18n_1.i18n)('chat.input.plusButton.removeSendAsUrgent'),
                        hidden: !_this.props.urgent || _this.props.hideUrgent,
                        onPress: _this.onRemoveSendAsUrgent,
                    },
                ],
            });
        };
        _this.showActions = function () {
            if (react_native_1.Platform.OS === 'web') {
                _this.showActionsWeb();
            }
            else {
                _this.showActionsNative();
            }
        };
        _this.toggleActions = function () {
            if (_this.state.showingActions) {
                _this.hideActions();
            }
            else {
                _this.showActions();
                analytics_1.MBAnalytics.track('Chat::Input::PlusButton::Show', { draftId: _this.props.draftId });
            }
        };
        return _this;
    }
    MBPlusButton.prototype.render = function () {
        /*
                TODO(fant): wrap the icon button in an MBHover
                and show a tooltip on hover. Current bug:
                hover, press the button => modal shows,
                hide modal => hoverContent shows (although
                not hovering)
    
                <MBHover hoverContent={!this.state.showingActions && (
                    <MBView style={Styles.plusButtonTooltipPosition}>
                        <MBTextTooltip
                            theme="dark" color="white" textSize="small" numberOfLines={1}
                            text={i18n('chat.input.plusButton.moreActions')}
                        />
                    </MBView>
                )}>
            */
        return (React.createElement(components_1.MBTouchableIcon, { onPress: this.toggleActions, color: styles_1.Colors.v2.purple, noPadding: true, source: this.state.showingActions
                ? require('@shared/assets/icons/v2/plus-circle-filled.png')
                : require('@shared/assets/icons/v2/plus-circle.png') }));
    };
    return MBPlusButton;
}(React.PureComponent));
exports.MBPlusButton = MBPlusButton;
