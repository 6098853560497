import * as React from 'react';
function SvgTimeRepeat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19 13v7a1 1 0 001 1h7v-2h-6v-6h-2z" fill={props.color} />
      <path
        d="M20 8A12.013 12.013 0 008 20h2a10.016 10.016 0 114 8l1.8-2.4-7.2-.4L11 32l1.8-2.406A12 12 0 1020 8z"
        fill={props.color}
      />
    </svg>
  );
}
export default SvgTimeRepeat;

