import * as React from 'react';
function SvgAvatarLocation(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 22c-2.824 0-5.329.638-6.975 1.193A2.99 2.99 0 009 26.032V30h8"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M18 22c-2.761 0-5-3.239-5-6v-1a5 5 0 0110 0v1c0 2.761-2.239 6-5 6zM31 24.817C31 27.183 27 31 27 31s-4-3.817-4-6.183C23 22.374 25.08 21 27 21s4 1.374 4 3.817z"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M27.5 25a.5.5 0 11-1 0 .5.5 0 011 0z" stroke={props.color} />
    </svg>
  );
}
export default SvgAvatarLocation;

