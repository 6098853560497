"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBView = exports.grid = exports.GRID_UNIT = void 0;
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var config_1 = require("@shared/config");
var styles_2 = require("./styles");
exports.GRID_UNIT = 8;
var grid = function (units) { return units * exports.GRID_UNIT; };
exports.grid = grid;
// Note(fant): the reason we've done MBView as a component and not an SFC is because
// we really want to avoid providing arrow functions as props since that would cause
// the components and children to re-render every time some prop is changed. This
// is really important for performance reasons. In other parts of the shared components
// we're a little sloppy with this, which is not great. But for these most fundamental
// components it's crucial.
var MBView = /** @class */ (function (_super) {
    __extends(MBView, _super);
    function MBView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onSize = function (event) {
            return !!_this.props.onSize &&
                _this.props.onSize({
                    width: event.nativeEvent.layout.width,
                    height: event.nativeEvent.layout.height,
                });
        };
        return _this;
    }
    MBView.prototype.render = function () {
        var props = this.props;
        return (React.createElement(react_native_1.View, __assign({ testID: props.testID, nativeID: props.nativeID, style: [
                // Default the flex (otherwise it's weird on web)
                // Reset zIndex, otherwise it defaults to 0, which
                // creates trouble. See eg MBDatePicker
                { flex: null },
                // react-native-web gives all views z-index 0 as default.
                // This causes issues when trying to overlay content in nested
                // views. Resetting to no z-index makes the HTML renderer
                // figure out the view hierarchy better.
                react_native_1.Platform.OS === 'web' && { zIndex: 'unset' },
                props.row === true && styles_2.Styles.row,
                props.row === 'reverse' && styles_2.Styles.rowReverse,
                props.wrap && styles_2.Styles.wrap,
                props.fill && styles_1.SharedStyles.fill,
                props.center && styles_1.SharedStyles.centerChildren,
                props.absolute && styles_1.SharedStyles.absoluteCover,
                props.fullWidth && { width: '100%' },
                props.fullHeight && { height: '100%' },
                // if flex is a bool: Number(true) => 1
                // if flex is a number: Number(val) => val
                !!props.flex && { flex: Number(props.flex) },
                !props.row && props.centerVertically && { justifyContent: 'center' },
                !props.row && props.centerHorizontally && { alignItems: 'center' },
                props.row && props.centerVertically && { alignItems: 'center' },
                props.row && props.centerHorizontally && { justifyContent: 'center' },
                typeof props.padding === 'number' && { padding: props.padding * exports.GRID_UNIT },
                typeof props.paddingTop === 'number' && { paddingTop: props.paddingTop * exports.GRID_UNIT },
                typeof props.paddingLeft === 'number' && { paddingLeft: props.paddingLeft * exports.GRID_UNIT },
                typeof props.paddingRight === 'number' && {
                    paddingRight: props.paddingRight * exports.GRID_UNIT,
                },
                typeof props.paddingBottom === 'number' && {
                    paddingBottom: props.paddingBottom * exports.GRID_UNIT,
                },
                typeof props.paddingVertical === 'number' && {
                    paddingTop: props.paddingVertical * exports.GRID_UNIT,
                    paddingBottom: props.paddingVertical * exports.GRID_UNIT,
                },
                typeof props.paddingHorizontal === 'number' && {
                    paddingLeft: props.paddingHorizontal * exports.GRID_UNIT,
                    paddingRight: props.paddingHorizontal * exports.GRID_UNIT,
                },
                // TODO(fant): this is somewhat ugly
                props.padding === 'none' && styles_2.Styles.paddingNone,
                props.paddingTop === 'none' && styles_2.Styles.paddingTopNone,
                props.paddingLeft === 'none' && styles_2.Styles.paddingLeftNone,
                props.paddingRight === 'none' && styles_2.Styles.paddingRightNone,
                props.paddingBottom === 'none' && styles_2.Styles.paddingBottomNone,
                props.paddingVertical === 'none' && styles_2.Styles.paddingVerticalNone,
                props.paddingHorizontal === 'none' && styles_2.Styles.paddingHorizontalNone,
                props.padding === 'tiny' && styles_2.Styles.paddingTiny,
                props.paddingTop === 'tiny' && styles_2.Styles.paddingTopTiny,
                props.paddingLeft === 'tiny' && styles_2.Styles.paddingLeftTiny,
                props.paddingRight === 'tiny' && styles_2.Styles.paddingRightTiny,
                props.paddingBottom === 'tiny' && styles_2.Styles.paddingBottomTiny,
                props.paddingVertical === 'tiny' && styles_2.Styles.paddingVerticalTiny,
                props.paddingHorizontal === 'tiny' && styles_2.Styles.paddingHorizontalTiny,
                props.padding === 'small' && styles_2.Styles.paddingSmall,
                props.paddingTop === 'small' && styles_2.Styles.paddingTopSmall,
                props.paddingLeft === 'small' && styles_2.Styles.paddingLeftSmall,
                props.paddingRight === 'small' && styles_2.Styles.paddingRightSmall,
                props.paddingBottom === 'small' && styles_2.Styles.paddingBottomSmall,
                props.paddingVertical === 'small' && styles_2.Styles.paddingVerticalSmall,
                props.paddingHorizontal === 'small' && styles_2.Styles.paddingHorizontalSmall,
                props.padding === 'normal' && styles_2.Styles.paddingNormal,
                props.paddingTop === 'normal' && styles_2.Styles.paddingTopNormal,
                props.paddingLeft === 'normal' && styles_2.Styles.paddingLeftNormal,
                props.paddingRight === 'normal' && styles_2.Styles.paddingRightNormal,
                props.paddingBottom === 'normal' && styles_2.Styles.paddingBottomNormal,
                props.paddingVertical === 'normal' && styles_2.Styles.paddingVerticalNormal,
                props.paddingHorizontal === 'normal' && styles_2.Styles.paddingHorizontalNormal,
                props.padding === 'large' && styles_2.Styles.paddingLarge,
                props.paddingTop === 'large' && styles_2.Styles.paddingTopLarge,
                props.paddingLeft === 'large' && styles_2.Styles.paddingLeftLarge,
                props.paddingRight === 'large' && styles_2.Styles.paddingRightLarge,
                props.paddingBottom === 'large' && styles_2.Styles.paddingBottomLarge,
                props.paddingVertical === 'large' && styles_2.Styles.paddingVerticalLarge,
                props.paddingHorizontal === 'large' && styles_2.Styles.paddingHorizontalLarge,
                config_1.Config.DEBUG_LAYOUT && styles_1.SharedStyles.dashedBorder,
                props.style,
            ], onLayout: !!props.onSize ? this.onSize : undefined, pointerEvents: props.pointerEvents }, { className: props.class }), props.children));
    };
    return MBView;
}(React.PureComponent));
exports.MBView = MBView;
