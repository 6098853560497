"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAnimation = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var AnimationUtil = __importStar(require("./util"));
var config_1 = require("@shared/config");
var react_native_1 = require("react-native");
/*
    This component wraps the Animated API to make it simple to
    animate different types of props. All animations (eg opacity,
    rotate, scale) can simply be written as props and this
    component takes care of the rest.
*/
var MBAnimation = /** @class */ (function (_super) {
    __extends(MBAnimation, _super);
    function MBAnimation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animation = new react_native_1.Animated.Value(0);
        _this.interpolate = function (_a) {
            var from = _a.from, to = _a.to;
            return _this.animation.interpolate({
                inputRange: [0, 1],
                outputRange: [from, to],
            });
        };
        return _this;
    }
    // We want to use native driver on native when all props we're
    // animating are suppported.
    MBAnimation.prototype.useNativeDriver = function () {
        // TODO(fant): stories hack. See more in .storybook/config/withPlatform
        // where Platform.select is hackily overridden.
        var isAvailable = react_native_1.Platform.select({ web: false, stories: false, default: true });
        return isAvailable && !this.props.width && !this.props.height && !this.props.backgroundColor;
    };
    MBAnimation.prototype.componentDidMount = function () {
        if (!this.props.disabled) {
            this.animate({ toValue: 1 });
        }
    };
    MBAnimation.prototype.componentWillUnmount = function () {
        if (!!this.loopingAnimation) {
            this.loopingAnimation.stop();
        }
    };
    // TODO(fant): replace with getDerivedStateFromProps
    MBAnimation.prototype.UNSAFE_componentWillUpdate = function (nextProps) {
        if (this.props.disabled !== nextProps.disabled) {
            this.animate({ toValue: nextProps.disabled ? 0 : 1 });
        }
    };
    MBAnimation.prototype.animate = function (_a) {
        var _this = this;
        var toValue = _a.toValue;
        // If we disable animations (eg because of Storybook screenshots),
        // make the animation appear in its end state right away.
        if (config_1.Config.DISABLE_ANIMATIONS) {
            this.animation.setValue(toValue);
            return;
        }
        var interpolation;
        if (this.props.type === 'spring') {
            interpolation = react_native_1.Animated.spring(this.animation, {
                toValue: toValue,
                useNativeDriver: this.useNativeDriver(),
            });
        }
        else {
            interpolation = react_native_1.Animated.timing(this.animation, {
                toValue: toValue,
                easing: this.props.easing,
                duration: this.props.duration,
                useNativeDriver: this.useNativeDriver(),
            });
        }
        if (this.props.loop) {
            var count_1 = 0;
            this.loopingAnimation = AnimationUtil.loop(interpolation, 
            // reset animation
            function () {
                _this.animation.setValue(0);
                // Print a warning message if we seem to be looping a lot on web
                count_1 += 1;
                if (config_1.Config.WARN_LOOPING_ANIMATIONS && react_native_1.Platform.OS === 'web' && count_1 > 4) {
                    console.warn('Performance warning: looping animations on ' +
                        'web continuously is a performance drain!', count_1, Object.keys(_this.props));
                }
            });
            this.loopingAnimation.start();
        }
        else {
            react_native_1.Animated.sequence([react_native_1.Animated.delay(this.props.delay || 0), interpolation]).start();
        }
    };
    MBAnimation.prototype.render = function () {
        var _a = this.props, duration = _a.duration, style = _a.style, opacity = _a.opacity, scale = _a.scale, scaleX = _a.scaleX, scaleY = _a.scaleY, rotate = _a.rotate, width = _a.width, height = _a.height, translateX = _a.translateX, translateY = _a.translateY, easing = _a.easing, backgroundColor = _a.backgroundColor, otherProps = __rest(_a, ["duration", "style", "opacity", "scale", "scaleX", "scaleY", "rotate", "width", "height", "translateX", "translateY", "easing", "backgroundColor"]);
        if (!!scale && (!!translateX || !!translateY)) {
            // For reference: https://stackoverflow.com/a/41833704/9117995
            throw new Error('Cannot translate and scale at the same time');
        }
        return (React.createElement(react_native_1.Animated.View, __assign({}, otherProps, { style: [
                style,
                !!width && { width: this.interpolate(width) },
                !!height && { height: this.interpolate(height) },
                !!opacity && { opacity: this.interpolate(opacity) },
                !!backgroundColor && { backgroundColor: this.interpolate(backgroundColor) },
                !!scale && { transform: [{ scale: this.interpolate(scale) }] },
                !!scaleX && { transform: [{ scaleX: this.interpolate(scaleX) }] },
                !!scaleY && { transform: [{ scaleY: this.interpolate(scaleY) }] },
                !!rotate && { transform: [{ rotate: this.interpolate(rotate) }] },
                !!translateX && { transform: [{ translateX: this.interpolate(translateX) }] },
                !!translateY && { transform: [{ translateY: this.interpolate(translateY) }] },
            ] })));
    };
    return MBAnimation;
}(React.PureComponent));
exports.MBAnimation = MBAnimation;
