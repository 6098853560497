"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBOnboardingSelectWorkflows = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var index_1 = require("@shared/scenes/auth/contentWrapper/index");
var component_1 = require("./component");
var theme_1 = require("./theme");
var blurredDesktopBackground_1 = require("../blurredDesktopBackground");
var CreateOnboardingWorkflowHooks_1 = require("./hooks/CreateOnboardingWorkflowHooks");
var AddingWorkflowLoadingScreen_1 = require("./AddingWorkflowLoadingScreen");
var MBOnboardingSelectWorkflows = function () {
    var isDesktop = (0, index_1.useIsDesktop)();
    var _a = (0, CreateOnboardingWorkflowHooks_1.useCreateOnboardingWorkflowHooks)(), isInitialLoading = _a.isInitialLoading, isAddingWorkflowLoading = _a.isAddingWorkflowLoading, workflowListItems = _a.workflowListItems, selectedWorkflowName = _a.selectedWorkflowName, onCreateWorkflows = _a.onCreateWorkflows;
    var componentToShow = (0, react_1.useMemo)(function () {
        if (isInitialLoading) {
            return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
        }
        if (isAddingWorkflowLoading) {
            return react_1.default.createElement(AddingWorkflowLoadingScreen_1.AddingWorkflowLoadingScreen, { workspaceName: selectedWorkflowName });
        }
        return (react_1.default.createElement(component_1.MBOnboardingSelectWorkflows, { isDesktop: isDesktop, workflowListItems: workflowListItems, onNext: onCreateWorkflows }));
    }, [
        isAddingWorkflowLoading,
        isDesktop,
        isInitialLoading,
        onCreateWorkflows,
        selectedWorkflowName,
        workflowListItems,
    ]);
    return (react_1.default.createElement(index_1.MBAuthContentWrapper, { logo: false, theme: theme_1.theme, padding: false, background: react_1.default.createElement(blurredDesktopBackground_1.MBOnboardingBlurredDesktopBackground, null) }, componentToShow));
};
exports.MBOnboardingSelectWorkflows = MBOnboardingSelectWorkflows;
