"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTagsBlock = exports.getTagOption = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var field_1 = require("@shared/components/blocks/lib/field");
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var render_1 = require("@shared/components/blocks/contexts/render");
var tagList_1 = require("@shared/components/blocks/lib/tags/tagList");
var utils_1 = require("@shared/components/blocks/utils");
var lodash_1 = require("lodash");
/**
 * Options must be derived using
 * const { options } = useTagOptions(fieldName);
 * or some other method of passing tag options
 * filtered only for a specific fieldName/componentId.
 */
var getTagOption = function (value, options) {
    if (!(0, lodash_1.isEmpty)(options) && !options.every(function (o) { return o.fieldName === options[0].fieldName; })) {
        throw new Error('All options must have the same fieldName');
    }
    return options.find(function (o) { return o.slug === value; });
};
exports.getTagOption = getTagOption;
var MBTagsBlock = function (_a) {
    var block = _a.block, onPress = _a.onPress, renderLeft = _a.renderLeft;
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var values = (0, react_1.useContext)(form_1.CardFormContext).values;
    var fieldBlock = (0, react_1.useMemo)(function () { return (__assign(__assign({}, block), { name: null, type: 'field', fieldType: 'text' })); }, [block]);
    var empty = (0, react_1.useMemo)(function () { return !block.names.some(function (name) { var _a; return !!((_a = values[name]) === null || _a === void 0 ? void 0 : _a.length); }); }, [block, values]);
    var isCalendarOrAgenda = ['calendar', 'agenda'].includes(renderContext);
    var tagListWrapperStyle = [
        { paddingVertical: (0, components_1.grid)(0.5) },
        !isCalendarOrAgenda && { flexWrap: 'wrap' },
    ];
    return (react_1.default.createElement(field_1.MBFieldBlock, { value: null, block: fieldBlock, onPress: onPress, renderLeft: renderLeft, presenter: function (_value, _fieldType, _textStyle, color) {
            if (empty) {
                var TypoComponent = (0, utils_1.getTypoComponent)(block.textStyle);
                return (react_1.default.createElement(TypoComponent, { color: colors.foregroundInactive }, block.placeholder));
            }
            return (react_1.default.createElement(components_1.MBView, { row: true, wrap: true, style: tagListWrapperStyle }, block.names.map(function (fieldName) { return (react_1.default.createElement(tagList_1.TagList, { key: fieldName, fieldName: fieldName, block: block, tagValues: values[fieldName], textColor: color })); })));
        } }));
};
exports.MBTagsBlock = MBTagsBlock;
