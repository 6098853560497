import * as React from 'react';
function SvgWaves(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 28a6.985 6.985 0 005.5-2.679 6.984 6.984 0 0011 0A6.984 6.984 0 0031 28M9 21a6.985 6.985 0 005.5-2.679 6.984 6.984 0 0011 0A6.984 6.984 0 0031 21M9 14a6.985 6.985 0 005.5-2.679 6.986 6.986 0 0011 0A6.984 6.984 0 0031 14"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgWaves;

