"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBNotifyBar = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var React = __importStar(require("react"));
var styles_2 = require("./styles");
var MBNotifyBar = function (_a) {
    var users = _a.users, onHide = _a.onHide, onPress = _a.onPress;
    var textNumUsers = "".concat(users.length, " ").concat(users.length === 1 ? 'user' : 'users');
    var textSeeWho = 'See who';
    return (React.createElement(components_1.MBTouchableOpacity, { onPress: onPress, style: styles_2.Styles.container },
        React.createElement(components_1.MBView, { row: true, fill: true, centerVertically: true, padding: "small" },
            React.createElement(components_1.MBIcon, { style: styles_2.Styles.icon, source: require('@shared/assets/icons/timer.png') }),
            React.createElement(components_1.MBView, { flex: true, paddingHorizontal: "small" },
                React.createElement(components_1.MBFormattedText, { color: styles_1.Colors.v2.purple, highlightColor: styles_1.Colors.v2.purple, tokens: [
                        {
                            type: null,
                            value: textNumUsers,
                            href: null,
                            highlight: true,
                        },
                        {
                            type: null,
                            value: textSeeWho,
                            href: null,
                            highlight: true,
                        },
                    ] }, "".concat(textNumUsers, " may not see your message immediately. ").concat(textSeeWho))),
            React.createElement(components_1.MBTouchableIcon, { size: "small", color: styles_1.Colors.gray.normal, onPress: onHide, source: require('@shared/assets/icons/cross.png') }))));
};
exports.MBNotifyBar = MBNotifyBar;
