"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelChatMobile = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var containers_1 = require("@shared/scenes/chat/actionButtons/containers");
var channel_1 = require("@shared/scenes/chat/input/containers/input/channel");
var channel_2 = require("@shared/scenes/chat/thread/containers/thread/channel");
var tooltipOverlay_1 = require("@shared/scenes/chat/input/containers/tooltipOverlay");
var channelWithSearch_1 = require("@shared/scenes/chat/thread/containers/thread/channelWithSearch");
var hooks_1 = require("@shared/scenes/channel/chat/components/hooks");
var bottomDrawer_1 = require("@shared/components/bottomDrawer");
var component_1 = require("@shared/scenes/billing/banners/component");
var MBChannelChatMobile = function (_a) {
    var channelSlug = _a.channelSlug, isFocused = _a.isFocused, autoFocus = _a.autoFocus, selectedMessage = _a.selectedMessage, onSend = _a.onSend;
    var _b = (0, react_1.useState)(null), scrollRef = _b[0], setScrollRef = _b[1];
    var handleSend = (0, hooks_1.useHandleSend)(scrollRef, onSend);
    // If we want to jump to a specific message, we
    // want to use another chat thread container
    var Component = !!selectedMessage ? channelWithSearch_1.MBChannelWithSearchChatThread : channel_2.MBChannelChatThread;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { fill: true },
            react_1.default.createElement(Component, { key: channelSlug, slug: channelSlug, selectedMessage: selectedMessage, isFocused: isFocused, scrollRef: setScrollRef, nativeOnBeginScroll: null }),
            react_1.default.createElement(tooltipOverlay_1.MBChatInputTooltipOverlay, null),
            react_1.default.createElement(component_1.MBBillingLimitBanners, null)),
        react_1.default.createElement(bottomDrawer_1.MBBottomDrawer, { discoveryKey: "channelChat", anchor: react_1.default.createElement(components_1.MBView, { paddingBottom: 1 },
                react_1.default.createElement(channel_1.MBChannelChatInput, { channelSlug: channelSlug, autoFocus: autoFocus, onSend: handleSend })) },
            react_1.default.createElement(containers_1.MBChatActionButtons, { channelSlug: channelSlug, visible: true }))));
};
exports.MBChannelChatMobile = MBChannelChatMobile;
