"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchOptions = void 0;
var react_1 = __importStar(require("react"));
var use_layout_animation_1 = require("use-layout-animation");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var i18n_1 = require("@shared/util/i18n");
var label_1 = require("../../utils/label");
var utils_1 = require("../input/utils");
var styles_1 = require("@shared/styles");
var BatchOptions = function (_a) {
    var enabled = _a.enabled, name = _a.name, onToggle = _a.onToggle, onChangeName = _a.onChangeName;
    (0, use_layout_animation_1.useImmediateLayoutAnimation)([enabled]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true },
            react_1.default.createElement(components_1.MBView, { flex: true, paddingRight: 4 },
                react_1.default.createElement(label_1.MBBlockLabel, { label: (0, i18n_1.i18n)('card.blocks.assigneeInput.batchOptions.enable.title') }),
                react_1.default.createElement(typography_1.MBTextBody2, { color: colors.foregroundActive }, (0, i18n_1.i18n)('card.blocks.assigneeInput.batchOptions.enable.description'))),
            (0, utils_1.renderToggle)(enabled, onToggle)),
        enabled && (react_1.default.createElement(components_1.MBView, { paddingTop: 2 },
            react_1.default.createElement(label_1.MBBlockLabel, { label: (0, i18n_1.i18n)('card.blocks.assigneeInput.batchOptions.name.title') }),
            react_1.default.createElement(components_1.MBAutosizeTextInput, { style: { backgroundColor: colors.backgroundInactive, borderRadius: (0, components_1.grid)(1) }, onChangeText: onChangeName, value: name, placeholder: (0, i18n_1.i18n)('card.blocks.assigneeInput.batchOptions.name.placeholder'), autoFocus: true, padding: "normal" })))));
};
exports.BatchOptions = BatchOptions;
