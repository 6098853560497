"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRGB = hexToRGB;
exports.RGBToHSL = RGBToHSL;
exports.hexToHSL = hexToHSL;
exports.adjustHexSaturationAndLightness = adjustHexSaturationAndLightness;
function hexToRGB(h) {
    var r = 0;
    var g = 0;
    var b = 0;
    // 3 digits
    if (h.length === 4) {
        r = '0x' + h[1] + h[1];
        g = '0x' + h[2] + h[2];
        b = '0x' + h[3] + h[3];
        // 6 digits
    }
    else if (h.length === 7) {
        r = '0x' + h[1] + h[2];
        g = '0x' + h[3] + h[4];
        b = '0x' + h[5] + h[6];
    }
    return [+r, +g, +b];
}
function RGBToHSL(red, green, blue) {
    // Make r, g, and b fractions of 1
    var r = red / 255;
    var g = green / 255;
    var b = blue / 255;
    // Find greatest and smallest channel values
    var cmin = Math.min(r, g, b), cmax = Math.max(r, g, b), delta = cmax - cmin, h = 0, s = 0, l = 0;
    // Calculate hue
    // No difference
    if (delta === 0) {
        h = 0;
    }
    // Red is max
    else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    }
    // Green is max
    else if (cmax === g) {
        h = (b - r) / delta + 2;
    }
    // Blue is max
    else {
        h = (r - g) / delta + 4;
    }
    h = Math.round(h * 60);
    // Make negative hues positive behind 360°
    if (h < 0) {
        h += 360;
    }
    // Calculate lightness
    l = (cmax + cmin) / 2;
    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    return [h, s, l];
}
function hexToHSL(h) {
    var _a = hexToRGB(h), r = _a[0], g = _a[1], b = _a[2];
    return RGBToHSL(r, g, b);
}
function adjustHexSaturationAndLightness(hex, satPct, lightPct) {
    if (satPct === void 0) { satPct = 0; }
    if (lightPct === void 0) { lightPct = 0; }
    var _a = hexToHSL(hex), h = _a[0], s = _a[1], l = _a[2];
    var newSat = Math.min(100, Math.max(0, s + satPct));
    var newLight = Math.min(100, Math.max(0, l + lightPct));
    return "hsl(".concat(h, ", ").concat(newSat, "%, ").concat(newLight, "%)");
}
