"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectivityNotifier = void 0;
var ConnectivityNotifier = /** @class */ (function () {
    function ConnectivityNotifier() {
        this.events = {};
    }
    ConnectivityNotifier.prototype.emit = function (event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        (this.events[event] || []).forEach(function (i) { return i.apply(void 0, args); });
    };
    ConnectivityNotifier.prototype.offline = function (reason) {
        this.emit('offline', reason);
    };
    ConnectivityNotifier.prototype.online = function (reason) {
        this.emit('online', reason);
    };
    ConnectivityNotifier.prototype.addEventListener = function (event, cb) {
        (this.events[event] = this.events[event] || []).push(cb);
    };
    ConnectivityNotifier.prototype.removeEventListener = function (event, cb) {
        this.events[event] = (this.events[event] || []).filter(function (i) { return i !== cb; });
    };
    return ConnectivityNotifier;
}());
exports.ConnectivityNotifier = ConnectivityNotifier;
