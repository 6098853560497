/* @flow */

import uploadFileFactory from '@common/core/uploadFileFactory';

import { logError } from '@src/core/ErrorLogger';

import type { DraftAttachment } from '@src/types/ChatTypes';

const doUploadFile = uploadFileFactory('', logError);

export default function uploadFile(file: File): Promise<?DraftAttachment> {
  return doUploadFile(file)
    .then((policy) => {
      const finishedAttachment: DraftAttachment = {
        fileKey: policy.fileKey,
        publicUrl: policy.publicUrl,
        originalFilename: file.name,
        isGIF: false,
      };
      return finishedAttachment;
    }).catch((err) => {
      alert('We were unable to upload your file.'); // eslint-disable-line no-alert
      return Promise.reject(err);
    });
}
