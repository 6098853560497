"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBActionSheet = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var styles_1 = require("@shared/styles");
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var overlay_1 = require("@shared/util/overlay");
///////////////////////////////// COMPONENTS /////////////////////////////////
/*
    A list item for MBActionSheet options
*/
var MBActionSheetOptionListRow = function (_a) {
    var option = _a.option, onPress = _a.onPress;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: onPress },
        react_1.default.createElement(components_1.MBHover, { hoverStyle: { backgroundColor: colors.backgroundInactive } },
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: "normal", paddingVertical: "small" },
                react_1.default.createElement(typography_1.MBTextBody1, { color: option.destructive ? colors.error.foreground : colors.foregroundActive }, option.title)))));
};
var MBActionSheetTooltip = function (_a) {
    var parent = _a.parent, title = _a.title, options = _a.options, close = _a.close, tooltipProps = __rest(_a, ["parent", "title", "options", "close"]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var filteredOptions = options.filter(function (option) { return !option.hidden; });
    if (!filteredOptions) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBTooltip, __assign({ padding: "none" }, tooltipProps),
        !!title && (react_1.default.createElement(components_1.MBView, null,
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: "normal", paddingVertical: "small" },
                react_1.default.createElement(typography_1.MBTextBody1Bold, { color: colors.foregroundActive }, title)))),
        !!title && !!filteredOptions.length && react_1.default.createElement(components_1.MBSeparator, null),
        filteredOptions.map(function (option, index) { return (react_1.default.createElement(MBActionSheetOptionListRow, { key: index, option: option, onPress: function (ev) {
                var _a;
                close();
                (_a = option.onPress) === null || _a === void 0 ? void 0 : _a.call(option, parent);
                // If not calling preventDefault, the DOM element
                // under the clicked option will also be clicked.
                ev.preventDefault();
            } })); })));
};
///////////////////////////////// HELPER FUNCTIONS /////////////////////////////////
/*
    Positions the tooltip relative tot the parent rect depending
    on tooltip arrow alignment.
*/
function position(parentRect, verticalArrowAlignment, horizontalArrowAlignment) {
    var style = { position: 'absolute', maxWidth: 250 };
    if (verticalArrowAlignment === 'above') {
        style.bottom = window.innerHeight - parentRect.top;
    }
    else {
        style.top = parentRect.top + parentRect.height;
    }
    if (horizontalArrowAlignment === 'left') {
        style.left = parentRect.left;
    }
    else if (horizontalArrowAlignment === 'right') {
        style.right = window.innerWidth - parentRect.right;
    }
    else {
        // TODO(fant): same positioning as left
        style.left = parentRect.left;
    }
    return style;
}
///////////////////////////////// IMPLEMENTATION /////////////////////////////////
/*
    This is the equivalent of the native action sheet. The current way of doing it
    is somewhat hacky, but it creates a simple interface to use MBActionSheet.

    What we do is attaching our custom React component to a new virtual React DOM
    in another HTML element (#shared-components-tooltip). This is then absolutely
    positioned below the parent element to make it look like they're connected.
    This is how react-overlays works (which is what the current web Tooltip
    component uses). react-modal works in a similar way (which MBModal is built on.
    This is how it's possible to overlay all other content and for child views to
    appear somewhere unrelated to the parent's position.

    Caveats: if the content/window sizing changes, the action sheet options won't
    change with it.
*/
exports.MBActionSheet = {
    show: function (_a) {
        var title = _a.title, parent = _a.parent, options = _a.options, _b = _a.webHorizontalAlignment, webHorizontalAlignment = _b === void 0 ? 'left' : _b, _c = _a.webVerticalAlignment, webVerticalAlignment = _c === void 0 ? 'below' : _c, _d = _a.webPosition, webPosition = _d === void 0 ? function (parentRect) { return position(parentRect, webVerticalAlignment, webHorizontalAlignment); } : _d, onCancel = _a.onCancel, onClose = _a.onClose;
        overlay_1.MBOverlay.show({
            parent: parent,
            position: webPosition,
            closeOnBackdropPress: true,
            onBackdropPress: function () {
                onCancel === null || onCancel === void 0 ? void 0 : onCancel();
                onClose === null || onClose === void 0 ? void 0 : onClose();
            },
            // TODO(fant): figure out how to make this HOC situation nicer
            component: function (_a) {
                var close = _a.close;
                return (react_1.default.createElement(MBActionSheetTooltip, { parent: parent, title: title, options: options, close: function () {
                        close();
                        onClose === null || onClose === void 0 ? void 0 : onClose();
                    }, verticalArrowAlignment: webVerticalAlignment, horizontalArrowAlignment: webHorizontalAlignment }));
            },
        });
    },
};
